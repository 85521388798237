import { Icons } from 'assets/icons';
import { useStyle, useTranslation } from "hooks";
import { useState } from "react";
import { Pressable, ScrollView, TextInput, View, useWindowDimensions } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from "styles";
import SText from "./SText";

interface props {
    handleSearchQuery?: (e: any) => void,
    handleNavItemSelect?: (e: any) => void,
    navItems?: any[],
    noSearch?: boolean
}

export default ({ handleSearchQuery, navItems = [], handleNavItemSelect, noSearch }: props) => {

    const { width } = useWindowDimensions();
    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const searchString = useTranslation()("#search");
    const [selectedNav, setSelectedNav] = useState(0);
    const changeNavItem = (item, index) => {
        setSelectedNav(index);
        handleNavItemSelect && handleNavItemSelect(item);
    }

    return (
        <View style={[scopedStyles('bottomNav'), styles('bigPaddingHorizontal')]} >

            {navItems && navItems.length > 0 &&
                <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    style={scopedStyles('navContainer')}
                    contentContainerStyle={{ flexDirection: 'row', overflow: 'visible' }}
                >
                    {navItems.map((item, index) => {
                        return (
                            <Pressable
                                disabled={navItems.length == 1 || item.amount == 0}
                                key={index}
                                onPress={() => changeNavItem(item, index)}
                                style={{
                                    height: '100%',
                                    alignItems: 'center',
                                    marginRight: width > 768 ? 44 : 40,
                                    borderColor: colors.border,
                                    borderBottomWidth: index == selectedNav ? 1 : 0,
                                    flexDirection: 'row'
                                }}
                            >

                                <SText
                                    white
                                    greyTransparent={index !== selectedNav}
                                    sm
                                    medium
                                    style={[
                                        item.amount == 0 && { color: colors.darkgrey }
                                    ]}
                                >
                                    {item.title}
                                </SText>

                                {item.amount > 0 &&
                                    <View style={scopedStyles('badge')}>
                                        <SText white regular style={{ fontSize: 10 }}>
                                            {item.amount}
                                        </SText>
                                    </View>
                                }

                            </Pressable>
                        )
                    })}
                </ScrollView>
            }

            {!noSearch &&
                <View style={[
                    scopedStyles('searchBox'),
                    width < 768 && navItems.length == 0 && { marginBottom: 8 }
                ]}>
                    <TextInput
                        placeholder={searchString}
                        placeholderTextColor={colors.lightgrey}
                        onChangeText={handleSearchQuery && handleSearchQuery}
                        spellCheck={false}
                        style={[
                            styles('text-medium'),
                            styles('text-sm'),
                            styles('border-radius-sm'),
                            scopedStyles('searchInput'),
                            {
                                backgroundColor: colors.whiteTransparent,
                                color: colors.lightgrey,
                            },
                        ]}
                    />
                    <View style={scopedStyles('searchIcon')}>
                        <Icons.Search size={18} color={colors.lightgrey} />
                    </View>
                </View>
            }

        </View>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        bottomNav: {
            width: '100%',
            height: 76,
            zIndex: 10,
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: 'center',
            backgroundColor: colors.primaryDarkest
        },
        navContainer: {
            flexDirection: 'row',
            height: '100%'
        },
        searchBox: {
            height: '100%',
            width: 350,
            justifyContent: "center",
        },
        searchInput: {
            width: '100%',
            height: 35,
            paddingVertical: 8,
            paddingLeft: 8,
            paddingRight: 30,
            borderWidth: 1,
            borderColor: '#0000'
        },
        searchIcon: {
            position: 'absolute',
            right: 8,
            flex: 1,
            justifyContent: 'center'
        },
        badge: {
            position: 'absolute',
            left: '97%',
            marginTop: -10,
            marginLeft: 6,
            paddingHorizontal: 4,
            backgroundColor: 'rgba(255,255,255,0.2)',
            textAlign: 'center',
            borderRadius: 12
        }
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            bottomNav: {
                height: 'auto',
                paddingTop: 8,
                paddingBottom: 0,
                flexDirection: 'column-reverse',
                justifyContent: 'flex-start',
                alignItems: 'flex-end'
            },
            navContainer: {
                width: '100%',
                height: 45
            },
            searchBox: {
                width: 250,
                height: 40
            }
        }
    }
);