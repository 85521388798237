import { useNavigation } from '@react-navigation/native';
import { Button, SText } from 'components';
import { DamageCreateModal } from 'components/Modals';
import PolicyPie from 'components/PolicyPie';
import { QueryKeys } from 'enums';
import { ModalContext } from 'providers/ModalProvider';
import { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';
import { useQuery } from 'react-query';
import { colors } from 'styles';
import { QueryUtils } from 'utils';

//This screen show an overview of the customers policies values

export default ({ style }) => {

    const { showModal } = useContext(ModalContext)
    const navigation = useNavigation();
    const { data: policies, isLoading } = useQuery(QueryKeys.POLICY, QueryUtils.fetchPolicy);
    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const [policyAmount, setPolicyAmount] = useState(0);

    useEffect(() => {
        if (!isLoading) {

            let amount = 0;
            policies && policies.forEach(policy => {
                if (!policy.is_package) amount++
            })

            setPolicyAmount(amount);
        }
    }, [isLoading])

    const noData = () => policyAmount == 0;

    return (
        <Pressable
            style={style}
            disabled={tenant?.show_policies === false}
            onPress={() => navigation.navigate('verzekeringen')}
        >


            <View style={{ height: 45 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <SText md semibold white>#title_policy</SText>
                        {policyAmount > 0 && <SText sm semibold greyTransparent style={{ marginLeft: 8 }}>{policyAmount}</SText>}
                    </View>
                    <SText greyTransparent sm medium>#more</SText>
                </View>
            </View>

            {(noData() || tenant?.show_policies === false) ? (
                <View style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    {isLoading ? (
                        <>
                            <ActivityIndicator color={colors.greyTransparent} />
                            <SText greyTransparent sm regular center style={{ marginTop: 12 }}>#loading_policy_data</SText>
                        </>
                    ) : (
                        <SText greyTransparent sm regular center style={{ marginTop: -20 }}>#empty_policyOverview</SText>
                    )}
                </View>
            ) : (
                <View style={{ flex: 1 }}>
                    <View style={scopedStyles.itemContainer}>
                        <PolicyPie />
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                        <Button
                            label={'#action_damage'}
                            type='action'
                            onPress={() => { showModal(DamageCreateModal, {}, 'DamageCreateModal') }}
                        />
                    </View>
                </View>
            )}

        </Pressable>
    )
}

const scopedStyles = StyleSheet.create({
    badge: {
        marginLeft: 8,
        height: 28,
        width: 34,
        borderRadius: 28,
        backgroundColor: colors.whiteTransparent
    },
    itemContainer: {
        width: '100%',
        flex: 1,
        justifyContent: 'center'
    },
    item: {
        width: '50%'
    }
})
