import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<G>
		<G>
			<rect fill={color} x="269.6"  />
			<rect fill={color}  />
			<rect fill={color} y="323.5"  />
			<rect fill={color} x="269.6" y="215.6"  />
		</G>
	</G>
</G>
</Svg>
                    );
                }