import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { LayoutStylesExtraLarge, LayoutStylesLarge, LayoutStylesMedium, LayoutStylesSmall } from "./LayoutStyles";
import { TextStylesExtraLarge, TextStylesLarge, TextStylesMedium, TextStylesSmall } from "./textStyles";
export { default as colors } from './colors'


export default CreateResponsiveStyle(
    {
        ...LayoutStylesExtraLarge,
        ...TextStylesExtraLarge,

    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            ...TextStylesLarge,
            ...LayoutStylesLarge
        },
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {

            ...LayoutStylesMedium,
            ...TextStylesMedium

        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {

            ...LayoutStylesSmall,
            ...TextStylesSmall
        }
    }
);
