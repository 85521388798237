import { StyleSheet } from 'react-native';
import colors from './colors';

export const LayoutStylesExtraLarge: StyleSheet.NamedStyles<any> = {
    center: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    fullscreen: {
        width: '100%',
        height: '100%',
        flex: 1
    },
    container: {
        flex: 0.9,
        marginLeft: '5%'
    },
    rowContainer: {
        flexDirection: 'row'
    },
    line: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    highlight: {
        flex: 1,
        backgroundColor: colors.background,
        padding: 5,
        paddingVertical: 8,
        borderRadius: 8
    },
    'container-vertical': {
        marginTop: '1%',
        height: '96%'
    },
    boxShadow: {
        shadowColor: colors.black,
        shadowOpacity: 0.1,
        shadowRadius: 12,
        elevation: 12,
        shadowOffset: { width: 10, height: 10 },
    },


    'border-radius-lg': {
        borderRadius: 8
    },
    'border-radius-md': {
        borderRadius: 8
    },
    'border-radius-sm': {
        borderRadius: 4
    },
    'border-radius-xs': {
        borderRadius: 3
    },
    underline: {
        textDecorationLine: 'underline'
    },
    input: {
        paddingLeft: 32,
        marginVertical: 12,
        width: '100%'
    },
    title: {
        marginBottom: 12
    },
    hover: {
        shadowColor: colors.black,
        shadowOffset: { height: 2, width: 1 },
        shadowOpacity: 0.4
    },


    bigPaddingVertical: {
        paddingVertical: 50
    },
    bigPaddingHorizontal: {
        paddingHorizontal: 50
    },
    bigPaddingLeft: {
        paddingLeft: 50
    },
    bigPaddingRight: {
        paddingRight: 50
    },
    bigPaddingTop: {
        paddingTop: 50
    },
    bigPaddingBottom: {
        paddingBottom: 50
    },

    bigMarginVertical: {
        marginVertical: 50
    },
    bigMarginHorizontal: {
        marginHorizontal: 50
    },
    bigMarginLeft: {
        marginLeft: 50
    },
    bigMarginRight: {
        marginRight: 50
    },
    bigMarginTop: {
        marginTop: 50
    },
    bigMarginBottom: {
        marginBottom: 50
    }
}

export const LayoutStylesLarge: StyleSheet.NamedStyles<any> = {

    bigPaddingVertical: {
        paddingVertical: 38
    },
    bigPaddingHorizontal: {
        paddingHorizontal: 38
    },
    bigPaddingLeft: {
        paddingLeft: 38
    },
    bigPaddingRight: {
        paddingRight: 38
    },
    bigPaddingTop: {
        paddingTop: 38
    },
    bigPaddingBottom: {
        paddingBottom: 38
    },

    bigMarginVertical: {
        marginVertical: 38
    },
    bigMarginHorizontal: {
        marginHorizontal: 38
    },
    bigMarginLeft: {
        marginLeft: 38
    },
    bigMarginRight: {
        marginRight: 38
    },
    bigMarginTop: {
        marginTop: 38
    },
    bigMarginBottom: {
        marginBottom: 38
    }

}

export const LayoutStylesMedium: StyleSheet.NamedStyles<any> = {
    container: {
        flex: 0.95,
        marginLeft: '2.5%'
    },
    'container-vertical': {
        marginVertical: '1%',
        height: '98%'
    },

    bigPaddingVertical: {
        paddingVertical: 28
    },
    bigPaddingHorizontal: {
        paddingHorizontal: 28
    },
    bigPaddingLeft: {
        paddingLeft: 28
    },
    bigPaddingRight: {
        paddingRight: 28
    },
    bigPaddingTop: {
        paddingTop: 28
    },
    bigPaddingBottom: {
        paddingBottom: 28
    },

    bigMarginVertical: {
        marginVertical: 28
    },
    bigMarginHorizontal: {
        marginHorizontal: 28
    },
    bigMarginLeft: {
        marginLeft: 28
    },
    bigMarginRight: {
        marginRight: 28
    },
    bigMarginTop: {
        marginTop: 28
    },
    bigMarginBottom: {
        marginBottom: 28
    }
}

export const LayoutStylesSmall: StyleSheet.NamedStyles<any> = {
    container: {
        flex: 0.912,
        marginLeft: '1%'
    },

    bigPaddingVertical: {
        paddingVertical: 22
    },
    bigPaddingHorizontal: {
        paddingHorizontal: 22
    },
    bigPaddingLeft: {
        paddingLeft: 22
    },
    bigPaddingRight: {
        paddingRight: 22
    },
    bigPaddingTop: {
        paddingTop: 22
    },
    bigPaddingBottom: {
        paddingBottom: 22
    },

    bigMarginVertical: {
        marginVertical: 22
    },
    bigMarginHorizontal: {
        marginHorizontal: 22
    },
    bigMarginLeft: {
        marginLeft: 22
    },
    bigMarginRight: {
        marginRight: 22
    },
    bigMarginTop: {
        marginTop: 22
    },
    bigMarginBottom: {
        marginBottom: 22
    }
}
