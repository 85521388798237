import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} class="st0" d="M-0.5,242.5c0,9.6,3.7,18.7,10.6,25.6l135.5,135.5c6.8,6.8,15.9,10.6,25.6,10.6s18.8-3.7,25.7-10.6
	c14.1-14.1,14.1-37.1,0-51.2l-73.7-73.7H449c19.9,0,36.2-16.3,36.2-36.2s-16.3-36.2-36.2-36.2H123.3l73.7-73.7
	c14.1-14.1,14.1-37.1,0-51.2c-6.9-6.8-16-10.6-25.7-10.6c-9.6,0-18.7,3.7-25.6,10.6L10.1,217C3.3,223.8-0.5,232.9-0.5,242.5z"/>
</Svg>
                    );
                }