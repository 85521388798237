import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M41.4,243.1c0-46.1-0.1-92.2,0-138.4C41.7,44,85.2,0.5,146.1,0.2c37-0.1,74-0.7,111,0.5c8.6,0.3,19.1,4.5,25.2,10.4
	c51.5,50.4,102.5,101.3,152.9,152.8c5.7,5.8,9.7,15.9,9.8,24c0.8,65.3,0.8,130.7,0.4,196c-0.4,56.4-44.4,100.6-100.8,101
	c-67.7,0.5-135.5,0.5-203.2-0.1c-55.6-0.5-99.2-44.6-99.7-100.4C41.2,337.3,41.5,290.2,41.4,243.1C41.5,243.1,41.5,243.1,41.4,243.1
	z M243.4,40.7c-33.8,0-65.9-0.2-98,0c-37.9,0.3-63.3,25.5-63.4,63.4c-0.2,92.2-0.2,184.5,0,276.7c0.1,38,25.7,63.4,63.4,63.4
	c64.4,0.1,128.8,0.1,193.1,0.1c41.7-0.1,66.3-24.7,66.4-66.3c0.1-53.8,0-107.6,0-161.4c0-4.6-0.4-9.2-0.6-14.5
	c-20.9,0-40.1,0.2-59.3,0c-57.4-0.7-100.9-44.2-101.5-101.6C243.3,81.4,243.4,62.2,243.4,40.7z M368.9,161.4
	c-27.9-28.2-56.8-57.5-84.8-85.7C276.5,145.6,316.1,168.2,368.9,161.4z"/>
<Path fill={color} d="M244.9,262.8c25,0,49.9-0.1,74.9,0c15.3,0.1,24.5,7.8,24.6,20.1c0,12.3-9.2,20.2-24.5,20.2c-50.9,0.1-101.7,0.2-152.6,0
	c-15.4,0-24.6-7.9-24.6-20.1c0-12.3,9.1-20.1,24.5-20.2C193,262.7,218.9,262.8,244.9,262.8z"/>
<Path fill={color} d="M243.3,384c-25.4,0-50.9,0.1-76.3,0c-15.3-0.1-24.5-8-24.4-20.3c0.1-12.3,9.1-20,24.7-20c50.9-0.1,101.8-0.1,152.7,0
	c15.2,0,24.5,8.1,24.5,20.3c-0.1,12.2-9.5,20-24.7,20.1C294.2,384.1,268.8,384,243.3,384C243.3,384,243.3,384,243.3,384z"/>
<Path fill={color} d="M182,222.4c-6.7,0-13.5,0.5-20.1-0.1c-11.6-1-18.7-7.8-19.2-19.4c-0.4-11.7,6.4-19.5,17.9-20.4c14.8-1.1,29.7-1.2,44.5,0
	c11.4,0.9,18.2,8.8,17.8,20.6c-0.4,11-7.2,17.8-18,19.1c-7.6,0.9-15.3,0.2-23,0.2C182,222.3,182,222.3,182,222.4z"/>
</Svg>
                    );
                }