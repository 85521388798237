import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M55.7,484.409V1.473h165.845c59.918,0,106.689,11.113,140.302,33.335c33.606,22.22,50.414,55.336,50.414,99.34
		c0,23-5.412,43.443-16.25,61.364c-10.837,17.914-27.53,31.403-50.088,40.465c28.305,6.41,49.199,19.29,62.69,38.642
		c13.483,19.35,20.235,41.63,20.235,66.834c0,47.101-15.813,82.703-47.427,106.801c-31.628,24.107-76.952,36.155-135.999,36.155
		H55.7z M168.143,202.807h55.723c25.428-0.441,44.448-5.362,57.054-14.763c12.604-9.396,18.907-23.159,18.907-41.292
		c0-20.567-6.415-35.544-19.234-44.945c-12.832-9.396-32.511-14.097-59.045-14.097h-53.405V202.807L168.143,202.807z
		 M168.143,277.108v121.064h77.28c23.884,0,41.797-4.702,53.737-14.104c11.938-9.396,17.914-23.819,17.914-43.278
		c0-21.009-5.144-36.872-15.428-47.6c-10.279-10.722-26.92-16.083-49.914-16.083H168.143z"/>
</G>
</Svg>
                    );
                }