import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M380.9,165.7c-12.3-1-24.8-0.5-37.2-0.6c-1.5,0-3,0-4.7,0c0-11.4,0.1-22.1,0-32.8c-0.3-26.6-20.5-46.6-46.9-46.6
	c-33,0-65.9-0.1-98.9,0.1c-5.1,0-10.5,0.8-15.4,2.4c-19.1,6.3-31.2,23.5-31.5,43.8c-0.1,10.8,0,21.7,0,33.1c-2,0-3.4,0-4.8,0
	c-10.6,0-21.2-0.1-31.8,0c-20.1,0.2-34.4,14.4-34.4,34.4c-0.1,55.2-0.1,110.4,0,165.6c0,20.3,14.4,34.4,34.8,34.4
	c88.4,0,176.8,0,265.3,0c3.4,0,6.8-0.4,10.1-1.2c15.2-4,24.6-17,24.6-34c0-54.5,0-109.1,0-163.6c0-1.2,0-2.4-0.1-3.5
	C409.2,180.8,397.1,167.1,380.9,165.7z M154.5,374.4c-8.3,0-16.2,0-24.5,0c0-61.3,0-122.5,0-183.9c8.2,0,16.2,0,24.5,0
	C154.5,251.7,154.5,312.9,154.5,374.4z M292.8,289.9c-0.1,5.9-3.4,9.1-9.3,9.2c-7.8,0.1-15.7,0-24.2,0c0,7.9,0,15.3,0,22.7
	c0,8.1-2.8,10.8-10.9,10.8c-4.2,0-8.4,0.1-12.6,0c-6.8-0.1-9.8-3.1-9.9-9.8c-0.1-7.7,0-15.4,0-23.7c-6.4,0-12.4,0-18.5,0
	c-13.8,0-15-1.2-15-14.9c0-3,0-6,0-9c0.1-6.2,3.2-9.5,9.4-9.6c7.1-0.1,14.1,0,21.2,0c0.8,0,1.5-0.1,3-0.2c0-1.7,0-3.3,0-4.9
	c0-5.9,0-11.8,0-17.7c0-7.9,2.7-10.5,10.7-10.5c4.1,0,8.1,0,12.2,0c7.7,0,10.4,2.7,10.5,10.3c0,6.3,0,12.6,0,18.8
	c0,1.3,0.1,2.5,0.2,4.2c7.5,0,14.6,0,21.8,0c8.8,0,11.6,2.7,11.6,11.4C292.8,281.3,292.9,285.6,292.8,289.9z M313.3,164.8
	c-47,0-94.2,0-141.5,0c0-12.4-0.6-24.6,0.2-36.6c0.7-9.9,9.7-17.3,19.6-17.4c34-0.1,68-0.1,101.9,0c9.8,0,18.9,7.4,19.5,17
	C314,140.1,313.3,152.3,313.3,164.8z M355.4,374.4c-8.3,0-16.2,0-24.5,0c0-61.3,0-122.5,0-183.9c8.2,0,16.2,0,24.5,0
	C355.4,251.7,355.4,312.9,355.4,374.4z"/>
</Svg>
                    );
                }