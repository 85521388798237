import { useStyle } from 'hooks';
import React, { ChangeEvent, useRef } from 'react';
import { NativeSyntheticEvent, StyleProp, StyleSheet, TextInput, TextInputFocusEventData, View } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import SText from './SText';


interface Props {
    label?: string
    placeholder?: string,
    value?: any,
    onChangeText?: (e: string | ChangeEvent<any>) => void,
    onBlur?: (e: string | ChangeEvent<any>) => void,
    onFocus?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void) | undefined,
    onKeyPress?: (e: any) => void,
    style?: StyleSheet.NamedStyles<any> | StyleProp<any>,
    disabled?: boolean
    autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined
    autoCompleteType?: 'username' | 'password' | 'code'
    keyboardType?: any    
}

const CustomTextInput: React.FC<Props> = ({ label, value, placeholder, onChangeText, onFocus, onBlur, onKeyPress, style, disabled, autoCapitalize, autoCompleteType, keyboardType }) => {

    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();

    const inputRef = useRef(null);

    return (
        <View style={style}>
            <SText medium md black style={scopedStyles('label')}>
                {label}
            </SText>

            {disabled ? (
                <SText light sm black style={[scopedStyles('input'), scopedStyles('input-disabled'), styles('border-radius-xs')]}>
                    {value}
                </SText>
            ) : (
                <TextInput
                    placeholder={placeholder}
                    value={value}
                    onChangeText={onChangeText}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onKeyPress={onKeyPress}
                    autoCapitalize={autoCapitalize}
                    spellCheck={false}
                    autoCompleteType={autoCompleteType}
                    keyboardType={keyboardType}
                    style={[
                        styles('text-light'), styles('text-sm'), styles('text-black'), styles('border-radius-xs'),
                        scopedStyles('input'), scopedStyles('input-border'),
                    ]}
                />
            )}
        </View>
    );
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        label: {
            marginBottom: 4
        },
        input: {
            paddingLeft: 12,
            paddingVertical: 8
        },
        'input-border': {
            borderWidth: 1,
            borderColor: colors.border,
        },
        'input-disabled': {
            backgroundColor: colors.disabled,
            paddingVertical: 8,
        }
    },
    {
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            input: {
                fontSize: 14,
                paddingVertical: 6
            },
            'input-disabled': {
                paddingVertical: 6,
            }
        }
    }
)

export default CustomTextInput;