
export default {
    valutaString: (val: string | number | null, withoutCents?: boolean) => {

        if (typeof val === 'string') val = val.replace(',', '.');
        let mVal = Number(val).toFixed(2).replace('.', ',');
        let idx = mVal.search(',');
        if (idx < 0) idx = mVal.length;
        for (let i = idx - 3; i > 0; i -= 3) {
            mVal = mVal.substring(0, i) + '.' + mVal.substring(i);
        }
        mVal = '€ ' + mVal;
        return (withoutCents) ? mVal.substring(0, mVal.indexOf(',')) : mVal;
    }
}