import { SText } from "components";
import { useStyle } from "hooks";
import { View, Image, ActivityIndicator } from "react-native";
import { colors } from "styles";

const splashScreen = require('assets/icon.png');

export default () => {

    const styles = useStyle();

    return (
        <View style={[styles('fullscreen'), styles('center')]}>
            <Image 
                source={splashScreen} 
                resizeMode='contain'
                style={{
                    width: 128,
                    height: 128,
                    marginBottom: 32
                }} 
            />
            <SText sm medium primary center style={{ paddingVertical: 12, marginBottom: 32, width: '100%' }}>#after_successful_login</SText>
            <ActivityIndicator color={colors.primary} />
        </View>
    )

}