import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} class="st0" d="M476.4,69.1l-5.7-5.7L422,14.7l-6.1-6.1c-4.8-4.8-11-7.1-17.3-7.1c-6.3,0-12.5,2.4-17.3,7.1l-2.8,2.8
		l-61.3,61.3L213.8,175.9c-0.1,0.1-0.1,0.1-0.2,0.2l95.2,95.2c0.1-0.1,0.1-0.1,0.2-0.2l103.7-103.7l61.3-61.3l2.4-2.4
		C485.9,94.2,485.9,78.7,476.4,69.1z"/>
	<Path fill={color} class="st0" d="M412.7,409.8c0,6.8-5.8,12.6-12.6,12.6H75.6c-6.8,0-12.6-5.8-12.6-12.6V85.3c0-6.8,5.8-12.6,12.6-12.6h198.2
		l61.3-61.3H75.6c-40.6,0-73.9,33.3-73.9,73.9v324.5c0,40.6,33.3,73.9,73.9,73.9h324.5c40.6,0,73.9-33.3,73.9-73.9V149.5l-61.3,61.3
		V409.8z"/>
	<Path fill={color} class="st0" d="M159.1,325.8c4.7,4.7,11,7.1,17.3,7.1c3.2,0,6.4-0.6,9.4-1.9l89.6-37.6l-84-84l-37.6,89.6
		C150,308.2,152.1,318.8,159.1,325.8z"/>
</G>
</Svg>
                    );
                }