import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M385.224,360.614H253.707c-9.423,0-17.057,7.639-17.057,17.058c0,9.418,7.634,17.056,17.057,17.056h131.517
		c9.422,0,17.059-7.638,17.059-17.056C402.282,368.253,394.646,360.614,385.224,360.614z"/>
	<Path fill={color} d="M419.23,22.132H65.981c-20.467,0-37.06,16.589-37.06,37.061v363.065c0,20.464,16.592,37.06,37.06,37.06H419.23
		c20.469,0,37.062-16.596,37.062-37.06V59.193C456.292,38.721,439.698,22.132,419.23,22.132z M431.269,422.258
		c0,6.637-5.402,12.037-12.039,12.037H65.981c-6.636,0-12.038-5.4-12.038-12.037V59.193c0-6.637,5.402-12.038,12.038-12.038H419.23
		c6.637,0,12.039,5.4,12.039,12.038V422.258z"/>
	<Path fill={color} d="M382.016,89.919H103.201c-11.192,0-20.264,9.069-20.264,20.269v63.598c0,11.192,9.071,20.268,20.264,20.268h278.814
		c11.192,0,20.267-9.076,20.267-20.268v-63.598C402.282,98.988,393.208,89.919,382.016,89.919z"/>
	<Path fill={color} d="M382.016,229.801H103.201c-11.192,0-20.264,9.07-20.264,20.269v63.599c0,11.191,9.071,20.267,20.264,20.267h278.814
		c11.192,0,20.267-9.075,20.267-20.267V250.07C402.282,238.872,393.208,229.801,382.016,229.801z"/>
</G>
</Svg>
                    );
                }