import { PlatformWebview } from "components";
import { QueryKeys } from 'enums';
import Constants from "expo-constants";
import { useStyle } from "hooks";
import { useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { useQuery } from "react-query";
import { colors } from "styles";
import { ApiUtils, QueryUtils } from "utils";

const Config = Constants.expoConfig?.extra?.config;

export default ({ route }) => {
    const type = route?.params?.type;
    const styles = useStyle();
    const [link, setLink] = useState('');
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);

    useEffect(() => {
        const setInventoryLink = async () => {
            const token = await QueryUtils.fetchAssessmentToken(customer.invitation, type);
            const tenantCode = await ApiUtils.getTenant();
            const url = `https://${tenantCode}.${Config.INVENTORY_URL}/login?token=${token}&redirect_success=${location.href}&redirect_fail=${location.href}`;
            setLink(url);
        };
        setInventoryLink();
    }, [type]);

    const reloadOnRedirect = (e: any) => {
        try {
            const access = e.target.contentDocument || e.target.contentWindow.document;
            if (access) location.reload();
        } catch {
            console.info('Inventarisatie portaal');
        }
    }

    return (
        <View style={styles('fullscreen')}>
            {link ? (
                <PlatformWebview
                    url={link}
                    onLoad={reloadOnRedirect}
                />
            ) : (
                <View style={[styles('fullscreen'), styles('center')]}>
                    <ActivityIndicator color={colors.primary} size={40} />
                </View>
            )}
        </View>
    )

}
