
import mapHtml from 'assets/html/maps';
import { Icons } from "assets/icons";
import { BottomNavBar, Button, EnergyLabel, Footer, PlatformWebview, SText } from "components";
import { LivingModal } from "components/Modals";
import { DateTimeFormat, QueryKeys } from 'enums';
import Constants from "expo-constants";
import { LinearGradient } from "expo-linear-gradient";
import { useLayoutMargin, useStyle } from "hooks";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { Image, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { Content } from 'screens/home';
import { colors } from "styles";
import { QueryUtils, ValutaUtils } from "utils";

const Config = Constants.expoConfig.extra.config;



export default () => {

    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { showModal } = useContext(ModalContext);
    const margin = useLayoutMargin();
    const { width, height } = useWindowDimensions();
    const { data: living } = useQuery(QueryKeys.LIVING, QueryUtils.fetchLiving);
    const largeScreen = width > 768;

    const activeAddress = living?.property || { street_name: '', house_number: '', house_number_addition: '', city: '', postcode: '' };
    const owner = living?.owns_property;
    const marketValue = activeAddress?.market_value || activeAddress?.market_value_high || activeAddress?.market_value_low || '#unknown';

    const mapURL = encodeURI(`https://maps.google.com/maps?q=${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}, ${activeAddress.city}&t=&z=16&ie=UTF8&iwloc=&output=embed`);
    const streetviewURL = encodeURI(activeAddress ? `${Config.PROXY_URL}/streetview?location=${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}, ${activeAddress.city}&size=600x400` : '');
    
    const renderMap = () => {

        return (
            <View style={{
                height: largeScreen ? '100%' : (width - margin * 2) * 1.314,
                flexDirection: 'column', 
                gap: 40,
            }}>
                <Image 
                    source={{uri: streetviewURL}} 
                    style={{ width: '100%', flex: 1, borderRadius: 12 }}
                />
                <PlatformWebview
                    html={mapHtml}
                    replaceVars={[
                        ['MAPURL', mapURL]
                    ]}
                    style={{ width: '100%', flex: 1, borderRadius: 12 }}
                />
            </View>
        )
    }

    const renderTitle = () => {
        return (
            <View style={scopedStyles('addressContainer')}>
                <View style={{ flexDirection: 'row' }}>
                    <Icons.Home size={50} color={colors.primary} />
                    <View style={{ marginLeft: 12 }}>
                        <View style={{ flexDirection: 'row' }}>
                            <SText xl primary regular>{`${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}`}</SText>
                            <EnergyLabel label={activeAddress.energy_label} style={{ marginLeft: largeScreen ? 30 : 8 }} />
                        </View>
                        <SText md primary regular>{`${activeAddress.postcode && (activeAddress.postcode + ',')} ${activeAddress.city}`}</SText>
                    </View>
                </View>
            </View>
        );
    }

    const renderUnderTitle = () => {
        return owner ? (
            <View style={scopedStyles('underTitleContainer')}>
                <View style={scopedStyles('underTitleItem')}>
                    <SText grey sm semibold style={{ marginRight: 8 }}>#koopsom</SText>
                    <SText black medium md>{activeAddress.purchase_price ? ValutaUtils.valutaString(activeAddress.purchase_price, true) : '#unknown'}</SText>
                </View>

                <View style={scopedStyles('underTitleItem')}>
                    <SText grey sm semibold style={{ marginRight: 8 }}>#wozwaarde</SText>
                    <SText black medium md>{activeAddress.woz_value_home ? ValutaUtils.valutaString(activeAddress.woz_value_home, true) : '#unknown'}</SText>
                </View>

                <View style={scopedStyles('underTitleItem')}>
                    <SText grey sm semibold style={{ marginRight: 8 }}>#marktwaarde</SText>
                    <SText black medium md>{marketValue ? ValutaUtils.valutaString(marketValue, true) : '#unknown'}</SText>
                </View>
            </View>
        ) : (
            <View style={scopedStyles('underTitleContainer')}>
                <View style={scopedStyles('underTitleItem')}>
                    <SText grey sm semibold style={{ marginRight: 8 }}>#kale_huur</SText>
                    <SText black medium md>{activeAddress.rent ? ValutaUtils.valutaString(activeAddress.rent, true) : '#unknown'}</SText>
                </View>

                <View style={scopedStyles('underTitleItem')}>
                    <SText grey sm semibold style={{ marginRight: 8 }}>#service_kosten</SText>
                    <SText black medium md>{activeAddress.service_costs ? ValutaUtils.valutaString(activeAddress.service_costs, true) : '#unknown'}</SText>
                </View>

                <View style={scopedStyles('underTitleItem')}>
                    <SText grey sm semibold style={{ marginRight: 8 }}>#other_kosten</SText>
                    <SText black medium md>{activeAddress.other_costs ? ValutaUtils.valutaString(activeAddress.other_costs, true) : '#unknown'}</SText>
                </View>
            </View>
        )
    }

    const renderButtons = () => {
        return (
            <View style={[scopedStyles('buttonContainer'), width < 768 && styles('bigMarginBottom')]}>
                <Button
                    type="action"
                    label="#sell"
                    onPress={() => { showModal(LivingModal, { mode: 'sell' }, 'LivingModal') }}
                    style={{ marginRight: 8 }}
                />
                <Button
                    type="secondary"
                    label="#sustain"
                    onPress={() => { showModal(LivingModal, { mode: 'sustain' }, 'LivingModal') }}
                    style={{ marginRight: 8 }}
                />
                <Button
                    type="secondary"
                    label="#appraise"
                    onPress={() => { showModal(LivingModal, { mode: 'appraise' }, 'LivingModal') }}
                />
            </View>
        );
    }


    const renderInfo = () => {
        return (
            <View style={[styles('bigMarginBottom'), scopedStyles('infoContainer')]}>
                {/* bouw */}
                <SText sm white semibold style={scopedStyles('infoTitle')}>#house_build</SText>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_type</SText>
                    <SText sm white medium>{activeAddress?.residential_building_type || '#unknown'}</SText>
                </View>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_buildyear</SText>
                    <SText sm white medium>{activeAddress?.construction_year || '#unknown'}</SText>
                </View>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_function</SText>
                    <SText sm white medium>{activeAddress?.purpose || '#unknown'}</SText>
                </View>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_function_status</SText>
                    <SText sm white medium>{activeAddress?.purpose_status || '#unknown'}</SText>
                </View>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_rentable</SText>
                    <SText sm white medium>{activeAddress?.rental_type_description || '#unknown'}</SText>
                </View>



                {/* oppervlaktes */}
                <SText sm white semibold style={[scopedStyles('infoTitle'), { marginTop: 80 }]}>#house_size</SText>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_house</SText>
                    <SText sm white medium>{activeAddress?.size ? activeAddress?.size + ' m²' : '#unknown'}</SText>
                </View>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_land</SText>
                    <SText sm white medium>{activeAddress?.plot_size ? activeAddress?.plot_size + ' m²' : '#unknown'}</SText>
                </View>



                {/* overige */}
                <SText sm white semibold style={[scopedStyles('infoTitle'), { marginTop: 80 }]}>#house_energy</SText>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_energy_label</SText>
                    <SText sm white medium>{activeAddress?.energy_label || '#unknown'}</SText>
                </View>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_energy_label_end_date</SText>
                    <SText sm white medium>{activeAddress?.energy_label_end_date ? moment(activeAddress?.energy_label_end_date).format(DateTimeFormat.display_date) : '#unknown'}</SText>
                </View>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_solar</SText>
                    <SText sm white medium>{activeAddress?.solar_panels ? '#yes' : '#no'}</SText>
                </View>

                <View style={scopedStyles('infoLine')}>
                    <SText sm greyTransparent medium style={scopedStyles('infoDescription')}>#house_smartmeter</SText>
                    <SText sm white medium>{activeAddress?.smart_meter ? '#yes' : '#no'}</SText>
                </View>

            </View>
        )
    }



    return (
        <View style={styles('fullscreen')}>

            {/* map */}
            {largeScreen &&
                <View
                    style={[
                        scopedStyles('mapContainer'),
                        styles('bigPaddingRight'),
                        styles('bigPaddingVertical')
                    ]}
                >
                    {renderMap()}
                </View>
            }

            <Content
                specificTopHeight={width < 768 ? 'auto' : height * 0.3}
                title={''}
            >

                {/* topcontainer */}
                <View style={{ flex: 1, justifyContent: "space-between" }}>
                    {renderTitle()}
                    {renderUnderTitle()}
                    {renderButtons()}
                </View>

                <BottomNavBar navItems={[{ title: 'kenmerken' }]} noSearch />

                {/* gradient container with advisor */}
                <LinearGradient
                    colors={[colors.gradientFrom, colors.gradientTo]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                    style={[
                        styles('fullscreen'),
                        styles('bigPaddingHorizontal'),
                        styles('bigPaddingVertical'),
                        scopedStyles('gradientContainer')
                    ]}
                >

                    {renderInfo()}

                    {/* map */}
                    {!largeScreen &&
                        <View style={scopedStyles('mapContainer')}>
                            {renderMap()}
                        </View>
                    }

                    {!largeScreen &&
                        <View style={{ height: 40 }} />
                    }
                    <Footer style={{ bottom: largeScreen ? 0 : 4 }} />

                </LinearGradient>

            </Content>

        </View>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        gradientContainer: {
            flexDirection: 'row',
            overflow: 'visible'
        },
        contactInfo: {
            position: 'absolute',
            width: 360,
            right: '40%',
            top: -120,
            height: 'auto',
            backgroundColor: colors.white,
            borderRadius: 8,
            padding: 20,
            zIndex: 10
        },
        mapContainer: {
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            width: '32%',
            borderRadius: 12,
            overflow: 'visible',
            zIndex: 1
        },
        underTitleContainer: {
            width: '60%',
            flexDirection: 'row',
            justifyContent: "space-between",
            borderColor: colors.grey,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            paddingVertical: 12
        },
        underTitleItem: {
            flexDirection: "row",
            alignItems: "center"
        },
        addressContainer: {
            flexDirection: 'row',
            height: 'auto',
            width: '100%',
            alignItems: "center"
        },
        homeAddress: {
            marginLeft: 20,
            flex: 1
        },
        buttonContainer: {
            flexDirection: "row"
        },
        infoContainer: {
            width: '60%'
        },
        infoTitle: {
            borderBottomColor: colors.greyTransparent,
            borderBottomWidth: 1,
            paddingBottom: 20
        },
        infoLine: {
            flexDirection: "row",
            paddingVertical: 8,
            borderBottomColor: colors.greyTransparent,
            borderBottomWidth: 1
        },
        infoDescription: {
            width: 320
        }
    }, {


    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
        gradientContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },

    },

    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        mapContainer: {
            position: 'relative',
            width: '100%',
            height: 'auto',
        },
        addressContainer: {
            marginBottom: 14
        },
        underTitleContainer: {
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginBottom: 24
        },
        underTitleItem: {
            width: '80%',
            justifyContent: 'space-between'
        },
        buttonContainer: {
            justifyContent: 'space-between'
        },
        infoContainer: {
            width: '100%'
        },
        infoDescription: {
            width: 'auto'
        },
        infoLine: {
            justifyContent: 'space-between'
        },
        homeAddress: {
            marginLeft: 4
        }
    }
}
)