import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M361.2,272.7c-25,0.1-50,0-75,0c-1.2,0-2.4,0-3.8,0c0,3.5-0.1,6.7,0,9.9c0.1,4.5-1.9,7.6-5.9,9.7
		c-9.7,5.1-19.3,10.2-28.9,15.4c-3.5,1.9-6.9,1.9-10.3,0c-9.7-5.2-19.4-10.4-29.2-15.5c-3.7-2-5.6-5-5.6-9.2c0-3.3,0-6.6,0-10.2
		c-1.5,0-2.7,0-3.8,0c-22.5,0-45-0.4-67.5,0.1c-15.7,0.3-30.3-2-43-12.1c0,33.9,0,67.7,0,101.6c2.8,6.7,8,8.7,15,8.7
		c92.8-0.1,185.6-0.1,278.4,0c7.1,0,12.3-2,15-8.7c0-33.9,0-67.7,0-101.6c-7.3,5.3-15,9.6-24.1,11
		C368.8,272.4,365,272.7,361.2,272.7z"/>
	<Path fill={color} d="M381.8,155.6c-92.9,0.1-185.8,0.1-278.7,0c-7.1,0-12.3,2.1-14.9,9c0,20,0,40.1,0,60.1c0.5,2,1,4.1,1.6,6
		c4.6,14.2,17.2,23.3,32.6,23.3c25.7,0.1,51.4,0,77.1,0c0.9,0,1.8-0.1,3-0.2c0-4.7,0-9.3,0-13.9c0-8.4,3.3-11.7,11.6-11.7
		c19,0,37.9,0,56.9,0c7.9,0,11.3,3.4,11.4,11.3c0,4.7,0,9.4,0,14.4c1.4,0,2.5,0,3.6,0c25,0,50,0,75,0c18.8,0,31.1-9.9,35.3-28.3
		c0.1-0.4,0.3-0.7,0.4-1.1c0-20,0-40.1,0-60.1C394.1,157.7,388.9,155.6,381.8,155.6z"/>
	<Path fill={color} d="M278.7,90.5c-14.9-0.1-29.9,0-44.8,0c-10.3,0-20.7-0.1-31,0.2c-9.4,0.3-18,7.6-20,16.8c-1.2,5.3-1.8,10.6-2.6,16
		c-0.7,4.4-1.3,8.8-2,13.5c6.5,0,12.5,0,18.8,0c1.1-7.3,2.1-14.5,3.1-21.7c0.7-5.2,1.7-6.1,6.9-6.1c23.5,0,47,0,70.4,0
		c5.3,0,6.3,0.9,7,6.1c1,7.2,2.1,14.4,3.1,21.7c6.3,0,12.5,0,18.9,0c-1.4-9.5-2.5-18.7-4.3-27.9C300,97.3,291.1,90.5,278.7,90.5z"/>
	<Path fill={color} d="M263.7,250.1c0-0.9-0.1-1.9-0.2-2.8c-14.2,0-28.1,0-42,0c-0.1,0.4-0.3,0.7-0.3,1c0,9.4-0.1,18.8,0,28.2
		c0,0.9,1.1,2.1,1.9,2.5c5.7,3.2,11.4,6.3,17.3,9.2c1.1,0.5,2.9,0.6,4,0c5.7-2.8,11.3-6,17.1-8.9c1.8-0.9,2.3-2,2.3-4
		C263.7,266.9,263.7,258.5,263.7,250.1z"/>
</G>
</Svg>
                    );
                }