import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M404.4,110.1l-93.2-93.2c-9-9-21.2-14.1-33.9-14H114.7c-26.5,0-48,21.5-48,48v383.6c0,26.5,21.5,48,48,48h255.7
	c26.5,0,47.9-21.5,47.9-48V143.9C418.5,131.2,413.4,119,404.4,110.1z M306.5,130.7c-8.8,0-16-7.2-16-16V41.5l89.3,89.3H306.5z"/>
</Svg>
                    );
                }