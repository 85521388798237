import { Icons } from "assets/icons";
import { BottomNavBar, Button, ContentScroller, SText } from "components";
import { ConfirmModal } from "components/Modals";
import OnlineAfspraakModal from "components/Modals/OnlineAfspraakModal";
import { QueryKeys } from "enums";
import { useStyle } from "hooks";
import { OnlineAfsprakenAppointment } from "models";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import React, { useContext, useEffect, useState } from "react";
import {
    ActivityIndicator,
    Dimensions,
    Pressable,
    View
} from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { QueryUtils } from "utils";
import OnlineAfsprakenUtils from "utils/OnlineAfsprakenUtils";
import Content from "./Content";



export default () => {

    const { makeAppointment } = useContext(StoreContext);
    const { showModal, hideModal } = useContext(ModalContext);
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const styles = useStyle();
    const { data: appointments, isLoading: appointmentsLoading, refetch: refetchAppointments } = useQuery(QueryKeys.APPOINTMENTS, QueryUtils.fetchOnlineAfsprakenAppointments);
    const [deletingAppointmentId, setDeletingAppointmentId] = useState(null);
    const [selectedNavItem, setSelectedNavItems] = useState('APPOINTMENTS');
    const [navItems, setNavItems] = useState([{ title: '#my_appointments', key: 'APPOINTMENTS' }]);

    useEffect(() => {
        makeAppointment({ clear: true });
    }, [])

    const deleteAppointment = (id: string) => {
        setDeletingAppointmentId(id);
        OnlineAfsprakenUtils.removeOnlineAfsprakenAppointment(id).finally(() => {
            refetchAppointments().finally(() => {
                setDeletingAppointmentId(null);
            });
        });
    }

    const deleteAppointmentConfirm = (id: string) => {

        showModal(
            ConfirmModal,
            {
                title: '#cancel_appointment_title',
                text: '#cancel_appointment_text',
                buttons: [
                    <Button
                        label={'#no'}
                        type='error'
                        onPress={() => hideModal()}
                        icon={(color) => <Icons.Delete size={12} color={color} />}
                    />,
                    <Button
                        label={'#yes'}
                        type={"action"}
                        onPress={() => {
                            deleteAppointment(id);
                            hideModal();
                        }}
                        icon={(color) => <Icons.Check size={12} color={color} />}
                    />
                ]
            },
            'ConfirmModal - remove appointment - ' + id
        );
    }

    const renderAppointment = (appointment: OnlineAfsprakenAppointment) => {
        const date = moment(appointment.time_start).format("D MMMM YYYY");
        const timeStart = moment(appointment.time_start).format("HH:mm");
        const timeEnd = moment(appointment.time_end).format("HH:mm");
        const isLoading = deletingAppointmentId === appointment.id;
        return (
            <View
                key={`appointment_${appointment.id}`}
                style={scopedStyles('appointmentContainer')}
            >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icons.Calendar size={50} color={colors.primary} />
                    <View style={scopedStyles('appointmentDate')}>
                        <SText semibold primary sm>{date}</SText>
                        <SText semibold primary sm>{timeStart} - {timeEnd}</SText>
                    </View>
                </View>
                <View style={scopedStyles('appointmentContent')}>
                    <SText medium black sm oneline style={{ maxWidth: '100%', overflow: "hidden" }}>{appointment.summary}</SText>
                    <SText medium grey sm oneline style={{ maxWidth: '100%', overflow: "hidden" }}>{appointment.description}</SText>
                </View>
                <View style={{ alignItems: "flex-end", width: Dimensions.get('window').width > 768 ? 'auto' : '100%' }}>
                    {isLoading ? <ActivityIndicator color={colors.error} size={25} /> :
                        <Pressable onPress={() => deleteAppointmentConfirm(appointment.id)}>
                            <Icons.Delete_trash size={25} color={colors.error} />
                        </Pressable>
                    }
                </View>
            </View>
        );
    }

    return (
        <Content
            specificTopHeight={Dimensions.get('window').height * 0.3}
            title="#title_appointments"
        >
            <View style={styles('fullscreen')}>
                <SText sm regular black>#undertitle_appointments</SText>
                <View style={{ position: 'absolute', flexDirection: 'row', bottom: 30, width: '100%' }}>
                    <Button
                        label="#action_make_appointment_online"
                        type="action"
                        onPress={() => showModal(OnlineAfspraakModal, {}, 'OnlineAfspraakModal')}
                        style={{ marginTop: 8 }}
                    />
                </View>
            </View>

            <BottomNavBar navItems={navItems} handleNavItemSelect={(e) => { setSelectedNavItems(e.key) }} noSearch={true} />

            <View style={[styles('fullscreen')]}>
                <ContentScroller style={[
                    styles('bigPaddingRight'),
                    {
                        flex: 1,
                        paddingBottom: 25,
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                        justifyContent: appointmentsLoading || appointments?.length <= 0 ? 'center' : 'flex-start',
                        alignItems: appointmentsLoading || appointments?.length <= 0 ? 'center' : 'flex-start',
                    }
                ]}>
                    {!appointmentsLoading && appointments && appointments.length <= 0 ?
                        <SText white md medium style={scopedStyles('noResults')}>#empty_screen_appointments</SText>
                        : null}
                    {appointmentsLoading ? <ActivityIndicator color={colors.white} size={40} style={{ marginBottom: 20 }} /> : null}
                    {appointments ? appointments.map((appointment, index) =>
                        renderAppointment(appointment)
                    ) : null}
                </ContentScroller>
            </View>
        </Content>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        noResults: {
            marginHorizontal: 10
        },
        appointmentContainer: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: '100%',
            borderRadius: 8,
            padding: 30,
            marginBottom: 25,
            backgroundColor: colors.white,
        },
        appointmentDate: {
            paddingLeft: 20,
        },
        appointmentContent: {
            flex: 1,
            maxWidth: '100%',
            flexDirection: "column",
            paddingHorizontal: 20
        },
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            noResults: {
                marginHorizontal: 0
            },
            appointmentContainer: {
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: 8,
                paddingVertical: 20,
                paddingHorizontal: 10,
                marginBottom: 20,
                backgroundColor: colors.white,
            },
            appointmentIcon: {
                height: 0,
                overflow: 'hidden'
            },
            appointmentContent: {
                flex: 1,
                flexDirection: "column",
                paddingVertical: 20,
                paddingHorizontal: 0,
            },
        }
    }
)

