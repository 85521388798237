import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
    
<Path fill="#DCE3EB" d="M85.3,410.9c0-2.7,0-5.3,0-8c0.7-3.8,1.2-7.7,2.2-11.4c8-28.9,26.8-46.9,55.7-54.3c15-3.9,30.2-7.5,45.2-11.3
	c1.1-0.3,2.7-0.8,3-1.6c1.1-3.4,1.8-7,2.6-10.2c-10.3-2.6-20.3-4.7-30-7.8c-7.7-2.4-15.1-5.8-22.4-9.1c-4.4-2-5.3-7-2.1-10.2
	c9.2-9.2,14-20.7,17.6-32.9c5.3-18.1,6.7-36.8,6.8-55.6c0.1-14.4,2.2-28.6,7.6-42c9.9-24.8,27-42.4,52.3-51.5
	c4.5-1.6,9.2-2.5,13.9-3.7c4.3,0,8.6,0,12.9,0c0.6,0.2,1.1,0.5,1.7,0.5c9.1,0.7,16.9,4.4,23.7,10.5c1.3,1.2,3.4,1.9,5.1,2
	c11.5,0.2,20.2,5,26.5,14.6c6.7,10.4,10,22,11.2,34.1c1.5,14.6,2,29.4,2.7,44.1c0.7,16,2.2,31.9,6.8,47.3
	c3.4,11.5,7.9,22.4,16.3,31.3c4.6,4.8,4,9.3-1.9,12.2c-11.1,5.6-22.8,9.5-34.9,12.3c-5.4,1.3-10.9,2.4-16.5,3.6c0.7,3,1.5,5.8,2,8.6
	c0.4,2.2,1.5,3.2,3.7,3.7c15.1,3.7,30.2,7.5,45.2,11.2c33.7,8.3,56.8,36,57.8,69.5c1.1,6.7,1.4,13.5,1.3,19.5
	c51.5-44.5,84-110.2,84-183.6C485.2,108.6,376.6,0,242.6,0C108.6,0,0,108.6,0,242.6c0,74.2,33.3,140.6,85.8,185.1
	c-0.1-5.2-0.2-10.7-0.3-16.4C85.5,411.2,85.4,411,85.3,410.9z"/>
<Path fill="#FFFFFF" d="M401.2,426.2c0.1-6-0.3-12.8-1.3-19.5c-1-33.5-24.1-61.2-57.8-69.5c-15.1-3.7-30.1-7.5-45.2-11.2
	c-2.2-0.5-3.3-1.5-3.7-3.7c-0.5-2.8-1.3-5.5-2-8.6c5.6-1.2,11.1-2.3,16.5-3.6c12.1-2.8,23.8-6.7,34.9-12.3c5.9-3,6.4-7.4,1.9-12.2
	c-8.4-8.9-12.9-19.8-16.3-31.3c-4.6-15.4-6.1-31.3-6.8-47.3c-0.6-14.7-1.2-29.5-2.7-44.1c-1.2-12-4.5-23.7-11.2-34.1
	c-6.2-9.6-15-14.4-26.5-14.6c-1.7,0-3.8-0.8-5.1-2c-6.8-6.1-14.5-9.8-23.7-10.5c-0.6,0-1.1-0.3-1.7-0.5c-4.3,0-8.6,0-12.9,0
	c-4.6,1.2-9.4,2.1-13.9,3.7c-25.3,9.1-42.4,26.7-52.3,51.5c-5.4,13.5-7.5,27.6-7.6,42c-0.1,18.8-1.5,37.4-6.8,55.6
	c-3.6,12.2-8.4,23.7-17.6,32.9c-3.2,3.2-2.3,8.2,2.1,10.2c7.3,3.4,14.7,6.7,22.4,9.1c9.7,3.1,19.7,5.2,30,7.8
	c-0.8,3.2-1.5,6.8-2.6,10.2c-0.3,0.8-1.9,1.3-3,1.6c-15.1,3.8-30.2,7.4-45.2,11.3c-28.9,7.4-47.7,25.4-55.7,54.3
	c-1,3.7-1.5,7.6-2.2,11.4c0,2.7,0,5.3,0,8c0.1,0.1,0.2,0.3,0.2,0.4c0.1,5.7,0.2,11.2,0.3,16.4c42.3,35.8,97,57.5,156.8,57.5
	C303.2,485.2,358.7,463,401.2,426.2z"/>
</Svg>
                    );
                }