import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M439.9,124.5c-72.5,0-144.9,0.2-217.4-0.1c-29.4-0.1-51.5,11.8-67.2,36.6c-21.3,33.5-42.9,66.8-64.5,100.1
	c-2.6,4-3.9,7.9-3.7,12.7c0.3,10,0.1,20,0.1,30.4c-29.5,0-58.3,0-87.2,0c0,9.5,0,18.9,0,28.4c9.4,0,18.8,0,28.5,0
	c0,9.7,0,18.8,0,28.1c9.6,0,18.8,0,28.5,0c0-9.5,0-18.7,0-27.9c1.1-0.1,1.7-0.2,2.4-0.2c64.9,0,129.7,0,194.6-0.1c3,0,3.9,1.4,5,3.7
	c7.8,15.8,20.7,24.5,38.4,24.6c17.6,0.1,30.7-8.4,38.5-24.3c1.5-3,3-4,6.3-4c47.7,0.1,95.3,0.1,143,0.1c0-56.7,0-113.5,0-170.2
	c-1.4-4.5-2.2-9.2-4.3-13.4C472.5,132.2,458.4,124.5,439.9,124.5z M297.4,332.6c-7.7-0.1-14.1-6.6-14.1-14.3
	c0.1-7.7,6.6-14.1,14.3-14c7.7,0.1,14.1,6.6,14.1,14.3C311.7,326.2,305.2,332.6,297.4,332.6z M314.2,228.2c-36.9,0-73.8,0-111.2,0
	c0-9.4,0-18.5,0-27.8c37.1,0,74,0,111.2,0C314.2,209.5,314.2,218.7,314.2,228.2z M413.8,304c-18.9,0-37.5,0-56.3,0
	c0-34.5,0-68.8,0-103.4c18.6,0,37.3,0,56.3,0C413.8,234.8,413.8,269.2,413.8,304z"/>
</Svg>
                    );
                }