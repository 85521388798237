import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M102,387.1c3.4,12.2,12,16.9,24.2,13.5c15.4-4.3,30.9-8.5,46.3-12.8c55.6-15.4,111.2-30.8,166.8-46.3
		c3.1-0.9,6.2-2.2,8.7-4.2c6-4.9,7.4-11.5,5.3-18.9c-2-7.2-4-14.4-6.2-22.2c7.9,1.7,15.3,3.2,22.6,4.7c11.4,2.4,19.9-2.9,22.3-14.2
		c3.1-14.2,6-28.4,9-42.6c2-9.6,4.1-19.2,6.1-29c-1.7-0.4-3-0.7-4.4-1c-26.8-5.7-53.6-11.3-80.4-16.9c-2.4-0.5-3.5-1.5-4-3.9
		c-0.9-4.1-2-8.1-3.5-12c-3-7.9-11.1-12.6-19.3-10.5c-13.4,3.3-26.7,7.2-40,10.8c-1.7,0.5-3.7,0.7-5.4,0.3c-19-3.9-37.9-8-56.9-12
		c-12.7-2.7-25.4-5.4-38.3-8.1c-0.3,1.2-0.7,2.2-0.9,3.2c-3.1,14.7-6.2,29.3-9.2,44c-0.5,2.7-1.6,4-4.4,4.8
		c-19.8,5.4-39.5,11.2-59.4,16.3c-8.5,2.2-14.5,6.3-17,14.9c0,2.1,0,4.2,0,6.3c0.2,0.3,0.5,0.5,0.5,0.8
		C77.1,297,89.5,342.1,102,387.1z M341,251.6c1.6-7.5,3.5-16.4,5.6-25.3c0.8-3.3,3.5-5.3,6.8-4.7c9.2,1.7,18.4,3.6,27.5,5.8
		c3.5,0.8,5.2,4.1,4.5,7.6c-1.6,8.5-3.4,17-5.4,25.5c-1,4.3-4.4,6.3-8.7,5.5c-8.2-1.6-16.4-3.3-24.5-5.2
		C342.6,259.8,340.7,257.2,341,251.6z M317.9,243.6c2.9,10.3,5.7,20.2,8.4,30.2c4.3,15.7,8.7,31.3,13,47c1.4,5,0.9,5.8-4,7.2
		c-70.8,19.7-141.6,39.4-212.4,59c-5,1.4-5.8,0.9-7.2-4c-6.8-24.6-13.7-49.2-20.5-73.8c-0.3-1-0.5-2-0.9-3.5
		C168.9,285,243.2,264.3,317.9,243.6z M83,244.1c26.7-7.4,53.4-14.9,80.2-22.3c44.1-12.2,88.1-24.4,132.2-36.6c5.2-1.5,6-1,7.4,4.1
		c2.2,7.9,4.4,15.8,6.7,24.1c-74.7,20.7-149,41.4-223.7,62.1c-1.9-6.7-3.7-13.2-5.6-19.8c-0.4-1.5-0.8-2.9-1.2-4.4
		C77.7,246.4,78.2,245.4,83,244.1z"/>
	<Path fill={color} d="M405.4,129.6c-36.9-7.8-73.8-15.6-110.8-23.4c-35-7.4-70-14.8-105-22.1c-10.8-2.3-19.8,4-21.5,15
		c-0.7,4.5-1.4,8.9-2.1,13.4c-0.6,3.7-1.1,7.5-1.6,11.4c84.3,17.8,168.2,35.6,252.4,53.4c1.4-8.8,2.7-17.3,4-25.8
		C422.6,139.5,417.3,132.1,405.4,129.6z"/>
	<Path fill={color} d="M123.8,365.7c1.2,3.7,4.4,5.1,8.5,4.1c8.3-2.1,16.6-4.4,24.8-6.8c4.3-1.3,6.3-4.6,5.2-8.9c-2.1-8.4-4.5-16.8-7-25.1
		c-1.2-4.1-4.7-6-8.7-5c-8.4,2.2-16.8,4.5-25.1,7c-3.3,1-5.2,3.3-5.2,6.6c0.1,0.8,0,1.3,0.1,1.7
		C118.8,348.1,121.1,356.9,123.8,365.7z"/>
</G>
</Svg>
                    );
                }