import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M474.6,217L339,81.4c-6.9-6.9-16-10.6-25.6-10.6c-9.7,0-18.8,3.8-25.7,10.6c-14.1,14.1-14.1,37.1,0,51.2
	l73.7,73.7H35.7c-19.9,0-36.2,16.3-36.2,36.2s16.3,36.2,36.2,36.2h325.8l-73.7,73.7c-14.1,14.1-14.1,37.1,0,51.2
	c6.9,6.9,16,10.6,25.7,10.6s18.8-3.8,25.6-10.6l135.5-135.5c6.9-6.9,10.6-16,10.6-25.6C485.2,232.9,481.4,223.8,474.6,217z"/>
</Svg>
                    );
                }