import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M478.347,179.012c-5.775-8.131-15.486-12.792-26.66-12.792h-42.536v-46.238
	c0-17.83-14.501-32.332-32.317-32.332H227.766c-6.344,0-16.243-5.023-19.979-10.151l-11.162-15.253
	c-8.708-11.912-26.389-20.886-41.143-20.886h-51.65c-16.238,0-32.571,11.55-37.984,26.855l-3.554,10.061
	c-1.671,4.727-8.248,9.375-13.266,9.375H33.544c-17.831,0-32.326,14.502-32.326,32.332v291.891c0,1.555,0.271,3.031,0.777,4.402
	c0.259,5.775,2.059,11.227,5.334,15.836c5.762,8.133,15.474,12.793,26.648,12.793h329.325c19.9,0,40.657-14.723,47.234-33.51
	l71.073-202.989C485.286,197.852,484.109,187.131,478.347,179.012z M27.425,119.981c0-3.328,2.803-6.125,6.119-6.125h15.485
	c16.231,0,32.571-11.55,37.977-26.855l3.554-10.048c1.67-4.739,8.248-9.387,13.272-9.387h51.65c6.345,0,16.237,5.024,19.979,10.139
	l11.168,15.266c8.694,11.913,26.382,20.886,41.136,20.886h149.067c3.313,0,6.111,2.796,6.111,6.125v46.238H122.361
	c-19.901,0-40.65,14.722-47.229,33.51L27.425,336.009V119.981z"/>
</Svg>
                    );
                }