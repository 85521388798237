import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M471,243.9l-0.1-3.6l0-44.8c0-37.3,0-74.6,0-111.9c-0.1-42.6-26.4-69.2-68.8-69.2c-113.2-0.1-217.7-0.1-319.5-0.1
		c-41.5,0-68.3,26.9-68.3,68.6c0,101.7,0,205.8,0.1,318.3c0,43.7,26,69.8,69.5,69.8c75.5,0.1,151,0.1,226.4,0.1
		c30.6,0,61.2,0,91.8,0c42.5,0,68.9-26.5,69-69.2c0-36.6,0-73.2,0-109.7L471,243.9z M428.9,259.7c0,19.3,0,38.7,0,58.3l-35.5-28.3
		c-23.8-19-44.6-19.4-69.6-1.5c-3.9,2.8-7.8,5.6-11.6,8.4c-12,8.6-24.3,17.4-36.3,26.5c-6.5,4.9-10.9,4.6-16.6-1.3
		c-15.9-16.4-32.4-32.7-48.3-48.4l-0.5-0.5c-23.1-22.8-49.4-24-74.1-3.3c-19.5,16.3-38.8,32.7-58.7,49.5c-7,5.9-14,11.8-21.1,17.8
		c-0.1-1.3-0.2-2.4-0.2-3.6c-0.1-83.4-0.1-166.9-0.1-250.3c0-18.9,7.6-26.7,26.3-26.7c52.5,0,105.1,0,157.4,0
		c54.9,0,109.4,0,163.2,0.1c16.5,0,25.6,7.9,25.6,22.3C429,138.6,428.9,197.4,428.9,259.7z M56.6,393c0.2-1.2,3.2-3.8,4.6-5
		c13.1-11,26.2-22,39.4-33c20.5-17.2,41.8-35,62.6-52.6c3.4-2.9,6.3-4.4,9-4.4c3,0,5.8,1.8,9.2,5.3c13.9,14.7,28.4,29.3,42.3,43.4
		l6,6c21.9,22.2,47.6,24,72.2,5.3c14.5-11,30.9-23.5,46.8-36.4c6.5-5.2,11-5.2,17.2-0.1c13.5,11.1,27.3,22.1,40.7,32.8
		c6,4.8,12,9.6,18,14.4c0.4,0.3,0.9,0.7,1.3,1c1,0.7,2,1.5,2.5,2.2c4.1,6.4,0.1,45.5-5,49.2c-6.9,4.6-14.9,7.5-21.3,7.6
		c-40,0.5-80.7,0.5-120,0.4c-28.6,0-57.2,0-85.8-0.1c-37.2,0-75.7-0.1-113.5,0.1c-9.4,0.1-16.3-2.4-20.7-7.4
		C55.4,414.2,55.4,402,56.6,393z"/>
	<Path fill={color} d="M311.8,106.3c-0.1,0-0.2,0-0.3,0c-36.3,0.2-66.4,30-67,66.4c-0.3,17.6,6.5,34.4,19.1,47.4c12.8,13.1,29.7,20.4,47.6,20.6
		c0.2,0,0.4,0,0.6,0c17.5,0,34.1-6.9,46.8-19.5c13-12.8,20.1-29.7,20.2-47.6c0-17.8-7-34.7-19.9-47.5
		C346.2,113.3,329.4,106.3,311.8,106.3z M311.8,198.6c-6.5,0.1-12.9-2.7-17.7-7.5c-4.8-4.8-7.5-11.3-7.5-17.7
		c0-6.5,2.9-12.9,7.7-17.7c4.8-4.6,11.1-7.3,17.4-7.3c0.1,0,0.3,0,0.4,0c6.5,0.1,13,3.1,17.7,8.1c4.7,4.9,7.2,11.4,7,17.7
		C336.4,187.3,324.9,198.5,311.8,198.6z"/>
</G>
</Svg>
                    );
                }