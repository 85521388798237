import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 417 417"  >
<Path fill={color} d="M136.7,356.2c2.6-5.6,5.9-7.3,12.1-7.2c41.3,0.3,82.6,0.2,124,0.1c3.3,0,5.1,0.9,6.7,3.9c4.7,9.1,9.7,18,14.9,27.5h45.4
	c-5-9.2-10.1-18.3-15.1-27.5c-0.8-1.4-1.5-2.9-2.1-4.1c5.2-2.5,10.6-4.3,15.1-7.3c11.1-7.4,17-18.2,17.7-31.6c0.1-1.5,0.1-3,0.1-4.5
	c0-59.2-0.1-118.4,0-177.6c0.1-22.9-6.6-43.2-21.7-60.5c-18.1-20.7-41.2-30.7-68.6-30.7c-37.6,0-75.1-0.1-112.7,0.1
	c-6,0-12.1,0.6-18,1.6c-36.8,6.3-66.8,37.2-72,74.1c-0.8,5.5-1.2,11.1-1.2,16.7c-0.1,58.7,0,117.5-0.1,176.2
	c0,15.8,5.3,28.8,19.1,37.3c4.2,2.6,9,4,14.1,6.1c-0.7,1.3-1.4,2.9-2.3,4.4c-4.9,9.1-9.9,18.2-14.8,27.4h46.2
	C128.2,372.1,132.9,364.3,136.7,356.2z M291.4,312.4c-15.9,0-29.5-13.5-29.5-29.5c0-16.1,13.3-29.4,29.5-29.4
	c16.3,0,29.4,13.1,29.5,29.3C320.9,298.6,307.2,312.4,291.4,312.4z M141.4,81.8c4.4-1,9-1.4,13.5-1.4c35.7-0.1,71.5-0.1,107.2,0
	c30,0.1,53.4,19.7,58.3,49.3c0.9,5.5,0.6,11.2,0.6,16.8c0.1,12.6,0.1,25.1,0,37.7c0,13.5-7.9,21.5-21.4,21.5
	c-30.1,0.1-60.2,0-90.4,0c-29.7,0-59.3,0-89,0c-14.3,0-21.8-7.4-22.1-21.7c-0.3-15.1-0.8-30.3-0.9-45.4
	C96.9,110.6,114.7,87.9,141.4,81.8z M97.2,281c0.5-16,13.9-28.9,29.9-28.5c16.3,0.4,29.6,14.1,29,29.9c-0.6,16.4-14,29.3-30,28.9
	C109.4,310.9,96.7,297.5,97.2,281z"/>
</Svg>
                    );
                }