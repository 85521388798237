
export default {

    //main colors
    primary: '#085FCE',
    primaryTransparent: '#085FCE55',
    primarySuperTransparent: '#085FCE11',
    primaryLessTransparent: '#085FCEcc',
    primaryDarker: '#06489d',
    primaryDarkest: '#043471',
    secondary: '#1362C7',
    tertiary: '#fff',
    transparent: 'rgba(0,0,0,0)',

    //gradient
    gradientFrom: '#4158b3',
    gradientTo: '#2c5fa3',

    //backgrounds
    background: 'rgb(246, 246, 246)',
    formBackground: 'rgb(221,227,234)',
    formDecoration: 'rgb(223,223,223)',

    //black
    black: '#1A141F',
    blackTransparent: 'rgba(0, 0, 0, 0.1)',
    blackLessTransparent: 'rgba(0, 0, 0, 0.2)',

    //grey
    grey: '#BEBEBE',
    greyTransparent: 'rgba(255,255,255,0.6)',
    darkgrey: '#898989',
    darkestgrey: 'rgb(120, 120, 120)',
    lightgrey: '#F5F3F7',
    lightergrey: '#F5F5F7AA',

    //white
    white: '#FFF',
    whiteTransparent: 'rgba(255, 255, 255, 0.1)',

    //utility colors
    hint: '#4B3A5A',
    border: '#ABA7AF',
    disabled: '#D4D2D5',
    spacer: '#D9D1E0',
    hover: '#1053A8',
    headerNavText: '#7a8391',

    //action colors
    error: '#D51A52',
    errorDarker: '#8a1135',
    action: '#30E494',
    actionDark: '#1ac77a',
    approval: '#00b99801',
    link: '#0F0BAB'
}