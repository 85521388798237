import { BottomNavBar, ContentScroller, SText } from "components";
import { QueryKeys } from 'enums';
import { useItemSize, useSignalItems, useStyle } from "hooks";
import { useState } from "react";
import { Dimensions, View } from "react-native";
import { useQuery } from "react-query";
import { QueryUtils } from "utils";
import Content from "./Content";



export default () => {

    const styles = useStyle();
    const itemSize = useItemSize();
    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const { signalItems } = useSignalItems({ categorized: true, excludeFinishedUpload: true });

    const [selectedNavItem, setSelectedNavItems] = useState('TASKS');
    const navItems = [{ title: 'taken', key: 'TASKS' }];

    return (
        <Content
            specificTopHeight={Dimensions.get('window').height * 0.3}
            title="#title_tasks"
        >
            <View style={styles('fullscreen')}>
                <SText sm regular black>#undertitle_tasks</SText>
            </View>

            <BottomNavBar navItems={navItems} handleNavItemSelect={(e) => { setSelectedNavItems(e.key) }} />

            <View style={[styles('fullscreen')]}>
                <ContentScroller style={{
                    justifyContent: signalItems?.[selectedNavItem]?.length > 0 ? 'flex-start' : 'center',
                    alignItems: signalItems?.[selectedNavItem]?.length > 0 ? 'flex-start' : 'center',
                }}>

                    {signalItems?.[selectedNavItem]?.length > 0 ? (

                        signalItems?.[selectedNavItem]?.map((item, key) => (
                            <View key={key} style={[
                                styles('bigMarginRight'),
                                styles('bigMarginBottom'),
                                {
                                    width: itemSize,
                                    height: itemSize,
                                    borderRadius: 8,
                                    overflow: 'hidden'
                                }
                            ]}>
                                {item}
                            </View>
                        ))

                    ) : (
                        <SText white md medium>#empty_screen_tasks</SText>
                    )}

                </ContentScroller>
            </View>
        </Content>
    )
}
