import { Svg, Path, Circle, Rect, Line, G, Polygon } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Polygon fill={color} points="187.326,137.176 182.997,132.846 178.668,137.176 148.922,166.92 138.111,156.105 133.775,151.773 
		129.442,156.105 121.875,163.68 117.546,168.012 121.875,172.34 144.59,195.057 148.922,199.389 153.251,195.057 194.901,153.406 
		199.233,149.074 194.901,144.746"/>
	<Rect fill={color} x="216.129" y="163.541" width="142.878" height="21.439"/>
	<Polygon fill={color} points="187.326,222.939 182.997,218.611 178.668,222.939 148.922,252.686 138.111,241.869 
		133.775,237.537 129.442,241.869 121.875,249.443 117.546,253.773 121.875,258.105 144.59,280.82 148.922,285.152 153.251,280.82 
		194.901,239.172 199.233,234.84 194.901,230.508 	"/>
	<Rect fill={color} x="216.129" y="249.307" width="142.878" height="21.439"/>
	<Polygon fill={color} points="187.326,308.705 182.997,304.373 178.668,308.705 148.922,338.451 138.111,327.635 
		133.775,323.303 129.442,327.635 121.875,335.209 117.546,339.537 121.875,343.871 144.59,366.586 148.922,370.918 
		153.251,366.586 194.901,324.938 199.233,320.604 194.901,316.271 	"/>
	<Rect fill={color} x="216.129" y="335.07" width="142.878" height="21.439"/>
	<Path fill={color} d="M393.949,15.543h-81.66H172.921H91.266c-17.54,0-31.756,14.219-31.756,31.752v384.607
		c0,17.541,14.216,31.756,31.756,31.756h302.684c17.532,0,31.755-14.215,31.755-31.756V47.295
		C425.704,29.762,411.482,15.543,393.949,15.543z M404.259,431.902c0,5.689-4.625,10.314-10.31,10.314H91.266
		c-5.688,0-10.313-4.625-10.313-10.314V47.295c0-5.689,4.625-10.314,10.313-10.314h81.655v26.133
		c0,9.592,7.777,17.367,17.364,17.367h104.641c9.59,0,17.363-7.775,17.363-17.367V36.981h81.66c5.685,0,10.31,4.625,10.31,10.314
		V431.902z"/>
</G>
</Svg>
                    );
                }