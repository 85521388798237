import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M380.1,176.7c-3.8-17.9-7.5-35.9-11.3-53.8c-6.4-30.7-13.3-63.3-22.3-105.9c-2.3-11-9.2-17-19.3-17.1l-0.1,0
		c-11.1,0-15.9,7.7-19.3,15.9c-4.4,10.6-9.2,17.9-14.6,22.5c-11.7,9.9-23.1,16.8-33.7,20.7c-39.8,14.4-80.6,27.6-122.3,40.9
		c-5.5,1.8-11.2,3.4-16.6,5.1c-21.1,6.3-43,12.7-62.2,25.7c-16.5,11.2-28.9,24.6-36.6,40c-7.9,15.8-11.1,33.7-9.5,53.3
		c3.8,46.8,25.5,78.7,64.4,95c6.5,2.7,13.3,4.8,20.6,6.1c-8.5,1.5-14.1,8-14.8,17.5l-0.1,0.8c-2.4,28.9-4.8,58.7-6.3,88.1
		c-0.9,17.8,6,32.4,20.2,42.3c10.3,7.2,22.7,11.1,35.6,11.1c12.8,0,25.6-3.9,36-10.8c11.2-7.5,19.2-18,23.3-30.4
		c5.3-16.1,7.5-33.1,9-47.6c2-18.8,3.2-37.3,4.5-56.8c0.5-6.9,0.9-14,1.4-21c27.9-4.4,60.5-8.7,93.4-8.7c6.9,0,13.7,0.2,20.2,0.6
		c9.7,0.5,19.9,1.5,29.1,4.6c9.3,3.2,17.6,8,23.4,13.6c5,4.8,10.6,10.2,18.3,10.2c2.8,0,5.6-0.7,8.5-2.1c13.4-6.6,10.6-20,9.3-25.8
		C398.8,266.1,389.3,220.7,380.1,176.7z M361.2,277.1c-19.7-4.3-40.4-6.4-62.9-6.4c-33.2,0-66.2,4.6-98.2,9
		c-7.9,1.1-16.1,2.2-24.1,3.3c-14.1,1.8-32.6,3.9-51.2,3.9c-6.8,0-13.3-0.3-19.4-0.8c-13.8-1.2-25.3-6.4-34.1-15.3
		c-8.3-8.4-14.3-20-17.8-34.7c-7.5-31.1,0.8-55.3,23.8-70.2c14.5-9.4,31.7-15.8,48.1-21.4c13.2-4.5,27.1-8.1,40.5-11.7
		c8.1-2.1,16.4-4.3,24.6-6.7c58.3-16.9,75.2-24.3,127.2-56.7l3.9,18.6L361.2,277.1z M148.7,326.2c4.8-0.5,9.6-0.9,15.1-1.4
		c1.2-0.1,2.4-0.2,3.7-0.3c-0.9,9.1-1.8,18.1-2.7,26.8c-2.4,24.8-4.7,48.2-8.1,71.8c-1.2,8.2-3.5,13.8-7.1,17.3
		c-3.4,3.3-8.2,4.8-14.9,4.8c-2.7,0-5.8-0.3-9.2-0.8c-8.2-1.3-11.1-4.8-10.4-12.8c0.4-4.7,1-9.4,1.5-14c0.7-6.1,1.5-12.5,1.9-18.8
		c1.2-18.4,2.4-36.3,2.6-54.2c0.1-8-3.6-14.3-9.9-17.4c3.9,0.3,7.9,0.4,12.1,0.4C130.6,327.6,138.7,327.1,148.7,326.2z"/>
	<Path fill={color} d="M90.4,88.6c2.1,0,4.3-0.3,6.6-0.8l2.2-0.5c12.3-3,24.9-6,37-10.5c4.9-1.8,8.6-5.8,11.3-8.7c0.8-0.9,1.6-1.7,2.1-2.2
		l1.4-1.2l0.1-1.9c0.2-7.6-1.9-13.8-6.1-18.2c-3.5-3.7-8.4-5.7-13.8-5.7h0c-1.9,0-3.9,0.2-5.9,0.7c-11.1,2.7-22.8,6-38,10.5
		c-12.2,3.6-18.5,13.3-15.5,24.1C74.3,83.2,81.3,88.6,90.4,88.6z"/>
	<Path fill={color} d="M407.5,139.7c-9.4,2.6-14.3,11.1-12.4,21.7c2.6,14.5,16.6,15.4,21.2,15.7l0.6,0l0.6-0.1c3-0.7,6.2-1.3,9.6-2
		c8.3-1.7,17.7-3.6,26.8-6.4c9.4-2.9,14.1-11.8,11.6-22.3c-2.2-9.2-8.8-14.9-17.3-14.9h0c-1,0-2,0.1-3,0.2
		C432.5,133.6,419.8,136.3,407.5,139.7z"/>
	<Path fill={color} d="M454.5,196.3c-7.3-0.7-17-1.6-26.7-1.9c-0.4,0-0.7,0-1.1,0c-12.1,0-20.3,7.2-20.9,18.3c-0.5,11,6.6,18.8,18.7,20.4
		c4.2,0.5,8.5,0.9,12.6,1.3c1.8,0.2,3.7,0.3,5.5,0.5l4.3,0.4l0-0.3c1.7,0.1,3.4,0.2,5.2,0.2l0.5,0c11.7-0.1,20.3-7.7,20.9-18.3
		C474,206.1,466,197.5,454.5,196.3z"/>
	<Path fill={color} d="M396.4,121.4c2.8,0,5.7-0.6,8.6-1.9c10.1-4.4,19.8-9.6,28.6-15.3c3.6-2.4,5.7-6.4,7-9.1c0.4-0.7,0.7-1.4,0.9-1.7l0.7-1.1
		l0-1.3c-0.1-12.6-8.3-21.8-19.3-21.8h0c-3,0-5.9,0.7-8.8,2c-8,3.6-16.2,7.9-26.6,14.1c-9.3,5.5-12.2,15-7.7,24.8
		C383.2,117.2,389.2,121.4,396.4,121.4z"/>
</G>
</Svg>
                    );
                }