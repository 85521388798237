import { LinearGradient } from "expo-linear-gradient";
import React, { useState } from "react";
import { Platform, ScrollView, StyleProp, View } from "react-native";
import { colors } from "styles";
import SText from "./SText";

//
//
//
// A horizontal carousel. used for native pagination with page indicators.
//
//
//

interface CarouselProps {
    children: React.ReactNode
    style?: StyleProp<any>
    paginated?: boolean
    pageWidth?: number
}

export default ({ children, style, paginated, pageWidth }: CarouselProps) => {

    const [carouselIndex, setCarouselIndex] = useState(0);    
    const childrenAmount = children.filter(c=>c !== false).length;
    
    
    return (
        <LinearGradient
            colors={[
                colors.gradientFrom,
                colors.gradientTo
            ]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={[{ justifyContent: "center" }, style]}
        >

            <ScrollView
                horizontal
                pagingEnabled={paginated}
                snapToInterval={pageWidth}
                disableScrollViewPanResponder={true}
                disableIntervalMomentum={true}
                showsHorizontalScrollIndicator={false}
                snapToAlignment="start"
                contentContainerStyle={{
                    marginBottom: (paginated && Platform.OS == 'web') ? '-2%' : 0,
                    marginRight: (!paginated) ? 24 : 0
                }}
                onScroll={({ nativeEvent }) => {
                    const index = Math.floor((nativeEvent.contentOffset.x / nativeEvent.contentSize.width) * 10 / 3);
                    (carouselIndex != index) && setCarouselIndex(index);
                }}
                scrollEventThrottle={32}
            >
                {children}
            </ScrollView>
            {(childrenAmount > 1) &&
                <View style={{ position: 'absolute', left: 0, right: 0, bottom: -1, flexDirection: 'row', justifyContent: "center" }}>
                    <SText lg bold greyTransparent={carouselIndex != 0} white={carouselIndex == 0}>• </SText>
                    <SText lg bold greyTransparent={carouselIndex != 1} white={carouselIndex == 1}> • </SText>
                    {(childrenAmount > 2) && <SText lg bold greyTransparent={carouselIndex != 2} white={carouselIndex == 2}> •</SText>}
                </View>            
            }
        </LinearGradient>
    );

}