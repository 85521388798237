import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M295,220.8l-37,54.7l18-69h-18l12.1-26.4c-6.7-6.8-13.5-13.6-20.2-20.5c-5.9-6-9.7-6-15.5-0.2
		c-37.7,38.4-75.4,76.7-113.2,115.1c-2.1,2.1-3,4.2-3,7.1c0.1,36.8,0,73.7,0.1,110.5c0,8.2,2.8,11,11,11c23.4,0,46.8,0,70.3,0
		c8.7,0,11.3-2.6,11.3-11.1c0-19.5,0-39,0-58.6c0-7.9,2.9-10.8,10.7-10.9c13.7-0.1,27.5-0.1,41.2,0c8.2,0,11,2.9,11,11.1
		c0,19.5,0,39,0,58.6c0,8.3,2.6,10.9,11,10.9c23.4,0,46.8,0,70.3,0c8.6,0,11.3-2.7,11.3-11.2c0-18.2,0-36.3,0-54.5
		c0-18.5-0.1-37,0.1-55.5c0.2-2.9-0.5-5-2.7-7.2c-17.3-17.4-34.5-34.9-51.8-52.3l-4,19.1L295,220.8z"/>
	<Path fill={color} d="M409.6,245c-24.4-24.8-48.8-49.6-73.2-74.4l-4.4,21.9l-11-13l-4.1,19.5c22.8,23.2,45.6,46.4,68.4,69.7c0.5,0.5,1,1,1.4,1.4
		c3.4,3.2,7.2,5.5,12.2,5.4c6.3,0.1,11.5-2.7,14.6-7.5c0.4-0.7,0.8-1.4,1.2-2.1c0.6-1.4,1.1-2.9,1.4-4.6
		C417.2,254.5,414.1,249.5,409.6,245z"/>
	<Path fill={color} d="M274,140.5l10.9-22.3c-9.1-9.3-18.3-18.6-27.4-27.9c-10.8-11-19.1-11-29.9,0c-35.8,36.4-71.6,72.9-107.5,109.3
		c-15.5,15.7-30.9,31.5-46.4,47.2c-4.7,4.8-6.3,10.4-4.3,16.8c1.9,6.2,6.3,10,12.6,11.3c6.8,1.4,12.1-1.6,16.8-6.4
		c42.8-43.6,85.7-87.2,128.5-130.7c10.4-10.6,19-10.6,29.5,0.1c7.4,7.5,14.9,15.1,22.3,22.7l6.9-15.1L274,140.5z"/>
</G>
</Svg>
                    );
                }