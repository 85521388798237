import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M183.111,107.553h279.983c11.601,0,21.008-9.401,21.008-21.007c0-11.607-9.407-21.009-21.008-21.009
		H183.111c-11.6,0-21.007,9.402-21.007,21.009C162.103,98.151,171.511,107.553,183.111,107.553z"/>
	<Path fill={color} d="M463.094,221.598H183.111c-11.6,0-21.007,9.402-21.007,21.009c0,11.605,9.408,21.008,21.007,21.008h279.983
		c11.601,0,21.008-9.402,21.008-21.008C484.102,231,474.695,221.598,463.094,221.598z"/>
	<Path fill={color} d="M463.094,377.66H183.111c-11.6,0-21.007,9.401-21.007,21.007c0,11.607,9.408,21.01,21.007,21.01h279.983
		c11.601,0,21.008-9.402,21.008-21.01C484.102,387.062,474.695,377.66,463.094,377.66z"/>
	<Path fill={color} d="M53.351,35.525c-28.177,0-51.02,22.837-51.02,51.021c0,28.183,22.843,51.02,51.02,51.02
		s51.021-22.837,51.021-51.02C104.371,58.362,81.528,35.525,53.351,35.525z"/>
	<Path fill={color} d="M53.351,191.586c-28.177,0-51.02,22.838-51.02,51.021s22.843,51.021,51.02,51.021
		s51.021-22.838,51.021-51.021S81.528,191.586,53.351,191.586z"/>
	<Path fill={color} d="M53.351,347.647c-28.177,0-51.02,22.837-51.02,51.02c0,28.184,22.843,51.021,51.02,51.021
		s51.021-22.837,51.021-51.021C104.371,370.484,81.528,347.647,53.351,347.647z"/>
</G>
</Svg>
                    );
                }