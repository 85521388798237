import { PortalInvitationType, QueryKeys, TenantShowCategoryKeys } from 'enums';
import {
    Customer,
    Damage,
    Document,
    Income,
    Living,
    Mortgage,
    Note,
    Obligation,
    OnlineAfsprakenAppointment,
    OnlineAfsprakenAppointmentCategory,
    OnlineAfsprakenAppointmentType,
    OnlineAfsprakenResource,
    Person,
    Policy,
    Possession,
    Retirement,
    Signal,
    Task,
    Tenant
} from 'models';
import moment from 'moment';
import { Platform } from 'react-native';
import { QueryClient } from "react-query";
import * as Sentry from 'sentry-expo';
import { ApiUtils, SentryUtils } from 'utils';


const prefetch = async (queryClient: QueryClient) => {

    //prefetch and wait for them to finish
    await Promise.all([
        queryClient.prefetchQuery(QueryKeys.TASKS, fetchTasks),
        queryClient.prefetchQuery(QueryKeys.SIGNALS, fetchSignals),
        queryClient.prefetchQuery(QueryKeys.NOTES, fetchNotes),
    ]);

    //wait for customer for sentry user
    const customer: Customer = await queryClient.fetchQuery(QueryKeys.CUSTOMER, fetchCustomer);
    const setUser = Platform.select({
        native: () => {
            Sentry.Native.setUser({
                id: customer.id,
                email: customer.email_address,
                username: customer.full_name
            });
        },
        default: () => {
            Sentry.Browser.setUser({
                id: customer.id,
                email: customer.email_address,
                username: customer.full_name
            });
        }
    });
    setUser();

    //wait for show_[category] from tenant data
    const tenant: Tenant = await queryClient.fetchQuery(QueryKeys.TENANT, fetchTenant);

    //prefetch without waiting    
    queryClient.prefetchQuery(QueryKeys.LIVING, fetchLiving);
    queryClient.prefetchQuery(QueryKeys.FAMILY, fetchFamily);
    queryClient.prefetchQuery(QueryKeys.DOCUMENTS, fetchDocuments);
    queryClient.prefetchQuery(QueryKeys.TENANT_DOCUMENTS, fetchTenantDocuments);

    tenant[TenantShowCategoryKeys.Polissen] !== false && queryClient.prefetchQuery(QueryKeys.DAMAGE, fetchDamage);
    tenant[TenantShowCategoryKeys.Inkomen] !== false && queryClient.prefetchQuery(QueryKeys.INCOME, fetchIncome);
    tenant[TenantShowCategoryKeys.Inkomen] !== false && queryClient.prefetchQuery(QueryKeys.RETIREMENT, fetchRetirement);
    tenant[TenantShowCategoryKeys.Verplichtingen] !== false && queryClient.prefetchQuery(QueryKeys.OBLIGATION, fetchObligation);
    tenant[TenantShowCategoryKeys.Polissen] && queryClient.prefetchQuery(QueryKeys.POLICY, fetchPolicy);
    tenant[TenantShowCategoryKeys.Hypotheken] !== false && queryClient.prefetchQuery(QueryKeys.MORTGAGE, fetchMortgage);
    tenant[TenantShowCategoryKeys.Afspraak] && queryClient.prefetchQuery(QueryKeys.APPOINTMENTS, fetchOnlineAfsprakenAppointments);
    tenant[TenantShowCategoryKeys.Afspraak] && queryClient.prefetchQuery(QueryKeys.APPOINTMENT_TYPES, fetchOnlineAfsprakenAppointmentTypes);
    tenant[TenantShowCategoryKeys.Afspraak] && queryClient.prefetchQuery(QueryKeys.APPOINTMENT_TYPES_RESOURCES, fetchOnlineAfsprakenResources);

}

//customer
const fetchCustomer = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get('/customer/customer?include=addresses,avatar,country_of_birth,identification,identification.issue_country,nationality,post_address,social,visit_address,advisor,advisor.avatar,account_manager,account_manager.avatar,invitation')
        .then(async (response) => {
            if (ApiUtils.isRequestSuccessful(response.status)) return new Customer(response.data.data);
            Promise.reject(response);
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'customer: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
            return [];
        })
}

//familie, partner/kinderen
const fetchFamily = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get('/customer/customer/family?include=country_of_birth,nationality,avatar')
        .then((response) => {
            if (ApiUtils.isRequestSuccessful(response.status)) {

                const family: {
                    children: Person[]
                    partner: Person
                } = {
                    children: response.data.data.children.map(child => new Person(child)),
                    partner: response.data.data.partner ? new Person(response.data.data.partner) : undefined
                };

                return family;

            } else {

                Promise.reject(response);

            }

        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'family: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//living
const fetchLiving = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get('/customer/living?include=customer_property.property')
        .then((response) => {
            return new Living(response.data.data)
        })
        .catch((error) => {
            if (error?.response?.status == 500) handleBadRequest(error?.response?.status || 500, 'living: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
            return [];
        })
}


//adviseur
const fetchTenant = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get('/customer/tenant')
        .then((response) => {
            if (ApiUtils.isRequestSuccessful(response.status)) {
                return new Tenant(response.data.data);
            }
            Promise.reject(response);
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'tenant: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
            return { error: true };
        })

}

//taken
const fetchTasks = async () => {
    const today = moment();
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get("/customer/task")
        .then((response) => {
            const tasks = new Array<Task>();
            if (ApiUtils.isRequestSuccessful(response.status)) {
                if (response.data.data instanceof Array) {
                    response.data.data.map((data: any) => {
                        const task = new Task(data);
                        if (task.description) tasks.push(task);
                    })
                } else {
                    const task = new Task(response.data.data);
                    tasks.push(task);
                }
            }
            const unfinishedTasks = new Array<Task>();
            const finishedTasks = new Array<Task>();
            tasks.forEach((task: Task) => {
                if (today.diff(moment(task.date)) > 0) {
                    if (!task.status) {
                        unfinishedTasks.push(task);
                    } else {
                        finishedTasks.push(task);
                    }
                }
            })

            return {
                unfinishedTasks,
                finishedTasks
            };

        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'task: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
            return {};
        })
}

//hypotheken
const fetchMortgage = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
    .get(
        "/customer/mortgage", 
        { params: { include: ['mortgage_properties.property', 'progress'] } }
    )
        .then(response => {
            const mortgages: Mortgage[] = response.data.data.map(data => new Mortgage(data));
            return mortgages;
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'mortgage: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//verzekeringen
const fetchPolicy = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get("/customer/policy")
        .then(response => {
            const policies: Policy[] = [];
            response.data.data.map((data: any) => {
                if (data.mutation_code !== 999) { //vervallen polissen doen niet mee!
                    policies.push(new Policy(data));
                }
            });
            return policies;
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'Policies: ' + + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//gedeelde documenten
const fetchDocuments = async () => {

    const apiClient = await ApiUtils.getApiClient({});

    try {

        const response = await apiClient.get("/customer/document?include=documents.damage,documents.mortgage")
        const documents: Record<string, Document[]> = {};

        response.data.data.map(category => {

            documents[category.name] = [];

            if (category.documents.data) {
                category.documents.data.map(document => {
                    documents[category.name].push(new Document(document));
                })
            } else {
                category.documents.map(document => {
                    documents[category.name].push(new Document(document));
                });
            }

            documents[category.name] = documents[category.name].reverse();

        });
        return documents;


    } catch (error) {

        // ApiUtils.handleBadRequest( error?.response?.status || 500, 'documents: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        return {};

    }
}

//tenant documenten
const fetchTenantDocuments = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get("/customer/tenant/document")
        .then(response => {

            const url = response.config.baseURL;
            const tenantDocuments: Document[] = [];

            response.data.data.map((doc: any) => {

                doc.is_tenant_document = true;
                doc.link = url + `/customer/tenant/document/${doc.id}?include=content`
                const document = new Document(doc);
                tenantDocuments.push(document);
            })

            return tenantDocuments;
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'documents: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//inkomen
const fetchIncome = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get("/customer/income?include=retirement_incomes")
        .then(response => {
            return new Income(response.data.data);
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'income: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//pensioen
const fetchRetirement = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get("/customer/retirement?include=retirement_benefit_income,old_age_retirement_incomes,employer_retirements,private_retirements,life_insurance_retirements")
        .then(response => {
            const r = new Retirement(response.data.data);
            return r;

        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'retirement: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//vermogens
const fetchPossession = async () => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get("/customer/possession")
        .then(response => {
            const possessions: Possession[] = response.data.data.map(data => new Possession(data));
            return possessions;
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'posession: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//verplichtingen
const fetchObligation = async () => {
    const obligations = new Array<Obligation>();
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get("/customer/obligation")
        .then(response => {
            response.data.data.map((data: any) => {
                obligations.push(new Obligation(data));
            });
            return obligations;
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'obligation: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//signals
const fetchSignals = async () => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient.get('/customer/signal/active')
        .then(response => {
            const signals: Signal[] = [];
            response.data.data.map((signal: any) => signals.push(new Signal(signal)));
            return signals;
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'signal: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//notes
const fetchNotes = async () => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient.get('/customer/note')
        .then(response => {
            const notes: Note[] = [];
            response.data.data.map((note: any) => notes.push(new Note(note)));
            return notes;
        })
        .catch((error) => {
            handleBadRequest(error?.response?.status || 500, 'notes: ' + error?.message + "\n\n" + JSON.stringify(error?.response?.data, null, 2));
        })
}

//damage
const fetchDamage = async () => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get('/customer/damage?include=progress_items')
        .then(({ data }) => {
            const damages: Damage[] = [];
            data.data.map(data => damages.push(new Damage(data)));
            return damages;
        });
}


//assessmenttoken
const fetchAssessmentToken = async (invitation: any, type?: PortalInvitationType) => {
    const data = {
        type: type,
        portal_invitation_id: !type ? invitation.id : undefined
    };
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .post('/customer/customer/assessment_token', data)
        .then(({ data }) => data.data.token);

}


const handleBadRequest = (code: number, message: string) => {
    if (message.includes('Network Error')) return;
    (code === 500) ? global.setError500() : global.setError(code);

    SentryUtils.sendError('Een api call went verkeerd: ' + message);
}

const fetchOnlineAfsprakenAppointmentTypes = async () => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get('/customer/online_afspraken/appointment_type/index')
        .then(({ data }) => {
            const onlineAfsprakenCategories: OnlineAfsprakenAppointmentCategory[] = [];
            data.data.map((data: any) => {
                let categoryIndex = onlineAfsprakenCategories.findIndex((onlineAfsprakenCategory: OnlineAfsprakenAppointmentCategory) => onlineAfsprakenCategory.id === data.category_id);
                if (categoryIndex < 0 && data.canBeBookedByConsumer === 1) {
                    const category = new OnlineAfsprakenAppointmentCategory({
                        id: data.category_id,
                        name: data.category,
                        onlineAfsprakenAppointmentTypes: [new OnlineAfsprakenAppointmentType(data)]
                    });
                    onlineAfsprakenCategories.push(category);
                } else {
                    if (data.canBeBookedByConsumer === 1) {
                        const category = onlineAfsprakenCategories[categoryIndex];
                        category.onlineAfsprakenAppointmentTypes.push(new OnlineAfsprakenAppointmentType(data));
                        onlineAfsprakenCategories[categoryIndex] = category;
                    }
                }
            });
            return onlineAfsprakenCategories;
        });
}

const fetchOnlineAfsprakenAppointments = async () => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get('/customer/online_afspraken/appointment/')
        .then(({ data }) => {
            const onlineAfsprakenAppointments: OnlineAfsprakenAppointment[] = [];
            data.data.map((data: object) => onlineAfsprakenAppointments.push(new OnlineAfsprakenAppointment(data)));
            return onlineAfsprakenAppointments;
        }).catch(() => {
            return new Array<OnlineAfsprakenAppointment>();
        });
}

const fetchOnlineAfsprakenResources = async () => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get('/customer/online_afspraken/resource/index')
        .then(({ data }) => {
            const resources: OnlineAfsprakenResource[] = [];
            data.data.map((data: object) => resources.push(new OnlineAfsprakenResource(data)));
            return resources;
        }).catch(() => {
            return new Array<OnlineAfsprakenResource>();
        });
}

export default {
    prefetch,
    fetchCustomer,
    fetchTenant,
    fetchDocuments,
    fetchTenantDocuments,
    fetchMortgage,
    fetchPolicy,
    fetchTasks,
    fetchFamily,
    fetchIncome,
    fetchObligation,
    fetchPossession,
    fetchRetirement,
    fetchSignals,
    fetchNotes,
    fetchDamage,
    fetchLiving,
    fetchAssessmentToken,
    fetchOnlineAfsprakenAppointmentTypes,
    fetchOnlineAfsprakenAppointments,
    fetchOnlineAfsprakenResources,
}
