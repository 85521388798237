import { useNavigation } from "@react-navigation/native";
import { Icons } from "assets/icons";
import { SText } from "components";
import { useHover, useSignalItems, useStyle } from "hooks";
import { Pressable, View, useWindowDimensions } from "react-native";
import * as Animatable from 'react-native-animatable';
import { colors } from "styles";


export const moduleWidths = {
    contact: 400,
    other: 340
}

export default ({ itemStyle = {}, hoveredItemStyle = false }) => {

    const { width } = useWindowDimensions();
    const styles = useStyle();
    const navigation = useNavigation();
    const { signalItems, brondata_added } = useSignalItems({ includeContactAndBrondata: true, excludeFinishedUpload: true, excludeSettledDamage: true, excludeReadMessages: true });


    let hookBankIndex = 0;
    const hookBank: any[] = [];
    for (let i = 0; i < 1000; i++) {
        hookBank[i] = useHover();
    }

    const renderInboxAndDocuments = () => {

        if (width < 992 || signalItems?.length == 0) return;

        const [hoveredInbox, hoverInboxElt] = hookBank[999];
        const [hoveredDocuments, hoverDocumentElt] = hookBank[998];

        return (
            <View style={{ flex: 1, height: '100%', justifyContent: "center" }}>

                {/* inbox icon */}
                <Pressable onPress={() => navigation.navigate('inbox')}>

                    <View ref={hoverInboxElt} style={{
                        height: 50,
                        width: 50,
                        borderRadius: 50,
                        backgroundColor: hoveredInbox ? colors.primary : colors.primaryLessTransparent,
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Icons.Inbox_all
                            size={20}
                            color={colors.white}
                        />
                    </View>

                    {hoveredInbox &&
                        renderInboxLabel()
                    }

                </Pressable>

                {/* spacer */}
                <View style={{ height: 50 }}></View>

                {/* documents icon */}
                <Pressable onPress={() => navigation.navigate('documenten')}>

                    <View ref={hoverDocumentElt} style={{
                        height: 50,
                        width: 50,
                        borderRadius: 50,
                        backgroundColor: hoveredDocuments ? colors.primary : colors.primaryLessTransparent,
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Icons.Doc
                            size={20}
                            color={colors.white}
                        />
                    </View>

                    {hoveredDocuments &&
                        renderDocumentLabel()
                    }

                </Pressable>

            </View>
        );

    }

    const renderInboxLabel = () => (
        <Animatable.View
            style={{
                position: 'absolute',
                left: -33,
                top: '120%',
                width: '100%',
                overflow: "visible"
            }}
            animation={'fadeIn'}
            duration={500}
        >

            <View style={{
                position: 'absolute',
                width: 10,
                height: 10,
                left: 55,
                top: -4,
                backgroundColor: colors.darkestgrey,
                transform: 'rotateZ(45deg)'
            }}></View>

            <SText xs white regular center style={{
                width: 115,
                padding: 8,
                borderRadius: 8,
                backgroundColor: colors.darkestgrey
            }}>
                berichten
            </SText>

        </Animatable.View>
    );

    const renderDocumentLabel = () => (
        <Animatable.View
            style={{
                position: 'absolute',
                left: -33,
                top: '120%',
                width: '100%',
                overflow: "visible"
            }}
            animation={'fadeIn'}
            duration={500}
        >

            <View style={{
                position: 'absolute',
                width: 10,
                height: 10,
                left: 55,
                top: -4,
                backgroundColor: colors.darkestgrey,
                transform: 'rotateZ(45deg)'
            }}></View>

            <SText xs white regular center style={{
                width: 115,
                padding: 8,
                borderRadius: 8,
                backgroundColor: colors.darkestgrey
            }}>
                documenten
            </SText>

        </Animatable.View>
    );


    //returning view largescreen or mobile
    if (width > 768) {

        //largescreen
        //convert items, calculate space
        const items = signalItems.map((item, index) => {
            const [hovered, hoverElt] = hookBank[hookBankIndex++];
            return (
                // @ts-ignore It doesn't like web specific styling...
                <View
                    key={index}
                    ref={hoverElt}
                    style={[
                        itemStyle,
                        { width: moduleWidths.other },
                        index == 0 && { width: moduleWidths.contact },
                        brondata_added && index == 1 && { width: 'auto' },
                        (hovered && hoveredItemStyle) && hoveredItemStyle,
                        index != 0 && styles('bigPaddingRight')
                    ]}
                >
                    {item}
                </View>
            )
        })

        //max amount of visible items
        const maxVos = Math.floor(Math.max(2, 1 + (width - moduleWidths.contact - 100) / (moduleWidths.other + 5)));
        return (
            <View style={{ flexDirection: 'row', height: '100%', flex: 1 }}>

                {items.slice(0, maxVos).map((item, index) => <View key={index}>{item}</View>)}
                {renderInboxAndDocuments()}

            </View>
        )

    } else {

        //mobile (contactComponent last)
        const items = signalItems?.slice(0, Math.min(signalItems.length || 0, 997));
        const contactComponent = items.shift();
        items.push(contactComponent);

        return (
            <>
                {items.map((item, index) => {
                    const [hovered, hoverElt] = hookBank[hookBankIndex++];
                    return (
                        <View key={index} ref={hoverElt} style={[
                            itemStyle,
                            { height: 'auto' },
                            (hovered && hoveredItemStyle) && hoveredItemStyle
                        ]}>
                            {item}
                        </View>
                    )
                })}
            </>
        );

    }

}
