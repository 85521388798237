import { ReactChildren } from "react";
import { Dimensions, View, ViewStyle } from "react-native"
import { colors } from "styles"
import SText from "./SText";


export default ({ Icon, title, children, keepLayout, style, iconSize }: { Icon: any, title: string, children: any | any[], keepLayout?: boolean, style: ViewStyle, iconSize?: Number }) => {

    const smallScreen = Dimensions.get('window').width < 768;

    return (
        <View style={{
            marginVertical: 20,
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: 'wrap',
            ...style
        }}>

            <View style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginLeft: -8
            }}>
                <Icon size={iconSize || 40} color={colors.primary} />
                <SText lg regular primary style={{ marginLeft: 8 }}>{title}</SText>
            </View>

            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: smallScreen ? 8 : 0,
                marginLeft: 0,
                flex: keepLayout ? 1 : undefined
            }}>
                {children}
            </View>

        </View>
    )
}
