import { useEffect, useRef, useState } from "react";

export default () => {

    const hoverElt = useRef(null);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        const enter = () => setHovered(true);
        const leave = () => setHovered(false);

        if (hoverElt.current?.addEventListener) {
            hoverElt?.current?.addEventListener('mouseenter', enter)
            hoverElt?.current?.addEventListener('mouseleave', leave)
            return () => {
                hoverElt?.current?.removeEventListener('mouseenter', enter);
                hoverElt?.current?.removeEventListener('mouseleave', leave);
            }
        }
    }, [hoverElt.current])

    return [hovered, hoverElt];
}