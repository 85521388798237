import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M57.342,185.265C25.673,185.265,0,210.924,0,242.607c0,31.679,25.673,57.341,57.341,57.341
		c31.668,0,57.341-25.662,57.341-57.341C114.682,210.924,89.01,185.265,57.342,185.265z"/>
	<Path fill={color} d="M242.57,185.265c-31.668,0-57.341,25.659-57.341,57.342c0,31.679,25.673,57.341,57.341,57.341
		c31.667,0,57.34-25.662,57.34-57.341C299.91,210.924,274.238,185.265,242.57,185.265z"/>
	<Path fill={color} d="M427.798,185.265c-31.668,0-57.341,25.659-57.341,57.342c0,31.679,25.673,57.341,57.341,57.341
		s57.342-25.662,57.342-57.341C485.14,210.924,459.466,185.265,427.798,185.265z"/>
</G>
</Svg>
                    );
                }