
import { colors } from "styles";
import { Office } from "./Office";

export class Tenant {
    id: string;
    name: string;
    logo: string;
    header: string;
    full_name: string;
    website: string;
    primary_color: string;
    secondary_color: string;
    tertiary_color: string;
    use_slogan: boolean;
    use_contact_email: boolean;
    use_support_email: boolean;
    slogan: string;
    contact_email: string;
    support_email: string;

    pronoun: string;
    show_account_manager: boolean | undefined
    show_income: boolean | undefined
    show_mortgages: boolean | undefined
    show_obligations: boolean | undefined
    show_opening_hours: boolean | undefined
    show_policies: boolean | undefined
    show_visiting_address: boolean | undefined

    online_afspraken_enabled: boolean

    // relations
    offices: Office[];

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Tenant {
        this.name = json.name ? json.name : "";
        this.logo = json.logo ? json.logo : "";
        this.header = json.header ? json.header : "";
        this.full_name = json.full_name ? json.full_name : "";
        this.website = json.website ? json.website : "";
        this.primary_color = json.primary_color ? json.primary_color : colors.error;
        this.secondary_color = json.secondary_color ? json.secondary_color : colors.white;
        this.tertiary_color = json.tertiary_color ? json.tertiary_color : false;
        this.use_slogan = json.use_slogan ? json.use_slogan : false;
        this.use_contact_email = json.use_contact_email ? json.use_contact_email : false;
        this.use_support_email = json.use_support_email ? json.use_support_email : false;
        this.slogan = json.slogan ? json.slogan : "";
        this.contact_email = json.contact_email ? json.contact_email : "";
        this.support_email = json.support_email ? json.support_email : "";

        this.pronoun = json.pronoun ? json.pronoun : "";
        this.show_account_manager = json.show_account_manager;
        this.show_income = json.show_income;
        this.show_mortgages = json.show_mortgages;
        this.show_obligations = json.show_obligations;
        this.show_policies = json.show_policies;
        this.show_opening_hours = json.show_opening_hours;
        this.show_visiting_address = json.show_visiting_address;

        this.online_afspraken_enabled = json.online_afspraken_enabled ? json.online_afspraken_enabled : false;

        this.offices = this.setOffices(json.offices);

        return this;
    }

    setOffices(json: any) {
        const offices = new Array<Office>();
        if (json) {
            if (json.data) {
                for (const data of json.data) {
                    offices.push(new Office(data));
                }
            } else {
                for (const data of json) {
                    offices.push(new Office(data));
                }
            }
        }
        return offices;
    }

}
