import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M32.8,324.1c6.2-0.2,6.2,0,6.2,6.2c0,9.4,0,18.9,0,28.4c11.9,0,23.3,0,35.1,0c0-11.6,0-22.9,0-34.3c112.5,0,224.6,0,337.2,0
	c0,11.7,0,23.1,0,34.4c11.8,0,23,0,34.8,0c0-11.6,0-22.8,0-34.8c4.5,0,8.5,0.2,12.4,0c12.6-0.7,22.2-9.9,22.3-22.4
	c0.3-31.5,0.2-63,0-94.5c-0.1-8.3-4.2-14.9-11.4-19.1c-15.8-9.2-34.7,1.8-34.9,20.5c-0.3,17.5-0.1,35.1-0.1,52.6c0,1.6,0,3.2,0,4.8
	c-128.3,0-255.8,0-383.7,0c0-1.9,0-3.6,0-5.2c0-17.4,0.2-34.8-0.1-52.1c-0.3-17.5-17-28.6-32.5-21.8c-7.6,3.3-11.5,9.6-14,17
	c0,33.8,0,67.7,0,101.5c2.4,4.1,4.1,8.7,7.2,12.2C17,323.8,24.9,324.3,32.8,324.1z"/>
<Path fill={color} d="M410.9,155.8c-0.4-18.8-8.8-27.3-27.5-29c-3.1-0.3-6.2-0.4-9.3-0.4c-87.7,0-175.3,0-263,0.1c-5.7,0-11.5,0.6-17.1,1.7
	c-9.8,1.8-16.1,7.9-18.2,17.6c-1.2,5.4-1.7,11-1.8,16.6c-0.2,25.3-0.1,50.6-0.1,75.9c0,1.4,0,2.7,0,4.3c112.4,0,224.4,0,337.1,0
	c0-8.6,0-16.9,0-25.2C411.1,196.8,411.4,176.3,410.9,155.8z"/>
</Svg>
                    );
                }