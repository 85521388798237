import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="phone">
	<Path fill={color} d="M417.964,337.608c-29.318-25.073-59.072-40.265-88.033-15.227l-17.293,15.135c-12.652,10.984-36.176,62.312-127.124-42.312
		c-90.934-104.494-36.82-120.764-24.15-131.653l17.388-15.152c28.81-25.096,17.938-56.688-2.84-89.208l-12.538-19.698
		C142.5,7.05,119.773-14.257,90.889,10.8L75.281,24.436c-12.766,9.3-48.448,39.529-57.104,96.955
		c-10.416,68.904,22.445,147.81,97.732,234.381c75.191,86.613,148.812,130.12,218.547,129.362
		c57.957-0.626,92.959-31.727,103.906-43.032l15.664-13.653c28.81-25.041,10.908-50.533-18.43-75.666L417.964,337.608z"/>
</G>
</Svg>
                    );
                }