import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M242.6,54.6c50.2,0,97.5,19.6,133,55.1s55.1,82.7,55.1,133s-19.6,97.5-55.1,133c-35.5,35.5-82.7,55.1-133,55.1
	s-97.5-19.6-133-55.1c-35.5-35.5-55.1-82.7-55.1-133s19.6-97.5,55.1-133C145.2,74.1,192.4,54.6,242.6,54.6 M242.6,4.6
	c-131.5,0-238,106.6-238,238c0,131.5,106.6,238,238,238c131.5,0,238-106.6,238-238C480.7,111.1,374.1,4.6,242.6,4.6L242.6,4.6z"/>
<G>
	<Path fill={color} d="M242,293.6L242,293.6c-17.1,0-31-13.9-31-31v-132c0-17,13.9-31,31-31h0c17,0,31,13.9,31,31v132
		C273,279.7,259.1,293.6,242,293.6z"/>
</G>
<G>
	<Circle fill={color} cx="242" cy="354.6" r="31"/>
</G>
</Svg>
                    );
                }