import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M372.5,337.3c-9.1-51-18-102.1-27.1-153.1c-0.5-2.7,0.1-4.4,2.1-6.3c13.8-13.6,27.5-27.3,41.1-41.1
	c14.1-14.3,12.1-35.8-4.1-46.7c-11.9-7.9-27.3-6.1-38.1,4.7c-13.7,13.6-27.4,27.3-41.1,41.1c-1.8,1.8-3.4,2.5-6.1,2
	c-15-2.8-30.1-5.4-45.2-8c-36.5-6.4-73.1-12.9-109.6-19.4c-10.2-1.8-19.8-0.4-28.2,5.6c-5.1,3.7-9.5,8.5-14.1,12.9
	c-6.1,6-5,11.3,2.8,14.6c35.5,15.2,71.1,30.5,106.6,45.7c9.1,3.9,18.3,7.9,27.9,12c-0.9,1-1.3,1.5-1.8,2
	c-21.4,21.4-42.8,42.8-64.2,64.3c-1.5,1.6-2.9,1.7-4.9,1.2c-17.6-4.1-35.2-8.2-52.8-12.2c-8.2-1.9-15.4,0.2-21.4,6.2
	c-4.5,4.5-9,9-13.5,13.5c-5.5,5.5-4.6,10.8,2.5,14.1c23.9,11.2,47.8,22.3,71.7,33.6c1.7,0.8,3.3,2.5,4.1,4.2
	c11.3,24,22.5,48,33.7,72.1c3,6.4,8.5,7.4,13.6,2.4c4.2-4.2,8.3-8.5,12.6-12.5c7.3-6.9,9.4-15,7.1-24.7c-4-16.8-7.7-33.7-11.7-50.5
	c-0.6-2.6-0.1-4.2,1.8-6c21-20.8,41.9-41.8,62.8-62.7c0.8-0.8,1.6-1.6,2.7-2.7c0.7,1.5,1.3,2.5,1.7,3.5
	c7.2,16.9,14.5,33.9,21.7,50.8c11.6,27,23.2,54.1,34.8,81.1c2.9,6.8,8.5,7.8,13.7,2.6c2.4-2.4,4.7-4.8,7.1-7.1
	C371.5,364.3,375.1,351.9,372.5,337.3z"/>
</Svg>
                    );
                }