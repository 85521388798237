import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M473.8,361.1l-83.6-40.4l-5.3-2.6l-2.7,5.3c-9.2,18.1-23.4,33.7-41.1,45c-18.5,11.9-39.6,18.1-61,18.1
	c-49.5,0-92.4-24-116.5-64.6h158.3h6.1v-6.1v-52.1v-6.1h-6.1H143.8c-0.5-5-0.8-9.9-0.8-15.2c0-5.7,0-11.6,0.6-17.2h178.3h6.1v-6.1
	v-51.4v-6.1h-6.1H164.5c11.1-17.9,26.1-32.9,43.7-43.6c21-12.8,45.9-19.6,71.9-19.6c21.3,0,42.4,6.4,61,18.4
	c17.4,11.2,32,27.1,41.1,44.8l2.7,5.3l5.3-2.6l83.6-41.1l5.8-2.8l-3.2-5.6C446.8,62.6,388.7,0.3,280.1,0.3
	c-55.7,0-107.6,15.5-149.9,44.9C89,73.8,59,114.1,43.5,161.9H12.3H6.2v6.1v51.4v6.1h6.1h18.9c-0.3,5.8-0.3,11.8-0.3,17.2
	c0,5.2,0,10.2,0.2,15.2H12.3H6.2v6.1v52.1v6.1h6.1h30.5c15.6,48.1,45.6,88.8,86.8,117.6c42.4,29.7,94.4,45.4,150.5,45.4
	c47.2,0,89.1-12.1,124.4-35.9c28.3-19.1,52.5-45.8,72-79.5l3.3-5.6L473.8,361.1z"/>
</Svg>
                    );
                }