import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 28.35 28.35" enableBackground="new 0 0 28.35 28.35" >
<G display="none">
	<Path fill={color} display="inline" d="M20.551,10.259c-0.466,0.206-0.965,0.346-1.491,0.409c0.537-0.323,0.948-0.83,1.145-1.438
		c-0.503,0.297-1.06,0.516-1.65,0.63c-0.473-0.505-1.146-0.819-1.895-0.819c-1.436,0-2.598,1.163-2.598,2.597
		c0,0.203,0.024,0.401,0.067,0.591c-2.157-0.108-4.071-1.142-5.352-2.713c-0.223,0.383-0.352,0.829-0.352,1.306
		c0,0.9,0.457,1.694,1.154,2.16c-0.427-0.013-0.826-0.13-1.177-0.325c0,0.012,0,0.021,0,0.033c0,1.257,0.896,2.308,2.084,2.546
		c-0.219,0.061-0.448,0.092-0.686,0.092c-0.167,0-0.329-0.018-0.489-0.048c0.332,1.031,1.291,1.781,2.426,1.804
		c-0.888,0.696-2.007,1.111-3.224,1.111c-0.21,0-0.416-0.014-0.619-0.035c1.148,0.735,2.513,1.164,3.979,1.164
		c4.775,0,7.386-3.954,7.386-7.385c0-0.112-0.001-0.226-0.005-0.337C19.763,11.236,20.204,10.779,20.551,10.259z"/>
</G>
<G display="none">
	<G display="inline">
		<Path fill={color} d="M10.686,9.16c-0.697,0-1.151,0.459-1.151,1.059c0,0.588,0.44,1.06,1.124,1.06h0.014
			c0.71,0,1.152-0.472,1.152-1.06C11.812,9.619,11.382,9.16,10.686,9.16z"/>
	</G>
	<G display="inline">
		<rect fill={color} x="9.655" y="12.115"  />
	</G>
	<G display="inline">
		<Path fill={color} d="M16.688,11.971c-1.081,0-1.562,0.596-1.834,1.014v0.02H14.84c0.004-0.006,0.008-0.013,0.014-0.02v-0.87
			h-2.037c0.025,0.574,0,6.127,0,6.127h2.037V14.82c0-0.183,0.014-0.366,0.066-0.497c0.148-0.366,0.482-0.743,1.045-0.743
			c0.737,0,1.031,0.561,1.031,1.384v3.278h2.037v-3.513C19.034,12.846,18.029,11.971,16.688,11.971z"/>
	</G>
</G>
<G>
	<Path fill={color} d="M20.555,9.4h-4.269V6.599c0-1.05,0.702-1.296,1.192-1.296c0.489,0,3.008,0,3.008,0V0.686l-4.146-0.021
		c-4.602,0-5.652,3.448-5.652,5.656V9.4H8.031v4.759h2.659c0,6.11,0,13.47,0,13.47h5.597c0,0,0-7.438,0-13.47h3.78L20.555,9.4z"/>
</G>
<Path fill={color} display="none" d="M25.981,9.333L12.749,22.566c-0.979,0.979-2.568,0.979-3.55,0l-6.831-6.832
	c-0.979-0.979-0.979-2.569,0-3.55c0.981-0.979,2.57-0.979,3.55,0l5.057,5.058L22.432,5.783c0.981-0.98,2.57-0.979,3.55,0
	C26.962,6.764,26.962,8.353,25.981,9.333z"/>
<G display="none">
	<Path fill={color} display="inline" d="M4.757,27.455L4.757,27.455c-1.199,0-2.174-0.976-2.174-2.172V3.331
		c0-1.197,0.975-2.172,2.174-2.172c0.376,0,0.75,0.101,1.081,0.291l19.007,10.966c0.672,0.387,1.078,1.083,1.085,1.862
		c0.008,0.79-0.393,1.503-1.07,1.911L5.839,27.164C5.507,27.354,5.133,27.455,4.757,27.455z M4.771,3.177
		c-0.105,0-0.188,0.048-0.188,0.154v21.952c0,0.194,0.172,0.196,0.258,0.146l19.003-10.965c0.068-0.042,0.086-0.115,0.085-0.168
		c-0.001-0.102-0.062-0.137-0.084-0.149L4.84,3.183C4.816,3.179,4.792,3.177,4.771,3.177z"/>
</G>
<G display="none">
	<Path fill={color} display="inline" d="M20.395,11.156c0-5.148-4.174-9.322-9.322-9.322S1.75,6.007,1.75,11.156
		s4.174,9.322,9.322,9.322C16.221,20.479,20.395,16.305,20.395,11.156z M11.072,18.148c-3.855,0-6.992-3.137-6.992-6.992
		s3.137-6.992,6.992-6.992s6.992,3.137,6.992,6.992C18.064,15.012,14.927,18.148,11.072,18.148z"/>
	<Path fill={color} display="inline" d="M25.927,22.716l-5.703-5.704c-0.85,1.322-1.974,2.446-3.296,3.296l5.704,5.703
		c0.911,0.912,2.388,0.912,3.295,0C26.839,25.104,26.839,23.627,25.927,22.716z"/>
</G>
<Path fill={color} display="none" d="M14.175,21.692c-0.473,0-0.945-0.181-1.307-0.54L1.526,9.81
	c-0.721-0.722-0.721-1.892,0-2.612c0.723-0.722,1.893-0.722,2.613,0l10.035,10.036L24.211,7.198c0.721-0.722,1.891-0.722,2.61,0
	c0.723,0.722,0.723,1.892,0,2.612L15.48,21.152C15.12,21.512,14.647,21.692,14.175,21.692z"/>
<Path fill={color} display="none" d="M21.692,14.174c0,0.473-0.181,0.946-0.54,1.307L9.811,26.823
	c-0.722,0.722-1.892,0.722-2.611,0c-0.723-0.722-0.723-1.892,0-2.612l10.034-10.036L7.198,4.138c-0.722-0.721-0.722-1.891,0-2.611
	c0.721-0.722,1.892-0.722,2.612,0l11.342,11.343C21.512,13.229,21.692,13.702,21.692,14.174z"/>
<Path fill={color} display="none" d="M6.658,14.174c0-0.473,0.181-0.946,0.539-1.307L18.54,1.525c0.722-0.721,1.892-0.721,2.611,0
	c0.723,0.723,0.723,1.893,0,2.613L11.117,14.174L21.152,24.21c0.723,0.721,0.723,1.891,0,2.61c-0.721,0.723-1.891,0.723-2.611,0
	L7.198,15.479C6.839,15.119,6.658,14.646,6.658,14.174z"/>
<Path fill={color} display="none" d="M15.48,7.196l11.342,11.344c0.723,0.721,0.723,1.891,0,2.61c-0.721,0.724-1.891,0.724-2.61,0
	L14.175,11.115L4.14,21.15c-0.722,0.724-1.892,0.724-2.613,0c-0.721-0.721-0.721-1.891,0-2.61L12.868,7.196
	c0.36-0.358,0.834-0.54,1.307-0.54S15.12,6.837,15.48,7.196z"/>
<Path fill={color} display="none" d="M26.091,1.871c-0.358-0.329-0.887-0.402-1.322-0.177L2.51,13.224
	c-0.438,0.229-0.686,0.707-0.616,1.196c0.07,0.489,0.441,0.882,0.925,0.978l9.308,1.845l2.549,8.709
	c0.139,0.473,0.561,0.808,1.053,0.835c0.022,0,0.043,0.001,0.064,0.001c0.467,0,0.892-0.28,1.073-0.717l9.514-22.896
	C26.565,2.722,26.451,2.202,26.091,1.871z"/>
<G display="none">
	<G display="inline">
		<Path fill={color} d="M19.648,13.653c0,1.603-0.67,3.612-2.649,3.612c-0.604,0-1.082-0.291-1.267-0.845l-0.021-0.009
			c-0.464,0.442-1.048,0.639-1.633,0.639c-2.014,0-2.641-1.635-2.641-2.792s0.627-2.78,2.641-2.78c0.584,0,1.169,0.194,1.633,0.637
			l0.12-0.399c0.031-0.086,0.117-0.151,0.216-0.151h0.336c0.087,0,0.162,0.077,0.162,0.163v3.905
			c0.011,0.368,0.098,0.714,0.627,0.714c0.833,0,1.418-1.157,1.418-2.65c0-2.368-1.591-4.196-4.381-4.196
			c-2.801,0-4.512,1.914-4.479,4.89c0.021,1.71,0.974,3.634,3.548,4.544c0.076,0.031,0.118,0.119,0.086,0.205l-0.259,0.596
			c-0.033,0.085-0.13,0.128-0.217,0.098c-2.812-0.994-4.186-3.052-4.208-5.441c-0.031-3.364,2.131-5.862,5.56-5.862
			C17.571,8.526,19.648,10.722,19.648,13.653z M15.463,13.102c-0.229-0.378-0.65-0.725-1.33-0.725c-1.418,0-1.624,1.417-1.624,1.894
			c0,0.486,0.215,1.882,1.624,1.882c0.68,0,1.102-0.347,1.33-0.725V13.102z"/>
	</G>
</G>
<G display="none">
	<G display="inline">
		<G>
			<Path fill={color} d="M13.132,9.154c0.084,0.065,0.184,0.153,0.297,0.264c0.113,0.106,0.221,0.239,0.326,0.396
				c0.104,0.154,0.192,0.337,0.269,0.545c0.075,0.208,0.114,0.443,0.114,0.709s-0.034,0.497-0.1,0.694
				c-0.066,0.2-0.156,0.377-0.27,0.533s-0.241,0.297-0.383,0.425c-0.141,0.128-0.287,0.249-0.438,0.363
				c-0.094,0.094-0.185,0.193-0.27,0.297c-0.084,0.104-0.127,0.237-0.127,0.396c0,0.162,0.043,0.286,0.127,0.376
				c0.085,0.091,0.165,0.168,0.241,0.236l0.51,0.396c0.152,0.133,0.3,0.264,0.447,0.398c0.146,0.132,0.272,0.273,0.382,0.424
				c0.109,0.152,0.196,0.321,0.263,0.511c0.066,0.188,0.1,0.406,0.1,0.653c0,0.331-0.082,0.656-0.242,0.979
				c-0.16,0.321-0.396,0.607-0.707,0.857c-0.312,0.251-0.702,0.455-1.17,0.61c-0.467,0.155-1.003,0.233-1.607,0.233
				c-0.51,0-0.956-0.057-1.338-0.171c-0.383-0.111-0.701-0.263-0.956-0.451c-0.256-0.188-0.442-0.405-0.566-0.646
				c-0.123-0.24-0.184-0.493-0.184-0.758c0-0.254,0.077-0.545,0.233-0.871c0.154-0.325,0.44-0.611,0.856-0.857
				c0.218-0.131,0.452-0.235,0.701-0.316c0.25-0.08,0.498-0.146,0.743-0.191c0.246-0.048,0.477-0.08,0.694-0.099
				c0.217-0.021,0.405-0.033,0.565-0.043c-0.104-0.132-0.2-0.272-0.289-0.417c-0.091-0.147-0.136-0.329-0.136-0.546
				c0-0.123,0.014-0.225,0.043-0.305c0.029-0.081,0.062-0.164,0.099-0.249c-0.075,0.011-0.15,0.018-0.226,0.021
				c-0.076,0.004-0.151,0.007-0.228,0.007c-0.377,0-0.714-0.063-1.006-0.189c-0.293-0.13-0.539-0.298-0.736-0.506
				c-0.197-0.207-0.35-0.444-0.453-0.715c-0.104-0.269-0.156-0.54-0.156-0.813c0-0.33,0.076-0.663,0.228-0.998
				C8.935,10,9.16,9.701,9.463,9.436c0.406-0.331,0.833-0.542,1.281-0.637c0.447-0.095,0.865-0.144,1.253-0.144h2.918l-0.907,0.498
				H13.132z M13.67,17.323c0-0.179-0.031-0.343-0.093-0.487c-0.062-0.146-0.153-0.29-0.276-0.433
				c-0.122-0.143-0.284-0.289-0.481-0.438c-0.198-0.15-0.43-0.32-0.693-0.509c-0.058-0.012-0.106-0.016-0.149-0.016
				c-0.042,0-0.101,0-0.177,0c-0.029,0-0.094,0.002-0.198,0.007c-0.104,0.006-0.227,0.014-0.369,0.027
				c-0.141,0.015-0.292,0.039-0.453,0.071c-0.16,0.035-0.316,0.073-0.467,0.12c-0.078,0.027-0.183,0.075-0.318,0.135
				c-0.138,0.062-0.273,0.147-0.404,0.263c-0.133,0.114-0.247,0.254-0.347,0.426c-0.1,0.17-0.149,0.382-0.149,0.639
				c0,0.508,0.224,0.925,0.673,1.244c0.448,0.319,1.056,0.483,1.82,0.483c0.68,0,1.197-0.146,1.551-0.435
				C13.493,18.136,13.67,17.769,13.67,17.323z M11.573,13.162c0.179,0,0.357-0.039,0.531-0.114c0.174-0.076,0.319-0.172,0.434-0.285
				c0.159-0.161,0.259-0.341,0.297-0.54c0.037-0.199,0.057-0.365,0.057-0.499c0-0.254-0.039-0.534-0.114-0.837
				c-0.075-0.304-0.188-0.588-0.34-0.854c-0.15-0.265-0.339-0.485-0.56-0.66c-0.223-0.177-0.479-0.264-0.771-0.264
				c-0.189,0-0.374,0.043-0.553,0.128s-0.326,0.197-0.438,0.341C9.992,9.73,9.909,9.896,9.866,10.076
				c-0.044,0.18-0.062,0.365-0.062,0.555c0,0.237,0.034,0.502,0.105,0.797c0.069,0.294,0.179,0.568,0.325,0.823
				c0.147,0.257,0.33,0.471,0.554,0.647C11.009,13.073,11.27,13.162,11.573,13.162z"/>
		</G>
	</G>
	<G display="inline">
		<G>
			<Path fill={color} d="M20.277,11.273h-2.003v2.015h-0.636v-2.015h-2.013v-0.634h2.013V8.656h0.636v1.983h2.003V11.273z"/>
		</G>
	</G>
</G>
<Path fill={color} display="none" d="M16.545,14.175l9.276-9.279c0.655-0.652,0.655-1.715,0-2.37
	c-0.653-0.652-1.715-0.652-2.369,0l-9.277,9.279L4.896,2.526c-0.652-0.652-1.715-0.652-2.37,0c-0.652,0.655-0.652,1.718,0,2.37
	l9.279,9.279l-9.279,9.277c-0.652,0.654-0.652,1.716,0,2.369c0.655,0.655,1.718,0.655,2.37,0l9.279-9.276l9.277,9.276
	c0.654,0.655,1.716,0.655,2.369,0c0.655-0.653,0.655-1.715,0-2.369L16.545,14.175z"/>
</Svg>
                    );
                }