import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<G>
		<Path fill={color} d="M435.593,76.82c-0.012-0.012-0.026-0.025-0.04-0.039C406.46,47.688,363.91,28.89,327.145,28.889
			c-38.893,0-75.429,15.117-102.882,42.566L37.667,258.052c-21.921,21.92-33.995,51.088-34,82.141
			c0.002,31.043,12.069,60.203,33.982,82.119c0.004,0.006,0.012,0.012,0.017,0.018c21.918,21.912,51.087,33.986,82.135,33.994
			c0.021,0,0.044-0.002,0.066,0c31.03-0.014,60.182-12.088,82.086-33.996l165.711-165.703c5.798-5.74,24.725-26.864,24.805-57.538
			c0.035-14.287-4.092-35.489-23.966-55.364c-22.308-22.308-45.847-25.668-61.665-24.559c-19.214,1.348-37.706,10-52.072,24.364
			l-134.89,134.887c-9.431,9.432-9.431,24.721,0,34.148c9.431,9.432,24.718,9.432,34.15,0L288.92,177.671
			c6.044-6.043,13.809-9.81,21.301-10.338c8.435-0.59,16.559,2.953,24.136,10.534c6.534,6.535,9.84,13.629,9.821,21.089
			c-0.03,12.366-8.746,21.62-10.576,23.433l-165.8,165.792c-12.784,12.787-29.8,19.836-47.926,19.848
			c-0.042-0.002-0.084,0-0.127-0.002c-18.127-0.02-35.147-7.068-47.938-19.854c-0.004-0.006-0.004-0.006-0.009-0.01
			c-12.793-12.791-19.839-29.83-19.839-47.965c0.002-18.148,7.053-35.191,19.855-47.996l186.597-186.598
			c18.327-18.326,42.736-28.42,68.731-28.42c23.857,0,54.388,13.876,74.267,33.753c0.01,0.009,0.02,0.019,0.026,0.026
			c19.358,19.36,31.384,46.908,31.384,71.896c0,25.995-10.09,50.404-28.42,68.731l-65.183,65.172
			c-9.433,9.432-9.433,24.719-0.003,34.148c0,0.002,0,0.002,0.003,0.002c9.43,9.432,24.715,9.432,34.144,0.002l65.188-65.176
			c27.448-27.447,42.566-63.986,42.566-102.879C481.116,144.962,464.098,105.324,435.593,76.82z"/>
	</G>
</G>
</Svg>
                    );
                }