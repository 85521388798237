export enum QueryKeys {
    CUSTOMER = 'customer',
    TASKS = 'tasks',
    DOCUMENTS = 'documents',
    TENANT_DOCUMENTS = 'tenantDocuments',
    TENANT = 'tenant',
    MORTGAGE = 'mortgage',
    POLICY = 'policy',
    FAMILY = 'family',
    LIVING = 'living',
    INCOME = 'income',
    OBLIGATION = 'obligation',
    POSSESSION = 'posession',
    RETIREMENT = 'retirement',
    SIGNALS = 'signals',
    NOTES = 'notes',
    DAMAGE = 'damage',
    DEVICE = 'device',
    APPOINTMENTS = 'appointments',
    APPOINTMENT_TYPES = 'appointment_types',
    APPOINTMENT_TYPES_RESOURCES = 'appointment_type_resources',
}
