import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M66.904,478.835c6.089,3.385,12.817,5.057,19.535,5.057c7.418,0,14.834-2.047,21.354-6.121l322.334-201.457
	c11.775-7.377,18.938-20.285,18.938-34.171c0-13.889-7.162-26.795-18.938-34.173L107.794,6.51
	c-12.422-7.771-28.083-8.205-40.89-1.062c-12.808,7.102-20.757,20.579-20.757,35.235v402.918
	C46.147,458.255,54.096,471.733,66.904,478.835z"/>
</Svg>
                    );
                }