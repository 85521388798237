import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M248.906,484.076H138.451L234.974,1.14h110.454L248.906,484.076z"/>
</G>
</Svg>
                    );
                }