import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 417 417"  >
<Path fill={color} d="M290.6,90.3c1.7,1.9,1,3-0.1,4.7c-11.9,16.7-23.7,33.4-35.5,50.1c-4,5.7-8.1,11.4-12,17c-26.1-12.9-49.4-9.3-70,11
	c-21-16.3-41.9-32.6-62.9-48.8c-2.1-1.6-1.3-3-0.6-4.7c4.6-12.3,2.9-23.8-5.5-33.7C95.6,75.9,84.5,72,71.6,74.7
	C58.1,77.6,49.4,86,46.1,99.3c-3.4,13.9,0.7,25.9,12.2,34.6c11.8,8.9,26,9.3,37.8,1.8c2.2-1.4,3.5-1.2,5.4,0.3
	c12.4,9.8,24.9,19.4,37.4,29.1c8.7,6.8,17.4,13.5,25.9,20.2c-10,25.3-5.6,47.2,13.7,66.3c-13.4,18.9-26.7,37.8-40,56.6
	c-24.1-13.3-48.9-4-60.5,12.9c-12.7,18.5-10.3,42.8,5.8,58.4c15.5,15,40.3,16.7,57.7,3.9c9-6.6,14.8-15.3,17.3-26.1
	c3.4-14.6-0.1-27.9-9.3-39.6c13.9-19.7,27.6-39,41.3-58.5c14.4,6.4,28.7,7,43.2,1.8c0.8-0.3,2.8,1,3.5,2
	c10.9,15.9,21.6,31.8,32.4,47.7c4.9,7.3,9.9,14.6,14.7,21.8c-16.2,18.9-10.6,42,0.9,53.2c12.9,12.7,33.3,14.7,48.3,4.6
	c15.1-10.3,20.7-29.8,13.3-46.9c-3.6-8.3-9.6-14.5-17.7-18.5c-10.7-5.3-21.7-5.2-32.8-0.7c-16.2-23.9-32.2-47.6-48.4-71.5
	c9.5-7.7,15.8-17.3,19.1-28.5c0.7-0.1,0.9-0.2,1.1-0.2c11.5,2.3,23.1,4.4,34.6,6.9c1,0.2,2.2,2.1,2.4,3.3c2.1,17.3,16.6,30,34,29.7
	c17.5-0.4,31.3-14,32.4-31.7c0.8-14.1-7.7-27.5-21-32.7c-13.4-5.2-30.1-1.7-37.6,10c-4.8,7.5-9.6,7.4-16.4,5.3
	c-0.6-0.2-1.2-0.2-1.8-0.3c-8.5-1.7-17-3.4-25.6-5.1c-0.5-14.7-5.5-27.3-15.4-37.8c0.7-1,1.2-1.8,1.8-2.6
	c15.4-21.7,30.8-43.4,46-65.2c1.7-2.4,3-2.6,5.6-1.6c20,7.6,42.4-1.1,52.3-20.2c9.7-18.8,4-42.4-13.8-54c-5.5-3.6-12.3-5.2-18.5-7.7
	c-3.4,0-6.9,0-10.3,0c-0.6,0.2-1.1,0.5-1.7,0.6C282.6,26.3,268.9,65.6,290.6,90.3z"/>
</Svg>
                    );
                }