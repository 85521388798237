import { View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import SText from "./SText";

export default ({ style = {}, textStyle = {} }) => {
    const { styles: scopedStyles } = scopedResponsiveStyle();
    return (
        <View style={[scopedStyles('container'), style]}>
            <SText xs medium greyTransparent style={[scopedStyles('text'), textStyle]}>#footer_text{new Date().getFullYear()} </SText>
            <SText xs regular greyTransparent style={[scopedStyles('disclaimer'), textStyle]}>#footer_disclaimer1</SText>
            <SText xs regular greyTransparent style={[scopedStyles('disclaimer'), textStyle]}>#footer_disclaimer2</SText>
        </View>
    )
}

const scopedResponsiveStyle = CreateResponsiveStyle({
    container: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end"
    },
    text: {
        fontSize: 13
    },
    disclaimer: {
        fontSize: 12
    }
}, {
    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
        text: {
            fontSize: 11
        },
        disclaimer: {
            fontSize: 10
        }
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
        text: {
            fontSize: 10
        },
        disclaimer: {
            fontSize: 8
        }
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        container: {
            flexDirection: 'column',
            justifyContent: "flex-end",
            alignItems: "center",
        },
        text: {
            fontSize: 10,
            textAlign: 'center'
        },
        disclaimer: {
            fontSize: 8,
            textAlign: 'center'
        }
    }
})