import { LinearGradient } from "expo-linear-gradient";
import { useStyle } from "hooks"
import { Dimensions } from "react-native";
import { colors } from "styles";
import Footer from "./Footer";


// deze is anders dan de carousel omdat hij switched van verticaal naar horizontaal
// wanneer het een klein scherm is. 
// overigens wel te scrollen met de muis!

export default ({ children, style = {} }) => {

    const styles = useStyle();
    const smallScreen = Dimensions.get('window').width < 768;

    return (
        <LinearGradient
            colors={[colors.gradientFrom, colors.gradientTo]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={[
                {
                    width: '100%',
                    flex: 1,
                    flexGrow: 1,
                    flexDirection: smallScreen ? 'column' : 'row',
                    flexWrap: smallScreen ? 'nowrap' : 'wrap',
                },
                styles('bigPaddingLeft'),
                styles('bigPaddingTop'),
                style,
                smallScreen && { paddingBottom: 40 }
            ]}
        >
            {children}
            <Footer style={{ bottom: 4 }} />
        </LinearGradient>
    )
}