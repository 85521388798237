import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M380.1,419c7.2-2.5,10-7.7,10-15.1c-0.1-36.3,0-72.5,0-108.8c0-68.9-0.1-137.8,0.1-206.7c0-8.4-2.6-14.2-10.5-17.3
	c-91.2,0-182.4,0-273.6,0c-8,2.7-11,8.3-11,16.6c0.1,105,0.1,209.9,0,314.9c0,8.4,2.8,14.2,11,17c91.2,0,182.4,0,273.6,0
	C379.8,419.3,379.9,419,380.1,419z M228.8,392c-35.4,0-70.7,0-106.2,0c0-44.2,0-88.5,0-133.1c35.5,0,70.7,0,106.2,0
	C228.8,303.3,228.8,347.5,228.8,392z M228.8,231.4c-35.4,0-70.7,0-106.3,0c0-44.4,0-88.6,0-133c35.4,0,70.8,0,106.3,0
	C228.8,142.7,228.8,186.9,228.8,231.4z M362.6,392c-35.3,0-70.5,0-106.1,0c0-44.4,0-88.5,0-133c35.3,0,70.6,0,106.1,0
	C362.6,303.3,362.6,347.6,362.6,392z M362.6,231.4c-35.4,0-70.6,0-106,0c0-44.3,0-88.5,0-133.1c35.4,0,70.6,0,106,0
	C362.6,142.8,362.6,187,362.6,231.4z"/>
</Svg>
                    );
                }