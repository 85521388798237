import { DrawerItem, useDrawerStatus } from '@react-navigation/drawer';
import { Icons } from 'assets/icons';
import { Badge, DrawerToggle, LanguageToggle, NotificationToggle, Person, SText } from 'components';
import { ChangePasswordModal, EditModal } from 'components/Modals';
import { DateTimeFormat, QueryKeys, TenantShowCategoryKeys } from 'enums';
import Constants from "expo-constants";
import { useHover, useNewMessageCount, useStyle, useTranslation } from 'hooks';
import moment from 'moment';
import { ModalContext } from 'providers/ModalProvider';
import { StoreContext } from 'providers/StoreProvider';
import { useContext, useEffect, useRef, useState } from 'react';
import { Platform, Pressable, ScrollView, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { useQuery } from 'react-query';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import { QueryUtils, SentryUtils } from 'utils';


const InboxIcon = Icons.Inbox_all;
const TasksIcon = Icons.Checklist;
const DamagesIcon = Icons.Home_insurence;
const AppointmentsIcon = Icons.Calendar;
const DocumentIcon = Icons.Doc;
const SettingsIcon = Icons.Settings;


const Drawer: React.FC<{ navigation: any, state: any }> = ({ navigation, state }) => {

    const styles = useStyle();
    const { showModal } = useContext(ModalContext);
    const translate = useTranslation();
    const { styles: scopedStyles } = scopedResponsiveStyles();

    const { data: family } = useQuery(QueryKeys.FAMILY, QueryUtils.fetchFamily);
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: documents } = useQuery(QueryKeys.DOCUMENTS, QueryUtils.fetchDocuments);
    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const { data: tenantDocuments } = useQuery(QueryKeys.TENANT_DOCUMENTS, QueryUtils.fetchTenantDocuments);

    const { logout, reloadApp } = useContext(StoreContext);

    const dropdownState = useState(false);
    const customerDropdownMenuItems = [
        {
            label: translate('#change_avatar'),
            useHover: useHover(),
            fn: () => {
                navigation.navigate('avatar');
            }
        },
        {
            label: translate('#change_info'),
            useHover: useHover(),
            fn: () => {
                showModal(EditModal, {}, 'EditModal');
            }
        },
        {
            label: translate('#change_password'),
            useHover: useHover(),
            fn: () => {
                showModal(ChangePasswordModal, {}, 'ChangePasswordModal');
            }
        },
        {
            label: translate('#login_other_account'),
            useHover: useHover(),
            fn: () => {
                reloadApp();
            }
        },
        {
            label: translate('#action_logout'),
            useHover: useHover(),
            fn: () => {
                setTimeout(() => logout(), 150);
            }
        },
    ];

    const navState = navigation.getState();
    const route = navState.routeNames[navState.index];

    //close settings when drawer is closed
    const [settingsOpen, setSettingsOpen] = useState(false);
    const isClosed = useDrawerStatus() === 'closed';
    const scrollRef = useRef(null);
    useEffect(() => {
        if (isClosed) {
            setSettingsOpen(false);
            const [_, setDropdownState] = dropdownState;
            setDropdownState(false);
            scrollRef.current?.scrollTo({ x: 0 });
        }
    }, [isClosed])

    //collect total/new counts
    const [badgeValues, setBadgeValues] = useState({
        inbox: { total: 0, new: 0 },
        tasks: { total: 0, new: 0 },
        damages: { total: 0, new: 0 },
        appointments: { total: 0, new: 0 },
        documenten: { total: 0, new: 0 },
    });
    const newMessages = useNewMessageCount();

    useEffect(() => {

        const newValues = { ...newMessages };
        //@ts-ignore
        newValues.documenten = {
            total: (documents ? Object.keys(documents).reduce((acc, key) => {
                //filter out documents when tenant turns off sections
                if (TenantShowCategoryKeys[key] && tenant[TenantShowCategoryKeys[key]] === false) return acc;
                return acc + documents[key].length;
            }, 0) : 0) + (tenantDocuments?.length || 0),
            new: 0
        };

        setBadgeValues(newValues);

    }, [newMessages])




    const renderCustomer = () => {

        const activeAddress = customer?.addresses && customer?.addresses.filter(address => address.active)[0] || { street_name: '', house_number: '', house_number_addition: '', city: '', postcode: '' };
        const privateName = `${customer?.first_name}${customer?.suffix ? ' ' + customer?.suffix : ''} ${customer?.last_name}`

        return (
            <>
                <Person
                    name={customer?.is_business ? customer?.full_name : privateName}
                    nationality={customer?.nationality?.code || 'nl'}
                    age={customer?.age}
                    sex={customer?.sex}
                    avatar={customer?.avatar}
                    isBusiness={customer?.is_business}
                    background={true}
                    renderDropdownMenu={renderCustomerDropdownItems}
                    dropdownState={dropdownState}
                />

                <View style={{ width: '80%', marginLeft: '20%', marginVertical: 16 }}>

                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        <View style={styles('rowContainer')}>
                            <SText regular sm black >{!customer?.is_business ? '#birth_on ' : '#founded_on '}</SText>
                            <SText medium sm black >{`${moment(customer?.date_of_birth).format(DateTimeFormat.date_of_birth)} `}</SText>
                        </View>
                        <View style={styles('rowContainer')}>
                            <SText regular sm black >#birth_at </SText>
                            <SText medium sm black >{customer?.place_of_birth && customer?.place_of_birth + ', '}{customer?.nationality?.name}</SText>
                        </View>
                    </View>

                    <SText regular sm black style={{ marginTop: 8 }}>
                        {`${activeAddress?.street_name || ""} ${activeAddress?.house_number || ""}${activeAddress?.house_number_addition ? activeAddress?.house_number_addition : ''}`}
                    </SText>
                    <SText regular sm black>
                        {`${activeAddress?.postcode?.substring(0, 4) || ""} ${activeAddress?.postcode?.substring(4).toUpperCase() || ""} ${activeAddress?.city || ""}`}
                    </SText>

                    <SText regular sm black style={{ marginTop: 8 }}>
                        {`${customer?.email_address}\n${customer?.phone || customer?.mobile}`}
                    </SText>
                </View >
            </>
        )
    }

    const renderCustomerDropdownItems = () => {

        return customerDropdownMenuItems.map((item, index) => {
            const [hovered, hoverElt] = item.useHover;
            return (
                <Pressable
                    key={index}
                    onPress={() => {
                        item.fn();
                        const [_, setDropdownState] = dropdownState;
                        setDropdownState(false);
                    }}
                    style={{
                        width: '100%',
                        height: 40,
                        borderBottomColor: colors.border,
                        borderBottomWidth: 1,
                        paddingLeft: 12,
                        cursor: 'pointer'
                    }}
                >
                    <View
                        ref={hoverElt}
                        style={{ width: '100%', height: '100%', justifyContent: 'center' }}
                    >
                        <SText regular={!hovered} medium={hovered} sm black style={[
                            { fontSize: 15 },
                            Platform.OS == 'web' && { userSelect: 'none' }
                        ]}>
                            {item.label}
                        </SText>
                    </View>
                </Pressable>
            )
        });
    }

    const renderPartner = () => {

        if (!family?.partner) return;

        return (
            <View style={{ marginTop: '4%' }}>
                <SText sm semibold black style={{ marginBottom: 8 }}>Partner</SText>
                <Person
                    name={`${family.partner?.first_name} ${family.partner?.last_name}`}
                    nationality={family.partner.nationality.code}
                    age={family.partner?.age}
                    sex={family.partner?.sex}
                    avatar={family?.partner.avatar}
                    background
                />
            </View>
        )
    }

    const renderChildren = () => {
        if (!family || family.children.length == 0) return;
        return (
            <View style={{ marginTop: '4%' }}>
                <SText sm semibold black style={{ marginBottom: 8 }}>Kinderen</SText>
                {
                    family.children.map((child, index) => {
                        return <Person
                            key={index}
                            name={`${child?.first_name} ${child?.last_name}`}
                            nationality={child.nationality.code}
                            age={child?.age}
                            sex={child?.sex}
                            avatar={child.avatar}
                            background
                            style={{ marginBottom: 12 }}
                        />
                    })
                }
            </View>
        )
    }

    const renderCustomerNav = () => {

        const items = [
            { title: translate('#title_documents'), icon: DocumentIcon, route: 'documenten' },
            { title: translate('#title_inbox'), icon: InboxIcon, route: 'inbox' },
            { title: translate('#title_tasks'), icon: TasksIcon, route: 'tasks' },
        ];

        if (tenant && tenant[TenantShowCategoryKeys.Schades]) {
            items.push({ title: translate('#title_damages'), icon: DamagesIcon, route: 'damages' });
        }

        if (tenant && tenant[TenantShowCategoryKeys.Afspraak]) {
            items.push({ title: translate('#title_appointments'), icon: AppointmentsIcon, route: 'appointments' });
        }

        items.push({ title: translate('#title_settings'), icon: SettingsIcon });

        return (
            <View style={scopedStyles('customerNavigation')}>
                {items.map((Item, index) => {

                    if (Item.title == translate('#title_settings')) {

                        //settings menu item
                        if (settingsOpen) {

                            return (
                                <Animatable.View
                                    key="settngskey"
                                    style={scopedStyles('settings')}
                                    animation={{
                                        from: { height: 41 },
                                        to: { height: 80 },
                                    }}
                                    delay={150}
                                    duration={300}
                                >
                                    <View style={[styles('line'), { flex: 1, minHeight: 40, alignItems: 'center', paddingLeft: '20%', paddingVertical: 8, borderBottomWidth: 1, borderColor: colors.border }]}>
                                        <SText black sm regular style={{ paddingLeft: 8 }}>#language</SText>
                                        <LanguageToggle />
                                    </View>
                                    <View style={[styles('line'), { minHeight: 40, alignItems: 'center', paddingLeft: '20%', paddingVertical: 8, borderBottomWidth: 1, borderColor: colors.border }]}>
                                        <SText black sm regular style={{ paddingLeft: 8 }}>#notifications</SText>
                                        <NotificationToggle />
                                    </View>
                                </Animatable.View>
                            );

                        } else {

                            //settings menu item normal type (but only opens settings, and no badger)
                            return (
                                <View key={index} style={{ flexDirection: 'row' }}>
                                    <DrawerItem
                                        label={Item.title}
                                        style={[
                                            scopedStyles('navItem'),
                                            (index === items.length - 1) && {
                                                borderBottomWidth: 1
                                            },
                                            Platform.OS == 'web' && { userSelect: 'none' }
                                        ]}
                                        labelStyle={[
                                            styles('text-regular'),
                                            styles('text-black'),
                                            styles('text-sm')
                                        ]}
                                        icon={({ size }) => (
                                            <View style={{ width: '20%', marginRight: -32, justifyContent: 'center', alignItems: 'center' }}>
                                                <View style={{ marginLeft: -8 }}>
                                                    <Item.icon
                                                        size={size - 6}
                                                        color={colors.black}
                                                    />
                                                </View>
                                            </View>
                                        )}
                                        onPress={() => setSettingsOpen(true)}
                                    />

                                </View>
                            );

                        }

                    }

                    //standard menu item
                    return (
                        <View key={index} style={{ flexDirection: 'row', overflow: 'visible' }}>
                            <DrawerItem
                                label={Item.title}
                                style={[
                                    scopedStyles('navItem'),
                                    (index === items.length - 1) && {
                                        borderBottomWidth: 1
                                    },
                                    Platform.OS == 'web' && { userSelect: 'none' },
                                    (route == Item?.route) && { backgroundColor: colors.lightergrey }
                                ]}

                                labelStyle={[
                                    styles('text-regular'),
                                    styles('text-black'),
                                    styles('text-sm')
                                ]}
                                icon={({ size }) => (
                                    <View style={{ width: '20%', marginRight: -32, justifyContent: 'center', alignItems: 'center' }}>
                                        <View style={{ marginLeft: - 8 }}>
                                            <Item.icon
                                                size={size - 6}
                                                color={colors.black}
                                            />
                                        </View>
                                    </View>
                                )}
                                onPress={() => {
                                    navigation.navigate(Item.route);
                                    SentryUtils.breadCrumb({
                                        route: 'drawer-open-route',
                                        data: { ...Item, icon: undefined }
                                    });
                                }}
                            />

                            <Badge
                                sm
                                disabled={true}
                                value={badgeValues[Item.route].total}
                                style={scopedStyles('navItemBadge')}
                            >
                                {badgeValues[Item.route].new > 0 &&
                                    <View
                                        style={{
                                            position: 'absolute',
                                            right: -4,
                                            top: -2,
                                            width: 8,
                                            height: 8,
                                            borderRadius: 8,
                                            backgroundColor: 'red',
                                            zIndex: 99
                                        }}
                                    ></View>
                                }
                            </Badge>

                        </View>
                    );
                })}
            </View>
        )
    }

    const renderBuildNumber = () => {
        return (
            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                <SText xs grey regular center style={{ marginTop: 4 }}>
                    DossierData © {new Date().getFullYear() + ' ' + Constants?.expoConfig?.version}
                </SText>
            </View>
        )
    }

    return (
        <View style={scopedStyles('drawer')}>

            <Pressable onPress={navigation.toggleDrawer} style={scopedStyles('drawerToggleWrapper')}>
                <DrawerToggle
                    type={'close'}
                    style={[scopedStyles('drawerToggle'), styles('bigMarginRight')]}
                    onPress={navigation.toggleDrawer}
                />
            </Pressable>

            <ScrollView
                ref={scrollRef}
                style={{ flex: 1, paddingHorizontal: '5%' }}
                contentContainerStyle={{ flexGrow: 1 }}
                showsVerticalScrollIndicator={false}
            >

                {renderCustomer()}
                {renderCustomerNav()}

                {renderPartner()}
                {renderChildren()}

                {renderBuildNumber()}

            </ScrollView>

            <View style={{ height: 12, width: '100%', boxShadow: (Platform.OS === 'web') ? '0px 0px 10px 0 white' : undefined }} />

        </View>
    );
};

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        drawer: {
            flex: 1,
            overflow: 'hidden'
        },
        drawerToggle: {
            position: 'absolute',
            right: 0,
            top: 0,
            height: 80
        },
        drawerToggleWrapper: {
            width: '100%',
            height: 80,
            backgroundColor: colors.background,
            marginBottom: 8
        },
        customerNavigation: {
            marginTop: '4%'
        },
        navItem: {
            margin: 0,
            marginHorizontal: 0,
            marginVertical: 0,
            borderBottomColor: colors.border,
            borderTopColor: colors.border,
            borderTopWidth: 1,
            borderRadius: 0,
            flex: 1,
            zIndex: 2
        },
        navItemBadge: {
            position: 'absolute',
            right: 4,
            alignSelf: 'center',
            zIndex: 99
        },
        editIcon: {
            position: 'absolute',
            width: 20,
            height: 20,
            padding: 1,
            top: 16,
            right: 8,
            zIndex: 2
        },
        settings: {
            height: 'auto',
            paddingBottom: 8,
            borderColor: colors.border,
            borderBottomWidth: 1,
            borderTopWidth: 1,
            overflow: 'hidden'
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            drawerToggle: {
                right: 4
            }
        }
    }
);

export default Drawer;
