import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M158.2,340.8c-11-1.9-22.2-2.5-33.3-3.3c-9.4-0.7-17.1-4.3-23-11.6c-5.1-6.3-6.8-13.5-6.8-21.4c0-48.1,0-96.2,0-144.4
		c0-1.4,0-2.8,0-4.1c-12.6-1.4-16.5,0.3-20.8,9c0,65.6,0,131.1,0,196.7c3.9,8.2,8.1,10.4,15.9,9.1c16.3-2.6,32.6-5.4,49-7.3
		c19-2.1,38.1-1.5,57,1.2c6.4,0.9,12.8,2.2,19.9,3.4c-2.4-2-4.1-3.6-6-5.1C194.8,351,177.2,344,158.2,340.8z"/>
	<Path fill={color} d="M230.2,161.7c-12.6-19.6-30.7-32-52.5-39.5c-16.1-5.5-32.8-7.7-49.7-8.2c-9.1-0.3-14.3,4.5-14.3,13.5
		c0,59.2,0,118.4,0,177.6c0,8.7,4.7,13.3,13.3,13.8c10.4,0.7,20.8,1.2,31,2.8c27.8,4.3,52.8,14.9,73.2,34.9c0.5,0.5,1.1,0.9,2,1.6
		c0.1-0.9,0.1-1.5,0.1-2c0-61.8,0-123.6-0.1-185.5C233.2,167.7,231.9,164.3,230.2,161.7z M209.6,234.9l-20,20c-1,1-2.3,1.6-3.8,1.6
		c-1.4,0-2.8-0.5-3.8-1.6c-2.1-2.1-2.1-5.5,0-7.6l10.9-10.9h-48.1c-2.9,0-5.3-2.4-5.3-5.3c0-2.9,2.4-5.3,5.3-5.3h48.1l-10.9-10.9
		c-2.1-2.1-2.1-5.5,0-7.6c1-1,2.4-1.6,3.8-1.6c1.4,0,2.8,0.5,3.8,1.6l20,20c1,1,1.6,2.3,1.6,3.8
		C211.1,232.5,210.6,233.9,209.6,234.9z"/>
	<Path fill={color} d="M357.1,318.9c9.9-0.2,14.4-4.7,14.4-14.6c0-25.5,0-51.1,0-76.6c0-33.4,0-66.9,0-100.3c0-8.4-5.1-14-13.1-13.4
		c-11.6,0.9-23.2,1.5-34.6,3.8c-25.3,5-47.3,16.2-63.9,36.7c-5.3,6.5-8.3,13.2-8.2,22c0.4,59.1,0.2,118.2,0.2,177.2
		c0,1.3,0,2.6,0,4.2c0.7-0.2,0.9-0.2,1-0.3c0.6-0.5,1.1-1,1.7-1.6c11.8-11.6,25.7-20,41.1-26C315.4,322.4,336,319.3,357.1,318.9z
		 M285,239.3V233h4.4c0-0.4-0.1-1.1-0.1-1.9c0-0.9,0-1.6,0.1-2H285v-6.3h5.5c1.5-5.1,4.4-9.2,8.6-12.2c4.2-3,9.1-4.5,14.9-4.5
		c5.8,0,10.8,1.5,15,4.6c4.2,3.1,6.9,7.3,8.3,12.6h-13.2c-1-2-2.4-3.5-4.2-4.6c-1.8-1.1-3.8-1.6-6.1-1.6c-2.2,0-4.3,0.5-6,1.5
		c-1.8,1-3.2,2.4-4.3,4.2h11.7v6.3h-13.7c0,0.4-0.1,1.1-0.1,2c0,0.8,0,1.5,0.1,1.9h13.7v6.2h-11.8c1.1,1.9,2.5,3.4,4.3,4.4
		c1.8,1,3.8,1.5,6.1,1.5c2.3,0,4.3-0.5,6.1-1.6c1.8-1.1,3.2-2.6,4.2-4.6h13.2c-1.4,5.3-4.2,9.5-8.3,12.6c-4.2,3.1-9.2,4.6-15,4.6
		c-5.8,0-10.8-1.5-15-4.6c-4.2-3-7-7.2-8.6-12.3H285z"/>
	<Path fill={color} d="M410.9,166.4c-0.6-5.2-4.5-9.7-9.6-10.4c-3.5-0.5-7.2-0.1-11.2-0.1c0,1.5,0,2.9,0,4.3c0,47.9,0,95.8,0,143.7
		c0,3.4-0.1,6.9-1,10.1c-3.6,13.8-14.4,22.4-28.7,23.4c-8.6,0.6-17.3,0.9-25.9,2c-17,2.2-33.2,7-48,15.8c-6,3.6-11.6,8-17.9,12.5
		c12.5-1.7,23.8-3.7,35.2-4.6c29.5-2.1,58.7,0.6,87.5,6.8c1.5,0.3,3,0.7,4.5,0.9c8.9,1.3,15-3.8,15-12.8c0-63.2,0-126.3,0-189.5
		C411,167.9,411,167.1,410.9,166.4z"/>
</G>
</Svg>
                    );
                }