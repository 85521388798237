import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M462.437,216.928H301.622c-6.542-3.496-13.319-6.985-20.609-10.477c-33.212-15.841-54.255-26.828-63.141-32.939
		c-8.9-6.102-15.384-12.421-19.453-18.959c-4.075-6.535-6.104-14.197-6.104-22.981c0-14.145,5.028-25.601,15.095-34.39
		c10.079-8.772,24.537-13.172,43.399-13.172c15.854,0,31.979,2.046,48.369,6.108c16.388,4.074,37.117,11.247,62.182,21.533
		l32.13-77.452c-24.214-10.489-47.387-18.632-69.573-24.415c-22.185-5.792-45.469-8.682-69.895-8.682
		c-49.711,0-88.649,11.89-116.832,35.673c-28.176,23.783-42.268,56.456-42.268,98.013c0,22.075,4.292,41.354,12.854,57.845
		c4.505,8.677,9.856,16.747,15.966,24.295H22.777v51.368h170.481c4.332,2.191,8.796,4.398,13.522,6.614
		c30.635,14.561,50.948,25.178,60.895,31.808c9.954,6.643,17.508,13.551,22.658,20.735c5.138,7.175,7.703,15.373,7.703,24.578
		c0,16.492-5.845,28.923-17.509,37.279c-11.682,8.356-28.456,12.527-50.296,12.527c-18.215,0-38.3-2.89-60.258-8.675
		c-21.97-5.786-48.796-15.746-80.506-29.895v92.561c38.568,18.853,82.911,28.282,133.045,28.282
		c54.41,0,96.892-12.218,127.426-36.633c30.517-24.426,45.773-57.85,45.773-100.271c0-30.635-8.139-56.515-24.415-77.608
		c-0.344-0.444-0.777-0.88-1.132-1.314h92.264v-51.357H462.437z"/>
</G>
</Svg>
                    );
                }