import { Button, SText } from 'components';
import BaseModal from 'components/Modals/BaseModal';
import { useTenantLogo } from 'hooks';
import { HideKeyboardProvider } from 'providers';
import { ModalContext } from 'providers/ModalProvider';
import { StoreContext } from 'providers/StoreProvider';
import { useContext, useEffect, useState } from "react";
import { Dimensions, Image, StyleSheet, View } from "react-native";
import { ResetForm } from 'screens/login';
import { colors } from 'styles';
import { ApiUtils, AuthUtils, CustomerUtils } from "utils";


export const Password = () => {

    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);
    const [clearValues, setClearValues] = useState(false);
    const { showModal } = useContext(ModalContext);
    const { query, reloadApp } = useContext(StoreContext);

    const logo = useTenantLogo();
    const [username, setUsername] = useState();

    const fetchUsername = async () => {
        try {
            const response = await CustomerUtils.getUsernameByToken(query.get('token'));
            if (response?.data?.data?.customer?.data?.username) {
                setUsername(response.data.data.customer.data.username);
            }
        } catch (error) {
            setResponseMessage({
                error: true,
                message: '#login_error_token'
            });
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchUsername()
    }, []);

    const handleGotoLogin = async () => reloadApp();

    const handleReset = async ({ password }: { password: string, tenant: string }) => {

        setLoading(true);
        setResponseMessage(null);

        const response = await AuthUtils.resetPassword(password, query.get('token'));

        if (response.success) {

            const tenant = await ApiUtils.getTenant();
            await AuthUtils.retrieveTokens({ username, password, tenant });
            showModal(successModal, { login: () => handleGotoLogin() }, (query.get('register') ? 'register' : 'password_reset') + '/confirm-modal');

            setLoading(false);
            setClearValues(v => !v);

        } else {

            setResponseMessage({
                error: true,
                message: '#login_error_retry'
            });
            setLoading(false);

        }

    }

    const successModal = ({ settings }: { settings: { login: () => void } }) => {
        return (
            <BaseModal
                hideModal={settings.login}
                style={{ width: 600, minHeight: 300, height: 300, flex: 0, marginTop: 300 }}
            >
                <View style={{ flex: 1, justifyContent: 'center' }}>
                    <SText lg regular primary center>{query.get('reset_password') ? '#password_changed' : '#password_saved'}</SText>
                </View>
                <View style={{ height: 100, flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button
                        label={'#action_goto_login ➜'}
                        onPress={settings.login}
                        style={{ marginRight: 20 }}
                    />
                </View>
            </BaseModal>
        )
    };



    return (
        <HideKeyboardProvider>
            <View style={scopedStyles.container}>

                <View style={scopedStyles.loginSection} >

                    <View style={scopedStyles.loginContainer}>

                        <Image 
                            source={logo} 
                            resizeMode="contain"
                            style={scopedStyles.logo} 
                        />

                        <SText xl medium primary style={{ marginTop: -20 }}>{query.get('reset_password') ? '#title_reset_password' : '#title_save_password'}</SText>

                        <SText sm light black>#welcome</SText>

                        <View style={{ width: '100%', marginVertical: 12 }}>
                            <ResetForm
                                username={username}
                                loading={loading}
                                handleReset={handleReset}
                                hideButton={responseMessage && responseMessage.error != true}
                                clearValues={clearValues}
                                buttonText={query.get('reset_password') ? '#change_password' : '#save_password'}
                            />
                        </View>

                        {responseMessage?.error &&
                            <SText sm medium black style={[
                                scopedStyles.recoveryMessage,
                                { backgroundColor: colors.error }
                            ]}>
                                {responseMessage.message}
                            </SText>
                        }

                    </View>

                </View>

                <View style={scopedStyles.imageSection}>
                    <Image 
                        source={require('assets/images/login.png')} 
                        resizeMode="cover"
                        style={{ width: '100%', height: '100%' }} 
                    />
                </View>

            </View>
        </HideKeyboardProvider>
    );
}

const scopedStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        height: '100%'
    },
    logo: {
        width: '100%',
        height: '15%',
        marginBottom: 50
    },
    loginSection: {
        flex: (Dimensions.get('window').width > 768) ? 0.3 : 1.0,
        height: '100%',
        minWidth: (Dimensions.get('window').width > 768) ? 500 : 0
    },
    recoveryMessage: {
        marginTop: 12,
        paddingVertical: 20,
        paddingHorizontal: 8,
        color: colors.white,
        borderRadius: 4,
        textAlign: 'center'
    },
    imageSection: {
        flex: (Dimensions.get('window').width > 768) ? 0.7 : 0,
    },
    loginContainer: {
        width: '70%',
        marginLeft: '15%',
        height: '100%',
        justifyContent: 'center'
    }
})