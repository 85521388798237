import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M142,407.6c-13.3-33.5-26.6-66.7-39.9-100.2c-11.2,6.7-22.1,13.3-33.2,19.9c13.4,33.5,26.6,66.9,39.9,100.2
		c0.2,0,0.5,0,0.7,0c5.1-3.2,10.2-6.5,15.4-9.6C130.5,414.5,136.2,411.1,142,407.6z"/>
	<Path fill={color} d="M409,273.1c-19.4-5.8-37.9-4-55.5,6c-11.4,6.5-22.7,13.1-33.9,19.7c-10.3,6-20.7,11.9-30.8,18.3c-5.3,3.4-10.7,5.6-17,5.6
		c-15.6-0.1-31.3,0-46.9,0c-1.1,0-2.2,0-3.6,0c0-5.7,0-11,0-16.8c6.5,0,12.7,0,18.9,0c8.2,0,16.3,0.1,24.5,0
		c7.6-0.1,13.2-4.2,15.1-10.8c2.7-9.5-4.1-18.9-14.2-19.1c-10.7-0.2-21.4-0.3-32.1-0.2c-10.9,0.1-21.8,0.3-32.1-4.3
		c-0.9-0.4-2-0.6-3-0.8c-16.6-3.7-32.2-2.4-46.1,8.5c-11.5,9.1-18.4,21.2-23.1,34.8c-0.4,1,0,2.5,0.4,3.6c8.4,21.3,17,42.6,25.4,64
		c0.9,2.2,2,2.8,4.3,2.8c32.6-0.1,65.3,0,97.9-0.1c1.7,0,3.7-0.5,5.1-1.4c49.6-31.1,99.2-62.3,148.8-93.5c3.1-1.9,5.6-4.3,5-8.3
		C415.6,276.9,413.1,274.3,409,273.1z"/>
	<Path fill={color} d="M300.2,57.6c-48.5,0-87.7,39.3-87.7,87.7s39.3,87.7,87.7,87.7s87.7-39.3,87.7-87.7S348.6,57.6,300.2,57.6z M306.3,133v9.4
		H286c-0.1,0.6-0.1,1.6-0.1,3c0,1.3,0,2.2,0.1,2.8h20.3v9.3h-17.6c1.6,2.8,3.7,5,6.4,6.6c2.7,1.5,5.7,2.3,9.1,2.3
		c3.4,0,6.4-0.8,9.1-2.4c2.6-1.6,4.7-3.9,6.3-6.9h19.6c-2.1,7.9-6.2,14.2-12.4,18.7c-6.2,4.6-13.6,6.8-22.3,6.8
		c-8.6,0-16-2.3-22.2-6.8c-6.2-4.5-10.5-10.6-12.8-18.3h-8.1v-9.3h6.6c-0.1-0.6-0.1-1.6-0.1-2.8c0-1.3,0-2.3,0.1-3h-6.6V133h8.2
		c2.3-7.6,6.5-13.7,12.8-18.2c6.2-4.5,13.6-6.7,22.1-6.7c8.7,0,16.1,2.3,22.3,6.9c6.2,4.6,10.3,10.8,12.4,18.8h-19.6
		c-1.5-3-3.6-5.3-6.3-6.8c-2.6-1.6-5.7-2.3-9.1-2.3c-3.3,0-6.3,0.7-9,2.2c-2.6,1.5-4.8,3.5-6.4,6.3H306.3z"/>
</G>
</Svg>
                    );
                }