import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M242.3,5.2C111.2,5.2,4.9,111.5,4.9,242.6S111.2,480,242.3,480s237.4-106.3,237.4-237.4S373.4,5.2,242.3,5.2z M54.9,242.6
	c0-50.1,19.5-97.1,54.9-132.5s82.5-54.9,132.5-54.9c41.6,0,81.2,13.5,113.7,38.4L93.3,356.3C68.4,323.8,54.9,284.2,54.9,242.6z
	 M374.8,375.1c-35.4,35.4-82.5,54.9-132.5,54.9c-41.6,0-81.2-13.5-113.7-38.4l262.7-262.7c24.9,32.5,38.4,72,38.4,113.7
	C429.7,292.7,410.2,339.7,374.8,375.1z"/>
</Svg>
                    );
                }