import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="arrow_right">
	<Path fill={color} d="M370.917,266.547L162.351,475.108c-13.268,13.273-34.777,13.273-48.039,0c-13.262-13.261-13.262-34.766,0-48.034
		l184.544-184.541L114.305,57.994c-13.263-13.269-13.263-34.776,0-48.037c13.262-13.268,34.772-13.268,48.04,0l208.566,208.564
		c6.624,6.627,9.944,15.319,9.944,24.012C380.856,251.225,377.541,259.917,370.917,266.547z"/>
</G>
</Svg>
                    );
                }