import { EnergyLabelColors } from 'enums';
import { useHover } from 'hooks';
import React from 'react';
import { Dimensions, StyleProp, StyleSheet, View } from 'react-native';
import { colors } from 'styles';
import SText from './SText';


interface labelProps {
    label: string,
    style?: StyleProp<any>
}

export default ({ label, style }: labelProps) => {

    const color = label ? EnergyLabelColors[label.charAt(0).toUpperCase()] : '';
    const smallScreen = Dimensions.get('window').width < 768;
    const scale = smallScreen ? 0.5 : 0.7;
    const [hovered, hoverElt] = useHover();

    return (
        <View ref={hoverElt} style={[scopedStyles.container, style]}>
            <View style={{
                height: 36.5 * scale,
                width: 36.5 * scale,
                marginRight: -20 * scale,
                marginTop: - 0.5,
                borderRadius: 4 * scale,
                backgroundColor: color,
                transform: 'rotateZ(45deg)'
            }}></View>

            <View style={{
                height: 50 * scale,
                minWidth: 60 * scale,
                width: 'auto',
                borderRadius: 4 * scale,
                backgroundColor: color,
                justifyContent: 'center'
            }}>
                <SText white semibold md={!smallScreen} center style={{ 
                    marginLeft: -8,
                    textShadowColor: '#333', 
                    textShadowOffset: { width: 0.5, height: 0.5 }, 
                    textShadowRadius: 1
                }}>
                    {label && label}
                </SText>
            </View>
            {hovered &&
                <SText xs white style={{
                    backgroundColor: colors.blackLessTransparent,
                    position: 'absolute',
                    left: '-35%',
                    top: '101%',
                    borderRadius: 12,
                    paddingHorizontal: 8,
                }}>
                    #house_energy_label
                </SText>
            }
        </View>
    );
}

const scopedStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
})
