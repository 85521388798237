import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M239.2,257.6l0.2,0l0.2,0c1.1-0.1,2.2-0.1,3.4-0.2c2.7-0.1,5.4-0.3,8.2-0.5c2.3-0.2,4.7-0.6,7.2-1.2
		c46.3-11.4,76.7-42.4,90.2-92.2c10.6-38.8,0.7-79.3-26.5-108.2c-26.9-28.7-66.2-41.1-105-33.2c-34.9,7.1-63.7,29.4-78.9,61.2
		c-16,33.5-15.1,72.5,2.7,107.1C162.8,232.8,195.8,255.5,239.2,257.6z M301.4,148.7c-4.9,21.1-15.8,37.7-32.2,49.3
		c-16.4,11.6-33.6,13.2-51.1,4.6c-28.9-14.2-47.3-52.8-40.2-84.3c6.4-28.4,32.5-48.9,62-48.9c0.1,0,0.2,0,0.3,0
		c19.4,0.1,37.3,8.8,49.2,23.9C301.7,108.8,306,129,301.4,148.7z"/>
	<Path fill={color} d="M445.8,390c-0.3-15.9-3.2-30.8-8.7-44.4c-5.7-14.2-14-26.5-24.8-36.6c-19.4-18.2-45.3-28-75.1-28.5
		c-30.8-0.5-62.4-0.7-93.7-0.7c-31.6,0-63.8,0.3-95.7,0.7C90,281.5,48,318,40.9,373.7c-2,15.5-1.8,31.4-1.5,46.8
		c0.1,5.8,0.2,11.8,0.1,17.6c0,7.9,2.2,14.7,6.6,19.7c4.4,5,10.7,7.7,17.7,7.8l0.3,0c14.5,0,24.4-10.9,24.7-27.2
		c0.2-9.6,0.1-19.4,0.1-28.9c0-4.7,0-9.5,0-14.2c0.1-42.8,22.7-65.4,65.3-65.4c0,0,0,0,0,0h0.2c10.9,0,21.8,0,32.7,0l55.3,0
		c8.8,0,17.6,0,26.5,0c21.1,0,42.2,0,63.4,0c8.6,0,16.5,1.1,23.5,3.1c13.1,3.7,23.2,10.8,30,21c6.9,10.2,10.5,23.6,10.7,39.6
		c0.1,6.8,0.1,13.7,0.1,20.4c0,8.4,0,17,0.2,25.6c0.4,15.3,10.5,26,24.6,26l0.4,0c14.3-0.2,24-11,24.2-26.7
		C446,423.3,446.1,406.6,445.8,390z"/>
</G>
</Svg>
                    );
                }