import { Icons } from "assets/icons";
import {DocumentModal} from "components/Modals";
import SText from "components/SText";
import { Document } from "models";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { View, Dimensions, Platform, Pressable } from "react-native";
import { colors } from "styles";
import { DocumentUtils } from "utils";
import { DateTimeFormat } from "enums";
import BaseModule from "./BaseModule";

export default ({recentDocuments} : {recentDocuments: Document[]}) => {

    const { showModal } = useContext(ModalContext);
    const width = Dimensions.get('window').width;
    
    const handleDocumentPress = (document:Document) => {        

        const desktop = Platform.OS == 'web' && width > 768;
        const iOs = Platform.OS == 'ios';

        if (desktop || iOs) {

            showModal(DocumentModal, document, 'DocumentModal');

        } else {

            //add extension if filename has no extension, default to txt
            const filename = (document.name.indexOf('.') < 0) ? document.name + (document.extension ? '.' + document.extension : '.txt') : document.name;

            if (document.is_tenant_document) {

                DocumentUtils.downloadTenantDocument(document, filename);

            } else {

                DocumentUtils.downloadDocument(document, filename);

            }

        }

    }

    const renderDocuments = () => {
        return recentDocuments.map((document: Document, index) => {
            const name = document.name.includes('.') ? document.name.split('.')[0] : document.name;            
            return (
                <Pressable
                    key={'rcD' + index}
                    onPress={()=>handleDocumentPress(document)}
                    style={{ 
                        flex: 1,
                        marginBottom: 8,
                        backgroundColor: colors.formBackground,
                        borderRadius: 4,
                        paddingHorizontal: 6,
                        paddingVertical: 4,
                        flexDirection: "row",
                        flexWrap: "wrap"
                    }}
                >
                    <View style={{flex: 1}}>
                        <SText noTranslate medium sm primary style={{width: '100%', height: 22, overflow: "hidden"}}>{name}</SText>
                        <SText regular xs black style={{fontSize: 10}}>{document.updated_at ? moment(document.updated_at).format(DateTimeFormat.display_date) : '#unknown'}</SText>
                    </View>
                    <View style={{height: '100%', justifyContent: "center"}}>
                        <Icons.Eye_visible size={20} color={colors.primary} />
                    </View>
                </Pressable>
            );
        })
    }

    return (
        <BaseModule
            disabled={false}
            title="#recently_shared_documents"
            text={
                <View style={{justifyContent: recentDocuments.length >= 4 ? 'space-between' : 'flex-start', flex: 1}}>
                    {renderDocuments()}
                </View>
            }
        />
    )
}
