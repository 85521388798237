import { useStyle, useTranslation } from "hooks"
import { Text, TextStyle } from "react-native"
import { colors } from "styles"

interface props {
    children: React.ReactNode
    style?: TextStyle | TextStyle[] | false | (false | { color: string; })[]
    center?: boolean
    right?: boolean
    light?: boolean
    regular?: boolean
    medium?: boolean
    semibold?: boolean
    bold?: boolean
    xs?: boolean
    sm?: boolean
    md?: boolean
    lg?: boolean
    xl?: boolean
    black?: boolean
    white?: boolean
    primary?: boolean
    primaryTransparent?: boolean
    grey?: boolean
    greyTransparent?: boolean
    error?: boolean
    url?: boolean
    oneline?: boolean
    noTranslate?: boolean
    capitalize?: boolean
}

export default ({ children, noTranslate, light, medium, semibold, bold, xs, sm, md, lg, xl, black, white, primary, primaryTransparent, grey, greyTransparent, error, style, center, right, url, oneline, capitalize }: props) => {

    const translate = useTranslation();
    const styles = useStyle();

    return (
        <Text
            numberOfLines={oneline ? 1 : undefined}
            style={[
                styles('text-regular'),
                light && styles('text-light'),
                medium && styles('text-medium'),
                semibold && styles('text-semibold'),
                bold && styles('text-bold'),
                styles('text-sm'),
                xs && styles('text-xs'),
                md && styles('text-md'),
                lg && styles('text-lg'),
                xl && styles('text-xl'),
                black && styles('text-black'),
                white && styles('text-white'),
                primary && { color: colors.primary },
                primaryTransparent && { color: colors.primaryTransparent },
                grey && { color: colors.grey },
                greyTransparent && { color: colors.greyTransparent },
                error && { color: colors.error },
                center && { textAlign: "center" },
                right && { textAlign: "right" },
                url && { textDecorationLine: 'underline' },
                capitalize && { textTransform: 'capitalize' },
                style
            ]}>
            {noTranslate ? children : translate(children)}
        </Text>
    )
}
