import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >

<Circle fill={color} cx="114.7" cy="242.6" r="39.3"/>
<Circle fill={color} cx="242.3" cy="242.6" r="39.3"/>
<Circle fill={color} cx="370.6" cy="242.6" r="39.3"/>
</Svg>
                    );
                }