import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M445.3,160.9c-21.3-24-50-43.1-86.2-57.3c-36.2-14.2-75-21.2-116.6-21.2c-41.6,0-80.4,7.1-116.6,21.2
	c-36.2,14.2-64.9,33.3-86.2,57.3c-21.3,24-31.9,50-31.9,77.9c0,22.8,7.3,44.6,21.8,65.2c14.6,20.7,35.1,38.5,61.6,53.6l26.6,15.3
	l-8.2,29.3c-4.9,18.5-12,36.1-21.4,52.5c31-12.8,59-30.2,84-52.2l13.1-11.6l17.4,1.8c14.1,1.6,27.3,2.4,39.7,2.4
	c41.6,0,80.4-7.1,116.6-21.2c36.2-14.2,64.9-33.3,86.2-57.3c21.3-24,31.9-50,31.9-77.9S466.6,184.9,445.3,160.9z"/>
</Svg>
                    );
                }