import { useStyle } from "hooks"
import { StyleProp, StyleSheet, View } from "react-native"
import { colors } from "styles"
import SText from "./SText";

export default ({ label, value, style }: { label?: string, value: string | undefined, style?: StyleProp<any> }) => {

    const styles = useStyle();

    return (
        <View style={[
            styles('border-radius-md'),
            scopedStyles.container,
            { borderBottomColor: colors.primary },
            style
        ]}>
            <SText regular sm black style={scopedStyles.label}>
                {label}
            </SText>
            <SText light sm black>
                {value || 'Niet bekend'}
            </SText>
        </View>
    )
}

const scopedStyles = StyleSheet.create({
    container: {
        backgroundColor: colors.white,
        padding: 8
    },
    label: {
        marginBottom: 4
    }
})