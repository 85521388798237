import { Button } from "components";
import SText from "components/SText";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { View } from "react-native"
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import BaseModal from "./BaseModal";
import MessageModal from "./MessageModal";



export default ({ settings, style = {} }) => {

    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { hideModal } = useContext(ModalContext);

    const renderForm = () => {

        return (
            <View style={scopedStyles('container')}>

                <View style={{ flex: 1, marginBottom: 30 }}>

                    {/* title */}
                    <SText lg regular primary style={{ marginVertical: 20 }}>{settings.title || '#message_single'}</SText>

                    <View style={{ flex: 1, justifyContent: 'space-between' }}>
                        <SText sm regular black>{settings.message}</SText>
                        <SText sm medium grey style={{ marginTop: 20 }}>{settings.date}</SText>
                    </View>

                </View>


                <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>

                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label='#action_close'
                            type={"error"}
                            onPress={() => settings.hide ? settings.hide() : hideModal()}
                        />
                    </View>

                </View>

            </View >
        )
    }



    return (
        <BaseModal style={[scopedStyles('modal'), style]} hideModal={settings.hide && settings.hide}>

            {renderForm()}

        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            minWidth: 485,
            width: '40%',
            height: '50%',
            minHeight: 485,
            marginTop: 150
        },
        container: {
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        }
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            modal: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            }
        }
    }
)