import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M242.1,103.6c-24.9-33.4-64.2-52.9-105.9-52.6C63,51.3,3.7,110.6,3.5,183.8c0,54.3,39.9,114.3,118.7,179
	c36.8,30,75.9,57,117.1,80.6c0.9,0.5,1.9,0.7,2.9,0.7c1,0,2-0.2,2.9-0.7c41.3-23.4,80.5-50.1,117.4-80
	C441.3,299.1,481,238.7,481,184.1c-0.2-73.2-59.5-132.6-132.8-132.8C306.5,50.9,267.1,70.3,242.1,103.6z"/>
</Svg>
                    );
                }