import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M294.2,444.4c1.1-4.3,1.2-8.9,1.9-13.9c9.4,0,18.6,0,28.4,0c0-2.3,0-4.2,0-6.1c0-22.7,0-45.5,0-68.2
		c0-4.2,0.2-8.5,0.9-12.7c2.1-12,8.9-21.1,17.4-29.6C378.4,278.1,393,234.8,384,185C364.8,78.3,238.6,31.2,154.3,99.4
		c-42.6,34.5-59.7,80.2-51.8,134.6c4.6,31.3,18.9,58,41.7,79.9c12.6,12.1,18.7,26.3,18.4,43.9c-0.4,22.3-0.1,44.5-0.1,66.8
		c0,1.8,0,3.7,0,6.2c10,0,19.3,0,28.8,0c0.1,1.7,0.2,2.8,0.2,3.9c0.9,17.4,8.1,31.6,22.6,41.1c6.8,4.4,15.2,6.4,22.8,9.5
		c4.4,0,8.8,0,13.2,0c0.7-0.3,1.4-0.7,2.1-0.8C274.6,479.8,288.5,466.3,294.2,444.4z M186.3,326.6c-4.5-12.8-12.2-23.4-21.9-32.7
		c-17-16.3-27.7-36.1-32.9-59c-14.2-63,30.5-128.3,94.2-137.4c61.3-8.8,116.8,28.9,129.8,89.4c8.3,38.5-1.3,73.2-28.2,102
		c-13.7,14.7-26.3,29.3-29.8,49.8c-13.3,0-26.2,0-39.4,0c-0.1-1.2-0.3-2.2-0.3-3.3c0-14.2-0.1-28.4,0.1-42.6c0-1.8,1.3-4.2,2.8-5.2
		c10.6-7.1,21.3-14,32.1-20.8c2.5-1.6,3.7-3.3,3.7-6.4c-0.2-12.7-0.1-25.5-0.1-38.5c-9.6,0-18.6,0-28.4,0c0,7.7-0.1,15.4,0.1,23
		c0.1,2.8-0.7,4.6-3.2,6.1c-6.3,3.8-12.4,8.1-18.8,11.9c-1.2,0.7-3.7,0.8-4.9,0c-6.6-4-13.1-8.1-19.5-12.6c-1.3-0.9-2.4-3.2-2.5-4.9
		c-0.3-7.7-0.1-15.4-0.1-23.5c-9.4,0-18.6,0-28.4,0c0,9.3,0.9,18.7-0.2,27.8c-1.3,11.1,2.4,17.8,12.4,22.7c8.4,4.1,16,10,23.9,15.2
		c1.2,0.8,2.5,2.6,2.5,3.9c0.2,15.5,0.1,30.9,0.1,47c-13.4,0-26.3,0-39.4,0C188.7,334.7,187.7,330.6,186.3,326.6z M191.2,401.9
		c0-11.5,0-22.8,0-34.3c34.9,0,69.5,0,104.5,0c0,11.4,0,22.7,0,34.3C260.9,401.9,226.2,401.9,191.2,401.9z M230.6,452.8
		c-7.7-5-11-12.5-10.6-21.8c15.9,0,31.4,0,47.1,0c0.3,9.9-3.3,17.5-11.6,22.4C247.2,458.3,238.6,458.1,230.6,452.8z"/>
	<Path fill={color} d="M256.8,38.1c0-12.9,0-25.5,0-38.1c-9.5,0-18.9,0-28.4,0c0,12.7,0,25.4,0,38.1C238.2,38.1,247.4,38.1,256.8,38.1z"/>
	<Path fill={color} d="M32.2,224.9c12.8,0,25.3,0,37.9,0c0-9.5,0-18.6,0-27.8c-12.8,0-25.3,0-37.9,0C32.2,206.6,32.2,215.6,32.2,224.9z"/>
	<Path fill={color} d="M415.1,225c12.7,0,25.1,0,37.9,0c0-9.6,0-18.6,0-27.9c-12.8,0-25.3,0-37.9,0C415.1,206.5,415.1,215.6,415.1,225z"/>
	<Path fill={color} d="M417.9,93.1c-11.3,6.5-22.1,12.8-33.3,19.3c4.8,8.4,9.4,16.4,14.2,24.6c11.3-6.5,22.1-12.8,33.3-19.2
		C427.3,109.5,422.8,101.6,417.9,93.1z"/>
	<Path fill={color} d="M125,35.3c6.6,11.4,12.9,22.3,19.2,33.3c8.4-4.9,16.3-9.4,24.5-14.2c-6.5-11.2-12.8-22.2-19.2-33.3
		C141,26,133.1,30.6,125,35.3z"/>
	<Path fill={color} d="M316.4,54.4c8.4,4.9,16.4,9.5,24.6,14.2c6.6-11.4,12.8-22.2,19.2-33.3c-8.4-4.9-16.4-9.5-24.6-14.2
		C329.1,32.4,322.9,43.2,316.4,54.4z"/>
	<Path fill={color} d="M53.1,117.7c11.3,6.5,22.1,12.8,33.3,19.2c4.8-8.4,9.4-16.3,14.2-24.6c-11.2-6.5-21.9-12.7-33.3-19.3
		C62.4,101.5,57.8,109.5,53.1,117.7z"/>
</G>
</Svg>
                    );
                }