import { Icons } from "assets/icons";
import { useStyle } from "hooks";
import { Image, Pressable, StyleProp, View } from "react-native";
import CountryFlag from "react-native-country-flag";
import { colors } from "styles";
import SText from "./SText";



interface PersonProps {
    name: string
    nationality: string
    sex: number
    age: number
    style?: StyleProp<any>
    background?: boolean,
    avatar?: string
    renderDropdownMenu?: () => React.ReactElement,
    dropdownState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    isBusiness?: boolean
}

export default ({ name, nationality, sex, age, style, background, avatar, renderDropdownMenu, dropdownState, isBusiness }: PersonProps) => {

    const styles = useStyle();

    const [showDropdown, setShowDropdown] = dropdownState ? dropdownState : [false, () => { }];
    const toggleDropdown = () => setShowDropdown(s => !s);
    const useAvatar = avatar && !avatar.includes('iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAAAsTAAALEwEAmpwYAAA4JmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYm');

    const renderAvatar = () => {
        return useAvatar ? (
            <Image
                source={{ uri: `data:image/png;base64,${avatar}` }}
                width={50}
                height={50}
                style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    alignSelf: 'center'
                }}
            />
        ) : (
            <View style={{
                width: 50,
                height: 50,
                alignSelf: 'center'
            }}>
                {sex ? (
                    <Icons.Avatar_male size={50} color={''} />
                ) : (
                    <Icons.Avatar_female size={50} color={''} />
                )}
            </View>
        );
    }

    return (
        <>
            <Pressable
                onPress={toggleDropdown}
                disabled={!renderDropdownMenu}
                style={[
                    style,
                    {
                        height: 66,
                        paddingVertical: 8,
                        borderRadius: 8,
                        flexDirection: 'row',
                        alignItems: "center",
                        backgroundColor: background && colors.background
                    }
                ]}
            >
                <View style={{ flex: 0.2 }}>
                    {renderAvatar()}
                </View>
                <View style={{ flex: 0.8 }}>
                    <SText semibold primary md>{name}</SText>
                    <View style={styles('rowContainer')}>
                        <CountryFlag isoCode={nationality} size={8} style={{
                            marginRight: 8,
                            marginTop: -2,
                            alignSelf: 'center'
                        }} />
                        <SText medium black sm>{!isBusiness ? (sex ? '#gender_male' : '#gender_female') : ''}</SText>
                        <SText medium black sm>{!isBusiness ? ',' : ''}{age}#age</SText>
                    </View>
                </View>
                {renderDropdownMenu &&
                    <View
                        style={{
                            position: 'absolute',
                            right: 12,
                            top: 0,
                            height: 66,
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: 'row'
                        }}
                    >
                        <View style={{ marginRight: 8 }}>
                            {showDropdown ? (
                                <Icons.Arrow_up size={14} color={colors.primaryLessTransparent} />
                            ) : (
                                <Icons.Arrow_down size={14} color={colors.primaryLessTransparent} />
                            )}
                        </View>
                        <View
                            style={{
                                width: 35,
                                height: 35,
                                backgroundColor: colors.primaryLessTransparent,
                                borderRadius: 999999,
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Icons.User size={16} color={colors.white} />
                        </View>
                    </View>
                }
            </Pressable >
            <View
                style={{
                    position: 'absolute',
                    width: '80%',
                    marginLeft: '20%',
                    top: 48,
                    zIndex: 99,
                    borderRadius: 12,
                    borderColor: colors.border,
                    borderWidth: showDropdown ? 1 : 0,
                    backgroundColor: colors.white,
                    transition: 'all ease-in-out 200ms',
                    height: showDropdown ? 'auto' : 0,
                    overflow: 'hidden'
                }}
            >
                {showDropdown && renderDropdownMenu()}
            </View>
        </>
    )
}