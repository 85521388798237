import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill="#DCE3EB" d="M485.2,242.6C485.2,108.6,376.6,0,242.6,0S0,108.6,0,242.6C0,313.2,30.2,376.7,78.3,421
	c0.7-10,1.4-17.6,2.2-20.5c4.7-18.8,16.3-31.1,34.6-37.1c19.5-6.4,38.9-12.9,58.4-19.3c5.7-1.9,10.2-5.3,13.7-10.1
	c6.1-8.3,9.6-17.7,11.8-27.6c0.6-2.5,0.3-4.2-1.7-6.1c-11.1-10.2-17.3-22.9-18.7-37.9c-0.3-2.7-0.9-5.1-3.7-6.6
	c-1.3-0.7-2.4-2.4-2.9-3.8c-2.3-7.5-4.6-15-6.5-22.6c-1.6-6.5-1.3-12.7,4-17.9c1.2-1.2,2.1-3.4,2.1-5.1c0.2-10.8,0.1-21.5,0.1-32.3
	c0-12.9,3.3-25,9.9-36.1c19.3-32.6,56.9-44.8,92.6-31.4c12.3,4.6,21.5,12.8,24.6,26.3c0.2,0.8,0.7,1.7,1.3,2.2
	c9.8,8,12.6,19,13,30.9c0.4,13.3,0.3,26.6,0.3,40c0,2.3,0.5,4.1,2.3,5.9c3.2,3.1,5.3,6.9,4.8,11.6c-1.1,9.8-3.4,19.4-7.3,28.6
	c-0.6,1.5-1.9,3.1-3.3,4c-2.5,1.5-3.2,3.6-3.4,6.1c-1.3,15.3-7.6,28.1-18.8,38.5c-1.7,1.6-2.5,3.1-1.7,5.2
	c2.9,7.9,5.5,15.9,9.1,23.4c3.8,7.8,10.2,13,18.9,15.8c18.9,6,37.7,12.4,56.6,18.7c14.7,4.9,25.7,14.1,31.4,28.6
	c2.7,6.8,4.1,14,6,21.1c0.2,0.9,0.5,3.1,0.7,6.2C455.8,375.2,485.2,312.3,485.2,242.6z"/>
<Path fill="#FFFFFF" d="M402,392.2c-5.7-14.5-16.7-23.7-31.4-28.6c-18.8-6.3-37.6-12.7-56.6-18.7c-8.6-2.7-15-7.9-18.9-15.8
	c-3.7-7.5-6.2-15.6-9.1-23.4c-0.8-2.1,0-3.6,1.7-5.2c11.2-10.4,17.5-23.2,18.8-38.5c0.2-2.5,0.9-4.6,3.4-6.1c1.4-0.8,2.6-2.4,3.3-4
	c3.8-9.1,6.1-18.7,7.3-28.6c0.5-4.7-1.6-8.5-4.8-11.6c-1.8-1.8-2.3-3.5-2.3-5.9c0-13.3,0.1-26.6-0.3-40c-0.4-11.8-3.2-22.9-13-30.9
	c-0.6-0.5-1.1-1.4-1.3-2.2c-3.2-13.5-12.3-21.7-24.6-26.3c-35.6-13.5-73.3-1.2-92.6,31.4c-6.6,11.1-9.9,23.2-9.9,36.1
	c0,10.8,0.1,21.5-0.1,32.3c0,1.8-0.9,3.9-2.1,5.1c-5.3,5.2-5.6,11.4-4,17.9c1.9,7.6,4.2,15.1,6.5,22.6c0.5,1.5,1.6,3.1,2.9,3.8
	c2.8,1.5,3.5,3.9,3.7,6.6c1.4,15,7.6,27.7,18.7,37.9c2,1.9,2.3,3.5,1.7,6.1c-2.3,9.9-5.8,19.3-11.8,27.6c-3.5,4.8-8,8.2-13.7,10.1
	c-19.5,6.4-39,12.9-58.4,19.3c-18.3,6.1-29.9,18.4-34.6,37.1c-0.7,2.9-1.4,10.5-2.2,20.5c43.2,39.8,100.9,64.2,164.3,64.2
	c64.3,0,122.7-25,166.1-65.8c-0.2-3.1-0.5-5.3-0.7-6.2C406,406.2,404.6,398.9,402,392.2z"/>
</Svg>
                    );
                }