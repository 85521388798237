import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 417 417"  >
<G>
	<Path fill={color} d="M21.7,77.8c0-6.7,5.5-12.2,12.2-12.2h95.2c6.7,0,12.2,5.5,12.2,12.2c0,1.3-0.2,2.6-0.6,3.7c0.7,0,1.4-0.1,2.2-0.1H163V55
		c0-8.7-7.1-15.9-15.9-15.9H15.9C7.1,39.2,0,46.3,0,55v243.4c0,8.7,7.1,15.9,15.9,15.9h90V192.6H33.9c-6.7,0-12.2-5.5-12.2-12.2
		c0-6.7,5.5-12.2,12.2-12.2h71.9v-26.9H33.9c-6.7,0-12.2-5.5-12.2-12.2c0-6.7,5.5-12.2,12.2-12.2h72c0.5-10.8,5.6-20.4,13.4-26.9
		H33.9C27.2,90,21.7,84.5,21.7,77.8z M81.5,243.6c12.2,0,22.1,9.9,22.1,22.1c0,12.2-9.9,22.1-22.1,22.1s-22.1-9.9-22.1-22.1
		C59.4,253.5,69.3,243.6,81.5,243.6z"/>
	<Path fill={color} d="M401.1,39.2H269.9c-8.7,0-15.9,7.1-15.9,15.9v26.5h20.1c0.7,0,1.5,0,2.2,0.1c-0.4-1.2-0.6-2.4-0.6-3.7
		c0-6.7,5.5-12.2,12.2-12.2h95.2c6.7,0,12.2,5.5,12.2,12.2c0,6.7-5.5,12.2-12.2,12.2h-85.3c7.8,6.5,12.9,16.1,13.4,26.9h72
		c6.7,0,12.2,5.5,12.2,12.2c0,6.7-5.5,12.2-12.2,12.2h-71.9v26.9h71.9c6.7,0,12.2,5.5,12.2,12.2c0,6.7-5.5,12.2-12.2,12.2h-71.9
		v121.7h90c8.7,0,15.9-7.1,15.9-15.9V55C417,46.3,409.9,39.2,401.1,39.2z M335.5,287.7c-12.2,0-22.1-9.9-22.1-22.1
		c0-12.2,9.9-22.1,22.1-22.1c12.2,0,22.1,9.9,22.1,22.1C357.6,277.9,347.7,287.7,335.5,287.7z"/>
	<Path fill={color} d="M274.1,102.7H254h-91h-20.1c-8.2,0-15,6.3-15.8,14.2c-0.1,0.5-0.1,1.1-0.1,1.6v22.8v26.9v24.4v121.7V362
		c0,8.7,7.1,15.9,15.9,15.9h131.2c8.7,0,15.9-7.1,15.9-15.9v-47.6V192.6v-24.4v-26.9v-22.8c0-0.5,0-1.1-0.1-1.6
		C289.1,108.9,282.3,102.7,274.1,102.7z M208.5,351.2c-12.2,0-22.1-9.9-22.1-22.1c0-12.2,9.9-22.1,22.1-22.1
		c12.2,0,22.1,9.9,22.1,22.1C230.6,341.4,220.7,351.2,208.5,351.2z M256.1,256.1H254h-91h-2.1c-6.7,0-12.2-5.5-12.2-12.2
		c0-6.7,5.5-12.2,12.2-12.2h2.1h91h2.1c6.7,0,12.2,5.5,12.2,12.2C268.3,250.6,262.8,256.1,256.1,256.1z M256.1,204.8H254h-91h-2.1
		c-6.7,0-12.2-5.5-12.2-12.2c0-6.7,5.5-12.2,12.2-12.2h2.1h91h2.1c6.7,0,12.2,5.5,12.2,12.2C268.3,199.3,262.8,204.8,256.1,204.8z
		 M256.1,153.5H254h-91h-2.1c-6.7,0-12.2-5.5-12.2-12.2c0-6.7,5.5-12.2,12.2-12.2h2.1h91h2.1c6.7,0,12.2,5.5,12.2,12.2
		C268.3,148.1,262.8,153.5,256.1,153.5z"/>
</G>
</Svg>
                    );
                }