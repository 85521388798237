import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<G>
		<Path fill={color} d="M468.6,35.9c-3.8-3.8-8.3-5.7-13.6-5.7c-5.2,0-9.8,1.9-13.6,5.7l-39.2,38.9c-21.5-20.3-46.2-36.1-73.9-47.2
			c-27.8-11.2-56.3-16.7-85.7-16.7c-55.3,0-103.6,16.7-144.8,50.2c-41.2,33.5-68.4,77.2-81.5,131.1v2.1c0,2.6,1,4.9,2.9,6.8
			c1.9,1.9,4.2,2.9,6.8,2.9h60c4.4,0,7.4-2.3,9.1-6.9c8.4-20.1,13.8-31.9,16-35.3c14.1-22.9,32.8-40.9,56.1-54
			c23.3-13.1,48.5-19.6,75.4-19.6c40.4,0,75.5,13.8,105.3,41.3l-41.6,41.6c-3.8,3.8-5.7,8.4-5.7,13.6c0,5.2,1.9,9.8,5.7,13.6
			c3.8,3.8,8.4,5.7,13.6,5.7H455c5.2,0,9.8-1.9,13.6-5.7c3.8-3.8,5.7-8.3,5.7-13.6V49.5C474.3,44.3,472.4,39.8,468.6,35.9z"/>
		<Path fill={color} d="M457.1,281.2h-57.9c-4.4,0-7.4,2.3-9,6.9c-8.4,20.1-13.8,31.9-16,35.3c-14.1,22.9-32.8,40.9-56.1,54
			c-23.3,13.1-48.5,19.6-75.4,19.6c-19.5,0-38.3-3.6-56.4-10.9c-18.1-7.2-34.3-17.5-48.6-30.8l41.3-41.3c3.8-3.8,5.7-8.3,5.7-13.6
			c0-5.2-1.9-9.8-5.7-13.6c-3.8-3.8-8.3-5.7-13.6-5.7H30.2c-5.2,0-9.8,1.9-13.6,5.7c-3.8,3.8-5.7,8.3-5.7,13.6v135.2
			c0,5.2,1.9,9.8,5.7,13.6c3.8,3.8,8.4,5.7,13.6,5.7c5.2,0,9.8-1.9,13.6-5.7l38.9-38.9c21.5,20.5,46,36.3,73.5,47.4
			c27.5,11.1,55.9,16.6,85.2,16.6c55.1,0,103.2-16.7,144.2-50.2c41-33.5,68-77.2,80.9-131.1c0.2-0.4,0.3-1.1,0.3-2.1
			c0-2.6-1-4.9-2.9-6.8C462,282.2,459.7,281.2,457.1,281.2z"/>
	</G>
</G>
</Svg>
                    );
                }