import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M221.7,217.8c1.5-0.1,3.1-0.1,4.6-0.3c0.9-0.1,1.8-0.2,2.6-0.4c19.2-4.7,30.2-17.7,35.1-36c8.7-32-18.9-61.5-51-54.9
		c-29.2,6-43.7,37.8-29.5,65.4C191.4,207,203.5,216.9,221.7,217.8z"/>
	<Path fill={color} d="M303,268.7c-0.1-6.3-1.3-12.1-3.4-17.3c-2.2-5.5-5.5-10.3-9.6-14.1c-7.3-6.8-17.3-10.8-29.1-11
		c-12.5-0.2-25.1-0.3-37.6-0.3c-12.8,0-25.6,0.1-38.4,0.3c-22.4,0.3-38.7,14.5-41.5,36.1c-1.1,8.5-0.5,17.1-0.5,25.7
		c0,5.7,3.3,9.4,8.3,9.5c0,0,0,0,0.1,0c0,0,0,0,0,0h144.9v-0.1c4.1-0.7,6.8-4.1,6.9-9.1C303.1,281.8,303.2,275.2,303,268.7z"/>
</G>
<Path fill={color} d="M465.9,417l-64.8-64.8l-3.3-3.3l2.5-3.9l3.2-5.1c21.3-33.9,32.5-73,32.5-113.1C436,109.3,340.4,13.7,223,13.7
	C105.5,13.7,9.9,109.3,9.9,226.7c0,117.5,95.6,213.1,213.1,213.1c43.8,0,85.8-13.2,121.7-38.2l5.2-3.6l4-2.8l3.4,3.4l63.5,63.5
	c6,6,14,9.3,22.6,9.3c8.5,0,16.6-3.3,22.6-9.3C478.4,449.7,478.4,429.4,465.9,417z M223,393.1c-91.7,0-166.4-74.6-166.4-166.4
	C56.6,135,131.2,60.4,223,60.4S389.3,135,389.3,226.8C389.3,318.5,314.7,393.1,223,393.1z"/>
</Svg>
                    );
                }