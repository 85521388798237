import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M387.8,193.3c-6.4-66.9-62.2-119.5-130.8-119.5c-53.2,0-98.6,31.5-119.5,76.8c-6.2-2-12.8-3.4-19.9-3.4
	c-36.4,0-66,29.5-66,66c0,7.2,1.5,14.1,3.6,20.7c-28.4,16.6-47.6,46.9-47.6,82c0,52.7,42.7,95.3,95.3,95.3l0,0h109.9V298.8H187
	c-15.9,0-22.5-10.7-14.4-23.6l55.5-54.6c10.8-10.8,17.7-11.2,28.9,0l55.5,54.8c8,13,1.5,23.6-14.4,23.6h-26.1v112.4h95.3l0,0
	c60.7,0,109.9-49.2,109.9-109.9C477.1,247.6,438.7,203,387.8,193.3z"/>
</Svg>
                    );
                }