import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M284.7,393.5c34.6-6.2,66.4-19.5,95.2-39.8c5.9-4.2,11.5-8.7,17.4-13.2c-4.2-4.9-8-9.4-12-14c-16,13.4-33.1,24.4-52.2,33
	c-5.6-16-11.1-31.8-16.8-48.1c8.2,0,15.9,0,23.9,0c0-2.9,0-5.5,0-8.1c-0.4-26.4-17.8-47.8-43.1-51.8c-8.6-1.4-17.5-0.9-26.3-0.9
	c-21.2-0.1-42.4,0-63.6-0.1c-1,0-2.8-0.4-3-1c-1.9-5.5-3.5-11.1-5.3-17.1c27.1,0,53.7,0,80.5,0c0-6.2,0-12,0-18.1
	c-14.5,0-28.8,0-43.1,0c-14.3,0-28.6,0-42.9,0c-6.2-20.5-12.3-40.7-18.4-60.9c-3.4-11.4-6-23.1-10.5-34
	c-11.7-28.1-46.1-41.1-76.3-23.1c21.1,71.7,42.1,143.4,63.2,215.3c6.2,0,12.1,0,18.4,0c-5.6,16.4-11.2,32.4-16.8,48.7
	c-19.7-8.6-37.4-20-53.8-33.7c-4.1,4.8-7.9,9.2-11.8,13.8c0.8,0.7,1.4,1.2,1.9,1.6c12.6,10.6,26.2,19.8,40.8,27.5
	c26.5,14,54.6,22.9,84.4,26.2c5.4,0.6,10.8,1.2,16.2,1.7c7.7,0,15.3,0,23,0c1.1-0.2,2.2-0.4,3.2-0.5
	C266.3,395.7,275.6,395.1,284.7,393.5z M170.1,367c4.5-13,8.8-25.6,13.1-38.2c1.7-5,3.6-10.1,5.2-15.1c0.6-1.7,1.4-2.3,3.2-2.3
	c34.4,0.1,68.8,0,103.2,0.1c0.9,0,2.4,0.7,2.7,1.4c6.3,17.7,12.5,35.4,18.7,53.5C267.5,383.2,219,383.4,170.1,367z"/>
</Svg>
                    );
                }