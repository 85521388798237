import { AuthUsersList, Button, SText } from 'components';
import { AuthFailureCodes, LoginModes } from 'enums';
import { useGreeting, useTenantLogo } from "hooks";
import { UserProfile } from 'models/UserProfile';
import { HideKeyboardProvider } from 'providers';
import { StoreContext } from 'providers/StoreProvider';
import { useContext, useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, Image, View } from "react-native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useQueryClient } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { LoginForm, RecoveryForm, TwoFactorForm } from 'screens/login';
import { colors } from 'styles';
import { AuthUtils, QueryUtils } from "utils";

const splashScreen = require('assets/icon.png');

export const Login = () => {

    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const { query, error: loginError, login, logout, reloadApp } = useContext(StoreContext);
    const { styles: scopedStyles } = scopedResponsiveStyles();

    const [hasMultipleUsers, setHasMultipleUsers] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [recoveryMessage, setRecoveryMessage] = useState(null);
    const greeting = useGreeting();
    const [mode, setMode] = useState(LoginModes.LOGIN);
    const [twoFactorData, setTwoFactorData] = useState<{ username: string, password: string, tenant: string } | null>({ username: 'admin', password: 'root', tenant: 'notreal' });

    const logo = useTenantLogo();

    const checkMultipleUsers = async () => {
        const users: [string, UserProfile][] = Object.entries((await AuthUtils.getUsers()));
        if (users.length > 0) {
            showMulti();
            setHasMultipleUsers(true);
        } else {
            setHasMultipleUsers(false);
            showLogin();
        }
    }
    useEffect(() => {
        checkMultipleUsers();
    }, [])

    const handleLogin = async (values: { code: string, username: string, password: string, otp?: string, tenant?: string }) => {

        setLoading(true);
        setRecoveryMessage(null);
        const response = await AuthUtils.retrieveTokens(values);

        if (response === true) {
            setShowLoadingModal(true);
            await QueryUtils.prefetch(queryClient);
            login();
        } else {
            if (response?.error == AuthFailureCodes.TWO_FACTOR_REQUIRED ||
                response?.error?.message == AuthFailureCodes.USER_TWO_FACTOR_REQUIRED
            ) {
                AuthUtils.requestTwoFactorMail(values.username, response.transformedTenant);
                setTwoFactorData({ username: values.username, password: values.password, tenant: response.transformedTenant });
                showTwoFactor();
            } else {
                logout(response || '#login_error');
            }
        }

        setLoading(false);

    }

    const handleRecovery = async ({ username, code, tenant }: { username: string, code: string, tenant?: string }) => {

        setLoading(true);
        setRecoveryMessage(null);
        const response = await AuthUtils.recoverPassword(username, code, tenant);
        if (response) {

            setRecoveryMessage({
                message: '#login_recoverysent'
            });
            showLogin();
            setLoading(false);

        } else {

            setRecoveryMessage({
                error: true,
                message: '#login_error_retry'
            });
            setLoading(false);

        }

    }

    const handleAccountChange = async (id: string) => {
        const users: Record<string, UserProfile> = await AuthUtils.getUsers();
        await AuthUtils.storeCurrentUserId(id);
        if (!users[id].access_token) {
            showLogin();
        } else {
            setShowLoadingModal(true);
            await QueryUtils.prefetch(queryClient);
            login();
        }
    }

    const showLogin = () => {
        setMode(LoginModes.LOGIN);
    }

    const showMulti = () => {
        setMode(LoginModes.MULTI);
    }

    const showRecovery = () => {
        setMode(LoginModes.RECOVERY);
    }

    const showTwoFactor = () => {
        setMode(LoginModes.TWOFACTOR);
        setRecoveryMessage(null);
    }

    const errorMessage = () => {
        switch (loginError) {
            case AuthFailureCodes.NOT_FOUND:
                return "#login_error_notfound";
            case AuthFailureCodes.UNAUTHORIZED:
                return "#login_error_noauth";
            default:
                return "#login_error";
        }
    }
    const renderErrorMessage = () => {
        if (loginError && !loading && !recoveryMessage && mode !== LoginModes.RECOVERY) {
            return (
                <SText sm medium error style={{ textAlign: 'right', marginTop: 8 }}>
                    {errorMessage()}
                </SText>
            );
        }
    }

    const renderLoadingModal = () => {
        return (
            <View style={scopedStyles('loadingModal')}>
                <View style={scopedStyles('loadingModalForm')}>
                    <Image 
                        source={splashScreen} 
                        resizeMode="contain"
                        style={{
                            width: 128,
                            height: 128,
                            marginBottom: 32,
                        }} 
                    />
                    <SText sm medium primary center style={{ marginBottom: 32, width: '100%' }}>#after_successful_login</SText>
                    <ActivityIndicator color={colors.primary} />
                </View>
            </View>
        );
    }




    return (
        <HideKeyboardProvider>
            <View style={scopedStyles('container')}>

                <KeyboardAwareScrollView
                    showsVerticalScrollIndicator={false}
                    style={scopedStyles('loginSection')}
                    contentContainerStyle={{ flex: 1 }}
                >

                    <View style={scopedStyles('loginContainer')}>

                        <Image 
                            source={logo} 
                            style={scopedStyles('logo')} 
                            resizeMode="contain" 
                        />

                        <SText xl medium primary style={{ marginTop: -20, marginBottom: 8 }}>{greeting}</SText>

                        <SText sm light black>#welcome</SText>


                        <View style={{ width: '100%', marginVertical: 12 }}>
                            {(mode === LoginModes.MULTI) &&
                                <AuthUsersList
                                    handleAccountChange={handleAccountChange}
                                    handleAccountDelete={checkMultipleUsers}
                                />
                            }
                            {(mode === LoginModes.LOGIN) &&
                                <LoginForm
                                    loading={loading}
                                    handleLogin={handleLogin}
                                />
                            }
                            {(mode === LoginModes.RECOVERY) &&
                                <RecoveryForm
                                    loading={loading}
                                    handleRecovery={handleRecovery}
                                />
                            }
                            {(mode === LoginModes.TWOFACTOR) &&
                                <TwoFactorForm
                                    data={twoFactorData}
                                    loading={loading}
                                    handleLogin={handleLogin}
                                />
                            }
                        </View>

                        {(mode === LoginModes.MULTI) &&
                            <Button
                                label="#login_other_account"
                                onPress={showLogin}
                            />
                        }

                        {(mode === LoginModes.LOGIN) &&
                            <Button
                                label={'#login_forgotpassword'}
                                onPress={showRecovery}
                                style={{ width: '100%' }}
                                type={'outline'}
                            />
                        }

                        {(mode === LoginModes.RECOVERY) &&
                            <Button
                                label={'#login_back'}
                                onPress={showLogin}
                                style={{ width: '100%' }}
                                type={'outline'}
                            />
                        }

                        {(mode !== LoginModes.MULTI && hasMultipleUsers) &&
                            <Button
                                label="#login_other_account"
                                onPress={() => {
                                    AuthUtils.removeCurrentUser();
                                    showMulti();
                                }}
                                style={{ marginTop: 12 }}
                                type={'outline'}
                            />
                        }

                        {!showLoadingModal && mode !== LoginModes.MULTI && renderErrorMessage()}

                        {recoveryMessage &&
                            <SText sm medium black style={[
                                scopedStyles('recoveryMessage'),
                                { backgroundColor: recoveryMessage.error ? colors.error : colors.action }
                            ]}>
                                {recoveryMessage.message}
                            </SText>
                        }

                    </View>

                </KeyboardAwareScrollView>

                <View style={scopedStyles('imageSection')}>
                    <Image
                        source={require('assets/images/login.png')}
                        resizeMode="cover"
                        style={{ width: '100%', height: '100%' }}
                    />
                </View>

                {showLoadingModal && renderLoadingModal()}

            </View>
        </HideKeyboardProvider>
    );
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        container: {
            flexDirection: 'row',
            height: '100%'
        },
        logo: {
            width: '100%',
            height: '15%',
            marginBottom: 50
        },
        loginSection: {
            flex: (Dimensions.get('window').width > 768) ? 0.3 : 1.0,
            flexGrow: (Dimensions.get('window').width > 768) ? 0.3 : 1.0,
            height: '100%',
            minWidth: (Dimensions.get('window').width > 768 && Dimensions.get('window').width < 1500) ? 300 : 0
        },
        recoveryMessage: {
            marginTop: 12,
            paddingVertical: 8,
            paddingHorizontal: 8,
            color: colors.white,
            borderRadius: 4,
            textAlign: 'center'
        },
        imageSection: {
            flex: (Dimensions.get('window').width > 768) ? 0.7 : 0,
            justifyContent: 'center',
            overflow: 'hidden'
        },
        loginContainer: {
            width: '70%',
            marginLeft: '15%',
            height: '100%',
            justifyContent: 'center'
        },
        loadingModal: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.blackTransparent
        },
        loadingModalForm: {
            width: 300,
            height: 300,
            borderRadius: 12,
            backgroundColor: colors.white,
            justifyContent: 'center',
            alignItems: 'center',

            shadowColor: colors.black,
            shadowOpacity: 0.2,
            shadowRadius: 12,
            elevation: 12,
            shadowOffset: { width: 10, height: 10 },
        }
    },
    {
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            loginContainer: {
                width: '90%',
                marginLeft: '5%'
            },
        }
    }
);