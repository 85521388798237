import mapHtml from 'assets/html/maps';
import { Icons } from "assets/icons";
import { Button, Footer, OpenNow, PlatformWebview, SText } from "components";
import { MessageModal } from "components/Modals";
import OnlineAfspraakModal from "components/Modals/OnlineAfspraakModal";
import { DateTimeFormat, QueryKeys, TenantShowCategoryKeys } from 'enums';
import Constants from "expo-constants";
import { LinearGradient } from "expo-linear-gradient";
import { useLayoutMargin, useStyle } from "hooks";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { Image, Linking, Pressable, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { Content } from 'screens/home';
import { colors } from "styles";
import { QueryUtils } from "utils";

const Config = Constants.expoConfig.extra.config;

export default () => {

    const { width, height } = useWindowDimensions();
    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { showModal } = useContext(ModalContext);
    const margin = useLayoutMargin();    
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const office = tenant?.offices?.filter(office => office.id == customer?.office_id)[0] || tenant?.offices?.length > 0 ? tenant?.offices[0] : null;

    const largeScreen = width > 768;
    const mapURL = encodeURI(office ? `https://maps.google.com/maps?q=${office.street_name} ${office.house_number}${office.house_number_addition}, ${office.city}&t=&z=16&ie=UTF8&iwloc=&output=embed` : `https://maps.google.com/maps?q=Noorderhaven Z.z. 46, Groningen&t=&z=16&ie=UTF8&iwloc=&output=embed`);
    const streetviewURL = encodeURI(office ? `${Config.PROXY_URL}/streetview?location=${office.street_name} ${office.house_number}${office.house_number_addition}, ${office.city}&size=600x400` : '');


    const renderInfo = () => {
        const day_keys = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        const today = moment();
        const today_index = today.isoWeekday() - 1;

        return (
            <View style={[
                tenant[TenantShowCategoryKeys.VisitAddress] === false && tenant[TenantShowCategoryKeys.OpeningHours] == false ? scopedStyles('contactInfoBig') : scopedStyles('contactInfo'),
                tenant[TenantShowCategoryKeys.VisitAddress] === false && tenant[TenantShowCategoryKeys.OpeningHours] && scopedStyles('contactInfoWithoutAddress'),
                styles('bigMarginRight'),
            ]}>

                {tenant[TenantShowCategoryKeys.OpeningHours] !== false &&
                    <OpenNow />
                }

                <SText md medium primary style={{ marginBottom: 20 }}>{office?.name || ''}</SText>

                {tenant[TenantShowCategoryKeys.VisitAddress] !== false ? (
                    <>
                        <SText sm semibold primary style={{ marginBottom: 4 }}>#address</SText>

                        <SText sm medium grey>{`${office?.street_name || ''} ${office?.house_number || ''}${office?.house_number_addition || ''}`}</SText>
                        <SText sm medium grey>{`${office?.postcode || ''}${office?.city ? ', ' + office?.city : ''}`}</SText>
                        <Pressable onPress={() => { Linking.openURL(`tel:${office?.phone}`) }}>
                            <SText sm medium grey url>{office?.phone}</SText>
                        </Pressable>
                        {office &&
                            // the double slashes after /maps/dir are important, leave them!
                            <Pressable onPress={() => Linking.openURL(`https://www.google.com/maps/dir//${office?.street_name}+${office?.house_number}${office?.house_number_addition}+${office?.city}/`)}>
                                <SText sm medium grey url>#route</SText>
                            </Pressable>
                        }
                    </>
                ) : (
                    <View style={{ flex: 1, justifyContent: "flex-end" }}>

                        <Image
                            source={tenant?.logo ? { uri: 'data:image/png;base64,' + tenant?.logo } : {}}
                            resizeMode='contain'
                            style={scopedStyles('contactInfoImage')}
                        />

                        <SText sm semibold primary style={{ marginBottom: 4 }}>#title_contact</SText>

                        <Pressable onPress={() => { Linking.openURL(`${office?.website}`) }}>
                            <SText sm semibold grey url>{office?.website}</SText>
                        </Pressable>
                        <Pressable onPress={() => { Linking.openURL(`mailto:${office?.email}`) }}>
                            <SText sm medium grey url>{office?.email}</SText>
                        </Pressable>
                        <Pressable onPress={() => { Linking.openURL(`tel:${office?.phone}`) }}>
                            <SText sm medium grey url>{office?.phone}</SText>
                        </Pressable>
                    </View>
                )}



                {tenant[TenantShowCategoryKeys.OpeningHours] !== false &&
                    <>
                        {/* break line */}
                        <View style={{ width: '100%', height: 0, borderTopColor: colors.border, borderTopWidth: 1, marginVertical: 25 }}></View>

                        <SText sm semibold primary style={{ marginBottom: 8 }}>#opening_hours</SText>


                        {day_keys.map((key, index) => {

                            //get default hours
                            const today_style = [index == today_index && { color: colors.darkestgrey }];
                            const defaultHours = office?.default_hours[key];
                            const dayHours = defaultHours?.length > 0 ? defaultHours : [];

                            //insert upcoming hours
                            if (index >= today_index) {
                                const nDate = today.clone().add(index - today_index, 'd').format(DateTimeFormat.upcoming_hours_key);
                                if (office?.upcoming_hours[nDate]) {
                                    office?.upcoming_hours[nDate].map((hours: string, index: number) => {
                                        const [from, till] = hours.split('-');
                                        dayHours[index].from = from;
                                        dayHours[index].till = till;
                                    })
                                }
                            }

                            const dayHourString = dayHours.map((hours, index2) => {
                                return hours.open ? (
                                    <View key={'dh' + index + index2} style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                        <SText sm medium grey center style={[today_style, { flex: 0.45 }]}>{hours.from?.substring(0, 5)}</SText>
                                        <SText sm medium grey center style={[today_style, { flex: 0.1 }]}>-</SText>
                                        <SText sm medium grey center style={[today_style, { flex: 0.45 }]}>{hours.till?.substring(0, 5)}</SText>
                                    </View>
                                ) : (
                                    <SText key={'dh' + index + index2} sm medium grey center style={today_style}>#closed</SText>
                                );
                            });


                            return (
                                <View
                                    key={'openinghours_' + index}
                                    style={{ flexDirection: "row", justifyContent: "space-between" }}
                                >
                                    <SText sm medium grey style={[today_style, { width: '50%' }]}>
                                        {'#' + key}
                                    </SText>

                                    <View style={{ width: '30%', paddingVertical: 4 }}>
                                        {dayHourString}
                                    </View>

                                </View>
                            )
                        })}
                    </>
                }

            </View>
        )
    }


    const renderMap = () => {

        return (
            <View style={{
                height: largeScreen ? '100%' : (width - margin * 2) * 1.314,
                flexDirection: 'column', 
                gap: 40,
            }}>
                <Image 
                    source={{uri: streetviewURL}} 
                    style={{ width: '100%', flex: 1, borderRadius: 12 }}
                />
                <PlatformWebview
                    html={mapHtml}
                    replaceVars={[
                        ['MAPURL', mapURL]
                    ]}
                    style={{ width: '100%', flex: 1, borderRadius: 12 }}
                />
            </View>
        )
    }

    const renderAdvisor = () => {
        return (

            <View style={scopedStyles('advisorContainer')}>

                {/* Adviseur */}
                {customer?.advisor &&
                    <View style={[scopedStyles('advisorElement'), styles('bigMarginBottom'), styles('bigMarginRight')]}>

                        {customer?.advisor?.avatar ? (
                            <Image
                                source={{ uri: `data:image/png;base64,${customer?.advisor?.avatar}` }}
                                width={largeScreen ? 180 : 120}
                                height={largeScreen ? 180 : 120}
                                style={{
                                    width: largeScreen ? 180 : 120,
                                    height: largeScreen ? 180 : 120,
                                    borderRadius: largeScreen ? 90 : 60
                                }}
                            />
                        ) : (
                            <Icons.Avatar_male size={largeScreen ? 180 : 120} color={''} />
                        )}

                        <View style={scopedStyles('advisorContact')}>
                            <SText white sm semibold center>{customer?.advisor?.full_name}</SText>
                            <SText greyTransparent xs regular center>{customer?.advisor?.email}</SText>
                            <SText greyTransparent xs regular center>{customer?.advisor?.phone}</SText>

                            <View style={{ marginTop: 15, flexDirection: 'row' }}>
                                <Button
                                    label="#action_get_contact"
                                    type="action"
                                    onPress={() => showModal(MessageModal, { contact: customer.advisor }, 'MessageModal')}
                                />
                            </View>

                        </View>

                    </View>
                }


                {/* Behandelaar (account manager) */}
                {(tenant && tenant[TenantShowCategoryKeys.AccountManager] !== false) && customer?.account_manager &&
                    <View style={[scopedStyles('advisorElement'), styles('bigMarginBottom'), styles('bigMarginRight')]}>

                        {customer?.account_manager?.avatar ? (
                            <Image
                                source={{ uri: `data:image/png;base64,${customer?.account_manager?.avatar}` }}
                                width={largeScreen ? 180 : 120}
                                height={largeScreen ? 180 : 120}
                                style={{
                                    width: largeScreen ? 180 : 120,
                                    height: largeScreen ? 180 : 120,
                                    borderRadius: largeScreen ? 90 : 60
                                }}
                            />
                        ) : (
                            <Icons.Avatar_male size={largeScreen ? 180 : 120} color={''} />
                        )}

                        <View style={scopedStyles('advisorContact')}>
                            <SText white sm semibold center>{customer?.account_manager?.full_name}</SText>
                            <SText greyTransparent xs regular center>{customer?.account_manager?.email}</SText>
                            <SText greyTransparent xs regular center>{customer?.account_manager?.phone}</SText>

                            <View style={{ marginTop: 15, flexDirection: 'row' }}>
                                <Button
                                    label="#action_get_contact"
                                    type="action"
                                    onPress={() => showModal(MessageModal, { contact: customer.account_manager }, 'MessageModal')}
                                />
                            </View>
                        </View>

                    </View>
                }

            </View>

        )
    }

    const renderUnderTitle = () => {
        const websitelink = office?.website.includes('http') ? office?.website : `https://${office?.website}`
        return (
            <View style={{ marginTop: 20, width: largeScreen ? '55%' : '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
                <SText sm regular black>#undertitle_contact_1</SText>

                <Pressable onPress={() => { Linking.openURL(`mailto:${office?.email}`) }}>
                    <SText sm semibold black url>{office?.email}</SText>
                </Pressable>

                <SText sm regular black>#undertitle_contact_2</SText>


                <Pressable onPress={() => { Linking.openURL(`tel:${customer?.advisor?.phone}`) }}>
                    <SText sm semibold black url>{customer?.advisor?.phone || office?.phone}</SText>
                </Pressable>

                <SText sm regular black>#undertitle_contact_3</SText>

                <SText sm regular black>#undertitle_contact_4</SText>

                <Pressable onPress={() => { Linking.openURL(websitelink) }}>
                    <SText sm semibold black url>website</SText>
                </Pressable>

                <SText sm regular black url>.</SText>
            </View>
        )
    }




    return (
        <View style={styles('fullscreen')}>

            {/* map */}
            {(largeScreen && tenant[TenantShowCategoryKeys.VisitAddress] !== false) &&
                <View
                    style={[
                        scopedStyles('mapContainer'),
                        styles('bigPaddingRight'),
                        styles('bigPaddingVertical')
                    ]}
                >
                    {renderMap()}
                </View>
            }

            <Content
                specificTopHeight={height * 0.3}
                title={'#title_contact'}
            >

                {/* topcontainer */}
                <View style={[styles('fullscreen'), { overflow: 'visible' }]}>

                    {/* undertitle */}
                    {renderUnderTitle()}

                    <View style={[scopedStyles('buttonContainer'), !largeScreen && styles('bigMarginBottom')]}>
                        <View style={{ flexDirection: "row" }}>
                            {tenant[TenantShowCategoryKeys.Afspraak] &&
                                <Button
                                    label="#action_make_appointment_online"
                                    type="action"
                                    onPress={() => showModal(OnlineAfspraakModal, {}, 'OnlineAfsprakenModal')}
                                    style={{ marginTop: 8 }}
                                />
                            }
                        </View>
                    </View>

                </View>

                {/* needed for content component (instead of navbar) */}
                <View></View>

                {/* gradient container with advisor */}
                <LinearGradient
                    colors={[colors.gradientFrom, colors.gradientTo]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                    style={[
                        styles('fullscreen'),
                        styles('bigPaddingHorizontal'),
                        styles('bigPaddingVertical'),
                        scopedStyles('gradientContainer')
                    ]}
                >

                    {/* advisor */}
                    {renderAdvisor()}

                    {renderInfo()}

                    {/* map */}
                    {(!largeScreen && tenant[TenantShowCategoryKeys.OpeningHours] !== false) ?
                        <View style={scopedStyles('mapContainer')}>
                            {renderMap()}
                        </View>
                        : null}

                    <Footer />

                </LinearGradient>

            </Content>

        </View>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        contactContainer: {
            flexDirection: "row",
            marginTop: 10,
            justifyContent: "flex-end",
            alignItems: 'flex-end',
            width: '100%'
        },
        gradientContainer: {
            flexDirection: 'row',
            overflow: 'visible'
        },
        contactInfo: {
            position: 'absolute',
            width: 360,
            right: '40%',
            top: -150,
            height: 'auto',
            backgroundColor: colors.white,
            borderRadius: 8,
            padding: 20,
            zIndex: 10
        },
        contactInfoBig: {
            position: 'absolute',
            width: '40%',
            minHeight: 300,
            right: '10%',
            top: -120,
            height: 'auto',
            backgroundColor: colors.white,
            borderRadius: 8,
            padding: 20,
            zIndex: 10
        },
        contactInfoWithoutAddress: {
            right: '20%'
        },
        contactInfoImage: {
            position: "absolute",
            right: 0,
            height: '100%',
            width: '45%'
        },
        contactLine: {
            width: '80%',
            maxWidth: 400,
            alignSelf: 'flex-end',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        mapContainer: {
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            width: '40%',
            borderRadius: 12,
            overflow: 'visible',
            zIndex: 1
        },
        advisorContainer: {
            flexDirection: 'row'
        },
        advisorElement: {
            alignItems: "center",
            paddingTop: 20,
        },
        advisorContact: {
            justifyContent: "center",
            marginTop: 15
        },
        buttonContainer: {
            position: "absolute",
            bottom: 0,
            flexDirection: 'row',
        },
    }, {

    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
        contactInfoWithoutAddress: {
            right: '10%',
            top: -200,
        },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
        contactInfo: {
            right: '10%'
        },
        contactInfoBig: {
            minHeight: 500
        },
        gradientContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        advisorContainer: {
            flexDirection: 'column'
        },
        advisorElement: {
            height: 'auto',
            width: 360,
        }
    },

    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        mapContainer: {
            position: 'relative',
            width: '100%',
            height: 'auto',
            marginBottom: 25
        },
        advisorContainer: {
            flexDirection: 'column',
            width: '100%'
        },
        advisorElement: {
            flex: 1,
            width: '100%',
            height: 'auto',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            paddingBottom: 12,
            paddingTop: 0,
        },
        advisorContact: {
            marginTop: 0,
            justifyContent: "space-between"
        },
        contactInfo: {
            position: 'relative',
            left: 0,
            top: 0,
            width: '100%',
            height: 'auto',
            flex: 2,
            marginBottom: 25
        },
        contactInfoBig: {
            position: 'relative',
            left: 0,
            top: 0,
            width: '100%',
            height: 'auto',
            flex: 2,
            marginBottom: 25
        },
        contactInfoImage: {
            position: "relative",
            width: '100%',
            flex: 1
        },
        contactInfoWithoutAddress: {
            position: 'relative',
            top: undefined,
            right: undefined
        },
        buttonContainer: {
            width: '100%',
            flexWrap: 'wrap'
        },
    }
}
)
