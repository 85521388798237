import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M16,282.4c1.1-5,1.9-10.1,3.2-15.1c13.3-49,62.4-77.8,111.7-65.7c0.9,0.2,1.7,0.3,3.4,0.6c-1.3-3.5-2.4-6.6-3.6-9.6
	c-2-5.1-4.2-10.1-5.9-15.2c-1.1-3.2-2.8-4.3-6.1-4c-3.4,0.3-6.8,0.2-10.2,0c-7.6-0.4-13.2-6.1-13.2-13.3c0-7.4,5.8-13.1,13.7-13.2
	c14.6-0.1,29.2,0,43.8,0c8.6,0,14.4,5,14.7,12.6c0.3,7.7-4.9,13.1-13.5,14c-0.6,0.1-1.1,0.2-2.2,0.3c1.9,4.9,3.7,9.5,5.7,14
	c0.4,0.8,2,1.5,3.1,1.5c35.4,0.1,70.8,0.1,106.2,0c1.4,0,3.3-1.2,4-2.4c7.8-12.8,15.5-25.8,23-38.8c0.7-1.2,0.6-3.8-0.2-5
	c-5.9-8.6-14.1-13.7-24.5-14.8c-1.8-0.2-3.5-0.2-5.3-0.5c-7.3-1.1-12.1-7-11.6-14.3c0.5-7,6.4-12.2,13.9-12
	c20.7,0.6,37.1,9.4,49.2,26.2c5.6,7.8,8.5,16.7,10.5,26.1c3.7,17.2,10,33.6,17.1,49.6c0.2,0.4,0.5,0.7,0.3,0.4
	c9.8-1.7,19.1-4.1,28.6-4.7c47.9-2.9,91,32.6,96.6,79c6.1,50.3-26,95-74.7,104.1c-58.7,11-113.2-37-109.2-96.4
	c1.7-26.1,12.7-47.9,32.6-65c2.6-2.3,2.9-3.9,1.6-7c-4.1-9.6-7.7-19.4-11.6-29.2c-0.4-1-0.8-2.1-1.5-3.8c-1.2,2-2.1,3.3-2.9,4.6
	c-21.8,36.6-43.6,73.2-65.2,109.9c-3.8,6.4-8.7,9.5-16.2,9.2c-6.9-0.3-13.8-0.1-21.1-0.1c-0.8,3.4-1.4,6.7-2.3,10
	c-10.7,41.3-50,72.9-97.8,68.8c-43.1-3.7-78.2-37.6-83.4-80.3c-0.1-1-0.5-2-0.8-3C16,294.2,16,288.3,16,282.4z M172.7,304.5
	c-1.6,0-3.2,0-4.8,0c-19.2,0-38.4,0-57.5,0c-9,0-15-5.4-15-13.2c0-7.9,6-13.3,15-13.3c19.2,0,38.4,0,57.5,0c1.6,0,3.2,0,4.8,0
	c-3.1-23.2-27.4-53.9-66.3-52.5c-35.3,1.2-63.4,29.9-63.7,64.9c-0.3,35.7,27.1,64.9,62.7,66.7C143.4,358.9,169,329.4,172.7,304.5z
	 M332.2,243.3c-22.1,18.8-30.4,57.5-8.1,87.3c21.4,28.7,61.7,35.1,90.7,14.5c29.4-20.8,36.8-60.9,16.8-90.7
	c-16.6-24.7-55.7-37.6-75.9-24.8c4.2,7.5,8.3,15.2,12.7,22.7c6.1,10.2,12.5,20.3,18.9,30.4c4.6,7.2,3.3,15.3-3.1,19.5
	c-6.4,4.2-14.1,2.4-18.8-4.5c-1.7-2.4-3.3-4.9-4.9-7.4C351,274.8,341.6,259.1,332.2,243.3z M168.1,216.1c2.3,3.8,3.6,7.3,6,9.7
	c12.4,13,20.9,28,24.8,45.6c0.5,2.1,0.9,4.2,1.4,6.5c4.4,0,8.6-0.2,12.7,0.1c2.8,0.2,4.5-0.7,6-3.3c10.8-18.4,21.8-36.8,32.6-55.1
	c0.6-1.1,1.1-2.2,1.8-3.5C225,216.1,197.2,216.1,168.1,216.1z"/>
</Svg>
                    );
                }