import { StyleSheet } from 'react-native';
import colors from './colors';


export const TextStylesExtraLarge: StyleSheet.NamedStyles<any> = {
    'text-xl': {
        fontSize: 36
    },
    'text-lg': {
        fontSize: 24
    },
    'text-md': {
        fontSize: 17
    },
    'text-sm': {
        fontSize: 14
    },
    'text-xs': {
        fontSize: 13
    },

    'text-light': {
        fontFamily: 'Poppins_300Light',
        letterSpacing: 0.2
    },
    'text-regular': {
        fontFamily: 'Poppins_400Regular',
        letterSpacing: 0.2
    },
    'text-medium': {
        fontFamily: 'Poppins_500Medium',
        letterSpacing: 0.2
    },
    'text-semibold': {
        fontFamily: 'Poppins_600SemiBold',
        letterSpacing: 0.2
    },
    'text-bold': {
        fontFamily: 'Poppins_700Bold',
        letterSpacing: 0.2
    },


    'text-black': {
        color: colors.black
    },
    'text-white': {
        color: colors.white
    },
    'text-primary': {
        color: colors.primary
    },
    'text-disabled': {
        color: colors.disabled
    },
    'text-error': {
        color: colors.error
    },

    'text-mono-regular': {
        fontFamily: 'FiraMono_400Regular'
    },
    'text-mono-medium': {
        fontFamily: ', FiraMono_500Medium'
    },
    'text-mono-bold': {
        fontFamily: 'FiraMono_700Bold'
    }

}

export const TextStylesLarge: StyleSheet.NamedStyles<any> = {

    'text-xl': {
        fontSize: 32
    },
    'text-lg': {
        fontSize: 22
    },
    'text-md': {
        fontSize: 15
    },
    'text-sm': {
        fontSize: 14
    },
    'text-xs': {
        fontSize: 13
    }

}

export const TextStylesMedium: StyleSheet.NamedStyles<any> = {
    'text-xl': {
        fontSize: 24
    },
    'text-lg': {
        fontSize: 19
    },
    'text-md': {
        fontSize: 13
    },
    'text-sm': {
        fontSize: 12,
    },
    'text-xs': {
        fontSize: 11
    }
}

export const TextStylesSmall: StyleSheet.NamedStyles<any> = {
    'text-xl': {
        fontSize: 23
    },
    'text-lg': {
        fontSize: 18
    },
    'text-md': {
        fontSize: 12
    },
    'text-sm': {
        fontSize: 11
    },
    'text-xs': {
        fontSize: 10
    }
}
