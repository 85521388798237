import { useHover, useStyle } from 'hooks';
import React from 'react';
import { ActivityIndicator, Platform, Pressable, View, ViewStyle } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import SText from './SText';


interface Props {
    label: string
    onPress: () => void
    style?: ViewStyle,
    icon?: (color: string) => React.ReactNode,
    type?: 'primary' | 'secondary' | 'action' | 'outline' | 'inline' | 'error' | "disabled"
    rounded?: boolean,
    disabled?: boolean,
    loading?: boolean,
    unclickable?: boolean
}

//
//
//
// A buttom component with 3 types of styling, you can give an icon or set the loading property, that shows a loading icon.
//
//
//

const loadingIcon = (color, size) => <ActivityIndicator color={color} size={size} />;

const Button: React.FC<Props> = ({ label, onPress, style, icon, type, rounded, disabled, loading, unclickable }) => {

    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const [hovered, hoverElt] = useHover();
    const iconColor = (!type || type === 'primary' || type == 'action' || type == 'error' || disabled) ? colors.white : colors.primary;
    const hoverBgColor = type == 'action' ? colors.actionDark : (type == 'disabled') ? colors.darkestgrey : (type == 'secondary' || type == 'outline') ? colors.grey : (type == 'error') ? colors.errorDarker : colors.primaryDarker
    const iconElement = icon && icon(iconColor);
    const loadingIconElement = loadingIcon(iconColor, 16);

    return disabled ? (

        <View style={[{ width: 'auto', height: 'auto' }, style]}>
            <View style={[
                styles('center'),
                scopedStyles('button'),
                { borderRadius: 6 },
                (type === 'secondary') ? scopedStyles('button-secondary-disabled') : scopedStyles('button-disabled'),
                rounded && scopedStyles('button-rounded')
            ]}>
                {iconElement && iconElement}
                <SText regular style={[
                    scopedStyles('text'),
                    (iconElement || loading) && scopedStyles('text-margin'),
                    (type === 'secondary') ? styles('text-disabled') : styles('text-white')
                ]} oneline={true}>
                    {label}
                </SText>
            </View>
        </View >

    ) : (


        <Pressable
            disabled={unclickable}
            style={style}
            onPress={onPress}
        >
            <View ref={hoverElt} style={[
                styles('center'),
                { borderRadius: 6 },
                scopedStyles('button'),
                { backgroundColor: colors.primary },
                (type === 'secondary') && [scopedStyles('button-secondary'), { borderWidth: 0, backgroundColor: colors.primaryTransparent }],
                (type === 'outline') && [scopedStyles('button-secondary'), { borderColor: colors.primary }],
                (type === 'inline') && [scopedStyles('button-secondary'), { backgroundColor: colors.primary, borderColor: colors.primary, color: colors.white }],
                (type === 'action') && { backgroundColor: colors.action },
                (type === 'error') && scopedStyles('button-error'),
                (type === 'disabled') && { backgroundColor: colors.white, borderColor: colors.grey, borderWidth: 2, },
                Platform.OS == 'web' && { transition: 'all 0.12s linear' },
                (hovered && !unclickable) && { backgroundColor: hoverBgColor },
                rounded && scopedStyles('button-rounded')
            ]}>

                {(iconElement && !loading) && iconElement}
                {loading && loadingIconElement}

                <SText
                    oneline
                    sm
                    semibold
                    white
                    grey={type === 'disabled'}
                    style={[
                        (iconElement || loading) && scopedStyles('text-margin'),
                        (!disabled && (type === 'secondary' || type === 'outline')) && [styles('text-primary'), { color: colors.primary }],
                    ]}>
                    {label}
                </SText>
            </View>
        </Pressable>

    );
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        button: {
            flex: 0,
            flexGrow: 1,
            flexDirection: 'row',
            paddingVertical: 8,
            paddingHorizontal: 15
        },
        'button-secondary': {
            paddingVertical: 8,
            backgroundColor: colors.white,
            borderWidth: 1,
        },
        'button-error': {
            backgroundColor: colors.error,
        },
        'button-disabled': {
            backgroundColor: colors.disabled,
        },
        'button-rounded': {
            borderRadius: 24
        },
        'button-secondary-disabled': {
            paddingVertical: 10,
            backgroundColor: colors.white,
            borderWidth: 1,
            borderColor: colors.disabled,
        },
        'button-hovered': {
            opacity: 0.95
        },
        'text-margin': {
            marginLeft: 8
        }
    },
    {
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            button: {
                paddingVertical: 10,
            },
            'button-secondary': {
                paddingVertical: 8,
            },
            'button-secondary-disabled': {
                paddingVertical: 8,
            },
            'text': {
                fontSize: 18
            },
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            button: {
                paddingVertical: 10
            },
            'button-secondary': {
                paddingVertical: 8,
            },
            'button-secondary-disabled': {
                paddingVertical: 8,
            },
            'text': {
                fontSize: 18
            },
        }
    }
)

export default Button;
