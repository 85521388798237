import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M189,214.6H28.2c-12.6,0-23-10.3-23-23V30.9c0-12.6,10.3-23,23-23H189c12.6,0,23,10.3,23,23v160.8
	C212,204.3,201.6,214.6,189,214.6z"/>
<Path fill={color} d="M457,214.6H296.2c-12.6,0-23-10.3-23-23V30.9c0-12.6,10.3-23,23-23H457c12.6,0,23,10.3,23,23v160.8
	C480,204.3,469.6,214.6,457,214.6z"/>
<Path fill={color} d="M189,477.3H28.2c-12.6,0-23-10.3-23-23V293.6c0-12.6,10.3-23,23-23H189c12.6,0,23,10.3,23,23v160.8
	C212,467,201.6,477.3,189,477.3z"/>
<Path fill={color} d="M457,477.3H296.2c-12.6,0-23-10.3-23-23V293.6c0-12.6,10.3-23,23-23H457c12.6,0,23,10.3,23,23v160.8
	C480,467,469.6,477.3,457,477.3z"/>
</Svg>
                    );
                }