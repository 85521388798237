import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 512 512"  >
<G>
	<Circle fill={color} cx="256" cy="256" r="29"/>
	<Path fill={color} d="M434.2,77.8C386.7,30.2,323.4,4,256,4S125.3,30.2,77.8,77.8S4,188.6,4,256c0,43.4,11.2,86,32.3,123.6L5.2,476.5
		c-2.8,8.5-0.4,18.1,5.9,24.5c4.6,4.6,10.7,7.1,17.1,7.1c2.5,0,5-0.4,7.4-1.2l96.8-31.2C170,496.8,212.6,508,256,508
		c67.4,0,130.7-26.2,178.2-73.8S508,323.4,508,256S481.8,125.3,434.2,77.8z M256,459.7c-38.3,0-75.6-10.7-107.8-30.8
		c-3.9-2.4-8.3-3.7-12.8-3.7c-2.5,0-5,0.4-7.4,1.1l-62.5,20.1l20.1-62.4c2.2-6.7,1.2-14.1-2.5-20.2C62.9,331.6,52.3,294.3,52.3,256
		c0-112.3,91.4-203.7,203.7-203.7S459.7,143.7,459.7,256S368.3,459.7,256,459.7z"/>
	<Circle fill={color} cx="159.4" cy="256" r="29"/>
	<Circle fill={color} cx="352.6" cy="256" r="29"/>
</G>
</Svg>
                    );
                }