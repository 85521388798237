import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M343.4,308.6c0-5.3,0-10.7,0-16.6c-1.8,0.6-2.9,1-4,1.4c-28.1,10-56.2,19.9-84.3,30c-8,2.9-15.8,3.2-23.8,0.4
		c-27.9-10-55.7-19.9-83.6-29.8c-1.7-0.6-3.5-1.2-5.6-1.8c-0.1,1.5-0.2,2.6-0.2,3.7c0,12.7,0.1,25.4-0.1,38.1
		c-0.1,6.6,2.1,12.3,7.4,16c5.9,4.1,12.2,7.9,18.8,10.6c17.4,7,35.7,10.2,54.3,11.6c29.6,2.3,58.8,0.1,87.2-8.8
		c9.4-2.9,18.5-6.6,26.1-13c5.2-4.3,7.9-9.7,7.7-16.6C343.2,325.4,343.4,317,343.4,308.6z"/>
	<Path fill={color} d="M392.3,338.1c-2-1.6-2.6-3.2-2.6-5.6c0.1-26.6,0-26.5,0-53.1c0-1.2,0-2.3,0-3.9c-6.9,2.5-13.4,4.8-20.1,7.1
		c0,1.1,0,1.7,0,2.4c0,25,0,23.1,0,48.1c0,2.1-0.5,3.3-2.3,4.7c-10.6,8.1-10.5,23.7,0,31.7c1.7,1.3,2.3,2.7,2.3,4.7
		c-0.1,15.2-0.1,13.3,0,28.5c0,5.3,3,9.3,7.7,10.4c6.6,1.5,12.4-3.2,12.4-10.3c0.1-15.3,0.1-13.6,0-28.8c0-2,0.6-3.2,2.2-4.4
		C402.4,361.6,402.6,346.3,392.3,338.1z"/>
	<G>
		<Path fill={color} d="M405.1,228.3c-23.2-8.2-46.3-16.3-69.5-24.5c-4.9,9.7-11.4,18.6-19.3,26.5c-19.6,19.6-45.7,30.4-73.4,30.4
			s-53.8-10.8-73.4-30.4c-7.9-7.9-14.4-16.9-19.3-26.6c-23.1,8.2-46.2,16.3-69.3,24.4c-4.4,1.5-7.8,3.7-9.4,8.1c0,1.6,0,3.1,0,4.7
			c1.6,3.8,4.2,6.3,8.2,7.7c52.6,18.6,105.1,37.3,157.6,56c3.8,1.4,7.2,1.4,11,0c28.6-10.2,57.2-20.4,85.9-30.6
			c23.6-8.4,47.2-16.8,70.8-25.1c3.5-1.2,6.6-2.7,8.2-6.3C415.7,236.6,412.6,230.9,405.1,228.3z"/>
		<Path fill={color} d="M242.9,241.9c32.3,0,60.4-18,74.8-44.5c6.6-12.1,10.3-25.9,10.3-40.6c0-47-38.1-85.1-85.1-85.1c-47,0-85.1,38.1-85.1,85.1
			c0,14.7,3.7,28.5,10.2,40.5C182.5,223.9,210.6,241.9,242.9,241.9z M205.2,159.6h6.4c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6
			c0-0.5,0-1,0-1.6c0-1,0-1.7,0.1-2.4c0-0.2,0-0.4,0-0.6h-6.4v0v-9.1h8c2.2-7.4,6.3-13.3,12.4-17.6c1.5-1.1,3.1-2,4.7-2.9
			c1.2-0.6,2.5-1.1,3.8-1.6c1.7-0.6,3.4-1,5.2-1.4c0.2,0,0.3-0.1,0.5-0.1c1.4-0.2,2.8-0.4,4.2-0.5c1-0.1,2-0.1,3.1-0.1
			c5.8,0,11,1.1,15.6,3.2c2.1,1,4.1,2.1,6,3.5c3.7,2.8,6.7,6.2,8.9,10.2c0.9,1.6,1.6,3.3,2.3,5.2c0.3,0.9,0.6,1.8,0.9,2.8h0v0h-19
			c-0.7-1.4-1.6-2.7-2.6-3.8c-0.3-0.3-0.5-0.5-0.8-0.8c-0.3-0.3-0.5-0.5-0.8-0.7c-0.6-0.5-1.2-0.9-1.8-1.3c-1.2-0.7-2.5-1.3-3.9-1.6
			c-0.4-0.1-0.9-0.2-1.4-0.3c-1.1-0.2-2.3-0.3-3.6-0.3c-3.2,0-6.1,0.7-8.7,2.1c-2.2,1.2-4.1,2.9-5.6,5.1c-0.2,0.3-0.4,0.6-0.6,1
			h16.9v9.1v0h-19.7c0,0.2,0,0.3,0,0.5c0,0.6-0.1,1.4-0.1,2.4c0,0.6,0,1.1,0,1.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.5h19.7v9h-17.1
			c1.1,1.9,2.4,3.5,4,4.8c0.1,0.1,0.3,0.2,0.4,0.3c0.6,0.5,1.2,0.9,1.8,1.2c0.6,0.4,1.3,0.7,2,1c0.7,0.3,1.4,0.5,2.1,0.7
			c0.7,0.2,1.5,0.3,2.3,0.4c0.8,0.1,1.6,0.1,2.4,0.1c0.4,0,0.8,0,1.2,0c0.8,0,1.6-0.1,2.3-0.3c1.6-0.3,3.1-0.9,4.5-1.6
			c0.2-0.1,0.5-0.3,0.7-0.4c1.3-0.8,2.4-1.7,3.4-2.8c1-1.1,1.9-2.4,2.6-3.8h0h19h0c-0.3,1-0.5,1.9-0.9,2.8c-0.9,2.7-2.2,5.2-3.7,7.5
			c-0.5,0.8-1,1.5-1.6,2.2c-0.3,0.4-0.7,0.8-1,1.2c-1.5,1.6-3.1,3.2-4.9,4.5c-0.7,0.6-1.5,1.1-2.3,1.6c-0.8,0.5-1.6,0.9-2.4,1.3
			c-0.8,0.4-1.7,0.8-2.5,1.1c-3,1.2-6.3,2-9.8,2.4c-1.5,0.2-3,0.2-4.6,0.2c-6.3,0-11.9-1.2-16.8-3.7c-1.7-0.8-3.2-1.8-4.7-2.9
			c-1.1-0.8-2.1-1.7-3.1-2.6c-1.4-1.3-2.7-2.7-3.8-4.3c-0.2-0.3-0.4-0.6-0.6-0.8c-2.1-3-3.7-6.3-4.8-10.1h-7.9V159.6z"/>
	</G>
</G>
</Svg>
                    );
                }