import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<G>
		<Path fill={color} d="M194.079,193.465c14.283,21.424,24.208,54.191,24.208,79.937v142.254l54.424-18.141V273.548
			c0-25.773,9.966-58.717,24.209-80.083L388.716,55.77H102.282L194.079,193.465z M327.136,273.548V409.45
			c0,15.377-11.582,31.152-25.871,35.916l-111.53,37.176c-14.13,4.711-25.873-3.529-25.873-18.523V273.402
			c0-15-6.745-37.265-15.066-49.748L15.656,23.946C7.397,11.557,12.693,1.345,27.622,1.345h435.756
			c14.924,0,20.286,10.12,11.964,22.601L342.204,223.654C333.945,236.045,327.136,258.474,327.136,273.548z"/>
	</G>
</G>
</Svg>
                    );
                }