import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M221.7,230c-20.1,0-40.2,0-60.3,0C181.6,230.1,201.7,230,221.7,230c26.5,0,53.1,0,79.6,0
		C274.8,230.1,248.3,230,221.7,230z"/>
	<Path fill={color} d="M221.7,135.9c20.1,0,40.2,0,60.3,0C261.8,135.9,241.8,135.9,221.7,135.9c-26.7,0-53.3,0-80,0
		C168.3,135.9,195,135.9,221.7,135.9z"/>
	<Path fill={color} d="M202.3,261.4c-6.6,0-13.2,0-19.8,0c-6.7,0-13.5,0-20.2,0c6.7,0,13.5,0,20.2,0
		C189.1,261.4,195.7,261.4,202.3,261.4z"/>
	<Path fill={color} d="M209.7,425.1c-1.5-8.2-7.9-13-17.4-13c-21.8,0-43.7,0-65.5,0c-19.6,0-33-13.4-33-33c0-91,0-182,0-272.9
		c0-19.6,13.4-32.9,33.1-32.9c63.1,0,126.3,0,189.4,0c2.1,0,4.2,0,6.2,0.4c16.4,2.7,27,15.4,27,32.4c0,49.1,0,98.3,0,147.4
		c0,9.6,6.5,16.6,15.5,16.7c8.9,0.1,15.8-6.9,15.8-16.4c0-50.1,0.2-100.1-0.1-150.2c-0.1-26.8-18-50.5-43.5-58.6
		c-3.9-1.3-8-2.1-12-3.1c-69,0-138.1,0-207.1,0c-2.6,0.6-5.3,1.1-7.9,1.8c-27.6,6.8-47.7,31.8-47.7,60.2
		c-0.1,92.6-0.1,185.1,0,277.7c0,30.7,23.1,56.8,53.5,61.3c0.7,0.1,1.4,0.5,2.2,0.7c26.4,0,52.8,0,79.2,0c0.2-0.2,0.4-0.4,0.6-0.4
		C206.8,439.7,211.2,432.9,209.7,425.1z"/>
	<Path fill={color} d="M351,435.4c29.3-12.5,50.7-34.5,69-59.8c4.1-5.6,3.4-12-0.7-17.6c-14.2-19.4-30.7-36.7-51.1-49.7
		c-32.5-20.6-65.9-23.1-100.2-4.5c-24.3,13.2-43,32.5-59.1,54.6c-4.4,6-4.3,12.8,0.6,18.9c8.1,9.9,16.3,19.8,25.3,28.8
		c16.2,16.2,34.8,28.7,57.4,34.5c4.7,1.2,9.4,1.9,14.1,2.9c5.2,0,10.5,0,15.7,0c0.7-0.2,1.5-0.5,2.2-0.7
		C333.3,440.4,342.6,439,351,435.4z M293.6,408.2c-21.5-8.1-37.2-23.6-52.1-40.9c17.3-19.4,35.1-37.4,61.5-43.4
		c10.5-2.4,20.8-1.4,30.8,2.2c22,8,38,23.8,53.2,41.3c-17.3,19.4-35.1,37.4-61.4,43.4C314.7,413.3,304,412.1,293.6,408.2z"/>
	<Path fill={color} d="M142,167.3c47.8,0,95.7,0,143.5,0c5.7,0,11.5,0.1,17.2,0c6.9-0.2,12.7-4.6,14.7-11c3.2-10.3-4.2-20.3-15.4-20.3
		c-6.7,0-13.4,0-20.1,0c-20.1,0-40.2,0-60.3,0c-26.7,0-53.3,0-80,0c-7.7,0-13.4,4-15.7,10.6C122.4,157.1,130.1,167.3,142,167.3z"/>
	<Path fill={color} d="M301.9,198.7c-33.8-0.1-67.7,0-101.5,0c-19.6,0-39.2,0-58.8,0c-7.3,0-13,3.8-15.4,10.1c-4,10.5,3.6,21.3,15.2,21.3
		c6.7,0,13.4,0,20.1,0c20.1,0,40.2,0,60.3,0c26.5,0,53.1,0,79.6,0c7.8,0,13.9-4.1,16-10.8C320.7,208.8,313.2,198.7,301.9,198.7z"/>
	<Path fill={color} d="M222.1,261.4c-6.6,0-13.2,0-19.8,0c-6.6,0-13.2,0-19.8,0c-6.7,0-13.5,0-20.2,0c-6.7,0-13.5,0-20.2,0
		c-9.9,0-16.9,6.5-16.9,15.6c0,9.2,6.9,15.7,16.8,15.7c26.7,0,53.3,0,80,0c9.9,0,16.9-6.5,17-15.6C239,268,231.9,261.5,222.1,261.4z
		"/>
	<Path fill={color} d="M344.9,367.2c-0.1-16.3-13.4-29.6-29.7-29.7c-16.5,0-29.9,13.4-29.9,29.9c0.1,16.3,13.4,29.6,29.7,29.7
		C331.5,397.2,344.9,383.7,344.9,367.2z"/>
</G>
</Svg>
                    );
                }