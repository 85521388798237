import { useNavigation } from '@react-navigation/native';
import { Icons } from 'assets/icons';
import { EnergyLabel, SText } from 'components';
import { QueryKeys } from 'enums';
import Constants from "expo-constants";
import { ActivityIndicator, Image, Pressable, View, useWindowDimensions } from 'react-native';
import { useQuery } from 'react-query';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { colors } from 'styles';
import { QueryUtils, ValutaUtils } from 'utils';

const Config = Constants.expoConfig.extra.config;


//This screen show an overview of the customers values

export default ({ style, showMaps = false }) => {

    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { data: living, isLoading } = useQuery(QueryKeys.LIVING, QueryUtils.fetchLiving);
    const navigation = useNavigation();
    const { width } = useWindowDimensions();

    const activeAddress = living?.property;
    const owner = living?.owns_property;

    let marketValue = activeAddress?.market_value;
    if (!marketValue && activeAddress?.market_value_high != undefined) marketValue = activeAddress?.market_value_high;
    if (!marketValue && activeAddress?.market_value_low != undefined) marketValue = activeAddress?.market_value_low;

    const streetviewURL = encodeURI(activeAddress ? `${Config.PROXY_URL}/streetview?location=${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}, ${activeAddress.city}&size=400x300` : '');

    const renderOverview = () => {
        return (
            <View style={{ flex: 1, width: '50%' }}>
                <View style={scopedStyles('addressBlock')}>
                    <SText medium white sm>#living_address</SText>
                    <View style={scopedStyles('addressContainer')}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Icons.Home size={60} color={colors.white} />
                            <View style={{ marginLeft: 12 }}>
                                <SText md white semibold>{`${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}`}</SText>
                                <SText sm grey regular>{`${activeAddress.postcode}, ${activeAddress.city}`}</SText>
                            </View>
                        </View>
                        <EnergyLabel label={activeAddress.energy_label} style={{ marginRight: 4 }} />
                    </View>
                </View>

                <View style={scopedStyles('itemContainer')}>


                    {owner ? (
                        <View style={scopedStyles('itemRow')}>
                            <View style={scopedStyles('item')}>
                                <SText greyTransparent sm medium>#koopsom</SText>
                                <SText white regular md>{activeAddress.purchase_price ? ValutaUtils.valutaString(activeAddress.purchase_price, true) : '#unknown'}</SText>
                            </View>
                            <View style={scopedStyles('item')}>
                                <SText greyTransparent sm medium>#marktwaarde</SText>
                                <SText white regular md>{marketValue ? ValutaUtils.valutaString(marketValue, true) : '#unknown'}</SText>
                            </View>
                        </View>
                    ) : (
                        <View style={scopedStyles('itemRow')}>
                            <View style={scopedStyles('item')}>
                                <SText greyTransparent sm medium>#kale_huur</SText>
                                <SText white regular md>{activeAddress.rent ? ValutaUtils.valutaString(activeAddress.rent, true) : '#unknown'}</SText>
                            </View>
                            <View style={scopedStyles('item')}>
                                <SText greyTransparent sm medium>#service_kosten</SText>
                                <SText white regular md>{activeAddress.service_costs ? ValutaUtils.valutaString(activeAddress.service_costs, true) : '#unknown'}</SText>
                            </View>
                        </View>
                    )}

                    <View style={scopedStyles('itemRow')}>
                        {owner ? (
                            <View style={scopedStyles('item')}>
                                <SText greyTransparent sm medium>#wozwaarde</SText>
                                <SText white regular md>{activeAddress.woz_value_home ? ValutaUtils.valutaString(activeAddress.woz_value_home, true) : '#unknown'}</SText>
                            </View>
                        ) : (
                            <View style={scopedStyles('item')}>
                                <SText greyTransparent sm medium>#other_kosten</SText>
                                <SText white regular md>{activeAddress.other_costs ? ValutaUtils.valutaString(activeAddress.other_costs, true) : '#unknown'}</SText>
                            </View>
                        )}
                        <View style={scopedStyles('item')}>
                            <SText greyTransparent sm medium>#bouwjaar</SText>
                            <SText white regular md>{activeAddress.construction_year || '#unknown'}</SText>
                        </View>
                    </View>
                    <View style={scopedStyles('itemRow')}>
                        <View style={scopedStyles('item')}>
                            <SText greyTransparent sm medium>#opp_woning</SText>
                            <SText white regular md>{activeAddress.size ? (activeAddress.size + ' m²') : '#unknown'}</SText>
                        </View>
                        <View style={scopedStyles('item')}>
                            <SText greyTransparent sm medium>#opp_perceel</SText>
                            <SText white regular md>{activeAddress.plot_size ? (activeAddress.plot_size + ' m²') : '#unknown'}</SText>
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    const renderMap = () => {    
        if ( !activeAddress ) return;
        return (
            <Image 
                source={{uri: streetviewURL}} 
                style={{ width: '100%', flex: 1, borderRadius: 12 }}
            />
        )
    }


    return (
        <Pressable
            style={[style, showMaps && {width: width > 768 ? width * 0.618 : 800, minWidth: width > 768 ? 1200 : 800}]}
            onPress={() => navigation.navigate('wonen')}
        >


            <View style={{ height: 45 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SText md semibold white>#title_living</SText>
                    <SText greyTransparent sm medium>#more</SText>
                </View>
            </View>


            {!activeAddress ? (

                <View style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    {isLoading ? (
                        <>
                            <ActivityIndicator color={colors.greyTransparent} />
                            <SText greyTransparent sm regular center style={{ marginTop: 12 }}>#loading_living_data</SText>
                        </>
                    ) : (
                        <SText greyTransparent sm regular center style={{ marginTop: -20 }}>#empty_livingOverview</SText>
                    )}
                </View>

            ) : (

                <View style={{flexDirection: 'row', flex: 1, columnGap: 22}}>
                    { renderOverview() }
                    { showMaps && renderMap() }
                </View>

            )}

        </Pressable>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        addressBlock: {
            flex: 0.6,
            marginBottom: 22,
            justifyContent: 'space-between'
        },
        addressContainer: {
            flex: 0.9,
            width: '100%',
            borderRadius: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: colors.blackLessTransparent,
            padding: 12,
            marginBottom: 4
        },
        itemContainer: {
            flex: 0.6,
            width: '100%',
            justifyContent: 'space-around',
        },
        item: {
            width: '50%'
        },
        itemRow: {
            flexDirection: 'row'
        }
    },
    {

    }
)
