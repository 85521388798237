import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M399.7,418c-6.7,0.3-12.7-2-18.8-4.2c-6.8-2.4-13.4-5.3-20.3-7.3c-16.6-4.9-31.4,3.8-35.1,20.8c-1.9,8.8-2.9,17.7-4.8,26.5
	c-4.1,19.4-18.5,31.2-38.3,31.3c-26.6,0.2-53.2,0.2-79.8,0c-19.7-0.2-33.9-12.2-38-31.7c-1.7-8.1-2.8-16.3-4.4-24.4
	c-3.9-19.5-18.9-28.1-37.9-21.8c-8.2,2.7-16.1,6.2-24.4,8.7c-17.6,5.5-34.9,0.1-44.3-15.5c-14.8-24.2-28.9-48.9-42.4-73.9
	c-8.4-15.6-4.4-33.2,8.8-45.1c6.1-5.6,12.8-10.5,19-16c16.3-14.4,16.2-31.6-0.3-46c-5.4-4.7-11.2-9-16.7-13.7
	C6.5,192.6,2.5,174,12.2,156.3c12.9-23.7,26.4-47.1,40.3-70.2c10.1-16.8,27.1-22.6,45.8-16.7c8.6,2.7,16.8,6.3,25.4,9
	c17.7,5.6,32.6-3.2,36.4-21.5c1.8-8.4,2.8-17,4.6-25.5c4.1-19.3,18.5-31.2,38.3-31.3c26.6-0.2,53.2-0.2,79.8,0
	c19.7,0.2,33.9,12.2,38,31.7c1.7,8.1,2.8,16.3,4.4,24.4C329,75.7,344,84.3,363,78c8.2-2.7,16.1-6.2,24.4-8.7
	c17.6-5.5,34.9-0.1,44.3,15.4c14.8,24.2,28.9,48.9,42.4,73.9c8.4,15.6,4.4,33.2-8.8,45.1c-6.6,6-13.9,11.4-20.5,17.5
	c-14.1,12.9-14,30.1,0.3,43.1c5.9,5.3,12.3,10,18.3,15.1c15.4,13.2,19.4,31.7,9.7,49.5c-12.9,23.7-26.5,47-40.2,70.2
	C425.3,411.7,413.1,418,399.7,418z M435.7,172.7c-1.2-2.3-2.4-5.3-4-8.1c-7-12.2-13.9-24.3-21.1-36.4c-13.7-22.9-8-21.6-33.2-12.2
	c-32.6,12.2-68.1-0.3-84.7-30.1c-5.7-10.3-8.2-21.6-9.2-33c-0.9-10.4-5.7-13.3-15.4-12.9c-12.9,0.6-25.9,0.1-38.8,0.1
	c-24.8,0-24.8,0-29.3,24.1c-6.1,32.5-32,55.1-64.4,56.5c-14.1,0.6-26.9-4.1-39.5-9.4c-6.9-2.9-11.2-1.6-14.9,5.2
	c-6.8,12.7-14.3,25-21.5,37.4C48,174,48,174,65.2,189c30.7,26.7,35.5,65.3,11.9,95.2c-5.6,7.1-12.3,13.2-19.7,18.3
	c-7.6,5.3-9.1,10.6-3.7,18.7c7.2,10.8,13.3,22.2,19.7,33.5c12.7,22.2,12.6,22.2,37.1,13.5c36.2-12.9,76,6.9,87.2,43.5
	c2.2,7.2,3.7,14.7,4.5,22.2c0.8,8.8,5.1,11.5,13.5,11.2c13.3-0.5,26.6-0.1,39.9-0.1c25.2,0,25.2,0,29.9-24.7
	c6.2-32.3,32.5-54.9,64.9-56.1c13.7-0.5,26.2,4,38.5,9.2c7.3,3.1,11.8,1.9,15.7-5.5c6.9-13,14.8-25.5,22-38.4
	c11.6-20.7,14.8-16.1-5.8-33.2c-28.6-23.7-35.2-58.3-17.1-88.9c6.2-10.5,15.7-17.7,24.8-25.2C431.1,179.9,434.8,177.8,435.7,172.7z"
	/>
<Path fill={color} d="M337.6,241.8c0.5,51.4-44,95.9-95.5,95.8c-49.9-0.1-93.4-43.3-94.4-93.6c-1.1-51.2,43-95.8,95-96.3
	C292.8,147.2,337.1,191.1,337.6,241.8z M296.2,242.9c0.1-30.2-22.8-53.7-52.8-53.9c-30.7-0.3-54.4,22.9-54.5,53.3
	c-0.1,30.2,22.9,53.7,52.8,53.9C272.5,296.5,296.1,273.4,296.2,242.9z"/>
</Svg>
                    );
                }