import { registerRootComponent } from 'expo';
import { AppRegistry, LogBox } from 'react-native';
import * as Sentry from 'sentry-expo';
import App from './src';

//ignoring invalid element property transforms for web
// console.log = () => {};
// console.warn = () => {};
// console.error = () => {};

Sentry.init({
    dsn: 'https://a2994438ec5246e28374248917eeb803@sentry.ddat.io/7',
    enableInExpoDevelopment: false,
    debug: false,
    sampleRate: 1
});

AppRegistry.registerComponent(() => App);
registerRootComponent(App);