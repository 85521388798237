import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M451.6,350.8l-17.3-14.9c-28.8-24.6-57.9-39.5-86.3-14.9l-17,14.8c-10.3,9-28,45.3-85.1-1.8l-69.2,60.6
		c60.3,57.8,119.3,86.7,175.6,86c56.8-0.6,91.2-31.1,101.9-42.2l15.4-13.4C498,400.4,480.4,375.4,451.6,350.8z"/>
	<Path fill={color} d="M365,185.7c7.9-7,11.1-17.4,9.2-27.1c-0.9-4.7-3.1-9.3-6.5-13.2l-1.1-1.3c-10.4-11.8-28.5-13-40.3-2.7L183.8,266.1
		c-56.3-77.4-12.2-91.3-1.1-100.9l17.1-14.9c28.3-24.6,17.6-55.6-2.8-87.5l-12.3-19.3c-20.5-31.8-42.8-52.7-71.1-28.1L98.3,28.8
		c-12.5,9.1-47.5,38.8-56,95.1C33.2,184,57.8,252,115.4,326L10.9,417.5c-11.8,10.4-13,28.5-2.7,40.3l1.1,1.3c4.3,4.9,9.9,8,15.8,9.1
		c8.4,1.7,17.6-0.4,24.5-6.5l103.9-90.9l68.2-59.7L365,185.7z"/>
</G>
</Svg>
                    );
                }