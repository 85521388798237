import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M441.237,202.885h-41.13H282.335h-79.451h-64.777H43.97c-21.938,0-39.722,17.784-39.722,39.729
	c0,21.938,17.784,39.722,39.722,39.722h94.137h64.778h79.451v-0.001h158.9c21.944,0,39.729-17.784,39.729-39.722
	C480.965,220.676,463.181,202.885,441.237,202.885z"/>
</Svg>
                    );
                }