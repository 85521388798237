import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 96.875 96.875" enableBackground="new 0 0 96.875 96.875" >
<Path fill={color} fill="#231F20" d="M89.911,13.284c-2.242-2.416-5.488-3.704-9.647-3.825C68.523,9.08,60.22,15.326,55.585,28.02
	c-0.243,0.667-0.095,1.405,0.388,1.929c0.479,0.521,1.199,0.728,1.88,0.541c1.333-0.366,2.655-0.552,3.93-0.552
	c1.711,0,3.987,0.314,5.121,1.812c0.87,1.15,0.988,2.875,0.352,5.126c-0.537,1.898-3.302,6.982-6.725,12.362
	c-4.17,6.554-6.473,9.491-7.137,9.491c-2.2,0-10.149-35.25-10.368-36.024c-2.28-8.079-3.425-12.134-10.84-12.134
	c-5.651,0-18.256,10.319-28.605,19.311c-0.637,0.553-1.173,1.019-1.588,1.373c-1,0.855-1.15,2.341-0.343,3.382l1.929,2.485
	c0.812,1.047,2.295,1.265,3.374,0.497l0.106-0.075c3.349-2.383,6.512-4.632,8.899-4.756c2.595-0.127,4.846,3.927,7.53,13.573
	c5.112,18.737,12.879,41.071,20.85,41.071c8.33,0,18.268-7.062,31.113-23.777c11.626-15.13,17.851-27.13,18.251-35.861l0,0
	C94.002,21.201,92.727,16.319,89.911,13.284z"/>
</Svg>
                    );
                }