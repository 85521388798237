import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M242.4,485.4c9.6,0,18.7-3.7,25.6-10.6l135.5-135.5c6.8-6.8,10.6-15.9,10.6-25.6s-3.7-18.8-10.6-25.7
	c-14.1-14.1-37.1-14.1-51.2,0l-73.7,73.7V35.9c0-19.9-16.3-36.2-36.2-36.2S206.2,16,206.2,35.9v325.7l-73.7-73.7
	c-14.1-14.1-37.1-14.1-51.2,0c-6.8,6.9-10.6,16-10.6,25.7c0,9.6,3.7,18.7,10.6,25.6l135.6,135.6
	C223.7,481.5,232.8,485.4,242.4,485.4z"/>
</Svg>
                    );
                }