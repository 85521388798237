import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M453.2,319.6c-4.4-23.2-17.4-39.4-39.3-48.1c-15.6-6.2-31.2-5.3-46.5,1.4c-1.3,0.6-3.8,0.6-4.5-0.2
		c-5.3-5.8-10.2-11.9-15.6-18.4c4.5-2.1,8.7-4.2,12.9-6c10.3-4.2,21.3-5.3,32.3-5.8c17.4-0.8,29.8-10.7,34.4-27.4
		c2.6-9.4,1.7-18.8,0-28.2c-7.5-41.7-31.6-69.7-70.3-85.7c-16.3-6.7-33.4-7.7-50.7-7.3c-8.1,0.2-13.6,5.1-13.7,12.3
		c0,7.2,5.5,12.4,13.5,12.5c4,0,8-0.1,12,0c3.8,0.2,7.6,0.6,11.4,0.9c0,8.3,0,16,0,23.9c-0.9,0.2-1.5,0.5-2.2,0.6
		c-17,3-31.6,10.3-41.7,24.7c-9.5,13.5-18.7,27.2-27.6,41.1c-3.6,5.5-8.1,8.3-14.5,9.6c-23.9,5-41.6,18.6-53.2,40
		c-0.7,1.2-0.5,3.4,0.2,4.7c2.4,5.1,5.4,9.9,7.6,15c5.1,11.8,7.6,24.2,7.6,37.3c-8.7,0-17,0-25.2,0c-0.6-4.6-0.9-9-1.7-13.3
		c-0.9-4.4-2.1-8.8-3.6-13.1c-10.3-28.3-38.8-47.7-69.3-47.7c-30.6,0-59,19.5-69.2,47.8c-2.2,6.2-3.4,12.7-5,19c0,5,0,9.9,0,14.9
		c1.6,6.1,2.7,12.4,4.8,18.3c10.4,28.8,37.4,47.9,68.2,48.6c29.7,0.7,57.7-17.5,69.2-45.3c1.4-3.3,2.9-4.4,6.5-4.3
		c36.5,0.2,72.9,0.1,109.4,0.1c10.4,0,14.9-4.2,15.3-14.5c0.8-20.8,7.6-39.2,21.5-54.9c0.5-0.6,1.1-1.2,2-2.1
		c5.6,6.7,11,13.1,16.6,19.7c-6.5,8.2-10.9,17.1-13.2,27.1c-7.6,33.4,16.9,68.8,50.8,73.4c35.2,4.8,64.7-16.9,71-52.2
		c0.1-0.8,0.5-1.5,0.7-2.3c0-4.4,0-8.8,0-13.2C453.7,321.7,453.4,320.7,453.2,319.6z M154.8,316.7c-1,0.1-2.1,0.2-3.1,0.2
		c-10.9,0-21.7,0.1-32.6,0c-8.1,0-12.8-4.5-13.6-12.4c-0.7-7.6-6.3-12.8-13.3-12.3c-7.2,0.5-12,6.7-11.3,14.5
		c1.9,20.6,17.1,34.7,37.8,35c9.5,0.1,19,0,28.9,0c-9.8,20.1-36.7,29.9-58.9,21.8c-24.2-8.9-37.4-34.1-31-59.2
		c6-23.6,30.4-40.1,53.8-36.5C137,271.8,156,293,154.8,316.7z M417,356.8c-15.1,13.1-35.3,12.9-50.2-0.3
		c-12.7-11.3-15.6-31.9-6-46.3c7.3,9.1,14.3,17.9,21.4,26.7c3.3,4.1,7.6,5.8,12.8,4.5c5-1.3,8-4.6,9.1-9.6c1-4.2-0.6-7.8-3.2-11.1
		c-5.9-7.3-11.7-14.6-17.5-21.9c-1-1.3-2-2.5-3-3.9c13.5-6.6,33.1-0.1,42.5,13.8C433,323.8,430.4,345.2,417,356.8z"/>
	<Path fill={color} d="M76.9,215.3c9.4,2.6,19.6,2.4,29.4,2.8c19.1,0.7,37,4.8,53.1,15.5c4,2.6,7.7,5.6,11.8,8.6c9.7-15.5,22.7-27.2,38.5-35.9
		c-9.5-4.4-18.8-8-27.3-12.9c-27.3-15.7-54.2-32-81.3-47.9c-1.9-1.1-4.3-1.8-6.5-1.8c-15.5-0.2-31.1,0.2-46.6-0.2
		c-7.7-0.2-13.3,2.1-16.6,9.2c0,3.3,0,6.6,0,9.9c0.3,1.2,0.6,2.3,0.9,3.5C37.3,191.4,51.9,208.5,76.9,215.3z"/>
	<Path fill={color} d="M232,186.8c9.6,2.4,9.4,2.3,15.1-5.7c8.3-11.9,16.6-23.9,25.6-35.3c4.1-5.2,10-8.9,15.2-13.4c-44.4-28.7-97.4-7.5-117,25.6
		c6,3.6,11.9,7.2,18,10.8C202.4,176.7,216.7,182.9,232,186.8z"/>
</G>
</Svg>
                    );
                }