import * as scale from 'd3-scale';
import { QueryKeys } from "enums";
import { useStyle } from "hooks";
import { Policy } from "models";
import { useEffect, useRef, useState } from "react";
import { Platform, StyleProp, View } from "react-native";
import { PieChart } from 'react-native-svg-charts';
import { useQuery } from "react-query";
import { colors } from 'styles';
import { QueryUtils, ValutaUtils } from "utils";
import SText from "./SText";

interface pieProps {
    style?: StyleProp<any>
    dark?: boolean
    opklik?: boolean
}

export default ({ style, dark, opklik }: pieProps) => {

    const styles = useStyle();
    const { data: policies, isLoading } = useQuery(QueryKeys.POLICY, QueryUtils.fetchPolicy);
    const [data, setData] = useState({ data: [], totaal: 0 });
    const [selectedPart, setSelectedPart] = useState(null);

    //prepare pie data
    useEffect(() => {
        if (!isLoading) {

            let colorInd = 0;
            const rColors = [
                scale.scaleLinear()
                    .domain([0, 100])
                    .range([colors.secondary, colors.white]),
                scale.scaleLinear()
                    .domain([0, 100])
                    .range([colors.primary, colors.black]),
                scale.scaleLinear()
                    .domain([0, 100])
                    .range([colors.grey, colors.black]),
                scale.scaleLinear()
                    .domain([0, 100])
                    .range([colors.secondary, 'rgb(100, 100, 100)']),
            ];
            const getRandomColor = () => {
                const rColor = rColors[colorInd](Math.floor(Math.random() * 50));
                if (++colorInd >= rColors.length) colorInd = 0;
                return rColor;
            }

            const data = policies.map((policy: Policy) => {
                if (!policy.is_package && policy.mutation_code === 0) { //only count active policies with mutation_code 0!!
                    return {
                        color: getRandomColor(),
                        value: policy.monthly_term_amount,
                        title: policy.descriptions[0] ? policy.descriptions[0].split(' ')[0] : '#unknown'
                    }
                }
            }).filter((val) => val);

            const totaal = data.reduce((acc, val) => acc + val.value, 0);

            setData({ data, totaal })

        }
    }, [isLoading])

    //compile pie data
    const money = (selectedPart === null) ? ValutaUtils.valutaString(data.totaal).split(',') : ValutaUtils.valutaString(data.data[selectedPart].value).split(',');
    const title = (selectedPart === null) ? '#total' : data.data[selectedPart].title;
    const euros = money[0];
    const cents = money[1];
    const pieData = data.data.map((pd, index) => ({
        key: `pie-${index}`,
        value: pd.value,
        svg: {
            fill: pd.color,
            onPress: () => { (opklik) && setSelectedPart(index === selectedPart ? null : index) },
        },
        arc: {
            innerRadius: selectedPart === index ? '75%' : '80%',
            outerRadius: selectedPart === index ? '99%' : '95%'
        }
    }))

    //add hover events to pie
    const hoverElt = useRef(null);
    if (opklik && hoverElt.current && Platform.OS === 'web') {
        setTimeout(() => {
            if (!hoverElt.current) return;
            const parts = hoverElt.current.querySelectorAll('path');
            parts.forEach((part, index) => {
                part.addEventListener('mouseenter', () => setSelectedPart(index))
                part.addEventListener('mouseleave', () => setSelectedPart(null))
            })
        }, 200)
    }

    return (
        <View ref={hoverElt} style={[styles('fullscreen'), style]}>

            <View style={{ position: 'absolute', height: '100%', width: '100%', justifyContent: 'center', zIndex: opklik ? 0 : 99 }}>

                <SText sm medium center style={{ color: colors.grey, letterSpacing: 2 }}>{title}</SText>

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <SText white xl semibold style={[
                        dark && styles('text-black')
                    ]}>
                        {`${euros},`}
                    </SText>
                    <SText white md semibold style={[
                        { marginTop: 6 },
                        dark && styles('text-black')
                    ]}>
                        {cents}
                    </SText>
                </View>
                <SText white md regular center style={[
                    dark && styles('text-black')
                ]}>
                    #per_month
                </SText>
            </View>

            <PieChart
                style={{ width: '100%', height: '100%' }}
                data={pieData}
                innerRadius={'85%'}
                outerRadius={'95%'}
                padAngle={0}
            />

        </View>
    )

}