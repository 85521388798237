import { StyleSheet, TextStyle, View } from "react-native"
import { colors } from "styles";
import SText from "./SText";

interface props {
    children: React.ReactNode
    style?: TextStyle,
    title?: string
}

export default ({ children, style, title }: props) => {

    const providedStyle = (Array.isArray(style)) ? [scopedStyles.container, ...style] : [scopedStyles.container, style];

    return (
        <View style={providedStyle}>
            <SText sm semibold primary>{title}</SText>
            {children}
        </View>
    )
}

const scopedStyles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: colors.white,
        borderRadius: 4,
        padding: 20
    }
})
