import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

export default {
    sendError: (msg:string) => {

        const sendMethod = Platform.select({
            web: ()=>{
                Sentry.Browser.captureException(new Error(msg));
            },
            default: ()=>{
                Sentry.Native.captureException(new Error(msg));
            }
        })
        sendMethod();
    },
    breadCrumb: async (data: any) => {
        Sentry.Browser.addBreadcrumb({
            level: 'info',
            data: data
        });
    }
}