import {ActivityIndicator, Pressable, ScrollView, View} from "react-native"
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles"
import { colors } from "styles"
import { Button, SText } from 'components';
import BaseModal from './BaseModal';
import Colors from "styles/colors";
import { useTranslation } from "hooks";

export default ({ hideModal, data, handleSelectValue, initialValue, buttonText, loading, title }) => {

    const { styles: scopedStyles } = scopedResponsiveStyles();
    const translate = useTranslation()

    let titleTransform: any = translate(title).split('');
    titleTransform[0] = titleTransform[0].toUpperCase();
    titleTransform = titleTransform.join('');

    return (
        <BaseModal style={scopedStyles('modal')} hideModal={hideModal}>

            <View style={{margin: 20}}>
                <SText lg regular primary style={{marginLeft: 12, marginVertical: 12}}>{titleTransform}</SText>

                <ScrollView style={ scopedStyles('dataContainer') }>
                    {loading ? <View><ActivityIndicator color={colors.primary} size={40} /></View> : null}
                    {data.map((item) =>
                        <Pressable
                            key={`select_model_${item.getValue()}`}
                            onPress={() => handleSelectValue(item.getValue())}
                            style={[scopedStyles('dataItem'), {backgroundColor: initialValue === item.getValue() ? Colors.action : Colors.grey}]}
                        >
                            <SText
                                style={scopedStyles('dataItemText')}
                            >{item.getLabel()}</SText>
                        </Pressable>
                    )}
                </ScrollView>
            </View>            

            <View style={{height: 100, flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingRight: 20}}>
                <View style={{ flexDirection: "row" }}>
                    <Button
                        label={buttonText}
                        type="action"
                        style={scopedStyles('button')}
                        onPress={hideModal}
                    />
                </View>
            </View>
            
        </BaseModal>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '40%',
            height: 'auto',
            minHeight: 'auto',
            marginTop: 180
        },
        dataContainer: {
            flex: 1,
            padding: 12,
        },
        dataItem: {
            padding: 12,
            marginBottom: 12,
            borderRadius: 8,
        },
        dataItemText: {
          fontWeight: 'bold',
          color: Colors.white
        },
        button: {}
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                width: '90%',
                marginHorizontal: 12,
                marginVertical: 30,
                paddingLeft: 0,                
            },
            button: {
                width: '60%',
                marginLeft: '40%'
            },
        }
    }
)
