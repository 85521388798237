import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
    <G transform="rotate(-180 241.65 183.6)">   
        <Path fill={color} d="m392.6,202l-40.2,-40.2c-5.6,-5.6 -14.5,-5.6 -20.1,0l-48,48l0,-195.6c0,-7.9 -6.4,-14.2 -14.2,-14.2l-56.9,0c-7.8,0 -14.2,6.4 -14.2,14.2l0,195.6l-48,-48c-5.6,-5.6 -14.5,-5.6 -20.1,0l-40.2,40.2c-5.6,5.6 -5.6,14.5 0,20.1l140.9,140.9c5.6,5.6 14.5,5.6 20.1,0l140.9,-140.9c5.6,-5.6 5.6,-14.6 0,-20.1z"/>
    </G>  
    <Path fill={color} d="m471,314.6l-56.9,0c-7.9,0 -14.2,6.4 -14.2,14.2l0,71.1l-314.6,0l0,-71.1c0,-7.9 -6.4,-14.2 -14.2,-14.2l-56.9,0c-7.8,0 -14.2,6.4 -14.2,14.2l0,113.7c0,23.5 19.1,42.6 42.6,42.6l399.9,0c23.5,0 42.6,-19.1 42.6,-42.6l0,-113.7c0.1,-7.8 -6.2,-14.2 -14.1,-14.2z"/>   
</Svg>
                    );
                }