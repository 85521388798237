import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M242.596,21.555c-122.066,0-221.042,98.974-221.042,221.039c0,122.071,98.976,221.063,221.042,221.063
		c122.069,0,221.062-98.992,221.062-221.063C463.658,120.529,364.665,21.555,242.596,21.555z M288.618,364.14
		c-11.38,4.492-20.438,7.896-27.233,10.254c-6.772,2.363-14.653,3.541-23.617,3.541c-13.771,0-24.494-3.373-32.127-10.086
		c-7.636-6.725-11.436-15.238-11.436-25.586c0-4.021,0.275-8.137,0.84-12.33c0.578-4.189,1.494-8.908,2.753-14.209l14.236-50.301
		c1.256-4.822,2.344-9.404,3.204-13.676c0.86-4.307,1.273-8.256,1.273-11.85c0-6.396-1.329-10.883-3.968-13.414
		c-2.678-2.524-7.709-3.763-15.218-3.763c-3.664,0-7.442,0.545-11.317,1.681c-3.841,1.186-7.168,2.251-9.904,3.297l3.765-15.494
		c9.319-3.794,18.246-7.048,26.759-9.745c8.514-2.734,16.566-4.082,24.141-4.082c13.679,0,24.236,3.333,31.662,9.914
		c7.396,6.611,11.115,15.202,11.115,25.754c0,2.19-0.262,6.043-0.765,11.546c-0.506,5.516-1.459,10.553-2.846,15.178l-14.164,50.15
		c-1.159,4.021-2.188,8.621-3.128,13.779c-0.915,5.145-1.362,9.074-1.362,11.707c0,6.67,1.477,11.211,4.47,13.627
		c2.96,2.412,8.142,3.625,15.476,3.625c3.465,0,7.336-0.615,11.714-1.812c4.343-1.195,7.487-2.26,9.47-3.182L288.618,364.14z
		 M286.106,160.581c-6.606,6.132-14.556,9.202-23.86,9.202c-9.284,0-17.292-3.07-23.951-9.202
		c-6.628-6.145-9.979-13.61-9.979-22.332c0-8.699,3.373-16.188,9.979-22.381c6.659-6.215,14.667-9.302,23.951-9.302
		c9.305,0,17.271,3.087,23.86,9.302c6.607,6.192,9.918,13.682,9.918,22.381C296.024,146.983,292.713,154.436,286.106,160.581z"/>
</G>
</Svg>
                    );
                }