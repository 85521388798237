import { Icons } from 'assets/icons';
import { DrawerToggle, SText } from 'components';
import { QueryKeys } from 'enums';
import { useHover, useStyle } from 'hooks';
import { useEffect, useState } from 'react';
import { Dimensions, Image, Platform, Pressable, View, useWindowDimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import colors from 'styles/colors';
import { QueryUtils, SentryUtils } from 'utils';

const header_height = 80;

const Header: React.FC<{ navigation: any, route: any }> = ({ navigation, route }) => {

    const { data: tenant, isLoading } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const { styles: scopedStyles } = scopedResponsiveStyle();
    const styles = useStyle();


    const windowSize = useWindowDimensions();
    const largeScreen = windowSize.width >= 1200;

    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapse = () => setCollapsed(c => !c);

    const navItemsHoverBank: any[] = [];
    for (let i = 0; i < 7; i++) navItemsHoverBank.push(useHover());

    const [headerItems, setHeaderItems] = useState<{ route: string; title: string; }[]>([]);
    useEffect(() => {
        if (!isLoading) {
            const items = [];
            items.push({ route: 'overzicht', title: '#menu_overview' });
            items.push({ route: 'wonen', title: '#menu_living' });
            if (tenant?.show_mortgages !== false) items.push({ route: 'hypotheken', title: '#menu_mortgage' });
            if (tenant?.show_policies !== false) items.push({ route: 'verzekeringen', title: '#menu_policy' });
            if (tenant?.show_income !== false) items.push({ route: 'inkomen', title: '#menu_income' });
            if (tenant?.show_obligations !== false) items.push({ route: 'verplichtingen', title: '#menu_obligation' });
            items.push({ route: 'contact', title: '#menu_contact' });
            setHeaderItems(items);
        }
    }, [tenant])


    const navItems = () => {

        return (

            headerItems.map((item, index) => {
                return navItem(item, index);
            })

        );

    };

    const navCollapse = () => {

        const collapseHeight = headerItems.length * 50 + 25;

        return !collapsed && (

            <View
                style={scopedStyles('navCollapse')}
            >

                <Pressable
                    onPress={toggleCollapse}
                    style={{ width: '100%', height: '100%' }}
                >
                    <Animatable.View
                        style={[scopedStyles('navCollapseOpen'), { height: collapseHeight }]}
                        animation={{
                            0: { top: -collapseHeight },
                            1: { top: 0 }
                        }}
                        duration={100}
                    >
                        {/* this is so that the pressable above doesn't get triggered */}
                        <Pressable onPress={() => { }}>
                            {headerItems.map((item, index) => {
                                return navItem(item, index);
                            })}
                        </Pressable>

                        {/* end bar decoration */}
                        <View style={{
                            width: '50%',
                            marginLeft: '25%',
                            marginTop: 12,
                            height: 3,
                            borderRadius: 1.5,
                            backgroundColor: colors.grey
                        }}></View>

                    </Animatable.View>
                </Pressable>

            </View>

        );
    }

    const navItem = (item, index) => {
        const [hovered, hoverElt] = navItemsHoverBank[index];
        return (
            <Pressable
                key={index}
                disabled={route.name === item.route}
                onPress={() => {
                    (!largeScreen) && toggleCollapse();
                    navigation.navigate(item.route);
                    SentryUtils.breadCrumb({
                        route: 'open-route',
                        data: item
                    });
                }}
                style={[
                    scopedStyles('navItemWrapper'),
                    (!largeScreen) && scopedStyles('navItemWrapperCollapse')
                ]}
            >
                <View
                    ref={hoverElt}
                    style={[
                        scopedStyles('navItem'),
                        (route.name !== item.route && largeScreen && hovered) && scopedStyles('navItemHover'),
                        (route.name === item.route) && scopedStyles('navItemActive'),
                        (route.name === item.route) && { borderBottomColor: colors.primary },
                    ]}
                >
                    <SText regular sm black key={index} style={[
                        scopedStyles('navItemText'),
                        (route.name === item.route) && styles('text-bold'),
                        (route.name === item.route) && { color: colors.primary },
                        { fontSize: 15 },
                        Platform.OS == 'web' && { userSelect: 'none' }
                    ]}>
                        {item.title}
                    </SText>
                </View>
            </Pressable>
        )
    }

    return (
        <>
            {!largeScreen && navCollapse()}

            <View style={scopedStyles('header')}>

                {!largeScreen &&
                    <Pressable
                        style={scopedStyles('navToggle')}
                        onPress={toggleCollapse}
                    >
                        <Icons.Menu
                            size={16}
                            color={colors.primary}
                        />
                    </Pressable>
                }

                <Pressable
                    style={[scopedStyles('logo'), styles('bigMarginLeft'), { zIndex: 2 }]}
                    onPress={() => largeScreen ? navigation.navigate('overzicht') : toggleCollapse()}
                >
                    <Image
                        source={tenant?.logo ? { uri: 'data:image/png;base64,' + tenant?.logo } : {}}
                        resizeMode='contain'
                        style={{ width: '100%', flex: 1 }}
                    />
                </Pressable>

                {largeScreen &&
                    <View style={scopedStyles('navContainer')}>
                        {navItems()}
                    </View>
                }

                <DrawerToggle
                    type={'open'}
                    style={[scopedStyles('drawerToggle'), styles('bigMarginRight')]}
                    onPress={() => {
                        navigation.toggleDrawer();
                        SentryUtils.breadCrumb({
                            route: 'drawer',
                            data: 'opened drawer'
                        });
                    }}
                />
            </View>
        </>

    );
};

const scopedResponsiveStyle = CreateResponsiveStyle(
    {
        header: {
            height: header_height,
            width: '100%',
            backgroundColor: colors.white,
            zIndex: 999
        },
        navToggle: {
            position: 'absolute',
            left: 12,
            top: 0,
            height: header_height,
            justifyContent: 'center'
        },
        navContainer: {
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'center'
        },
        navCollapse: {
            position: 'absolute',
            overflow: 'hidden',
            top: 78,
            left: 0,
            width: '100%',
            height: Dimensions.get('window').height - header_height,
        },
        navCollapseOpen: {
            width: '100%',
            overflow: 'hidden',
            top: Platform.OS == 'ios' ? initialWindowMetrics?.insets.top : 0,
            backgroundColor: 'white',
            borderBottomRightRadius: 12,
            borderBottomLeftRadius: 12
        },
        navItemWrapper: {
            height: '100%',
            justifyContent: 'center'
        },
        navItemWrapperCollapse: {
            height: 50
        },
        navItem: {
            height: '100%',
            alignSelf: 'center',
            marginHorizontal: 12,
            justifyContent: 'center',
            alignContent: 'center'
        },
        navItemActive: {
            borderBottomWidth: 1,
            borderBottomColor: colors.primary,
            paddingTop: 1
        },
        navItemHover: {
            height: '30%',
            paddingBottom: 8,
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            marginBottom: -9
        },
        navItemText: {
            textAlign: 'center',
            verticalAlign: 'middle',
            alignSelf: 'center',
            color: colors.headerNavText
        },
        drawerToggle: {
            position: 'absolute',
            right: 0,
            top: 0,
            height: header_height
        },
        logo: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: 160,
            height: header_height
        },
        logoCenter: {
            width: 160,
            height: header_height
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            drawerToggle: {
                right: 4,
                height: 40,
                marginTop: 20
            },
            logo: {
                left: 20,
                top: 10,
                width: 100,
                height: 60,
            },
            navCollapse: {
                // left: 13,
                alignItems: 'flex-start'
            },
            navItemText: {
                fontSize: 14
            },
            navItem: {
                height: '40%'
            }
        }
    }
)

export default Header;
