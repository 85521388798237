import { Icons } from "assets/icons";
import { Button, IconTitle, MortgageProgress } from "components";
import SText from "components/SText";
import { Mortgage } from "models";
import { MortgagePart } from "models/MortgagePart";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { Dimensions, View } from "react-native"
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { ValutaUtils } from "utils";
import BaseModal from "./BaseModal";
import MortgageUploadModal from "./MortgageUploadModal";
import { DateTimeFormat } from 'enums'



export default ({ settings }) => {

    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { hideModal, showModal } = useContext(ModalContext);
    const mortgage: Mortgage = settings.mortgage;
    const largeScreen = Dimensions.get("window").width > 768;


    const renderTitle = () => {
        return (
            <View style={scopedStyles('titleContainer')}>

                <View>
                    <SText md primary semibold>{mortgage.agency_name}</SText>
                    <SText md grey regular>{mortgage.hypotheeknummer}</SText>
                </View>

            </View>
        );
    }

    const renderProgress = () => {
        return (mortgage.status !== 0) && (
            <View style={scopedStyles('progressContainer')}>
                <SText md semibold primary center style={{ marginBottom: 4 }}>#mortgage_requested</SText>

                <MortgageProgress mortgage={mortgage} />

                {mortgage.openProgress > 0 &&
                    <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        <Button
                            type="action"
                            label="#action_upload ➜"
                            onPress={() => {
                                hideModal();
                                setTimeout(() => {
                                    showModal(MortgageUploadModal, { mortgage }, 'MortgageUploadModal');
                                }, 1);
                            }}
                        />
                    </View>
                }

            </View>
        );
    }

    const renderProperties = () => {
        return (
            <>
                <SText md semibold primary style={{ marginVertical: 12 }}>{mortgage.properties.length > 1 ? 'Onderpanden' : 'Onderpand'}</SText>

                {mortgage.properties.map(property => {
                    return (
                        <IconTitle
                            keepLayout
                            Icon={Icons.Home}
                            title={''}
                            style={{marginVertical: 8, marginLeft: 8}}
                            iconSize={22}
                        >                    
                            <SText md primary semibold style={{marginRight: 8}}>{property.street ? `${property.street} ${property.number}${property.number_addition}` : ''}</SText>
                            <SText sm grey regular>{property.street ? `${property.postal_code}, ${property.city}` : ' '}</SText>                    
                        </IconTitle>
                    );
                })}
            </>
        );
    }

    const renderDetails = () => {
        return (
            <>
                <SText md semibold primary style={{ marginVertical: 12, marginTop: 35 }}>#data</SText>

                <View style={scopedStyles('category')}>
                    <SText sm medium black style={{ width: '60%' }}>#mortgage_inschrijving</SText>
                    <View style={{ flex: 1 }}>
                        <SText sm grey medium right>{ValutaUtils.valutaString(mortgage.hypotheciareInschrijving)}</SText>
                    </View>
                </View>

                <View style={scopedStyles('category')}>
                    <SText sm medium black style={{ width: '60%' }}>#mortgage_original_hoofdsom</SText>
                    <View style={{ flex: 1 }}>
                        <SText sm grey medium right>{ValutaUtils.valutaString(mortgage.totaalSom)}</SText>
                    </View>
                </View>

                <View style={scopedStyles('category')}>
                    <SText sm medium black style={{ width: '60%' }}>#mortgage_rest_hoofdsom</SText>
                    <View style={{ flex: 1 }}>
                        <SText sm grey medium right>{ValutaUtils.valutaString(mortgage.restantHoofdsom)}</SText>
                    </View>
                </View>

                <View style={scopedStyles('category')}>
                    <SText sm medium black style={{ width: '60%' }}>#mortgage_verhogen_zonder_notaris</SText>
                    <View style={{ flex: 1 }}>
                        <SText sm grey medium right>{ValutaUtils.valutaString(mortgage.total_withdrawal_space_amount)}</SText>
                    </View>
                </View>
            </>
        );
    }
    
    const renderCosts = () => {
        return (
            <>
                <SText md semibold primary style={{ marginBottom: 12, marginTop: 35 }}>#cost</SText>

                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 40 }}>

                    <View style={[scopedStyles('tmortgage2'), { borderTopLeftRadius: 4 }]}></View>
                    <View style={scopedStyles('tmortgage')}><SText sm semibold black>#per_month</SText></View>
                    <View style={[scopedStyles('tmortgage'), { borderTopRightRadius: 4 }]}><SText sm semibold black>#per_year</SText></View>


                    <View style={scopedStyles('nmortgage2')}><SText sm regular black>#interest</SText></View>
                    <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(mortgage.monthly_interest_amount)}</SText></View>
                    <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(mortgage.monthly_interest_amount * 12)}</SText></View>

                    <View style={scopedStyles('nmortgage2')}><SText sm regular black>#redeemed</SText></View>
                    <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(mortgage.monthly_repayment_amount)}</SText></View>
                    <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(mortgage.monthly_repayment_amount * 12)}</SText></View>

                    <View style={scopedStyles('nmortgage2')}><SText sm regular black>#premium</SText></View>
                    <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(mortgage.monthly_premium)}</SText></View>
                    <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(mortgage.monthly_premium * 12)}</SText></View>

                    <View style={scopedStyles('nmortgage2')}><SText sm medium black>#total_small</SText></View>
                    <View style={scopedStyles('nmortgage')}><SText sm medium black>{ValutaUtils.valutaString(mortgage.monthly_amount)}</SText></View>
                    <View style={scopedStyles('nmortgage')}><SText sm medium black>{ValutaUtils.valutaString(mortgage.monthly_amount * 12)}</SText></View>

                </View>
            </>
        );
    }

    const renderMortgageParts = () => {
        return mortgage?.mortgageParts?.length > 0 && (
            <>
                <SText md semibold primary style={{ marginBottom: 12 }}>#mortgage_parts</SText>
                {mortgage.mortgageParts.map((part, index) => renderMortgagePart(part, index))}
            </>
        );
    }

    const renderMortgagePart = (part: MortgagePart, index: number) => {
        return (
            <View key={part.id} style={{ width: '100%', marginBottom: 40 }}>

                <View style={[scopedStyles('tmortgage2'), { width: '100%', height: 36, borderTopLeftRadius: 4, borderTopRightRadius: 4 }]}>
                    <SText md medium black>#part {index + 1}</SText>
                </View>
                <View style={{ backgroundColor: colors.white }}>

                    <SText sm semibold primary style={{ marginLeft: 12, marginVertical: 12 }}>#data</SText>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#mortgage_vorm</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{part.repayment_type_description}</SText>
                        </View>
                    </View>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#obligation_hoofdsom</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{ValutaUtils.valutaString(part.hoofdsom)}</SText>
                        </View>
                    </View>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#rest_debt</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{ValutaUtils.valutaString(part.remaining_debt)}</SText>
                        </View>
                    </View>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#start_date</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{moment(part.begindatum).format(DateTimeFormat.display_date)}</SText>
                        </View>
                    </View>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#end_date</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{moment(part.einddatum).format(DateTimeFormat.display_date)}</SText>
                        </View>
                    </View>


                    <SText sm semibold primary style={{ marginLeft: 12, marginVertical: 12 }}>#interest</SText>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#mortgage_interest_type</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{part.interest_type_description}</SText>
                        </View>
                    </View>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#mortgage_interest_percentage</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{part.rente} %</SText>
                        </View>
                    </View>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#start_date #mortgage_fixed_interest</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{moment(part.begindatumRentevast).format(DateTimeFormat.display_date)}</SText>
                        </View>
                    </View>

                    <View style={scopedStyles('category2')}>
                        <SText sm medium black style={{ width: '50%' }}>#end_date #mortgage_fixed_interest</SText>
                        <View style={{ flex: 1 }}>
                            <SText sm grey medium>{moment(part.einddatumRentevast).format(DateTimeFormat.display_date)}</SText>
                        </View>
                    </View>


                    <SText sm semibold primary style={{ marginLeft: 12, marginVertical: 12 }}>#cost</SText>

                    <View style={{ marginHorizontal: 8, flexDirection: 'row', flexWrap: 'wrap' }}>

                        <View style={[scopedStyles('tmortgage2'), { borderTopLeftRadius: 4 }]}></View>
                        <View style={scopedStyles('tmortgage')}><SText sm semibold black>#per_month</SText></View>
                        <View style={[scopedStyles('tmortgage'), { borderTopRightRadius: 4 }]}><SText sm semibold black>#per_year</SText></View>

                        <View style={scopedStyles('nmortgage2')}><SText sm regular black>#interest</SText></View>
                        <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(part.monthly_interest_amount)}</SText></View>
                        <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(part.monthly_interest_amount * 12)}</SText></View>

                        <View style={scopedStyles('nmortgage2')}><SText sm regular black>#redeemed</SText></View>
                        <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(part.monthly_repayment_amount)}</SText></View>
                        <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(part.monthly_repayment_amount * 12)}</SText></View>

                        <View style={scopedStyles('nmortgage2')}><SText sm regular black>#premium</SText></View>
                        <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(part.monthly_premium)}</SText></View>
                        <View style={scopedStyles('nmortgage')}><SText sm regular black>{ValutaUtils.valutaString(part.monthly_premium * 12)}</SText></View>

                        <View style={scopedStyles('nmortgage2')}><SText sm medium black>#total_small</SText></View>
                        <View style={scopedStyles('nmortgage')}><SText sm medium black>{ValutaUtils.valutaString(part.monthly_amount)}</SText></View>
                        <View style={scopedStyles('nmortgage')}><SText sm medium black>{ValutaUtils.valutaString(part.monthly_amount * 12)}</SText></View>

                    </View>

                </View>
            </View>
        )
    }

    return (
        <BaseModal style={scopedStyles('modal')}>
            <View style={scopedStyles('container')}>

                {renderTitle()}

                {renderProgress()}

                {renderProperties()}

                {renderDetails()}

                {renderCosts()}

                {renderMortgageParts()}
                
                <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label='#action_close'
                            type={"action"}
                            onPress={() => hideModal()}
                        />
                    </View>
                </View>

            </View >
        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '50%',
            height: 'auto',
            minHeight: 0,
            minWidth: 485,
            marginTop: 120,
        },
        container: {
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        titleContainer: {
            marginVertical: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: "center"
        },
        progressContainer: {
            width: '60%',
            minWidth: 400,
            alignSelf: "center",
            marginBottom: 12
        },
        agencyContainer: {
            marginRight: 20
        },
        category: {
            flexDirection: 'row',
            marginBottom: 12
        },
        category2: {
            marginLeft: 12,
            flexDirection: 'row',
            marginBottom: 12
        },
        tmortgage: {
            width: '25%',
            paddingVertical: 8,
            paddingLeft: 8,
            backgroundColor: colors.formDecoration
        },
        nmortgage: {
            width: '25%',
            paddingVertical: 8,
            paddingLeft: 8,
            backgroundColor: colors.white
        },
        tmortgage2: {
            width: '50%',
            paddingVertical: 8,
            paddingLeft: 8,
            backgroundColor: colors.formDecoration
        },
        nmortgage2: {
            width: '50%',
            paddingVertical: 8,
            paddingLeft: 8,
            backgroundColor: colors.white
        },
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            titleContainer: {
                flexDirection: 'column',
                alignItems: "flex-start"
            },
            progressContainer: {
                width: '100%',
                marginLeft: 0
            },
            agencyContainer: {
                marginLeft: 80
            },
            tmortgage: {
                width: '35%'
            },
            nmortgage: {
                width: '35%'
            },
            tmortgage2: {
                width: '30%'
            },
            nmortgage2: {
                width: '30%'
            }
        }
    }
)