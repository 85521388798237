import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 96.875 96.875" enableBackground="new 0 0 96.875 96.875" >
<G>
	<Path fill={color} d="M69.764,0.793H27.066c-14.5,0-26.295,11.797-26.295,26.297v42.695c0,14.5,11.796,26.297,26.295,26.297h42.697
		c14.5,0,26.297-11.797,26.297-26.297V27.09C96.061,12.59,84.264,0.793,69.764,0.793z M87.607,69.785
		c0,9.839-8.006,17.843-17.844,17.843H27.066C17.229,87.63,9.226,79.624,9.226,69.785V27.09c0-9.837,8.003-17.842,17.841-17.842
		h42.697c9.837,0,17.842,8.005,17.842,17.842v42.695H87.607z"/>
	<Path fill={color} d="M48.415,23.885c-13.539,0-24.553,11.016-24.553,24.554c0,13.54,11.014,24.552,24.553,24.552
		c13.539,0,24.555-11.013,24.555-24.552C72.97,34.9,61.954,23.885,48.415,23.885z M48.415,64.536
		c-8.876,0-16.099-7.223-16.099-16.098c0-8.877,7.221-16.099,16.099-16.099c8.877,0,16.1,7.221,16.1,16.099
		C64.515,57.313,57.292,64.536,48.415,64.536z"/>
	<Path fill={color} d="M73.999,16.716c-1.629,0-3.229,0.659-4.379,1.815c-1.156,1.15-1.821,2.751-1.821,4.385c0,1.629,0.666,3.23,1.821,4.384
		c1.149,1.15,2.75,1.816,4.379,1.816c1.635,0,3.229-0.666,4.385-1.816c1.156-1.154,1.815-2.756,1.815-4.384
		c0-1.634-0.659-3.235-1.815-4.385C77.233,17.375,75.634,16.716,73.999,16.716z"/>
</G>
</Svg>
                    );
                }