import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="arrow_up">
	<Path fill={color} d="M266.566,113.918l208.58,208.584c13.274,13.269,13.274,34.781,0,48.043c-13.262,13.264-34.769,13.264-48.038,0
		L242.55,185.985L57.994,370.552c-13.271,13.265-34.779,13.265-48.042,0c-13.269-13.263-13.269-34.775,0-48.044l208.583-208.584
		c6.628-6.626,15.32-9.945,24.014-9.945C251.242,103.979,259.935,107.292,266.566,113.918z"/>
</G>
</Svg>
                    );
                }