import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M435.8,257.6c0,15.2,0,29.7,0,44.8c0.1,0,0.1,0,0.2,0v-44.8C435.9,257.6,435.9,257.6,435.8,257.6z"/>
	<Path fill={color} d="M164.1,166.8c52.4,0.1,104.7,0.1,157.1-0.1c4.1,0,8.6-1.3,12.2-3.3c8.7-4.6,12.7-14.9,10.3-24.5
		c-2.6-10-10.7-16.8-21.1-16.9c-26.6-0.2-53.1-0.1-79.7-0.1c0,0,0,0,0,0c-26.2,0-52.4,0-78.6,0c-13.8,0-23.8,9.4-23.9,22.2
		C140.4,156.8,150.5,166.7,164.1,166.8z"/>
	<Path fill={color} d="M163.9,256.4c52.4,0.2,104.7,0.2,157.1-0.2c5.2,0,11.1-2.4,15.4-5.4c7.7-5.3,9.9-15.4,6.9-24.2c-3-8.9-11.1-14.9-21-14.9
		c-26.6-0.1-53.1,0-79.7,0c0,0,0,0,0,0c-25.6,0-51.2,0-76.9,0c-3.1,0-6.4,0.1-9.4,1c-10.3,3-17,13.4-15.6,23.7
		C142.3,248.4,151.1,256.4,163.9,256.4z"/>
	<Path fill={color} d="M253.6,321.2c-1-10.4-9.6-19.4-20.4-19.6c-24.1-0.4-48.2-0.2-72.3,0c-2.5,0-5.2,0.9-7.5,1.9c-9,4.1-14.1,13.9-12.6,23.4
		c1.8,10.5,9.5,18.3,19.9,18.8c11.9,0.5,23.9,0.1,35.9,0.1c0,0.1,0,0.2,0,0.2c10.3,0,20.6,0.1,30.8,0c3.5,0,7.2-0.1,10.5-1.1
		C248.3,342,254.6,332.1,253.6,321.2z"/>
	<Path fill={color} d="M435.8,392c0,0,0.1,0,0.1,0v-44.8c0,0-0.1,0-0.1,0C435.8,362.4,435.8,376.9,435.8,392z"/>
	<Path fill={color} d="M434.8,81c-0.2-1.4-0.4-2.8-0.6-4.2c-0.2-1.1-0.4-2.2-0.6-3.3c-0.1-0.3-0.1-0.6-0.1-0.8c-0.1-0.3-0.1-0.5-0.2-0.8
		c-0.2-1.2-0.5-2.4-0.8-3.5c-0.1-0.5-0.2-0.9-0.4-1.4c-6.4-24-21.8-43.6-43.4-55.2c-12.5-6.9-26.8-10.8-41.9-10.8
		c-4.2,0-8.3,0-12.5,0c-9.9,0-19.8-0.1-29.7-0.1c-18.9,0-37.9-0.1-56.8-0.1c-15.2,0-30.4,0-45.6,0c-21.1,0-42.1,0.1-63.2,0.1
		C112.4,1,88.6,12.6,72.2,31.2C61.4,43.5,53.9,58.9,51.1,76c-0.4,2.3-0.7,4.6-0.9,6.9c-0.2,1.8-0.6,3.6-0.9,5.5
		c0,10.8,0,21.6,0,32.4c0,15,0,29.9,0,44.9c0,15.2,0,30.4,0,45.6c0,14.3,0,28.6,0,42.9c0,31.7,0,63.4,0,95.1c0,16,0,32,0,47.9
		c1.1,6.8,1.8,13.7,3.4,20.4c9.5,38.6,44.7,66.8,84.4,67.3c4.6,0.1,9.3,0.1,13.9,0.1c1,0,2,0,3,0c15.9,0.1,31.8,0,47.8,0H226h45.6
		c0,0,0,0,0,0c2.3,0,4.3,0,6.3,0c16.9,0,33.9,0,50.8,0c9.2,0,18.4,0,27.6,0c3.3-0.8,6.5-1.6,9.8-2.4c13.7-3,25.7-8.9,36.2-17.7
		c8.8-7.1,16.1-15.8,21.6-25.6c7.2-12.7,11.3-27.2,11.6-42.4c0-0.4,0-0.8,0-1.2h0.1V259.1c0-55.7,0.2-111.4-0.1-167.2
		C435.4,88.2,435.2,84.6,434.8,81z M94.6,394.1c0-0.3,0-0.6,0-0.9c0-14.7,0-29.3,0-44c0-61.2,0-122.4,0-183.7c0-14.9,0-29.9,0-44.8
		c0-9.5,0-19,0-28.4c0-5.9,0.9-11.4,2.5-16.5c6.1-18.5,22.7-30.2,44.7-30.2c4.3,0,8.6,0,12.9,0c15.9,0,31.8,0,47.6,0
		c15.1,0,30.2,0,45.3,0c5.9,0,11.7,0,17.6,0c22.3,0.1,44.6,0.5,66.9,0c3.7,0,7.4,0,11.2,0c6.6,0,12.8,1.1,18.3,3.1
		c13.3,5.2,23.9,16.4,27.7,32c0.8,3.8,1.3,7.9,1.3,12.1c0,16.3,0,32.7,0,49c-0.1,23.6,0,47.2,0,70.8c0,10,0,20,0,30.1
		c0,50.5,0.1,101,0,151.6c0,5-0.7,9.8-2.1,14.3c0,0,0,0.1,0,0.1c-0.1,0.4-0.3,0.9-0.4,1.3c-4.5,13-13.8,21.6-23.8,26.2c0,0,0,0,0,0
		c-0.4,0.2-0.8,0.4-1.2,0.5c-3.1,1.2-6.4,2.2-9.8,2.7c-0.8,0.1-1.6,0.2-2.4,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.6,0.1-1.1,0.1-1.7,0.1
		c-1.2,0.1-2.5,0.1-3.7,0.1c-68.1,0-136.1,0.1-204.2,0C113.3,439.5,95,421.2,94.6,394.1z"/>
</G>
</Svg>
                    );
                }