import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M418.6,334.8c0-3.6-1.8-6.5-5-8.4c-1.7-1-3.5-1.4-5.2-1.4c-4,0-7.7,2.5-9.3,6.4c-0.5,1.2-0.8,2.5-0.8,4
		c-0.1,7.7,0,15.4,0,23c0,0.7,0,1.4,0,2.1c-56.1,0-111.9,0-167.9,0c0-0.8,0-1.6,0-2.3c0-7.6,0.1-15.2,0-22.8c0-1.5-0.3-2.8-0.8-4.1
		c-1.6-3.9-5.2-6.4-9.3-6.4c-1.4,0-2.8,0.3-4.1,0.9c-3.3,1.4-5,4.2-6.1,7.4c0,14.8,0,29.6,0,44.4c1,1.8,1.8,3.8,3.1,5.3
		c2.2,2.5,5.2,2.9,8.3,2.9c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,0.9,0c1.8,0,1.8,0.4,1.8,2.7c0,4.1,0,8.3,0,12.4c5.2,0,10.2,0,15.4,0
		c0-5.1,0-10,0-15c49.2,0,98.3,0,147.5,0c0,5.1,0,10.1,0,15c5.2,0,10.1,0,15.2,0c0-5.1,0-10,0-15.2c1.3,0,2.5,0,3.7,0
		c0.6,0,1.2,0,1.7,0c5.5-0.3,9.7-4.3,9.8-9.8C418.7,362.4,418.7,348.6,418.6,334.8z"/>
	<Path fill={color} d="M236.7,294.6c0.1,0,0.1,0,0.2,0c2.8-2.6,6.3-4.4,10.4-5.1c3.2-0.6,6.4-0.9,9.5-0.9c23.4,0,47.1,0,70.6,0
		c0-9.8,0-19.6,0.1-29.5c0.2-2.5-0.4-4.4-2.4-6.3c-15.1-15.3-30.3-30.5-45.4-45.8l-3.5,16.7l-11.4-18.1l-32.4,47.9l15.8-60.4h-15.8
		l10.6-23.2c-5.9-6-11.8-11.9-17.7-17.9c-5.2-5.3-8.5-5.3-13.6-0.2c-33,33.6-66,67.2-99.2,100.8c-1.8,1.8-2.6,3.7-2.6,6.2
		c0.1,32.2,0,64.6,0.1,96.8c0,7.2,2.5,9.6,9.6,9.6c20.5,0,41,0,61.6,0c7.6,0,9.9-2.3,9.9-9.7c0-17.1,0-34.2,0-51.3
		c0-6.9,2.5-9.5,9.4-9.5C212.6,294.5,224.7,294.5,236.7,294.6z"/>
	<Path fill={color} d="M345.2,248.7c3,2.8,6.3,4.8,10.7,4.7c5.5,0.1,10.1-2.4,12.8-6.6c0.4-0.6,0.7-1.2,1.1-1.8c0.5-1.2,1-2.5,1.2-4
		c1-6-1.8-10.3-5.7-14.3c-21.4-21.7-42.8-43.4-64.1-65.2l-3.8,19.2l-9.6-11.4l-3.6,17.1c20,20.3,40,40.7,59.9,61
		C344.4,247.9,344.9,248.4,345.2,248.7z"/>
	<Path fill={color} d="M231.4,132.9c6.5,6.6,13,13.2,19.5,19.8l6.1-13.2l-10.5-4.4l9.6-19.5c-8-8.1-16-16.3-24-24.4c-9.5-9.6-16.7-9.6-26.2,0
		c-31.4,31.9-62.7,63.9-94.2,95.7c-13.6,13.8-27.1,27.6-40.6,41.3c-4.1,4.2-5.5,9.1-3.8,14.7c1.7,5.4,5.5,8.8,11,9.9
		c6,1.2,10.6-1.4,14.7-5.6c37.5-38.2,75.1-76.4,112.6-114.5C214.7,123.6,222.2,123.6,231.4,132.9z"/>
	<Path fill={color} d="M256.8,299.6c-2.5,0-5,0.3-7.5,0.7c-1.2,0.2-2.3,0.6-3.2,1.1c-2.5,1.3-4.1,3.5-4.7,6.6c-0.5,2.4-0.7,4.8-0.8,7.3
		c0,4.8,0,9.6,0,14.5c0,6.2,0,12.5,0,18.7c0,0.6,0,1.2,0,1.9c49.2,0,98.2,0,147.5,0c0-3.8,0-7.4,0-11c0-3.2,0-6.4,0.1-9.6
		c0-5.8,0-11.6-0.1-17.4c-0.2-8.2-3.8-11.9-12-12.7c-1.4-0.1-2.7-0.2-4.1-0.2c-14.8,0-29.6,0-44.5,0
		C303.9,299.5,280.4,299.5,256.8,299.6z"/>
</G>
</Svg>
                    );
                }