import { MortgagePart } from "./MortgagePart";
import { MortgageProgress } from "./MortgageProgress";

export class Mortgage {
    id: string;
    customer_id: number;
    hypotheeknummer: number;
    koopsom: number;
    onderpandsgegevens: string;
    redenHypotheek: string;
    hoofdsomHypotheek: number;
    hypotheciareInschrijving: number;
    taxateur_id: number;
    marktwaarde: number;
    datumTaxatie: number;
    wozWaarde: number;
    wozDatum: number;
    totaalSom: number;
    bouwdepot: number;
    notaris_id: number;
    passeerdatum: number;
    verkopendMakelaar_id: number;
    geldverstrekker_id: number;
    label_id: number;
    nhg: boolean;
    rate: number;
    rangorde: number;
    status: number;
    getekend: number;
    voor_verlenging: number;
    na_verlening: number;
    financiering: number;
    bankgarantie: number;
    passeerdatumVoortgang: number;    
    restantHoofdsom: number;
    executiewaarde: number;
    type_woning: number;
    offerteNummer: number;
    datumAanvraag: number;
    datumOfferte: number;
    datumGeldigheidOfferte: number;
    datumOfferteGetekend: number;
    vovVoorVerbouwing: number;
    vovNaVerbouwing: number;
    ewVoorVerbouwing: number;
    ewNaVerbouwing: number;
    trackingNumber: number;    
    purchasing_broker_id: number;
    validity_unsigned_offer_date: number;
    validity_signed_offer_date: number;
    end_financing_reservation_date: number;
    has_bank_guarantee_date: number;
    bank_guarantee_date: number;
    mortgage_property_id: number;
    financier_agency_id: number;
    arrangement_type: number;
    existing_mortgage_number: number;
    own_resources_amount: number;
    mutation_code: number;
    is_property_purchase: false;
    bank_guarantee_costs: number;
    net_consultancy_costs: number;
    mortgage_deed_cost_specification: number;
    national_mortgage_guarantee_costs: number;
    fine_interest: number;
    loan_type: number;
    secondary_customer_id: number;
    loan_to_value: number;
    risk_class: number;
    risk_class_type: number;
    agency_name: string;
    appraiser_name: string;
    arrangement_type_description: string;    
    company_label_description: string;
    estimated_loan_to_value_description: string;
    loan_to_value_description: number;
    monthly_amount: number;
    monthly_interest_amount: number;
    monthly_premium: number;
    monthly_repayment_amount: number;
    mortgagors: unknown[];
    mutation_code_description: string;
    notary_name: string;
    remaining_debt_old_age_pension: number;
    risk_category: unknown[];
    status_description: string;
    taxation_date: string;
    taxation_type: number;
    total_redemption_free_amount: string;
    total_remaining_debt_old_age_pension: string;
    total_withdrawal_space_amount: string;
    progress: MortgageProgress[];
    openProgress: number;
    seen_by_customer: boolean
    properties: Array<any>
    mainProperty: Record<string, any>

    /** RELATIONS **/
    mortgageParts: Array<MortgagePart>;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Mortgage {
        this.id = json.id ? json.id : "";
        this.customer_id = json.customer_id ? json.customer_id : 0;
        this.hypotheeknummer = json.hypotheeknummer ? json.hypotheeknummer : '#unknown';
        this.koopsom = json.koopsom ? json.koopsom : 0;
        this.onderpandsgegevens = json.onderpandsgegevens ? json.onderpandsgegevens : "";
        this.redenHypotheek = json.redenHypotheek ? json.redenHypotheek : "";
        this.hoofdsomHypotheek = json.hoofdsomHypotheek ? json.hoofdsomHypotheek : 0;
        this.hypotheciareInschrijving = json.hypotheciareInschrijving ? json.hypotheciareInschrijving : 0;
        this.taxateur_id = json.taxateur_id ? json.taxateur_id : 0;
        this.marktwaarde = json.marktwaarde ? json.marktwaarde : 0;
        this.datumTaxatie = json.datumTaxatie ? json.datumTaxatie : 0;
        this.wozWaarde = json.wozWaarde ? json.wozWaarde : 0;
        this.wozDatum = json.wozDatum ? json.wozDatum : 0;
        this.totaalSom = json.totaalSom ? json.totaalSom : 0;
        this.bouwdepot = json.bouwdepot ? json.bouwdepot : 0;
        this.notaris_id = json.notaris_id ? json.notaris_id : 0;
        this.passeerdatum = json.passeerdatum ? json.passeerdatum : 0;
        this.verkopendMakelaar_id = json.verkopendMakelaar_id ? json.verkopendMakelaar_id : 0;
        this.geldverstrekker_id = json.geldverstrekker_id ? json.geldverstrekker_id : 0;
        this.label_id = json.label_id ? json.label_id : 0;
        this.nhg = json.nhg ? json.nhg : false;
        this.rate = json.rate ? json.rate : 0;
        this.rangorde = json.rangorde ? json.rangorde : 0;
        this.status = json.status != undefined ? json.status : 0;
        this.getekend = json.getekend ? json.getekend : 0;
        this.voor_verlenging = json.voor_verlenging ? json.voor_verlenging : 0;
        this.na_verlening = json.na_verlening ? json.na_verlening : 0;
        this.financiering = json.financiering ? json.financiering : 0;
        this.bankgarantie = json.bankgarantie ? json.bankgarantie : 0;
        this.passeerdatumVoortgang = json.passeerdatumVoortgang ? json.passeerdatumVoortgang : 0;        
        this.restantHoofdsom = json.restantHoofdsom ? json.restantHoofdsom : 0;
        this.executiewaarde = json.executiewaarde ? json.executiewaarde : 0;
        this.type_woning = json.type_woning ? json.type_woning : 0;
        this.offerteNummer = json.offerteNummer ? json.offerteNummer : 0;
        this.datumAanvraag = json.datumAanvraag ? json.datumAanvraag : 0;
        this.datumOfferte = json.datumOfferte ? json.datumOfferte : 0;
        this.datumGeldigheidOfferte = json.datumGeldigheidOfferte ? json.datumGeldigheidOfferte : 0;
        this.datumOfferteGetekend = json.datumOfferteGetekend ? json.datumOfferteGetekend : 0;
        this.vovVoorVerbouwing = json.vovVoorVerbouwing ? json.vovVoorVerbouwing : 0;
        this.vovNaVerbouwing = json.vovNaVerbouwing ? json.vovNaVerbouwing : 0;
        this.ewVoorVerbouwing = json.ewVoorVerbouwing ? json.ewVoorVerbouwing : 0;
        this.ewNaVerbouwing = json.ewNaVerbouwing ? json.ewNaVerbouwing : 0;
        this.trackingNumber = json.trackingNumber ? json.trackingNumber : 0;        
        this.purchasing_broker_id = json.purchasing_broker_id ? json.purchasing_broker_id : 0;
        this.validity_unsigned_offer_date = json.validity_unsigned_offer_date ? json.validity_unsigned_offer_date : 0;
        this.validity_signed_offer_date = json.validity_signed_offer_date ? json.validity_signed_offer_date : 0;
        this.end_financing_reservation_date = json.end_financing_reservation_date ? json.end_financing_reservation_date : 0;
        this.has_bank_guarantee_date = json.has_bank_guarantee_date ? json.has_bank_guarantee_date : 0;
        this.bank_guarantee_date = json.bank_guarantee_date ? json.bank_guarantee_date : 0;
        this.mortgage_property_id = json.mortgage_property_id ? json.mortgage_property_id : 0;
        this.financier_agency_id = json.financier_agency_id ? json.financier_agency_id : 0;
        this.arrangement_type = json.arrangement_type ? json.arrangement_type : 0;
        this.existing_mortgage_number = json.existing_mortgage_number ? json.existing_mortgage_number : 0;
        this.own_resources_amount = json.own_resources_amount ? json.own_resources_amount : 0;
        this.mutation_code = json.mutation_code ? json.mutation_code : 0;
        this.is_property_purchase = json.is_property_purchase ? json.is_property_purchase : 0; false;
        this.bank_guarantee_costs = json.bank_guarantee_costs ? json.bank_guarantee_costs : 0;
        this.net_consultancy_costs = json.net_consultancy_costs ? json.net_consultancy_costs : 0;
        this.mortgage_deed_cost_specification = json.mortgage_deed_cost_specification ? json.mortgage_deed_cost_specification : 0;
        this.national_mortgage_guarantee_costs = json.national_mortgage_guarantee_costs ? json.national_mortgage_guarantee_costs : 0;
        this.fine_interest = json.fine_interest ? json.fine_interest : 0;
        this.loan_type = json.loan_type ? json.loan_type : 0;
        this.secondary_customer_id = json.secondary_customer_id ? json.secondary_customer_id : 0;
        this.loan_to_value = json.loan_to_value ? json.loan_to_value : 0;
        this.risk_class = json.risk_class ? json.risk_class : 0;
        this.risk_class_type = json.risk_class_type ? json.risk_class_type : 0;
        this.agency_name = json.agency_name ? json.agency_name : "";
        this.appraiser_name = json.appraiser_name ? json.appraiser_name : "";
        this.arrangement_type_description = json.arrangement_type_description ? json.arrangement_type_description : "";        
        this.company_label_description = json.company_label_description ? json.company_label_description : "";
        this.estimated_loan_to_value_description = json.estimated_loan_to_value_description ? json.estimated_loan_to_value_description : 0;
        this.loan_to_value_description = json.loan_to_value_description ? json.loan_to_value_description : 0;
        this.monthly_amount = json.monthly_amount ? json.monthly_amount : 0;
        this.monthly_interest_amount = json.monthly_interest_amount ? json.monthly_interest_amount : 0;
        this.monthly_premium = json.monthly_premium ? json.monthly_premium : 0;
        this.monthly_repayment_amount = json.monthly_repayment_amount ? json.monthly_repayment_amount : 0;
        this.mortgagors = json.mortgagors ? new Array<any>(json.mortgagors.data) : new Array<any>();
        this.mutation_code_description = json.mutation_code_description ? json.mutation_code_description : "";
        this.notary_name = json.notary_name ? json.notary_name : "";
        this.remaining_debt_old_age_pension = json.remaining_debt_old_age_pension ? json.remaining_debt_old_age_pension : 0;
        this.risk_category = json.risk_category ? new Array<any>(json.risk_category.data) : new Array<any>();
        this.status_description = json.status_description ? json.status_description : "";
        this.taxation_date = json.taxation_date ? json.taxation_date : "";
        this.taxation_type = json.taxation_type ? json.taxation_type : 0;
        this.total_redemption_free_amount = json.total_redemption_free_amount ? json.total_redemption_free_amount : "";
        this.total_remaining_debt_old_age_pension = json.total_remaining_debt_old_age_pension ? json.total_remaining_debt_old_age_pension : "";
        this.total_withdrawal_space_amount = json.total_withdrawal_space_amount ? json.total_withdrawal_space_amount : "";
        this.seen_by_customer = json.seen_by_customer ? json.seen_by_customer : false;

        this.properties = json.mortgage_properties.data.map(mortgage_property => {
            return mortgage_property.property.data;
        });
        this.mainProperty = this.properties[0] || { street: '', number: '', number_addition: '', city: '', postal_code: '' };

        this.mortgageParts = this.setMortgageParts(json.mortgage_parts);

        this.progress = this.setProgress(json.progress);
        this.openProgress = this.progress.reduce((acc, val) => {
            return (!val.received) ? acc + 1 : acc;
        }, 0);

        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }

    setMortgageParts(json: any) {
        const mortgageParts = new Array<MortgagePart>();
        if (json) {
            if (json.data) {
                for (const data of json.data) {
                    mortgageParts.push(new MortgagePart(data));
                }
            } else {
                for (const data of json) {
                    mortgageParts.push(new MortgagePart(data));
                }
            }
        }
        return mortgageParts;
    }

    setProgress(progress) {
        return progress.data.map(data => new MortgageProgress(data));
    }

    removeMortgagePart(id: string) {
        const index = this.mortgageParts.findIndex((mortgagePart: MortgagePart) => mortgagePart.id === id);
        if (index >= 0) {
            this.mortgageParts.splice(index, 1);
        }
    }

    getMortgagePart(id: string): MortgagePart {
        return this.mortgageParts.find((mortgagePart: MortgagePart) => mortgagePart.id === id);
    }
}
