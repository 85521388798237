import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M443.01,200.181H285.413V42.596C285.413,19.062,266.345,0,242.818,0c-23.521-0.006-42.59,19.062-42.59,42.59v157.591H42.631
	c-23.522,0-42.596,19.068-42.596,42.595c0,23.519,19.068,42.59,42.596,42.596l157.591-0.003v157.6
	c0.006,23.53,19.074,42.596,42.596,42.596s42.596-19.077,42.596-42.59V285.369l157.603-0.003c23.516,0,42.584-19.071,42.584-42.59
	C485.6,219.255,466.531,200.187,443.01,200.181z"/>
</Svg>
                    );
                }