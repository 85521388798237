import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M464.646,390.639L316.62,242.613L464.652,94.58c20.437-20.436,20.443-53.577,0.006-74.014
	c-20.441-20.441-53.576-20.441-74.02,0.001L242.607,168.599L94.569,20.562C74.133,0.125,40.998,0.125,20.555,20.568
	c-20.436,20.437-20.436,53.57,0,74.006l148.038,148.039L20.562,390.646c-20.43,20.43-20.436,53.57,0.006,74.012
	c20.43,20.432,53.57,20.438,74.007,0.002l148.033-148.033l148.024,148.025c20.443,20.441,53.578,20.443,74.014,0.006
	C485.081,444.221,485.088,411.081,464.646,390.639z"/>
</Svg>
                    );
                }