import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M334.3,208.9l27.8,27.8L242.6,356.2L123.1,236.7l27.8-27.8l71.8,71.8V123.4H262v157.3L334.3,208.9z M478.5,241.9
	c0,130-105.9,235.9-235.9,235.9S6.7,371.9,6.7,241.9S112.6,6,242.6,6S478.5,111.9,478.5,241.9z M439.2,241.9
	c0-108.5-88.1-196.6-196.6-196.6S46,133.4,46,241.9s88.1,196.6,196.6,196.6S439.2,350.4,439.2,241.9z"/>
</Svg>
                    );
                }