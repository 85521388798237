import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="camera">
	<G>
		<Path fill={color} d="M242.822,194.127c-40.164,0-72.717,32.568-72.717,72.72c0,40.162,32.554,72.715,72.717,72.715
			c40.154,0,72.718-32.553,72.718-72.715C315.539,226.696,282.975,194.127,242.822,194.127z M436.736,121.41h-58.174
			c-8,0-16.619-6.206-19.146-13.797l-15.039-45.129c-2.535-7.588-11.145-13.792-19.145-13.792H160.408
			c-7.999,0-16.619,6.204-19.145,13.797l-15.038,45.129c-2.535,7.587-11.146,13.792-19.145,13.792H48.907
			c-26.663,0-48.478,21.814-48.478,48.478v218.154c0,26.664,21.814,48.479,48.478,48.479h387.828
			c26.663,0,48.478-21.814,48.478-48.479V169.888C485.213,143.226,463.399,121.41,436.736,121.41z M242.822,388.042
			c-66.935,0-121.195-54.262-121.195-121.195c0-66.931,54.263-121.197,121.195-121.197c66.925,0,121.197,54.267,121.197,121.197
			C364.018,333.781,309.746,388.042,242.822,388.042z M419.767,203.79c-9.365,0-16.968-7.592-16.968-16.968
			c0-9.366,7.603-16.969,16.968-16.969c9.376,0,16.969,7.603,16.969,16.969C436.736,196.198,429.138,203.79,419.767,203.79z"/>
	</G>
</G>
</Svg>
                    );
                }