import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M11.223,224.019L203.287,79.946c6.339-5.009,15.538-3.93,20.547,2.407c2.094,2.647,3.206,5.939,3.148,9.315v75.894
	c209.347,0,249.022,145.275,255.484,222.972c0.436,8.291-5.929,15.359-14.216,15.803c-4.54,0.238-8.947-1.592-11.983-4.98
	c-92.928-108.557-229.285-83.51-229.285-83.51v75.893c0.028,8.08-6.5,14.65-14.577,14.68c-3.199,0.01-6.314-1.027-8.867-2.955
	L11.223,261.44c-10.332-7.761-12.421-22.423-4.666-32.755C7.882,226.917,9.455,225.346,11.223,224.019z"/>
</Svg>
                    );
                }