import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} class="st0" d="M242.3-0.3c-9.6,0-18.7,3.7-25.6,10.6L81.2,145.8c-6.8,6.8-10.6,15.9-10.6,25.6s3.7,18.8,10.6,25.7
	c14.1,14.1,37.1,14.1,51.2,0l73.7-73.7v325.8c0,19.9,16.3,36.2,36.2,36.2s36.2-16.3,36.2-36.2V123.5l73.7,73.7
	c14.1,14.1,37.1,14.1,51.2,0c6.8-6.9,10.6-16,10.6-25.7c0-9.6-3.7-18.7-10.6-25.6L267.9,10.3C261,3.5,252-0.3,242.3-0.3z"/>
</Svg>
                    );
                }