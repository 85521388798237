import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M44.5,299.3c19.8-17.8,43.3-25.1,69.5-21.8c44,5.4,76.2,43.2,75.5,87.7c-0.1,7.6,0.6,8.3,7.8,8.3c31.6,0,63.2,0,94.8,0
		c14.3,0,23.3-7.9,25.5-22c4-25.9,16.8-46.4,38.7-60.7c31.2-20.3,70.7-18.4,100.4,4.2c1.8,1.4,4.1,2.7,6.3,2.9c6.3,0.5,9.5-6,6-12
		c-14.6-25.1-35.3-43.2-62-54.5c-2.1-0.9-3.8-1.7-3.9-4.6c-0.4-12.9-4.2-25.1-9.7-36.7c-13-27.8-32.6-50.4-55-70.8
		c-1.3-1.2-2.7-2.4-4.3-3.9c12.3-2.9,17.1-7,18-16.2c1.3-14-2.9-26.2-13.9-35.3c-9.4-7.8-25-12.1-38.7-2.4
		c-7.2,5.1-15.6,8.6-23.1,13.3c-3.5,2.2-7,5.2-9.1,8.7c-2,3.3-3.9,5.7-7.6,7.2c-4.3,1.7-6.1,5.5-5.5,10.2c0.6,4.3,3.1,8,7.2,7.9
		c6.6-0.1,12.3,1.9,17.8,5c0.7,0.4,1.7,0.6,2.6,0.6c2.9-0.1,4,1.5,4.7,4.1c11.4,41.4,23,82.8,34.4,124.2c3.4,12.1,3.8,24.3,0.7,36.5
		c-7.7,29.9-33.8,50.6-66.8,52.9c-20.5,1.4-39.4-14.4-40.2-35c-0.9-25,3.1-49.4,14.5-72c0.6-1.2,1.7-2.5,2.9-2.9
		c8-2.5,11.9-8.4,13.5-16.2c4.2-20.2-9.5-40-30.2-43.6c-14.8-2.6-29,0.2-42.8,4.9c-15.1,5.2-30.5,8-46.3,6.6
		c-15.8-1.3-31.5-3.7-47.2-5.7c-4.1-0.5-8.2-1.5-12.3-2.1c-19.4-2.6-34.8,10.4-35.8,30.4c-1,18.2,6.5,26.1,24.5,26.1
		c3.3,0,6.6,0,9.9,0c-22.2,15.6-40,34.2-50.4,59.1c0,2.7,0,5.3,0,8c0.3,0.7,0.6,1.3,0.9,2C21.1,307.1,32.6,310.1,44.5,299.3z"/>
	<Path fill={color} d="M102.9,297.6C66.3,297.7,37,327,37,363.5c0,36.4,29.6,65.9,66,65.9c36.1-0.1,65.7-29.6,65.8-65.7
		C168.8,327.2,139.3,297.6,102.9,297.6z"/>
	<Path fill={color} d="M403.7,297.6c-36.5-0.2-66,29.1-66.1,65.6c-0.1,36.2,29.2,65.9,65.3,66.1c36.6,0.2,66.3-29.3,66.4-65.9
		C469.3,327.2,440.1,297.8,403.7,297.6z"/>
</G>
</Svg>
                    );
                }