import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M242.7,79.1c90.6,2.5,160.6,43.7,214.5,114.9c9.6,12.7,17.5,26.5,25.5,40.3c3.2,5.6,3.4,10.5,0.2,16.2
	c-44.8,80.7-109,136.6-202.2,152.1c-74.9,12.5-141.6-9.1-200.4-56.2C48,320.5,23.2,288.3,3.1,252.3c-3.4-6.2-4.5-11.5-0.7-18.1
	C46.8,154.8,110,100.1,201.6,82.9C215.1,80.4,228.8,78.8,242.7,79.1z M243.9,122.5c-9.2,0.5-17.2,1-25.1,1.5
	c-2.2,0.1-4.3,0.6-6.4,0.9c-70.4,12-121.7,51.7-160.4,110c-3.8,5.8-3.4,10.1,0.3,15.7c13.5,20.7,29.1,39.6,47.8,55.8
	c51.2,44.2,109.9,64.4,177.4,52.8c60-10.3,106.7-43,142.6-90.9c22.6-30.1,22.2-21.8,0.3-50.7C376,158.7,317.7,124.5,243.9,122.5z"/>
<Path fill={color} d="M241.6,334.8c-49.9-0.3-91.5-41.7-91.5-91c0-52.8,42-94.5,94.5-93.7c49.4,0.7,91.4,43.5,90.5,92.2
	C334,294.9,293.3,335.2,241.6,334.8z M242.9,193.4c-26.9-0.2-49.3,21.8-49.6,48.6c-0.3,26.9,21.5,49.4,48.3,49.9
	c27.3,0.5,50.1-21.8,50.1-49.1C291.8,215.9,269.7,193.6,242.9,193.4z"/>
</Svg>
                    );
                }