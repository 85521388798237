import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M109.4,395.7c37,0,74.1,0,111.1,0c45.2,0,90.5,0,135.7,0c12.5,0,22.1-5.1,27.6-16.5c1.8-3.8,2.9-8.4,2.9-12.6
		c0.2-45.6,0.1-91.2,0.1-136.7c0-0.4-0.1-0.8-0.2-1.5c-27,16.4-55.1,19.4-83.8,6.1c-28.7-13.4-44.4-36.9-49.4-68.3
		c-1.1,0-2.3,0-3.5,0c-46.4,0-92.8,0.2-139.2-0.1c-16.4-0.1-29.2,9.7-31.5,25c0,0.2-0.2,0.4-0.3,0.6c0,59.5,0,119,0,178.6
		c1.9,4.6,3.1,9.6,5.8,13.6C90.4,392.3,99.2,395.7,109.4,395.7z M347.7,351.6c-1.8,4.1-5.3,5.9-9.7,5.9c-13.6,0-27.3,0-40.9,0
		c-11.5,0-23,0.1-34.5,0c-7.2-0.1-11.9-6.9-9.1-13.2c1.8-4.1,5.3-5.9,9.7-5.9c12.6,0,25.1,0,37.7,0c12.6,0,25.1-0.1,37.7,0
		C345.8,338.4,350.5,345.2,347.7,351.6z M339.4,261.8c5.2,0.3,9.3,4.8,9.1,10c-0.3,5.2-4.8,9.3-10,9.1c-5.2-0.3-9.3-4.8-9.1-10
		C329.6,265.7,334.2,261.5,339.4,261.8z M300.6,261.8c5.2,0,9.5,4.4,9.5,9.6c0,5.2-4.4,9.5-9.6,9.5c-5.2,0-9.5-4.4-9.5-9.6
		C291,266,295.4,261.7,300.6,261.8z M262.1,261.8c5.2,0,9.6,4.3,9.6,9.5c0,5.2-4.3,9.6-9.5,9.6c-5.2,0-9.6-4.3-9.6-9.5
		C252.6,266.2,256.9,261.8,262.1,261.8z M255,303.7c1.9-1.8,4.9-3.5,7.4-3.5c25.4-0.3,50.9-0.2,76.3-0.1c5.5,0,9.6,4.2,9.7,9.4
		c0.1,5.3-4.1,9.6-9.7,9.7c-12.8,0.1-25.6,0-38.3,0c-12.6,0-25.1,0-37.7,0c-4.2,0-7.4-1.9-9.2-5.7
		C251.9,309.9,252.2,306.2,255,303.7z M117.9,322.1c1.4-12.8,7.8-23.2,18-31.1c0.6-0.5,1.2-0.9,1.7-1.4c0.2-0.1,0.2-0.3,0.5-0.7
		c-8.2-8.5-12.2-18.7-11.1-30.6c0.9-10.2,5.4-18.6,13.1-25.4c14.5-12.8,36.8-12.3,51.2,1c13.3,12.3,18.4,36.4,1.4,54.6
		c4.2,4.8,8.8,9.2,12.5,14.4c5.5,7.6,7.7,16.5,7.8,25.8c0.1,6.2,0.1,12.3,0,18.5c-0.1,6.3-3.9,10.2-10.2,10.2c-25.1,0-50.3,0-75.4,0
		c-6.3,0-10.3-3.9-10.2-10.2C117.3,338.9,117,330.4,117.9,322.1z"/>
	<Path fill={color} d="M193.5,338.2c2.1-15.2-3-27.9-13.4-33.9c-10.5-6-23-5.5-31.8,1.2c-10.6,8-13.3,19.1-11.4,32.7
		C155.7,338.2,174.5,338.2,193.5,338.2z"/>
	<Path fill={color} d="M165.1,280.9c10.4,0,19.2-8.6,19.2-19.1c0-10.4-8.7-19.1-19.1-19.2c-10.4,0-19.2,8.6-19.2,19.1
		C145.9,272.1,154.6,280.8,165.1,280.9z"/>
	<Path fill={color} d="M405.4,148.1c-1-7.9-3.5-15.3-7.1-22.3c-13.5-26.3-45.4-41.2-74-34.7c-27.1,6.2-46.7,27-51.5,54.8
		c-6,34.8,18.5,70.1,53,76.3c36.5,6.5,69.5-14.7,78.5-50.6c0.7-2.9,1.2-5.8,1.8-8.7c0-4.3,0-8.5,0-12.8
		C405.8,149.5,405.5,148.8,405.4,148.1z M344.7,144.6v8.8h-19.1c-0.1,0.6-0.1,1.5-0.1,2.8c0,1.2,0,2.1,0.1,2.6h19.1v8.7h-16.6
		c1.5,2.7,3.5,4.7,6,6.2c2.5,1.4,5.4,2.2,8.6,2.2c3.2,0,6-0.8,8.5-2.3c2.5-1.5,4.4-3.7,5.9-6.5h18.4c-2,7.5-5.9,13.3-11.7,17.6
		c-5.8,4.3-12.8,6.4-21,6.4c-8.1,0-15.1-2.1-20.9-6.4c-5.9-4.2-9.9-10-12-17.3h-7.6V159h6.2c-0.1-0.6-0.1-1.5-0.1-2.6
		c0-1.2,0-2.2,0.1-2.8h-6.2v-8.8h7.7c2.2-7.2,6.2-12.9,12-17.1c5.9-4.2,12.8-6.3,20.8-6.3c8.2,0,15.2,2.2,21,6.5
		c5.8,4.3,9.7,10.2,11.7,17.7h-18.4c-1.4-2.8-3.4-5-5.9-6.4c-2.5-1.5-5.3-2.2-8.5-2.2c-3.1,0-6,0.7-8.4,2.1c-2.5,1.4-4.5,3.3-6,5.9
		H344.7z"/>
</G>
</Svg>
                    );
                }