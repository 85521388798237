import { Icons } from "assets/icons";
import { useState } from "react";
import { useWindowDimensions, View } from "react-native"
import { colors } from "styles"
import SText from "./SText";
import { MortgageStatus } from 'enums'


const CheckSign = Icons.Check;
const steps = [
    { title: '#inventarisatie', description: '#inventarisatie_short', icon: Icons.Checklist },
    { title: '#documenten_leveren', description: '#documenten_leveren_short', icon: Icons.File_upload },
    { title: '#rente_voorstel', description: '#rente_voorstel_short', icon: Icons.Doc },
    { title: '#beoordeling_geldverstrekker', description: '#beoordeling_geldverstrekker_short', icon: Icons.Read_only },
    { title: '#beoordeling_notaris', description: '#beoordeling_notaris_short', icon: Icons.Read_only }
];


export default ({ mortgage }) => {

    const allProgressSent = mortgage.progress.reduce((acc, val) => {
        if (!val.received) return false;
        return acc;
    }, true);


    let index = 0;
    if (mortgage?.progress?.length > 0 && !allProgressSent) {
        index = 1;
    }

    if (mortgage?.progress?.length > 0 && allProgressSent) {
        index = 2;
    }

    if (mortgage.status == MortgageStatus.STUKKEN_TER_BEOORDELING_NAAR_BANK) {
        index = 3;
    }

    if (mortgage.status == MortgageStatus.STUKKEN_NAAR_NOTARIS) {
        index = 4;
    }

    const { width } = useWindowDimensions();
    const [sizes, setSizes] = useState({ circle: 50, line: 30, thin: 2, width: 300 });
    const handleLayout = (e) => {
        const width = e.nativeEvent.layout.width * 0.8;
        setSizes({
            circle: Math.min((width * 0.7) / 5, 45),
            line: (width * 0.3) / 4,
            thin: Math.min((width * 0.3) / 4 * 0.16, 5),
            width: e.nativeEvent.layout.width
        })
    }


    return (
        <View style={{
            marginVertical: 20,
            justifyContent: "flex-end",
            alignItems: "center",
        }}>

            <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: 'center', width: '100%' }} onLayout={handleLayout}>
                {steps.map((Step, key) => {
                    return [
                        (<View
                            key={key}
                            style={{
                                width: sizes.circle,
                                height: sizes.circle,
                                borderRadius: sizes.circle,
                                borderWidth: sizes.thin,
                                borderColor: (key <= index) ? colors.primary : colors.grey,
                                backgroundColor: (key <= index) ? colors.primary : colors.lightgrey,
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "visible"
                            }}
                        >

                            <View style={{ marginLeft: width > 768 ? 1 : 0 }}>
                                {key < index ? (
                                    <CheckSign
                                        size={sizes.circle * 0.4}
                                        color={(key <= index) ? colors.white : colors.grey}
                                    />
                                ) : (
                                    <Step.icon
                                        size={sizes.circle * 0.4}
                                        color={(key <= index) ? colors.white : colors.grey}
                                    />
                                )}
                            </View>

                            {sizes.width >= 320 &&
                                <SText
                                    primary={(key == index)}
                                    grey={(key != index)}
                                    xs
                                    semibold
                                    style={{
                                        position: "absolute",
                                        top: sizes.circle + 2,
                                        fontSize: width < 768 ? 8 : 10,
                                        textAlign: "center",
                                        width: 200
                                    }}
                                >
                                    {Step.description}
                                </SText>
                            }

                        </View>)
                        ,
                        (key < steps.length - 1) &&
                        <View
                            key={key + 'x2'}
                            style={{
                                width: sizes.line,
                                borderBottomColor: (key < index) ? colors.primary : colors.grey,
                                borderBottomWidth: sizes.thin,
                            }}
                        />

                    ];
                }).flat()}
            </View>

            <SText sm semibold primary center style={{ width: '100%', marginTop: sizes.width >= 320 ? 28 : 8 }}>
                #step {index + 1} - {steps[index].title}
            </SText>

        </View>
    )
}
