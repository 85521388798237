import { Text, View } from "react-native"
import { Content } from 'screens/home';

export default () => {
    return (
        <Content
            title="Mijn Vermogen"
        >
            <></>
        </Content>
    )
}