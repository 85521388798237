import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M84,263.1c0-37.6,0-75.1,0-112.7c0-16.9,0-16.9-16.7-17.8c-15.1-0.8-24.4-7.6-26.6-19.4c-3-16.2,7.9-28.7,26.5-29
	c30-0.4,59.9-0.1,89.9-0.2c13.6-0.1,17.4,3.1,18.1-16.7c1-26.8,26-48.6,55.2-50.3c7.2-0.4,14.4-0.3,21.6-0.1
	c32.3,0.8,55.4,21.2,58.7,53.3c1.1,11.2,4.9,14.4,16.1,14.1c30.3-0.8,60.7-0.5,91.1-0.2c16.1,0.2,25.8,8.4,27,21.5
	c1.4,15.2-8.4,26.6-25.4,26.6c-21.3-0.1-18.1,3.6-18.2,20.2c-0.1,71.9,0,143.8,0,215.7c0,61.5-38.8,100.2-100.4,100.2
	c-40,0-79.9,0.1-119.9-0.1c-57.6-0.2-96.9-39.7-97-97.5C84,335,84,299,84,263.1z M132.7,261.4c0,37.9-0.2,75.9,0.1,113.8
	c0.2,27.2,17,44.3,44,44.5c43.9,0.3,87.9,0.3,131.8,0c27.3-0.2,43.9-17,44-44.4c0.2-76.3-0.1-152.6,0.3-228.9
	c0-10.3-3.1-14-13.7-13.9c-64.3,0.4-128.6,0.4-192.9,0c-10.5-0.1-13.9,3.5-13.7,13.9C132.9,184.7,132.7,223,132.7,261.4z M242.2,84
	c3.6,0,7.2,0.1,10.8,0c4.7-0.2,10.1,0.5,10.4-6.7c0.3-6.6-2.7-11.1-9.4-11.4c-7.9-0.4-16-0.5-23.8,0.3c-6.2,0.6-8.4,5.4-8.1,11.3
	c0.3,6.3,4.9,6.4,9.4,6.5C235,84,238.6,84,242.2,84z"/>
<Path fill={color} d="M221.7,277.4c0,24.3,0.1,48.6,0,73c-0.1,16.4-9.7,27.8-23.1,28.1c-14.2,0.3-24-10.9-24-28.1c-0.1-50.2-0.3-100.3,0.1-150.5
	c0.1-16.7,9.1-25.5,23.7-25.3c14,0.2,23.1,10,23.3,26.2C221.9,226.4,221.7,251.9,221.7,277.4z"/>
<Path fill={color} d="M263.6,276c0-24.7-0.1-49.5,0-74.2c0.1-17,9.2-27.1,23.6-27.2c15.2-0.1,23.4,9,23.5,26.7c0.2,50.6,0.2,101.2,0.3,151.8
	c0,14.8-9.5,25-23.1,25c-13.5,0.1-23.9-9.8-24.1-24.4C263.3,327.8,263.7,301.9,263.6,276C263.6,276,263.6,276,263.6,276z"/>
</Svg>
                    );
                }