import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<G id="thumb-up_1_">
		<Path fill={color} d="M10.1,299h84.6V45.3H10.1V299z M475.1,277.8L475.1,277.8l-0.1-42.2c0-4.2-2.1-10.6-4.2-14.8L407.4,70.7
			c-6.3-14.8-21.1-25.4-38-25.4H179.2c-23.3,0-42.3,19-42.3,42.3V299c0,12.7,4.2,23.3,12.7,29.6L289,468l23.3-21.1
			c4.3-6.4,8.5-14.8,8.5-23.3c0-2.1,0-4.2,0-6.3l-21.1-97.2h133.1C456.1,320.1,475.1,301.1,475.1,277.8
			C475.1,277.8,475.1,275.7,475.1,277.8z"/>
	</G>
</G>
</Svg>
                    );
                }