import { Icons } from 'assets/icons';
import { useHover } from 'hooks';
import { StoreContext } from 'providers/StoreProvider';
import { useContext } from 'react';
import { Pressable, View } from 'react-native';
import { colors } from 'styles';
import SText from './SText';

export default () => {

    const { notifications, setNotification } = useContext(StoreContext);
    const [hovered, hoverElt] = useHover();

    return (
        <Pressable ref={hoverElt} onPress={() => setNotification(!notifications)} >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <SText sm regular style={{ marginRight: 8 }}>{notifications ? '#on' : '#off'}</SText>
                <View style={[
                    hovered && { backgroundColor: '#d4d3d6' },
                    { width: 24, height: 24, borderWidth: 1, borderColor: colors.black, justifyContent: 'center', alignItems: 'center' }
                ]}>
                    {notifications ? (
                        <Icons.Check size={10} color={colors.black} />
                    ) : (
                        <Icons.Delete size={10} color={colors.black} />
                    )}
                </View>
            </View>
        </Pressable>
    )
}