import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 
>
    <Path fill={color} d="m441.237,202.885l-41.13,0l-117.772,0l-79.451,0l-64.777,0l-94.137,0c-21.938,0 -39.722,17.784 -39.722,39.729c0,21.938 17.784,39.722 39.722,39.722l94.137,0l64.778,0l79.451,0l0,-0.001l158.9,0c21.944,0 39.729,-17.784 39.729,-39.722c0,-21.937 -17.784,-39.728 -39.728,-39.728z"/>
    <Path fill={color} d="m441.237,43.55814l-41.13,0l-117.772,0l-79.451,0l-64.777,0l-94.137,0c-21.938,0 -39.722,17.784 -39.722,39.729c0,21.938 17.784,39.722 39.722,39.722l94.137,0l64.778,0l79.451,0l0,-0.001l158.9,0c21.944,0 39.729,-17.784 39.729,-39.722c0,-21.937 -17.784,-39.728 -39.728,-39.728z"/>
    <Path fill={color} d="m441.13069,361.55607l-41.13,0l-117.772,0l-79.451,0l-64.777,0l-94.137,0c-21.938,0 -39.722,17.784 -39.722,39.729c0,21.938 17.784,39.722 39.722,39.722l94.137,0l64.778,0l79.451,0l0,-0.001l158.9,0c21.944,0 39.729,-17.784 39.729,-39.722c0,-21.937 -17.784,-39.728 -39.728,-39.728z"/>
</Svg>
                    );
                }