export default {
    //greetings
    '#greeting_morning': 'Guten morgen',
    '#greeting_afternoon': 'Guten tag',
    '#greeting_evening': 'Guten abend',
    '#welcome': 'Willkommen in der Online-Akte Ihres Finanzdienstleisters.',

    //login
    '#login_advisor': 'Büro',
    '#login_advisor_code': 'Büro code',
    '#login_username': 'Nutzername',
    '#login_password': 'Passwort',
    '#login_login': 'Einloggen',
    '#login_forgotpassword': 'passwort vergessen',
    '#login_back': 'zurück zur Einloggen',
    '#login_renewpassword': 'Fordere ein neues Passwort an',
    '#login_recoverysent': 'Eine E-Mail wurde gesendet. Klick auf den Link in der E-Mail, um dein Passwort zurückzusetzen.',
    '#login_recoverysent_u': 'Eine E-Mail wurde gesendet. Klick auf den Link in der E-Mail, um dein Passwort zurückzusetzen.',

    '#login_error_username': 'Benutzername darf nicht leer sein',
    '#login_error_password': 'Passwort darf nicht leer sein',
    '#login_error_token': 'Diesen Link ist abgelaufen',
    '#login_error_tenant': 'Berater darf nicht leer sein',
    '#login_error': 'Beim Einloggen ist ein Fehler aufgetreten',
    '#login_error_notfound': 'Die Zugangsdaten sind unbekannt',
    '#login_error_noauth': 'Benutzername oder Passwort ist falsch',
    '#login_error_retry': 'Etwas ist schief gelaufen. Bitte versuche es erneut',

    '#login_2FA': 'Authentifizierungscode',
    '#login_authcode_sent': 'Eine E-Mail mit einem Authentifizierungscode wurde gesendet. Du kannst den Code unten eingeben.',
    '#login_authcode_sent_u': 'Eine E-Mail mit einem Authentifizierungscode wurde gesendet. Sie können den Code unten eingeben.',
    '#login_error_authcode': 'Authentifizierungscode darf nicht leer sein',

    //brondata
    '#brondata_collect': 'Sammle alle deine daten',
    '#brondata_collect_u': 'Sammeln Sie alle Ihre Daten',
    '#brondata_description': 'Für deine erstes Gespräch mit deinem Berater benötigen wir einige Informationen von du. Diese kannst du bequem über unser Inventarportal abholen. Je nach gewählter Option dauert dies etwa 5 bis 20 Minuten.',
    '#brondata_description_u': 'Für Ihr erstes Gespräch mit Ihrem Berater benötigen wir einige Informationen von Ihnen. Diese können Sie bequem über unser Inventarportal abholen. Je nach gewählter Option dauert dies etwa 5 bis 20 Minuten.',
    '#brondata_description_short': 'Für deine erstes Gespräch mit deinem Berater benötigen wir einige Informationen von du. Diese kannst du bequem über unser Inventarportal abholen.',
    '#brondata_description_short_u': 'Für Ihr erstes Gespräch mit Ihrem Berater benötigen wir einige Informationen von Ihnen. Diese können Sie bequem über unser Inventarportal abholen.',
    '#brondata_action': 'inventar starten',

    //numeric overview
    '#possession': 'Besitz',
    '#mortgage': 'Hypothek',
    '#income': 'Einkommen',
    '#pension': 'Ruhestand',
    '#debt': 'Schuld',
    '#obligation': 'Verpflichtungen',

    //mortgage overview
    '#redeemed': 'Erlösung',
    '#interest': 'Interesse',
    '#premium': 'Prämie',

    //page titles
    '#title_number': 'Meine zahlen',
    '#title_mortgage': 'Meine hypotheken',
    '#title_policy': 'Meine versicherung',
    '#title_income': 'Mein einkommen',
    '#title_obligations': 'Meine verpflichtungen',
    '#title_inbox': 'Meine nachrichten',
    '#title_tasks': 'Meine aufgaben',
    '#title_damages': 'Meine schadensmeldungen',
    '#title_appointments': 'Meine termine',
    '#title_documents': 'Meine dokumente',
    '#title_notes': 'Meine notizen',
    '#title_upload': 'Dokument hochladen',
    '#title_upload_multiple': 'Mehrere dokumente hochladen',
    '#title_message': 'Nachtricht',
    '#title_userdetails': 'Meine details',
    '#title_contact': 'Kontakt',
    '#title_settings': 'Meine einstellungen',
    '#title_avatar': 'Profilbild bearbeiten',
    '#title_send_message': 'Nachricht senden',
    '#title_reply_message': 'Nachricht beantworten',
    '#title_damage': 'Meldst du deinen Schaden sofort hier',
    '#title_damage_u': 'Melden Sie Ihren Schaden sofort hier',

    //undertitles
    '#undertitle_documents': 'Hier findst du alles, was du jemals verschickst haben. Hast du ein Dokument, das wir uns ansehen sollten? Dann kannst du es hier hochladen.',
    '#undertitle_mortgage': 'Hier findest du alle informationen rund um deine hypothek.',
    '#undertitle_policy': 'Hier findest du alle informationen rund um deine Versicherungen.',
    '#undertitle_income': 'Hier findest du alle informationen rund um deine einkommen.',
    '#undertitle_obligation': 'Hier findest du alle informationen rund um deine verpflichtungen.',
    '#undertitle_inbox': 'Hier findest du die gesamte Kommunikation, die mit deinem Berater stattgefunden hat.',
    '#undertitle_tasks': 'Hier findest du alle Aufgaben, die deine Berater für du eingerichtet hat.',
    '#undertitle_damages': 'Hier findest du alle Schadensmeldungen, die deinem Berater gemeldet wurden.',
    '#undertitle_appointments': 'Hier findest du alle Termine, die zwischen deinem und dem Berater vereinbart wurden',
    '#undertitle_create_appointment': 'Hier kannst du einen Termin mit deinem Berater vereinbaren. Wählst du ein Datum und einen Grund für den Termin aus, um die verfügbaren Zeiten zu erhalten.',

    '#undertitle_documents_u': 'Hier finden Sie alles, was Sie jemals verschickt haben. Haben Sie ein Dokument, das wir uns ansehen sollten? Dann können Sie es hier hochladen.',
    '#undertitle_mortgage_u': 'Hier finden sie alle informationen rund um Ihre hypothek.',
    '#undertitle_policy_u': 'Hier finden sie alle informationen rund um Ihre Versicherungen.',
    '#undertitle_income_u': 'Hier finden sie alle informationen rund um Ihre einkommen.',
    '#undertitle_obligation_u': 'Hier finden sie alle informationen rund um Ihre verpflichtungen.',
    '#undertitle_inbox_u': 'Hier finden Sie die gesamte Kommunikation, die mit Ihrem Berater stattgefunden hat.',
    '#undertitle_tasks_u': 'Hier finden Sie alle Aufgaben, die Ihr Berater für Sie eingerichtet hat.',
    '#undertitle_damages_u': 'Hier finden Sie alle Schadensmeldungen, die Ihrem Berater gemeldet wurden.',
    '#undertitle_appointments_u': 'Hier finden Sie alle Termine, die zwischen Ihnen und dem Berater vereinbart wurden',
    '#undertitle_create_appointment_u': 'Hier können Sie einen Termin mit Ihrem Berater vereinbaren. Wählen Sie ein Datum und einen Grund für den Termin aus, um die verfügbaren Zeiten zu erhalten.',

    //misc.
    '#hello': 'Hallo',
    '#on': 'Sind an',
    '#off': 'Sind aus',
    '#search': 'Suchen..',
    '#more': 'Mehr',
    '#total': 'GESAMT',
    '#total_small': 'Gesamt',
    '#total_small_mortgage': 'Gesamt hypotheken',
    '#afgelost': 'ABBEZAHLT',
    '#total_mortgage': 'Summe der hypotheken',
    '#hover': 'Klick auf den Kreis für weitere Details.',
    '#hover_u': 'Klicken Sie auf den Kreis für weitere Details.',
    '#description': 'Bezeichnung',
    '#add_note': 'Notiz hinzufügen',
    '#remove': 'löschen',
    '#language': 'Sprache',
    '#notifications': 'Benachrichtigungen',
    '#clear_cache': 'Cache leeren',
    '#all_messages': 'alle nachrichten',
    '#type_message_placeholder': 'Schreibst du deine Nachricht hier..',
    '#type_message_placeholder_u': 'Schreiben Sie ihre Nachricht hier..',
    '#service_line': 'Womit können wir Ihnen behilflich sein?',
    '#edit_line': 'Welche Änderungen machst du vornehmen?',
    '#edit_line_u': 'Welche Änderungen möchten Sie vornehmen?',
    '#next': 'Nächste',
    '#previous': 'Vorherige',
    '#optional': 'Optional',

    //documents
    '#upload': 'dokument hochladen',
    '#download': 'Herunterladen',
    '#open': 'open',
    '#sort': 'sortier',
    '#klanten': 'persönlich',
    '#polissen': 'versicherungen',
    '#hypotheken': 'hypotheken',
    '#krediet': 'einkommen',
    '#verplichtingen': 'verpflichtungen',
    '#bankzaken': 'banken',

    //inbox
    '#message_single': 'Nachricht',
    '#message_action_read': 'lesen',
    '#message_read': 'Gelesen Nachricht',
    '#message_new': 'Neue Nachricht',

    //openinghours
    '#opening_hours': 'Öffnungszeiten',
    '#closed': 'Geschlossen',
    '#monday': 'Montag',
    '#tuesday': 'Dienstag',
    '#wednesday': 'Mittwoch',
    '#thursday': 'Donnerstag',
    '#friday': 'Freitag',
    '#saturday': 'Samstag',
    '#sunday': 'Sonntag',
    '#hour': 'uhr',

    '#monday_short': 'Mo',
    '#tuesday_short': 'Di',
    '#wednesday_short': 'Mi',
    '#thursday_short': 'Do',
    '#friday_short': 'Fr',
    '#saturday_short': 'Sa',
    '#sunday_short': 'So',

    //uploads
    '#uploads_other': 'Andere hochladen',
    '#uploads_different_document': 'anderes dokument auswählen',
    '#uploads_cancel': 'abbrechen',
    '#uploads_confirm': 'bestätigen',
    '#uploads_description': 'Lädst du manuell eine Datei zu deinem Berater hoch',
    '#uploads_description_u': 'Laden Sie manuell eine Datei zu Ihrem Berater hoch',
    '#uploads_retry': 'erneut hochladen',
    '#uploads_error': 'Etwas ist schief gelaufen. Bitte versuche es erneut',

    //values and periods
    '#original_value': 'Ursprünglichen betrag',
    '#rest_value': 'Restbetrag',
    '#paid_value': 'Zurückgezahlter betrag',
    '#monthly_value': 'Monatliche betrag',
    '#per_month': 'pro monat',
    '#per_year': 'pro jahr',
    '#monthly': 'Monatliche',
    '#monthly_interest': 'Monatliche interesse',
    '#monthly_redeemed': 'Monatliche Erlösung',
    '#monthly_premium': 'Monatliche prämie',
    '#p/j': 'p/j',
    '#p/m': 'p/m',

    //actions
    '#action_view': 'ansehen',
    '#action_edit': 'bearbeiten',
    '#action_edit_long': 'Änderungen übermitteln  ➜',
    '#action_damage': 'Schaden melden',
    '#action_send_message': 'Nachricht senden',
    '#action_view_message': 'Nachricht ansehen',
    '#action_view_complete_message': 'vollständigen nachricht ansehen',
    '#action_upload': 'hochladen',
    '#action_add': 'hinzufügen',
    '#action_logout': 'Ausloggen',
    '#action_close': 'schließen',
    '#action_retry': 'wiederholen',
    '#action_react': 'antworten',
    '#action_date': 'datum auswählen',
    '#action_appointment_type': 'termingrund auswählen',
    '#action_appointment_type_resource': 'berater wählen',
    '#action_retrieve': 'abrufen',

    //menu items
    '#menu_overview': 'überblick',
    '#menu_mortgage': 'hypotheken',
    '#menu_policy': 'versicherungen',
    '#menu_living': 'leben',
    '#menu_income': 'einkommen',
    '#menu_obligation': 'verpflichtungen',
    '#menu_contact': 'kontakt',

    //genders and age
    '#gender_male': 'Mann',
    '#gender_female': 'Frau',
    '#unknown': 'Unbekannt',
    '#age': 'jahre',
    '#birth_on': 'Geb. am',
    '#birth_at': 'in',

    //family
    '#partner': 'Partner',
    '#children': 'Kinder',

    //uploading
    '#upload_pre': 'Um Ihren Hypothekenantrag abzuschließen, benötigen wir dokument:', //met 1 bestandsnaam
    '#upload_post': 'von Ihnen.',
    '#upload_pre_multi': 'Um unser Inventar zu vervollständigen, brauchen wir noch', //met het aantal bestanden
    '#upload_post_multi': 'dokumenten van Ihnen.',
    '#upload_empty': 'Wir benötigen derzeit keine Unterlagen von Ihnen.',

    //empty overview strings
    '#empty_numericOverview': 'Hier siehst du deine wichtigsten Kennzahlen auf einen Blick. Von Vermögenswerten bis zu Verbindlichkeiten, alles in einer praktischen Übersicht.',
    '#empty_mortgageOverview': 'In diesem Block kannst du schnell sehen, was an Hypotheken aussteht und was du monatlich zurückzahlst.',
    '#empty_policyOverview': 'In diesem Block siehst du sofort, wie viel du monatlich für Versicherungen ausgibt und woraus diese bestehen.',

    '#empty_numericOverview_u': 'Hier sehen Sie Ihre wichtigsten Kennzahlen auf einen Blick. Von Vermögenswerten bis zu Verbindlichkeiten, alles in einer praktischen Übersicht.',
    '#empty_mortgageOverview_u': 'In diesem Block können Sie schnell sehen, was an Hypotheken aussteht und was Sie monatlich zurückzahlen.',
    '#empty_policyOverview_u': 'In diesem Block sehen Sie sofort, wie viel Sie monatlich für Versicherungen ausgeben und woraus diese bestehen.',

    //empty pages string
    '#empty_screen_pre': 'Du hast noch keine',
    '#empty_screen_post': 'bei uns abgeschlossen.',
    '#empty_screen_pre_u': 'Sie haben noch keine',
    '#empty_screen_pre_unknown': 'Uns sind noch keine',
    '#empty_screen_post_unknown': 'bekannt.',

    '#empty_screen_mortgages': 'Hypotheken',
    '#empty_screen_policies': 'Versicherung',
    '#empty_screen_incomes': 'Einkommen',
    '#empty_screen_retirements': 'Ruhestand',
    '#empty_screen_obligations': 'Verpflichtungen',

    '#empty_screen_inbox': "Du hast noch keine Nachrichten von uns erhalten.",
    '#empty_screen_tasks': "Du hast noch keine Aufgaben von uns erhalten.",
    '#empty_screen_damages': "Du hast uns noch keinen Schaden gemeldet.",
    '#empty_screen_appointments': "Du hast noch keine Termine mit uns vereinbart.",

    '#empty_screen_inbox_u': "Sie haben noch keine Nachrichten von uns erhalten.",
    '#empty_screen_tasks_u': "Sie haben noch keine Aufgaben von uns erhalten.",
    '#empty_screen_damages_u': "Sie haben uns noch keinen Schaden gemeldet.",
    '#empty_screen_appointments_u': "Sie haben noch keine Termine mit uns vereinbart.",


    //income
    '#income_employment': 'Beschäftigung',
    '#income_company': 'Gesellschaft',
    '#income_benefit': 'Vorteile',
    '#income_retirement': 'Ruhestand',
    '#income_other': 'Andere einkommen',

    '#income_type': 'Einkommensart',
    '#start_date': 'Startdatum',
    '#end_date': 'Enddatum',
    '#company_name': 'Firmenname',
    '#company_employes': 'Hat das Unternehmen Angestellte?',
    '#company_start_date': 'Startdatum des Unternehmens',
    '#employment_name': 'Name des Arbeitgebers',
    '#employment_city': 'Standort',
    '#employment_start_service': 'Beschäftigung beginnen',
    '#employment_end_service': 'Beschäftigung beenden',
    '#employment_function': 'Position',
    '#employment_full-time': 'Vollzeitvertrag',
    '#employment_hours_per_week': 'Stunden pro Woche',

    '#income_gross_yearly': 'Bruttonutzen pro Jahr',
    '#income_holiday_bonus': 'Urlaubsgeld',
    '#income_net_profit': 'Nettogewinn',
    '#income_net_profit_total': 'Durchschnittlicher Nettogewinn der letzten 3 Jahre',
    '#income_gross_salery_yearly': 'Jahresbruttogehalt',
    '#income_13th_month': '13. Monat',
    '#income_end_of_year': 'Jahresendbonus',
    '#income_irregular': 'Unregelmäßigkeitsbeihilfe',
    '#income_overtime': 'Überstunden',
    '#income_provision': 'Rückstellung',

    '#retirement_agency': 'Altersvorsorgeagentur',
    '#retirement_aow_years': 'AOW Jahre anzusammeln',
    '#retirement_date': 'Erwartetes Rentendatum',
    '#ruhestandstyp': 'Typ',
    '#retirement_monthly_premium': 'Bruttorentenbetrag pro Monat',
    '#retirement_saved': 'Aufgelaufene Rente',
    '#retirement_goal': 'Zu erreichender Ruhestand',

    '#obligation_agency': 'Gesellschaft',
    '#obligation_bank_account': 'Kontonummer',
    '#obligation_hoofdsom': 'Auftraggeber',
    '#obligation_original_debt': 'Startbetrag',
    '#obligation_monthly_amount': 'Monatsbetrag',
    '#obligation_yearly_amount': 'Jahresbetrag',
    '#obligation_additional_redeemed': 'Zusätzlicher Rückzahlungsbetrag',
    '#obligation_current_balance': 'Aktueller Kontostand',
    '#obligation_current_balance_update': 'Kontostand pro',



    //obligations
    '#original_debt': 'Ursprünglich',
    '#rest_debt': 'Rest',
    '#monthly_obligation': 'Monatliche verpflichtung',
    '#Betaalrekening_met_roodstandmogelijkheid': 'Konto mit Überziehungskredit',
    '#Persoonlijke_lening': 'persönliches Darlehen',
    '#Doorlopend_krediet': 'Revolvierender Kredit',
    '#Studievoorschot': 'Studium voraus',
    '#Studieschuld': 'Studentische schulden',
    '#Alimentatie_ex-partner': 'Unterhalt ex-partner',

    //avatar
    '#pick_image': 'bild wählen',
    '#save': 'sparen',

    //message
    '#subject': 'Thema',
    '#message_to_advisor': 'Eine Nachricht an Ihren Berater..',
    '#message_sent': 'Die Nachricht wurde versendet.\nIhr Berater wird sich schnellstmöglich mit Ihnen in Verbindung setzen.',
    '#message_error': 'Die Nachricht wurde nicht korrekt gesendet.\nDu kannst es mit der Schaltfläche unten erneut versuchen.',
    '#message_error_u': 'Die Nachricht wurde nicht korrekt gesendet.\nSie können es mit der Schaltfläche unten erneut versuchen.',
    '#message_error_file_size': 'Die Nachricht wurde nicht korrekt gesendet.\nEines der angehängten Dokumente ist zu groß. Die Dokumente dürfen maximal 2 MB groß sein.\nSie können es mit der Schaltfläche unten erneut versuchen.',

    //damage modal
    '#damage_type_home': 'wohnen',
    '#damage_type_vehicle': 'fahrzeug',
    '#damage_type_liability': 'haftung',
    '#damage_type_travel': 'reisen',
    '#damage_type_theft': 'diebstahl',
    '#damage_type_other': 'andere',
    '#type_description': 'Welchen Schaden hast du bekommen?',
    '#type_description_u': 'Welchen Schaden haben Sie bekommen?',
    '#date_description': 'Wann ist der Schaden eingetreten?',
    '#cost_description': 'Wie hoch sind die (geschätzten) Kosten?',
    '#damage_date': 'Wann ist es passiert?',
    '#reason_description': 'Was ist passiert?',

    '#document_claim_form': 'Schadensform',
    '#document_purchase_invoice': 'Kaufrechnung',
    '#document_quote_repair_costs': 'Reparatur angebot',
    '#document_damage': 'Beschädigte ware',
    '#document_other': 'Andere',
    '#document_drivers_license': 'Kopie des Führerscheins',
    '#document_other_party': 'Kontrahentendaten',
    '#document_repairing_party': 'Reparaturdetails',
    '#document_account_number_payment': 'Zahlungskontonummer',

    '#title_add_images': 'Hast du Dokumenten? Dann kannst du es unter hinzufügen.',
    '#title_add_images_u': 'Haben Sie Dokumenten? Dann können Sie es unter hinzufügen.',

    '#address': 'Anschrift',
    '#primary_living': 'aktuelle wohnadresse',
    '#action_make_appointment': 'Termin vereinbaren',
    '#action_make_appointment_online': 'Einen Termin machen',
    '#action_get_contact': 'kontaktiere uns',
    '#preferred_date': 'Welches Datum bevorzugst du?',
    '#preferred_date_u': 'Welches Datum bevorzugen Sie?',
    '#preferred_appointment_type': 'Aus welchem Grund möchten du einen Termin vereinbaren?',
    '#preferred_appointment_type_u': 'Aus welchem Grund möchten Sie einen Termin vereinbaren?',
    '#my_appointments': "termine",
    '#cannot_create_appointment': 'Es kann kein Termin vereinbart werden. Bitte kontaktierst du deinen Berater, um einen Termin zu vereinbaren.',
    '#cannot_create_appointment_u': 'Es kann kein Termin vereinbart werden. Bitte kontaktieren Sie Ihren Berater, um einen Termin zu vereinbaren.',
    '#appointment_category': 'Kategorie auswählen',
    '#appointment_type': 'Termin auswählen',
    '#appointment_reason': 'Worum geht es bei dem Termin?',
    '#pakket': 'Bündel',
    '#advisor_judge': 'unter bewertung durch berater',

    '#undertitle_contact_1': 'Hast du Fragen oder Anmerkungen? Lasst du es uns wissen per ',
    '#undertitle_contact_1_u': 'Haben Sie Fragen oder Anmerkungen? Lassen Sie es uns wissen per ',
    '#undertitle_contact_2': ' oder ',
    '#undertitle_contact_3': '. Wenn du weitere ',
    '#undertitle_contact_3_u': '. Wenn Sie weitere ',
    '#undertitle_contact_4': 'informationen über unsere dienstleistungen lesen möchten, werfst du einen blick auf unsere ',
    '#undertitle_contact_4_u': 'informationen über unsere dienstleistungen lesen möchten, werfen Sie einen blick auf unsere ',
    '#route': 'Anfahrtsbeschreibung',
    '#save_password': 'passwort speichern#welcome',
    '#title_change_password': 'Hier kannst du dein Passwort ändern',
    '#title_change_password_u': 'Hier können Sie Ihr Passwort ändern',
    '#current_password': 'Jetziges passwort',
    '#new_password': 'Neues passwort',
    '#new_password_confirmation': 'Bestätige neues passwort',
    '#new_password_equal': 'Beide Passwörter müssen gleich sein',
    '#password_changed': 'Dein passwort wurde erfolgreich geändert.',
    '#password_changed_u': 'Ihr passwort wurde erfolgreich geändert.',
    '#password_saved': 'Dein passwort wurde erfolgreich gespeichert.',
    '#password_saved_u': 'Ihr passwort wurde erfolgreich gespeichert.',
    '#password_changeError': 'Beim ändern deines passworts ist etwas schief gelaufen.\nDu kannst es mit der schaltfläche unten erneut versuchen.',
    '#password_changeError_u': 'Beim ändern ihres passworts ist etwas schief gelaufen.\nSie können es mit der schaltfläche unten erneut versuchen.',
    '#password_min_length': 'Das passwort muss mindestens 8 zeichen lang sein',

    //nieuwe    
    '#income_now': 'Einkommen',
    '#income_later': 'Ruhestand',
    '#built_up_retirement': 'Rente angespart',
    '#currently_open': 'wir sind geöffnet',
    '#currently_closed': 'wir sind geschlossen',
    '#expires_on': 'Läuft aus am',
    '#yes': 'Ja',
    '#no': 'Nein',
    '#close': 'schließen',
    '#cost': 'Kosten',
    '#coverage': 'Deckung',
    '#data': 'Daten',
    '#insured_person': 'Versicherte person:',
    '#policy_number': 'Versicherungsnummer',
    '#advisor': 'berater',
    '#clear_cookies_1': 'Passiert das immer wieder? Versuchst du',
    '#clear_cookies_1_u': 'Passiert das immer wieder? Versuchen Sie',
    '#clear_cookies_2': 'dein Cookies löschen',
    '#clear_cookies_2_u': 'Ihre Cookies löschen',

    '#mortgage_number': 'Hypothekennummer',
    '#mortgage_parts': 'Hypothekenanteil',
    '#mortgage_inschrijving': 'Hypothekenregistrierung',
    '#mortgage_original_hoofdsom': 'Ursprünglicher Hypothekenbetrag',
    '#mortgage_rest_hoofdsom': 'Verbleibender Hypothekenbetrag',
    '#mortgage_verhogen_zonder_notaris': 'Platz zur Erhöhung der Hypothek ohne Notar',
    '#mortgage_vorm': 'Rückzahlungsformular',

    '#mortgage_interest_type': 'Zinsart',
    '#mortgage_interest_percentage': 'Zinsrate',
    '#mortgage_fixed_interest': 'fester zins',
    '#part': 'Teil',

    '#inventarisatie': 'Inventar',
    '#documenten_leveren': 'Dokumente hochladen',
    '#action_documenten_leveren': 'dokumente hochladen',
    '#rente_voorstel': 'Interessenvorschlag anfordern',
    '#beoordeling_geldverstrekker': 'Prüfung durch kreditgeber',
    '#beoordeling_notaris': 'Prüfung durch Notar',

    '#inventarisatie_short': 'Anfang',
    '#documenten_leveren_short': 'Unterlagen',
    '#rente_voorstel_short': 'Angebot',
    '#beoordeling_geldverstrekker_short': 'Kreditgeber',
    '#beoordeling_notaris_short': 'Notar',

    '#step': 'Schritt',
    '#of': 'von',

    '#title_living': 'Mein haus',
    '#koopsom': 'Kaufsumme',
    '#marktwaarde': 'Marktwert',
    '#wozwaarde': 'WOZ wert',
    '#bouwjaar': 'Baujahr',
    '#opp_woning': 'Fläche des hauses',
    '#opp_perceel': 'Grundstücksbereich',
    '#kale_huur': 'Kaltmiete',
    '#service_kosten': 'Servicekosten',
    '#other_kosten': 'Sonstige Kosten',

    '#documents': 'Dokumente',
    '#damage_amount': 'Schadensbetrag',
    '#damage': 'schaden',

    '#damage_soon': 'Möchtest du weitere Dokumente teilen? Bald wird es hier möglich sein. Es ist immer möglich, Dokumente zu teilen in Meine Dokumente',

    '#total_retirement_title': 'Total Gesamtrente',
    '#total_retirement_goal': 'Zu erreichende Gesamtrente',
    '#total_retirement_accumulated': 'Aufgelaufene Gesamtrente',
    '#build-up': 'AUFGELAUFENEN',

    '#house_build': 'Bauen',
    '#house_size': 'Oberflächen',
    '#house_energy': 'Energie',
    '#house_type': 'Haustyp',
    '#house_buildyear': 'Baujahr',
    '#house_function': 'Funktion',
    '#house_function_status': 'Funktionsstatus',
    '#house_rentable': 'Vermietbar',
    '#house_house': 'Haus',
    '#house_land': 'Grundstück',
    '#house_solar': 'Sonnenkollektoren',
    '#house_smartmeter': 'Intelligenter Zähler',
    '#house_energy_label': 'Energielabel',
    '#house_energy_label_end_date': 'Energielabel gültig bis',

    '#image': 'Bild',
    '#document': 'Dokument',

    '#action_hide_keyboard': 'Tastatur verstecken',

    '#sell': 'verkaufspläne',
    '#sustain': 'nachhaltigkeit',
    '#appraise': 'bewerten',

    '#title_living_sell': 'Pläne für ein Haus verkaufen',
    '#title_living_sustain': 'Ein Zuhause nachhaltiger gestalten',
    '#title_living_appraise': 'Haus bewerten',

    '#undertitle_living_sell': 'Du hast vor, Dir Haus zu verkaufen. Dein Berater prüft gerne mit dir die Möglichkeiten.',
    '#undertitle_living_sustain': 'Du hast Pläne, dein Zuhause nachhaltiger zu gestalten. Dein Berater prüft gerne mit dir die Möglichkeiten.',
    '#undertitle_living_appraise': 'Du Möchtest dir Haus schätzen lassen? Dein Berater prüft gerne mit dir die Möglichkeiten.',

    '#undertitle_living_sell_u': 'Sie haben vor, Ihr Haus zu verkaufen. Ihr Berater prüft gerne mit Ihnen die Möglichkeiten.',
    '#undertitle_living_sustain_u': 'Du hast Pläne, dein Zuhause nachhaltiger zu gestalten. Ihr Berater prüft gerne mit Ihnen die Möglichkeiten.',
    '#undertitle_living_appraise_u': 'Möchten Sie Ihr Haus schätzen lassen? Ihr Berater prüft gerne mit Ihnen die Möglichkeiten.',

    '#action_request': 'Anfrage',

    '#add_comment': 'Möchtest du weitere Informationen teilen? Bitte fügst du eine kurze Beschreibung hinzu',
    '#add_comment_sell': 'Möchtest du uns etwas über Deine Pläne mitteilen? So können wir uns gut auf das gespräch vorbereiten.',
    '#add_comment_sustain': 'Möchtest du uns etwas über Deine Pläne mitteilen? So können wir uns gut auf das gespräch vorbereiten.',
    '#add_comment_appraise': 'Möchtest du einige Informationen teilen? So können wir uns gut auf das gespräch vorbereiten.',

    '#add_comment_u': 'Möchten Sie weitere Informationen teilen? Bitte fügen Sie eine kurze Beschreibung hinzu',
    '#add_comment_sell_u': 'Möchten Sie uns etwas über Ihre Pläne mitteilen? So können wir uns gut auf das gespräch vorbereiten.',
    '#add_comment_sustain_u': 'Möchten Sie uns etwas über Ihre Pläne mitteilen? So können wir uns gut auf das gespräch vorbereiten.',
    '#add_comment_appraise_u': 'Möchten Sie ein paar Informationen teilen? So können wir uns gut auf das gespräch vorbereiten.',


    '#select_house_sell': 'Unten kannst du das Haus auswählen, das du verkaufen möchte',
    '#select_house_sustain': 'Unten kannst du das Haus auswählen, das du nachhaltiger machen möchte',
    '#select_house_appraise': 'Unten kannst du die Immobilie auswählen, die du bewerten möchte',
    '#selected_house_sell': 'Das Haus auswählen, das du verkaufen möchte',
    '#selected_house_sustain': 'Das Haus auswählen, das du nachhaltiger machen möchte',
    '#selected_house_appraise': 'Das Haus auswählen, die du bewerten möchte',

    '#select_house_sell_u': 'Unten können Sie das Haus auswählen, das Sie verkaufen möchten',
    '#select_house_sustain_u': 'Unten können Sie das Haus auswählen, das Sie nachhaltiger machen möchten',
    '#select_house_appraise_u': 'Unten können Sie die Immobilie auswählen, die Sie bewerten möchten',
    '#selected_house_sell_u': 'Das Haus auswählen, das Sie verkaufen möchten',
    '#selected_house_sustain_u': 'Das Haus auswählen, das Sie nachhaltiger machen möchten',
    '#selected_house_appraise_u': 'Das Haus auswählen, die Sie bewerten möchten',


    '#damage_report': 'Schadensbericht',
    '#shared_documents': 'Gelieferte Dokumente',

    '#input_fields_required': 'Nicht alle erforderlichen felder sind ausgefüllt.',
    '#input_fields_select_house': 'Vergisst du nicht, ein Haus auszuwählen?',
    '#input_fields_select_house_u': 'Vergessen Sie nicht, ein Haus auszuwählen?',

    '#mortgage_requested': 'Hypothek ausstehend',
    '#title_reset_password': 'Passwort zurücksetzen',
    '#title_save_password': 'Passwort festlegen',
    '#action_goto_login': 'zur Anmeldeseite',

    '#notifications_title': 'Benachrichtigungen zulassen',
    '#notifications_text': 'Möchtest du keine wichtigen Nachrichten verpassen? Dann kannst du hier die App-Einstellungen öffnen, um Benachrichtigungen zu aktivieren.',
    '#notifications_text_u': 'Mögen Sie keine wichtigen Nachrichten verpassen? Dann können Sie hier die App-Einstellungen öffnen, um Benachrichtigungen zu aktivieren.',
    '#cancel': 'Abbrechen',
    '#open_settings': 'Einstellungen öffnen',


    '#action_add_insurance': 'versicherung hinzufügen',
    '#title_add_insurance': 'Versicherungspolice hinzufügen',
    '#input_fields_document': 'Denkst du daran, ein Dokument hinzuzufügen?',
    '#input_fields_document_u': 'Denken Sie daran, ein Dokument hinzuzufügen?',

    '#add_insurance_policy': 'Hast du auch Versicherungen bei anderen Parteien abgeschlossen? Lädst du unten Deinen Police hoch. Diese wird dann mit Deinem Berater geteilt und die Versicherung wird später in dieser Übersicht angezeigt.',
    '#add_insurance_policy_u': 'Haben Sie auch Versicherungen bei anderen Parteien abgeschlossen? Laden Sie hier Ihren Policenplan hoch. Dies wird dann mit Ihrem Berater geteilt und die Versicherung wird später in dieser Übersicht angezeigt.',
    '#policies_tenant': 'über Ihren Berater',
    '#policies_other': 'woanders abgeschlossen',

    '#add_obligation_contract': 'Hast du auch Verpflichtungen, die Deinmen Berater noch nicht bekannt sind? Lädst du unten Deinen Vertrag hoch. Dieser wird dann mit Deinem Berater geteilt und die Verpflichtung wird später in dieser Übersicht angezeigt.',
    '#add_obligation_contract_u': 'Haben Sie auch Verpflichtungen, die Ihrem Berater noch nicht bekannt sind? Laden Sie unten Ihren Vertrag hoch. Dieser wird dann mit Ihrem Berater geteilt und die Verpflichtung wird später in dieser Übersicht angezeigt.',
    '#action_add_obligation': 'verpflichtung hinzufügen',
    '#title_add_obligation': 'Verpflicht hinzufügen',


    '#mortgage_verbouwen': 'umgestalten',
    '#mortgage_verhuizen': 'umziehen',
    '#mortgage_hypotheekcheck': 'Hypothekenscheck',
    '#mortgage_enrich': 'hypothek bearbeiten',
    '#mortgage_create': 'hypothek erstellen',

    '#title_mortgage_verhuizen': 'Umzug melden',
    '#title_mortgage_verbouwen': 'Renovation melden',
    '#title_mortgage_hypotheekcheck': 'Fordere einen Hypothekencheck an',

    '#undertitle_mortgage_verhuizen': 'Du hast vor, ein weiteres Haus zu kaufen. Hast du ein anderes Haus im Auge und möchten die Möglichkeiten eines Umzugs prüfen? Gerne bespricht dein Berater die Möglichkeiten bei einem Gespräch mit du.',
    '#undertitle_mortgage_verbouwen': 'Möchtest du renovieren und sind neugierig auf die Möglichkeiten zur Beantragung eines Bausparkontos, eines Darlehens oder einer Hypothekenänderung? Vielleicht kannst du sich die Renovierung selbst leisten. Dein Berater schaut sich das gerne mit dir an.',
    '#undertitle_mortgage_hypotheekcheck': 'Hast du bereits eine Hypothek, wollen aber wissen, ob es besser oder günstiger geht? Dein Berater schaut sich deine Hypothek gerne gemeinsam an, um festzustellen, ob du noch deinen Bedürfnissen entspricht.',

    '#undertitle_mortgage_verhuizen_u': 'Sie haben vor, ein weiteres Haus zu kaufen. Sie haben ein anderes Haus im Auge und möchten die Möglichkeiten eines Umzugs prüfen? Gerne bespricht Ihr Berater die Möglichkeiten mit Ihnen bei einem Gespräch.',
    '#undertitle_mortgage_verbouwen_u': 'Sie möchten renovieren und sind neugierig auf die Möglichkeiten zur Beantragung eines Bausparkontos, eines Darlehens oder einer Hypothekenänderung? Vielleicht können Sie sich die Renovierung selbst leisten. Ihr Berater schaut sich das gerne mit Ihnen an.',
    '#undertitle_mortgage_hypotheekcheck_u': 'Sie haben bereits eine Hypothek, wollen aber wissen, ob es besser oder günstiger geht? Ihr Berater schaut sich Ihre Hypothek gerne gemeinsam an, ob sie noch Ihren Bedürfnissen entspricht.',

    '#add_comment_mortgage': 'Kannst du einige Informationen teilen? So können wir uns gut auf das gespräch vorbereiten.',
    '#add_comment_mortgage_u': 'Könnten Sie einige Informationen teilen? So können wir uns gut auf das gespräch vorbereiten.',

    '#select_house_verhuizen': 'Unten kanst du ein Haus auswählen, für das ein Umzug geplant ist',
    '#select_house_verbouwen': 'Unten kannst du ein Haus auswählen, das Sie renovieren möchten',
    '#select_house_hypotheekcheck': 'Du kannst unten ein Haus auswählen',
    '#select_house_verhuizen_u': 'Unten können Sie ein Haus auswählen, für das ein Umzug geplant ist',
    '#select_house_verbouwen_u': 'Unten können Sie ein Haus auswählen, das Sie renovieren möchten',
    '#select_house_hypotheekcheck_u': 'Sie können unten ein Haus auswählen',

    '#selected_house_verbouwen': 'Das Haus, das du renovieren möchtest',
    '#selected_house_verbouwen_u': 'Das Haus, das Sie renovieren möchten',
    '#selected_house_verhuizen': 'Das Haus, für das ein Umzug geplant ist',
    '#selected_house_hypotheekcheck': 'Das ausgewählte Haus',

    '#founded_on': 'Gründungsdatum',

    '#footer_text': 'DossierData © ',
    '#footer_disclaimer1': 'Aus dieser Übersicht können keine Rechte abgeleitet werden.',
    '#footer_disclaimer2': 'Die Verwaltung des Versicherers und des Kreditgebers ist immer führend.',

    '#no_results_bookable_times': 'Für den Wunschtermin gibt es keine buchbaren Zeiten. Wählen Sie ein anderes Datum',
    '#send_appointment_error': 'Bei der Terminvereinbarung ist etwas schief gelaufen.',
    '#made_appointment': `Termin wurde erstellt. Den Termin findest du unter 'Meine Termine'.`,
    '#made_appointment_u': `Termin wurde erstellt. Den Termin finden Sie unter 'Meine Termine'.`,

    '#appointment_with': 'Termin mit:',
    '#appointment_on': 'Termin am:',
    '#appointment_description': 'Terminbeschreibung:',

    '#cancel_appointment_title': 'Termin stornieren',
    '#cancel_appointment_text': 'Möchten du den Termin wirklich stornieren?',
    '#cancel_appointment_text_u': 'Möchten Sie den Termin wirklich stornieren?',

    '#after_successful_login': 'Du hast sich erfolgreich angemeldet.\nDaten werden geladen...',
    '#after_successful_login_u': 'Sie haben sich erfolgreich angemeldet.\nDaten werden geladen...',

    '#loading_mortgage_data': 'Deine Hypothekendaten werden gerade geladen.\nBitte warten...',
    '#loading_mortgage_data_u': 'Ihre Hypothekendaten werden gerade geladen.\nBitte warten...',

    '#loading_policy_data': 'Deine Versicherungsdaten werden gerade geladen.\nBitte warten...',
    '#loading_policy_data_u': 'Ihre Versicherungsdaten werden gerade geladen.\nBitte warten...',

    '#recently_shared_documents': 'Geteilte Dokumente',

    '#living_address': 'Wohnadresse',

    '#install_update': 'Eine neue Version ist verfügbar, machst du das Kundenportal jetzt aktualisieren?',
    '#install_update_u': 'Eine neue Version ist verfügbar, möchten Sie das Kundenportal jetzt aktualisieren?',

    '#times_available': 'Zeit(en) erhältlich',

    '#select_account': 'Wählst du ein Konto aus',
    '#select_account_u': 'Wählen Sie ein Konto aus',
    '#login_other_account': 'Mit einem anderen Konto anmelden',

    '#change_avatar': 'Profilbild ändern',
    '#change_info': 'Details ändern',
    '#change_password': 'Passwort ändern',
    '#status': 'Status',

    '#action_remove': 'entfernen',

    '#removing_document': 'Du kannst Dokumente bis zu einer Stunde nach dem Hochladen entfernen.',
    '#removing_document_u': 'Sie können Dokumente bis zu einer Stunde nach dem Hochladen entfernen.',

    '#message_to_reply_to': 'Die Nachricht, auf die du antwortet',
    '#message_to_reply_to_u': 'Die Nachricht, auf die Sie antworten',

    '#action_read_more': 'mehr lesen',
}
