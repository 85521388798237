import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M149.452,209.444h-23.539v28.053h23.539c8.729,0,15.268-5.454,15.268-14.03
		C164.72,214.746,158.181,209.444,149.452,209.444z"/>
	<Path fill={color} d="M242.052,209.444h-18.856v64.994h18.856c20.578,0,32.58-14.803,32.58-32.421
		C274.632,223.626,263.409,209.444,242.052,209.444z"/>
	<Path fill={color} d="M399.019,1.136H86.194c-28.045,0-50.796,22.743-50.796,50.794v380.027c0,28.053,22.751,50.795,50.796,50.795h312.825
		c28.059,0,50.796-22.742,50.796-50.795V51.931C449.815,23.879,427.077,1.136,399.019,1.136z M152.412,256.981h-26.499v36.941
		h-22.132V189.96h48.63c22.598,0,34.914,15.277,34.914,33.507C187.325,241.55,174.857,256.981,152.412,256.981z M242.052,293.923
		h-40.988V189.96h40.988c32.58,0,55.178,20.731,55.178,52.057C297.23,273.345,274.632,293.923,242.052,293.923z M386.572,209.444
		h-51.438v21.978h50.345v19.479h-50.345v43.021h-22.131V189.96h73.568V209.444z"/>
</G>
</Svg>
                    );
                }