import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M54.945,421.203c-0.946-52.53,14.671-104.588,44.959-147.655L264.125,36.445C283.056,8.997,320.443,2.37,347.891,21.3
	c27.45,18.93,34.075,56.319,15.145,83.768l-13.724,19.877l23.189,16.091c20.822,14.671,26.028,43.538,11.831,64.363l-68.622,97.489
	c-6.626,9.465-19.878,11.831-29.814,4.732c-9.466-6.625-11.832-19.401-5.207-28.867l68.149-97.965
	c0.947-1.893,0.947-4.259-0.947-5.206l-23.189-16.091l-126.36,182.204c-29.815,43.541-72.881,76.195-122.573,93.704l0,0
	c-8.045,2.841-17.038-1.419-19.876-9.465C54.945,424.517,54.945,422.624,54.945,421.203z M379.6,432.089
	c-4.259,0-6.151-1.421-13.251-9.465c-8.52-9.465-20.825-23.663-44.959-23.663c-24.137,0-36.916,14.672-44.96,23.663
	c-7.099,8.518-8.993,9.465-13.252,9.465s-6.151-1.421-13.251-9.465c-8.519-9.465-20.824-23.663-44.959-23.663
	c-24.137,0-36.442,13.725-44.487,23.663c-8.045,8.044-8.045,21.771,0,29.814c8.045,8.047,21.296,8.047,29.815,0l0.474-0.474
	l0.946-0.945c7.099-8.52,8.991-9.465,13.251-9.465c4.259,0,6.151,1.419,13.25,9.465c8.519,9.466,20.824,23.663,44.96,23.663
	c24.137,0,36.915-14.672,44.959-23.663c7.099-8.52,8.991-9.465,13.253-9.465c4.259,0,6.151,1.419,13.25,9.465
	c8.518,9.466,20.823,23.663,44.96,23.663s36.913-14.672,44.959-23.663c8.047-8.52,7.573-21.771-0.947-29.816
	c-8.518-8.044-21.768-7.57-29.814,0.947c0,0-0.474,0-0.474,0.474C385.751,431.142,384.332,432.089,379.6,432.089L379.6,432.089z"/>
</Svg>
                    );
                }