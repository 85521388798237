import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M291.1,203.3c-29.3-29.3-58.9-58.9-88.3-88.3c-29.2,29.2-58.8,58.8-88.3,88.3c29.6,29.6,59.2,59.1,88.3,88.3
	C232.3,262,262,232.4,291.1,203.3z"/>
<Path fill={color} d="M283.3,400.9c-3.6,0-7.2,0-10.8,0c-0.3-1.1-0.5-1.9-0.7-2.6c-6.6-23.9-26.7-39.3-51.6-39.4c-28.1-0.1-56.3-0.1-84.4,0.1
	c-14.6,0.1-26.9,5.9-37,16.4c-6.9,7.1-11.2,15.7-13.4,25.6c-2.1,0-4.1,0-6,0c-2,0-4-0.1-6,0c-5.1,0.2-9.3,4.1-10,9.1
	c-0.7,5,2.4,9.8,7.4,11.2c1.9,0.5,3.9,0.6,5.9,0.6c66.8,0,133.5,0,200.3,0c2.2,0,4.4,0,6.7,0c6.3-0.2,10.8-4.6,10.9-10.5
	C294.5,405.4,289.8,401,283.3,400.9z"/>
<Path fill={color} d="M379.5,333.6c-21.4-21-42.8-42-64.3-62.9c-1.9-1.9-2.7-3.6-2.3-6.4c1-8.6-3.3-16.3-10.8-20.3c-7.3-3.8-17-3.1-22.9,2.5
	c-11,10.6-21.9,21.4-32.4,32.4c-5.9,6.1-6.5,16-2.4,23.5c4.1,7.4,12.1,11.5,20.5,10.3c2.4-0.3,3.9,0.3,5.5,1.9
	c15.4,15.2,30.8,30.3,46.2,45.4c7.2,7.1,14.5,14.2,21.4,20.9c15-15,29.7-29.7,44.7-44.7C381.7,335.6,380.5,334.6,379.5,333.6z"/>
<Path fill={color} d="M306.2,187.4c1,1,2,2,3.1,2.8c6.8,5,14.2,5.8,21.8,2.2c7.7-3.7,11.6-10.2,11.8-16.3c0.1-8.9-2.6-14-7.1-18.5
	c-20.5-20.4-40.9-40.9-61.4-61.4c-8.8-8.8-17.6-17.7-26.5-26.5c-7.2-7-17.2-8.4-25.4-3.6c-12.6,7.3-14.4,23.3-3.7,34
	C248,129.2,277.1,158.3,306.2,187.4z"/>
<Path fill={color} d="M158.2,336.6c0.5,0.5,1,1,1.5,1.4c6.9,5.6,14.6,6.8,22.6,3.1c7.9-3.6,11.8-10.2,12-16.3c0.1-9.3-2.9-14.4-7.4-19
	c-28.9-28.8-57.8-57.7-86.7-86.6c-1.1-1.1-2.2-2.1-3.4-3c-10.7-8.1-26-4.2-31.6,8c-3.8,8.3-2.2,17.3,4.6,24.1
	C99.3,277.7,128.7,307.1,158.2,336.6z"/>
<Path fill={color} d="M398.4,352.4c-14.9,14.9-29.7,29.7-44.6,44.6c4.7,5.1,9.5,10.7,14.7,15.8c11.8,11.7,30.6,12,42.9,1
	c12.5-11.1,14.3-30,3.8-43C409.8,364.3,403.7,358.3,398.4,352.4z"/>
</Svg>
                    );
                }