import { Button } from "components"
import { Modal, Pressable, StyleSheet, View } from "react-native"
import { colors } from "styles"

export default ({ close, onPick }) => {
    return (
        <Modal
            transparent
        >
            <Pressable
                style={scopedStyles.background}
                onPress={close}
            >
                <View style={scopedStyles.iosupload}>
                    <Button
                        type='outline'
                        label="#image"
                        style={{ marginBottom: 12 }}
                        onPress={() => onPick('image')}
                    />
                    <Button
                        type='outline'
                        label="#document"
                        onPress={() => onPick('document')}
                    />
                </View>
            </Pressable>
        </Modal>
    )
}

const scopedStyles = StyleSheet.create({
    background: {
        backgroundColor: 'rgba(0,0,0,0.2)',
        width: '100%',
        flex: 1,
        justifyContent: "flex-end"
    },
    iosupload: {
        position: 'absolute',
        backgroundColor: colors.background,
        bottom: 0,
        padding: 20,
        width: '100%',
        zIndex: 99
    }
})