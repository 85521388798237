import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M376.7,51C271.3,51,166.2,51,61,51c0,60.4,0,120.8,0,181.1c105.2,0,210.4,0,315.6,0C376.7,171.5,376.7,111.3,376.7,51z"/>
<Path fill={color} d="M116,305.2c2.8,0,4.8-0.7,6.9-2.8c34.5-33.9,90.4-33.8,124.9,0.1c1.9,1.9,3.8,2.7,6.5,2.7c39.4-0.1,78.8-0.1,118.2-0.1
	c1.3,0,2.7,0,4.1,0c0-17.1,0-33.7,0-50.6c-105.4,0-210.5,0-315.6,0c0,17,0,33.9,0,50.9c1.5-0.1,3-0.3,4.4-0.3
	C82.3,305.1,99.2,305.1,116,305.2z"/>
<Path fill={color} d="M251.8,356.2c-5.4-36.4-40.6-61.8-77.5-55.8c-30.6,4.9-54.4,31.3-56.3,62.1c-1.9,31.5,18.2,60.2,48.2,68.9
	c4,1.2,8.2,1.9,12.3,2.9c4.5,0,9,0,13.5,0c0.7-0.2,1.4-0.5,2.1-0.6C232.1,428.1,257.4,394.1,251.8,356.2z M185.1,400.5
	c-18.6-0.1-33.6-15.4-33.5-34.1c0.2-18.4,15.4-33.4,33.9-33.3c18.6,0.1,33.6,15.4,33.5,34.1C218.8,385.6,203.6,400.6,185.1,400.5z"
	/>
<Path fill={color} d="M93.9,350c1.2,0,3.1-0.8,3.4-1.7c2.3-6.7,4.2-13.5,6.4-20.7c-3.3,0-6.8,0-10.2,0c-10.8,0.1-21.7,0.2-32.5,0.3
	c0,7.5,0,15,0,22.5c1.4-0.1,2.7-0.3,4.1-0.3C74.7,350,84.3,350.1,93.9,350z"/>
<Path fill={color} d="M272.6,346.1c0.8,2.9,1.7,4,4.8,4c54.2-0.1,108.5-0.1,162.7-0.1c1.3,0,2.6-0.1,4-0.2c0-7.5,0-14.6,0-22
	c-59.4,0-118.4,0-177.4,0C268.8,334.1,271,340,272.6,346.1z"/>
<Path fill={color} d="M185.5,355.6c-6.2-0.1-11.5,5.1-11.4,11.3c0,6.1,5.1,11.1,11.2,11.2c6.1,0,11.2-5,11.3-11.1
	C196.6,360.9,191.6,355.8,185.5,355.6z"/>
</Svg>
                    );
                }