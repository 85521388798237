import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="arrow_down">
	<Path fill={color} d="M242.605,380.879c-8.694,0-17.388-3.319-24.016-9.946L9.989,162.332c-13.271-13.27-13.271-34.785,0-48.048
		c13.264-13.265,34.774-13.265,48.045,0l184.57,184.582L427.177,114.29c13.271-13.265,34.779-13.265,48.042,0
		c13.275,13.264,13.275,34.778,0,48.048l-208.597,208.6C259.99,377.565,251.297,380.879,242.605,380.879z"/>
</G>
</Svg>
                    );
                }