import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M477.802,228.685c7.756,10.332,5.667,24.995-4.665,32.755L280.822,405.464c-2.553,1.928-5.668,2.965-8.867,2.955
	c-8.076-0.029-14.605-6.6-14.576-14.68v-75.893c0,0-136.357-25.047-229.285,83.51c-3.036,3.389-7.443,5.219-11.983,4.98
	c-8.287-0.443-14.651-7.512-14.216-15.803c6.462-77.697,46.137-222.972,255.484-222.972V91.668
	c-0.059-3.376,1.054-6.668,3.147-9.315c5.009-6.337,14.208-7.417,20.547-2.407l192.064,144.073
	C474.906,225.346,476.478,226.917,477.802,228.685z"/>
</Svg>
                    );
                }