import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M441.16,54.51H46.052c-18.013,0-32.622,14.608-32.622,32.622V398.08c0,18.02,14.608,32.622,32.622,32.622H441.16
	c18.02,0,32.622-14.603,32.622-32.622V87.132C473.782,69.119,459.18,54.51,441.16,54.51z M384.023,200.253
	c-3.672,0-7.142-0.744-10.426-1.881l-61.18,75.114c2.862,4.807,4.61,10.35,4.61,16.341c0,17.743-14.379,32.131-32.122,32.131
	c-17.742,0-32.124-14.388-32.124-32.131c0-2.969,0.536-5.789,1.287-8.518l-53.833-38.509c-5.162,3.532-11.394,5.616-18.121,5.616
	c-2.898,0-5.658-0.509-8.328-1.228l-44.222,62.644c3.604,5.192,5.743,11.474,5.743,18.271c0,17.736-14.378,32.126-32.121,32.126
	s-32.125-14.39-32.125-32.126s14.382-32.124,32.125-32.124c2.9,0,5.657,0.511,8.331,1.23l44.219-62.646
	c-3.604-5.193-5.744-11.475-5.744-18.271c0-17.737,14.382-32.13,32.122-32.13c17.743,0,32.124,14.393,32.124,32.13
	c0,2.968-0.531,5.79-1.287,8.519l53.838,38.517c5.159-3.542,11.39-5.624,18.116-5.624c3.672,0,7.143,0.743,10.428,1.88
	l61.175-75.113c-2.858-4.801-4.605-10.344-4.605-16.342c0-17.731,14.381-32.125,32.12-32.125c17.746,0,32.124,14.394,32.124,32.125
	C416.147,185.872,401.769,200.253,384.023,200.253z"/>
</Svg>
                    );
                }