import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M441.16,54.511H46.052c-18.013,0-32.622,14.608-32.622,32.622V398.08c0,18.02,14.608,32.622,32.622,32.622H441.16
		c18.02,0,32.622-14.603,32.622-32.622V87.133C473.782,69.119,459.18,54.511,441.16,54.511z M451.756,398.08
		c0,5.841-4.754,10.595-10.596,10.595H46.052c-5.841,0-10.595-4.754-10.595-10.595V87.133c0-5.842,4.754-10.596,10.595-10.596
		H441.16c5.842,0,10.596,4.754,10.596,10.596V398.08z"/>
	<Path fill={color} d="M384.023,136.004c-17.739,0-32.12,14.393-32.12,32.124c0,5.995,1.747,11.542,4.605,16.342l-61.175,75.115
		c-3.285-1.135-6.756-1.881-10.428-1.881c-6.727,0-12.957,2.083-18.119,5.619l-53.835-38.513c0.752-2.729,1.287-5.549,1.287-8.517
		c0-17.737-14.381-32.131-32.124-32.131c-17.74,0-32.122,14.394-32.122,32.131c0,6.797,2.139,13.078,5.744,18.271l-44.223,62.644
		c-2.67-0.72-5.427-1.229-8.327-1.229c-17.743,0-32.125,14.388-32.125,32.125c0,17.736,14.382,32.123,32.125,32.123
		s32.121-14.387,32.121-32.123c0-6.798-2.138-13.079-5.743-18.271l44.222-62.645c2.67,0.719,5.427,1.229,8.328,1.229
		c6.729,0,12.961-2.085,18.121-5.621l53.833,38.513c-0.751,2.729-1.287,5.55-1.287,8.519c0,17.743,14.382,32.13,32.124,32.13
		c17.743,0,32.122-14.387,32.122-32.13c0-5.992-1.748-11.536-4.61-16.343l61.177-75.114c3.287,1.134,6.757,1.88,10.429,1.88
		c17.746,0,32.124-14.381,32.124-32.124C416.147,150.397,401.769,136.004,384.023,136.004z"/>
</G>
</Svg>
                    );
                }