import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="arrow_left">
	<Path fill={color} d="M104.359,242.533c0-8.693,3.32-17.384,9.944-24.012L322.868,9.957c13.268-13.268,34.779-13.268,48.041,0
		c13.262,13.261,13.262,34.768,0,48.037L186.358,242.533l184.544,184.541c13.262,13.269,13.262,34.773,0,48.034
		c-13.262,13.273-34.771,13.273-48.039,0L114.297,266.547C107.673,259.917,104.359,251.225,104.359,242.533z"/>
</G>
</Svg>
                    );
                }