import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M88,458.8c-17.1,0-30.9-13.8-30.9-30.9V127.1C57.1,110,71,96.2,88,96.2S119,110,119,127.1v300.8
		C119,445,105.1,458.8,88,458.8z"/>
</G>
<G>
	<Path fill={color} d="M242.6,458.8c-17.1,0-30.9-13.8-30.9-30.9V188.6c0-17.1,13.8-30.9,30.9-30.9s30.9,13.8,30.9,30.9v239.3
		C273.5,445,259.7,458.8,242.6,458.8z"/>
</G>
<G>
	<Path fill={color} d="M397.2,458.8c-17.1,0-30.9-13.8-30.9-30.9V57.3c0-17.1,13.8-30.9,30.9-30.9c17.1,0,30.9,13.8,30.9,30.9v370.6
		C428.1,445,414.2,458.8,397.2,458.8z"/>
</G>
</Svg>
                    );
                }