import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M435.5,165.9c-8-14.4-20.6-23.7-34.6-31.4c-37.7-20.8-75.2-41.9-113.2-62.2c-28.4-15.1-57.6-16.6-86.5-1
	c-40.4,21.9-80.6,44-120.8,66.2c-19.5,10.7-31.1,27.8-36,49c-2.3,10-2,20.6-2,31c-0.2,45-0.1,90-0.1,134.9c0,2.8,0.2,5.7,0.7,8.5
	c2.3,12.5,4.2,25.2,12.3,35.5c14.1,17.9,32,28.6,55.5,28.5c10.7,0,21.3-0.1,32-0.1c32,0,64,0,96.1,0c32,0,64,0.1,96.1,0
	c10.7,0,21.3,0,32-0.1c6.8,0,13.6-0.7,20.3-2.1c33.2-6.7,55.6-31.8,55.8-63.8c0.3-55.9-0.3-111.9-0.5-167.8
	C442.4,182.1,439.9,173.8,435.5,165.9z M333.6,380.7c-0.6,4.3-3,7-7.4,7.6c-1.8,0.3-3.7,0.3-5.5,0.3c-52,0-104.1,0-156.1,0
	c-2,0-4,0-5.9-0.4c-4.5-0.8-7.1-4.2-7.1-8.8c0-4.6,2.6-7.9,7.1-8.8c1.8-0.3,3.7-0.4,5.5-0.4c26.3,0,52.6,0,78.9,0
	c26.6,0,53.2,0,79.8,0C330.5,370.2,334.5,374.3,333.6,380.7z M151.6,270c0-4.6,2.6-7.9,7.1-8.8c1.8-0.3,3.7-0.3,5.5-0.4
	c26.3,0,52.6,0,78.9,0c26.6,0,53.2,0,79.8,0c7.8,0,11.8,4.3,10.6,10.9c-0.7,3.9-3,6.4-6.9,7.1c-1.8,0.3-3.7,0.3-5.5,0.3
	c-52.3,0-104.6,0-157,0c-1.8,0-3.7,0-5.5-0.4C154.1,278,151.6,274.6,151.6,270z M321.2,333.9c-26.2,0-52.3,0-78.5,0
	c-26.7,0-53.5,0-80.2,0c-7.7,0-11.7-4-10.8-10.4c0.6-4.3,3-7,7.3-7.7c1.7-0.3,3.4-0.3,5.1-0.3c52.3,0,104.6,0,157,0
	c8.7,0,12.6,2.8,12.6,9.1C333.8,331,329.8,333.9,321.2,333.9z M406.5,355.5c0,14.1-11.7,27.9-25.5,31c-3.4,0.8-6.9,1.4-10.9,2.2
	c0-2.6,0-4.3,0-6c0-37.3,0.2-74.6-0.2-111.9c-0.1-7.4-1.4-15.3-4.2-22c-6.1-14.3-18-22.2-33.5-22.3c-55.6-0.4-111.3-0.3-166.9-0.1
	c-7,0-14.2,1-21,2.8c-4.9,1.3-10.1,3.8-13.7,7.2c-9.3,8.8-15.4,19.5-15.4,33c0.1,37.9,0,75.7,0,113.6c0,1.7,0,3.3,0,5.2
	c-11.5,1.1-19.8-4.1-27.1-11.5c-6.9-6.9-9.2-15.9-9.2-25c-0.3-48.4-0.1-96.8,0-145.2c0-4.8,0.2-9.7,1.2-14.4
	c2-9.8,7.9-16.8,16.7-21.5c20.6-11.2,41.2-22.5,61.7-33.8c20.8-11.5,41.3-23.5,62.2-34.6c14.5-7.7,29.8-7.7,44.2,0
	c25.1,13.4,49.7,27.6,74.5,41.4c13.7,7.6,27.3,15.3,41.2,22.4c11.4,5.9,19.6,14.5,23.8,26.6c1.3,3.8,2.1,8,2.1,12.1
	C406.6,255,406.6,305.3,406.5,355.5z"/>
</Svg>
                    );
                }