import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M377.5,111.7c-21.7-18-46.6-25.7-74.7-20.9c-23.6,4.1-42.1,17-57.3,35.1c-0.9,1.1-1.8,2.2-2.5,3c-5.3-5.4-10-10.9-15.4-15.7
	c-20.7-18.5-45-27-72.7-22.9c-28.7,4.3-51,19.4-66.9,43.4c-20.3,30.8-23,64.3-13.5,99.3c0.6,2.2,1.8,2.4,3.7,2.4
	c15.6,0,31.1-0.1,46.7,0c2.1,0,3.2-0.7,4.1-2.6c5.5-11.2,11.2-22.3,16.7-33.6c2.1-4.3,5-7.3,10-7.4c5,0,7.9,3,10,7.3
	c10.5,21.2,21.1,42.4,31.7,63.5c0.5,1,1.1,2,1.8,3.3c0.8-1.5,1.4-2.6,1.9-3.7c10.6-21.2,21.2-42.3,31.7-63.5c2.1-4.2,5-7,9.9-7
	c4.8,0,7.8,2.9,9.8,7.1c5.5,11.1,11.1,22.2,16.5,33.3c1.1,2.4,2.5,3.2,5.1,3.2c22-0.1,44-0.1,66-0.1c6.9,0,11.5,4.3,11.4,10.2
	c-0.1,5.9-4.7,10-11.3,10c-24.8,0-49.7,0-74.5,0c-5.2,0-8.8-2.1-11-6.8c-3.5-7.2-7.1-14.3-10.7-21.5c-0.4-0.7-0.9-1.4-1.5-2.3
	c-0.8,1.5-1.4,2.6-1.9,3.7c-10.5,21-21,41.9-31.4,62.9c-2.1,4.3-5,7.4-10,7.4c-5,0-7.9-3-10-7.3c-10.5-21.2-21.1-42.4-31.7-63.5
	c-0.5-1-1.1-2.1-1.8-3.5c-4.2,8.4-8.2,16.2-12,24.1c-2.3,4.7-5.9,6.9-11,6.8c-9.3-0.1-18.5,0-27.8,0c-7.1,0-14.2,0-21.9,0
	c0.7,1.7,1.2,2.8,1.7,3.9c14.5,30.1,35.8,54.9,60.4,77c28,25.1,59.4,45.5,92.3,63.6c3.8,2.1,7.5,1.8,11.2-0.2
	c28.5-15.7,55.8-33.2,80.9-54c24.5-20.3,46.3-43,62.7-70.5c16.6-27.7,26.4-57.3,23.3-90.1C412.6,155.9,400.5,130.8,377.5,111.7z"/>
</Svg>
                    );
                }