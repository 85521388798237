import { Icons } from 'assets/icons';
import { BaseItem, BottomNavBar, Button, ContentScroller, ObligationPie, SText } from "components";
import { AddObligationModal, EditModal, ObligationModal } from 'components/Modals';
import { QueryKeys, Screen } from 'enums';
import { useLayoutMargin, useSorted, useStyle } from "hooks";
import { Obligation } from "models";
import { ModalContext } from 'providers/ModalProvider';
import React, { useContext, useEffect, useState } from "react";
import { Dimensions, Image, Platform, Pressable, View } from 'react-native';
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { Content } from 'screens/home';
import { colors } from "styles";
import { QueryUtils, StringUtils, ValutaUtils } from "utils";

const ObligationIcons = {
    '#Betaalrekening_met_roodstandmogelijkheid': Icons.Bank_account,
    '#Persoonlijke_lening': Icons.Loan,
    '#Doorlopend_krediet': Icons.Credit,
    '#Studievoorschot': Icons.Study_deposit,
    '#Studieschuld': Icons.Student_debt,
    '#Alimentatie_ex-partner': Icons.Child_support,
}

export default () => {

    const largeScreen = Dimensions.get('window').width > 768;
    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { data: obligations, isLoading: loadingObligations } = useQuery(QueryKeys.OBLIGATION, QueryUtils.fetchObligation);
    const { data: sortedObligations, handleSearchQuery, setData } = useSorted();
    const { showModal } = useContext(ModalContext);
    const [typedObligations, setTypedObligations] = useState<Record<string, Obligation[]>>({});
    const [pieSize, setPieSize] = useState(200);
    const total = obligations ? obligations.reduce((acc, child) => acc + child.monthly_amount, 0) : [];
    const margin = useLayoutMargin();

    useEffect(() => {
        !loadingObligations && setData(obligations);
    }, [obligations])

    useEffect(() => {
        const typedObligations: Record<string, Obligation[]> = {};
        sortedObligations?.map((obligation: Obligation) => {
            const description = StringUtils.replaceAll('#' + obligation.type_description, ' ', '_');
            if (!typedObligations[description]) typedObligations[description] = [];
            typedObligations[description].push(obligation)
        })
        setTypedObligations(typedObligations);

    }, [sortedObligations])








    const renderObligationsPie = () => {
        return (
            <>
                {(largeScreen) &&
                    <View style={{
                        width: 150,
                        top: '4%',
                        alignSelf: 'flex-end',
                        marginRight: 300
                    }}>
                        <SText regular sm black>#hover</SText>
                        <Image
                            style={{
                                position: 'absolute',
                                width: 50,
                                height: 50,
                                marginTop: '15%',
                                marginLeft: '80%'
                            }}
                            source={require('assets/images/arrow.png')}
                        />
                    </View>
                }

                <View
                    style={[scopedStyles('pieChart'), { width: pieSize }]}
                    onLayout={(e) => setPieSize(e.nativeEvent.layout.height)}
                >
                    <ObligationPie obligations={typedObligations} dark opklik style={{ width: '100%', height: '100%' }} />
                </View>
            </>
        )
    }






    const renderObligationItems = () => {

        if (!typedObligations || obligations?.length == 0) {
            return (
                <View style={[{ height: '100%', flex: 1, justifyContent: "center", alignItems: "center", flexDirection: 'row' }, styles('bigMarginBottom'), styles('bigMarginRight')]}>
                    <SText white md medium center>#empty_screen_pre_unknown #empty_screen_obligations #empty_screen_post_unknown</SText>
                </View>
            )
        }

        const res = Object.entries(typedObligations).map((entry, index) => {
            const [title, children] = entry;

            return children.map((child: Obligation) => {

                const original = child.original_debt ? child.original_debt : child.principal_amount ? child.principal_amount : child.credit_limit ? child.credit_limit : 0;
                const rest = child.current_balance ? child.current_balance : 0;
                const Settings = {
                    title: title,
                    obligation: child,
                    icon: ObligationIcons[title],
                    total: child.monthly_amount
                }
                const showDetails = () => showModal(ObligationModal, Settings, 'ObligationModal')

                return (
                    <Pressable
                        key={child.id}
                        onPress={showDetails}
                        style={[Platform.OS == 'ios' && { marginBottom: margin }]}

                    >
                        <BaseItem
                            key={`obligation_${child.id}`}
                            buttonValue={ValutaUtils.valutaString(child.monthly_amount) + ' #p/m'}
                            buttonAction={showDetails}
                        >

                            <View style={{
                                flex: 0.7,
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 20
                            }}>
                                <Settings.icon size={80} color={colors.primary} />
                                <SText bold md primary center style={{ marginTop: 8, marginBottom: 20 }}>{title}</SText>
                            </View>

                            <View style={[styles('highlight'), { flex: 0.3 }]}>

                                <View style={[styles('line'), { marginBottom: 4 }]}>
                                    <SText sm regular grey>#original_debt</SText>
                                    <SText sm regular grey>{ValutaUtils.valutaString(original)}</SText>
                                </View>

                                <View style={styles('line')}>
                                    <SText sm regular grey>#rest_debt</SText>
                                    <SText sm regular grey>{ValutaUtils.valutaString(rest)}</SText>
                                </View>

                            </View>

                        </BaseItem>
                    </Pressable>
                );


            });

        }).flat();

        return res;

    }






    return (
        <Content title="#title_obligations">
            <View style={styles('fullscreen')}>

                <SText sm regular black style={scopedStyles('underTitle')}>#undertitle_obligation</SText>

                <View style={[scopedStyles('buttonContainer'), !largeScreen && styles('bigMarginBottom')]}>
                    <View style={{ flexDirection: 'row', marginRight: 8 }}>
                        <Button
                            label="#action_edit_long"
                            type='action'
                            onPress={() => showModal(EditModal, { screen: Screen.VERPLICHTINGEN }, 'EditModal')}
                        />
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Button
                            label="#action_add_obligation"
                            type='secondary'
                            onPress={() => showModal(AddObligationModal, {}, 'AddObligationModal')}
                        />
                    </View>
                </View>

                {total > 0 && renderObligationsPie()}

            </View>

            <BottomNavBar handleSearchQuery={handleSearchQuery} noSearch={obligations?.length == 0} />

            <View style={{ width: '100%', flex: 1 }}>

                <ContentScroller>
                    {renderObligationItems()}
                </ContentScroller>

            </View>
        </Content>
    )
}






const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        obligationInfoContainer: {
            flex: 1,
            width: '47%',
            flexShrink: 0,
            backgroundColor: colors.white,
            marginTop: 20,
            padding: 20,
            borderRadius: 12,
            justifyContent: "space-evenly"
        },
        bottomNav: {
            backgroundColor: colors.blackTransparent,
            width: '100%',
            height: 60
        },
        pieChart: {
            position: 'absolute',
            height: '110%',
            right: -20,
            marginTop: '-2%'
        },
        underTitle: {},
        buttonContainer: {
            flexDirection: 'row',
            position: "absolute",
            bottom: 0
        },
    }, {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        pieChart: {
            position: 'absolute',
            width: Dimensions.get('window').height * 0.35,
            height: Dimensions.get('window').height * 0.35,
            left: (Dimensions.get('window').width * 0.5) - (Dimensions.get('window').height * 0.2),
            top: Dimensions.get('window').height * 0.05
        },
        obligationInfoContainer: {
            width: '100%',
            marginTop: 0,
            paddingHorizontal: 10,
            flex: 1
        },
        underTitle: {
            flex: 1
        },
        buttonContainer: {
            width: '100%',
            justifyContent: 'space-between'
        }
    }
}
)
