import { useNavigation } from "@react-navigation/native";
import { Icons } from "assets/icons";
import { SText, Button, CloseButton } from "components"
import { useLayoutMargin, useStyle } from "hooks";
import { useState } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { colors } from "styles";


export default ({ moduleWidths }: { moduleWidths: Record<any, any> }) => {

    const styles = useStyle();
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const margin = useLayoutMargin();

    const [status, setStatus] = useState('large');
    const close = () => setStatus('small');

    return (
        <View style={{
            zIndex: 999,
            height: '100%',
            width: status == 'large' ? (width - moduleWidths.contact - (2 * margin)) : (moduleWidths.other - margin)
        }}>
            <View style={[scopedStyles.form, { backgroundColor: colors.action }]}>
                {status == 'large' &&
                    <View style={[scopedStyles.iconContainer, { backgroundColor: colors.actionDark }, status == 'large' && { width: moduleWidths.other - margin }]}>
                        <View style={scopedStyles.icon}>
                            <Icons.Data_search size={'100%'} color={''} />
                        </View>
                    </View>
                }
                <View style={[scopedStyles.text, status == 'large' ? styles('bigPaddingHorizontal') : { paddingHorizontal: 30 }]}>
                    <SText white lg semibold>#brondata_collect</SText>
                    <SText white sm regular style={{fontSize: status == 'large' ? 20 : undefined}}>{status == 'large' ? '#brondata_description' : '#brondata_description_short'}</SText>
                    <View style={{ flexDirection: 'row' }}>
                        <Button
                            label='#brondata_action'
                            type="primary"
                            onPress={() => navigation.navigate('portaal')}
                        />
                    </View>
                </View>
                {status == 'large' &&
                    <CloseButton onPress={close} style={{ top: 0, right: 0 }} />
                }
            </View>
        </View>
    )
}

const scopedStyles = StyleSheet.create({
    form: {
        flex: 1,
        borderRadius: 4,
        flexDirection: 'row',
        overflow: "hidden",
    },
    iconContainer: {
        flex: 0.25,
        width: '100%',
        height: '100%',
        justifyContent: "center",
        alignItems: "center"
    },
    icon: {
        width: '40%',
        height: '40%',
    },
    text: {
        flex: 1,
        height: '100%',
        paddingVertical: 20,
        justifyContent: "space-between"
    }
})