import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M480.5,235.4c-7.8-35.2-28.9-58.1-62.6-68.3c-5.4-1.6-8.6-3.7-10.1-10c-4.9-19.6-16.7-37.3-33.2-50
		c-16.3-12.5-36.2-19.4-56-19.4c-5.5,0-10.9,0.5-16.3,1.5c-0.3,0.1-0.7,0.1-1.1,0.1c-2.1,0-4.6-1-6.2-2.5
		c-22.3-21-48.1-33.1-76.9-36c-4.9-0.5-9.9-0.7-14.6-0.7c-32.3,0-61.4,11.4-86.5,33.9c-22.4,20.1-36.2,45.4-41.2,75.3
		c-0.5,2.8-1.3,6-5.8,7.1c-29.3,7.4-49.9,25.7-61.2,54.6c-2.1,5.3-3.4,11.1-4.7,16.7c-0.6,2.5-1.2,5.2-1.9,7.7v22.3
		c0.3,1.3,0.6,2.6,0.9,3.8c0.6,2.8,1.3,5.8,2,8.6c9.8,39.3,46,68.4,86,69.2c9.6,0.2,20,0.3,32.8,0.3c7.3,0,14.6,0,21.8-0.1
		c6.8,0,13.7,0,20.5-0.1c2.3,0,4-0.2,5.5-0.7c8.2-2.5,13.2-10.4,12-19.2c-1.1-8-8.5-14.3-17-14.3c-9.6,0-19.2,0-28.7,0l-16,0l-16,0
		l-9.7,0c-19.2,0-36.7-8.5-48-23.3c-11.3-14.8-14.8-34-9.7-52.6c7-25.5,25-39.5,53.6-41.6c9.1-0.7,15.5-7.4,16-16.8l0-0.8
		c0.3-6.4,0.6-13,2.1-19.2c4.7-19.2,12.9-35.2,24.6-47.5c11.9-12.6,27.5-21.3,46.3-26.1c8.6-2.2,17.2-3.3,25.3-3.3
		c26.9,0,50.7,11.6,70.9,34.6c4.8,5.5,9.6,8,15.3,8c2.4,0,5.1-0.5,7.9-1.5c5.9-2,11.8-3.1,17.7-3.1c3.8,0,7.6,0.4,11.3,1.3
		c28.4,6.5,46.4,28.7,47.2,57.9c0.2,8.8,6.8,15.8,15.7,16.5c0.7,0.1,1.3,0.1,2,0.2c3.4,0.3,7,0.5,10.4,1.3
		c27.3,5.7,45.2,30.5,43.5,60.2c-1.8,32.3-27,55.8-60,55.8c-6.2,0-12.5,0-18.7,0l-5.9,0l-5.9,0c-12.7,0-25.4,0-38.2,0
		c-2.1,0-3.9,0-5.6,0.5c-9,2.3-14.1,9.9-13,19.3c0.9,8,8.3,14.3,16.8,14.4c7.6,0,15.8,0.1,25.9,0.1c5.6,0,11.2,0,16.8,0
		c5.6,0,11.2,0,16.8,0c4.6,0,9.2,0,13.8,0l0.2,0c11.9,0,23.5-2.4,34.3-7.1c20.8-9,37-24,47-43.4
		C482.8,279.6,485.5,257.6,480.5,235.4z"/>
	<Path fill={color} d="M311.8,256.5c-14.1-14.5-30-30.9-46.2-46c-6.2-5.8-14.5-9-23.2-9c-8.7,0-16.8,3.2-23,8.9c-14.2,13.2-28.9,27.9-46.5,46.4
		c-4,4.2-5.4,9.9-3.8,15.6c1.7,6,6.4,10.5,12.5,12c1.5,0.4,2.9,0.6,4.3,0.6c4.6,0,8.7-2,12.7-6c5.2-5.2,10.4-10.5,15.9-16l7.5-7.6
		l3.2-3.3v4.6v6.4v20l0,131.3c0,0.5,0,0.9,0,1.4c0,1.3,0,2.5,0.1,3.7c0.6,8.9,8,15.9,16.9,15.9c1.4,0,2.8-0.2,4.2-0.5
		c8.3-2.1,12.9-8.7,12.9-18.4c0-44.2,0-73.4,0-109.6l0-43.5V258v-1.1l1-0.5l0.8-0.4l0.6-0.3l1.3-0.7l1,1.1c2.4,2.5,4.8,5,7.2,7.5
		c5.4,5.6,10.9,11.4,16.6,16.8c3.2,3,7.2,4.7,11.4,4.7c4.4,0,8.7-1.8,12-5.1C317.9,273.3,318.2,263,311.8,256.5z"/>
</G>
</Svg>
                    );
                }