import { SText } from "components";
import { useStyle } from "hooks";
import { View, Image, Dimensions, Pressable } from "react-native";

const splashScreen = require('assets/images/error500.jpg');

export const Error500 = ({ clearCookies }: { clearCookies: () => void }) => {

    const styles = useStyle();

    return (
        <View style={[styles('fullscreen'), styles('center')]}>
            <Image 
                source={splashScreen} 
                resizeMode={Dimensions.get('window').width < 768 ? 'contain' : 'cover'}
                style={{
                    width: '100%',
                    height: '100%',                    
                }} 
            />
            <View style={{ position: 'absolute', flexDirection: 'row', justifyContent: 'center', bottom: 40 }}>
                <SText sm regular black>#clear_cookies_1 </SText>
                <Pressable onPress={clearCookies}>
                    <SText sm regular primary url>#clear_cookies_2</SText>
                </Pressable>
                <SText sm regular black>.</SText>
            </View>
        </View>
    )

}