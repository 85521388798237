import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="email">
	<G>
		<Path fill={color} d="M47.291,169.631c6.129,4.306,24.564,17.137,55.321,38.445c30.757,21.316,54.326,37.714,70.7,49.239
			c1.799,1.259,5.62,3.996,11.462,8.239c5.842,4.227,10.698,7.635,14.567,10.252c3.868,2.604,8.541,5.532,14.041,8.756
			c5.477,3.24,10.651,5.659,15.506,7.299c4.856,1.609,9.361,2.419,13.484,2.419h0.271h0.271c4.138,0,8.641-0.81,13.495-2.419
			c4.855-1.624,10.029-4.059,15.507-7.299c5.491-3.224,10.156-6.152,14.041-8.756c3.853-2.617,8.708-6.025,14.566-10.252
			c5.841-4.243,9.663-6.966,11.462-8.239c16.54-11.525,58.632-40.746,126.274-87.685c13.135-9.178,24.104-20.242,32.923-33.193
			c8.803-12.943,13.213-26.522,13.213-40.739c0-11.876-4.283-22.041-12.816-30.494c-8.548-8.462-18.657-12.68-30.358-12.68H44.059
			c-13.85,0-24.516,4.68-31.967,14.025C4.626,75.911,0.885,87.604,0.885,101.637c0,11.327,4.951,23.609,14.853,36.831
			C25.625,151.688,36.147,162.085,47.291,169.631z"/>
		<Path fill={color} d="M457.426,198.509c-58.999,39.919-103.796,70.953-134.363,93.083c-10.251,7.561-18.577,13.436-24.961,17.67
			c-6.384,4.227-14.885,8.542-25.504,12.942c-10.603,4.427-20.504,6.623-29.67,6.623h-0.271h-0.271
			c-9.17,0-19.072-2.212-29.69-6.623c-10.61-4.4-19.111-8.716-25.495-12.942c-6.375-4.234-14.701-10.109-24.954-17.67
			c-24.285-17.814-68.98-48.842-134.1-93.083c-10.26-6.83-19.342-14.662-27.262-23.473V389.25c0,11.884,4.235,22.042,12.688,30.494
			c8.462,8.462,18.619,12.688,30.487,12.688h397.176c11.875,0,22.049-4.227,30.501-12.688c8.454-8.452,12.673-18.61,12.673-30.494
			V175.036C476.656,183.656,467.678,191.488,457.426,198.509z"/>
	</G>
</G>
</Svg>
                    );
                }