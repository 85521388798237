import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >

<Path fill="#FEDF7D" d="M5.8,454.9c0-84.6,0-169.3,0-253.9c4.7-16.8,16.3-24.2,33.3-24c20.7,0,41.5,0,62.2,0.1
	c16.9,0,33.8,0.1,50.7,0.1c0.1,1.1,0.9,1.4,1.8,1.7c8.9,3.5,14,10.1,15.9,19.4c1.2,5.9,2.8,11.6,4.4,17.4c1.8,6,4.8,11.2,10.3,14.8
	c4,2.6,8.1,4.3,13,3.9c7.6-0.5,15.3,1,22.9-0.8c1.8,0.3,3.6,0.8,5.4,0.8c26,0,52,0,78-0.1c2.5,2,5.3,3.8,7.2,6.5
	c-3.3,5.4-6.8,10.8-9.4,16.7c-3,5-6,10.1-9.1,15.1c-9-2.9-14.5-1.3-18.5,5.3c-19,33-38.3,65.8-57,98.9c-9.7,17-3.4,35.6,14.6,45.4
	c16.2,7,32.7,1.3,41.8-14.4c18.2-31.4,36.2-62.9,54.4-94.3c5.6-9.8,5-14.7-3.1-22.3c2.8-5,5.7-10.1,8.5-15.1
	c3.8-5.2,6.7-10.9,9.7-16.6c3.9,1,7.8,2.1,11.7,3.1c0,2.3-0.1,4.6-0.1,6.9c0,60.6,0,121.2,0,181.9c0,7.6-2.9,13.9-7.9,19.5
	c-4.4,4.9-10.1,7.1-16.1,9.1c-99.9,0-199.8,0-299.7,0c-3.7-1.3-7.7-2-11.1-4.2C11.9,470.9,7.8,463.6,5.8,454.9z"/>
<Path fill="#F6F5F6" d="M152,177.2c-16.9,0-33.8-0.1-50.7-0.1c-20.7,0-41.5-0.1-62.2-0.1c0-48.1-0.1-96.2,0.1-144.4
	c0-8.8,3.9-15.9,11-21.3c2.7-2.1,6.3-2.9,8.4-5.9c83.9,0,167.7,0,251.6,0c0.1,1,0.6,1.5,1.6,1.9c13.2,5.5,18.8,15.8,18.7,29.7
	c0,11.4,0,22.8,0,34.1C319.7,77.9,309.5,85,301,94.5c-27.2,30.4-33.7,75.8-15.8,112.4c4.9,10.1,11.7,18.7,18.6,27.4
	c-26,0-52,0.1-78,0.1c-1.8,0-3.6-0.5-5.4-0.8c-9.7-3.2-15.7-9.7-17.8-19.8c-1.2-5.8-2.7-11.6-4.4-17.4c-2.9-10.1-9.2-16.8-19.9-18.7
	C169.5,176.2,160.7,177.2,152,177.2z M193.1,55.5c33.1,0,66.3,0,99.4,0c7.3,0,10.9-2.3,10.9-7c0.1-4.8-3.4-7.1-10.8-7.1
	c-66.6,0-133.1,0-199.7,0c-1.5,0-3.1-0.1-4.6,0.1c-3.7,0.5-5.7,2.8-5.8,6.6c-0.2,4.9,2.8,7.4,9,7.4
	C125.3,55.5,159.2,55.5,193.1,55.5z M171.4,98.3c24.5,0,49,0,73.4,0c3.1,0,6.2,0.1,9.2-0.1c3.3-0.2,5.5-2,6.1-5.3
	c0.7-3.6-0.6-6.3-4-8c-1.6-0.8-3.3-0.7-5-0.7c-53.3,0-106.5,0-159.8,0c-1.2,0-2.5-0.1-3.7,0.3c-3.6,1.2-5.7,3.6-5.3,7.6
	c0.4,3.8,2.5,5.8,6.3,6.1c1.5,0.1,3.1,0.1,4.6,0.1C119.4,98.3,145.4,98.3,171.4,98.3z M164.2,141.6c24.6,0,49.3,0,73.9,0
	c4.9,0,8-2.8,8.1-7c0.2-4.1-2.9-7.2-7.6-7.6c-1.1-0.1-2.2,0-3.2,0c-47.6,0-95.2,0-142.7,0c-1.5,0-3.1-0.1-4.6,0.3
	c-3.7,0.9-6,3.4-5.9,7.3c0.1,3.8,2.6,6.2,6.3,7c1.3,0.3,2.8,0.1,4.1,0.1C116.5,141.7,140.4,141.7,164.2,141.6z"/>
<Path fill="#FECF64" d="M383.2,265.1c0,61.7,0,123.3,0,185c0,14.9-7.5,24.8-22,29.1c-0.4,0.1-0.8,0.5-1.1,0.8c-9.9,0-19.7,0-29.6,0
	c6-1.9,11.7-4.2,16.1-9.1c5.1-5.6,7.9-11.9,7.9-19.5c0-60.6,0-121.2,0-181.9c0-2.3,0.1-4.6,0.1-6.9c3.7,0.5,7.3,1.1,11,1.6
	C371.3,265.9,377.3,264.4,383.2,265.1z"/>
<Path fill="#ECEAEC" d="M339.7,5.5c0.1,2,1.8,2.3,3.1,2.9c10.9,5.6,15.9,14.8,16.2,26.7c0.2,8.9,0.1,17.8,0.1,26.7
	c-10,1.9-19.4,5.4-28.7,9.4c0-11.4,0-22.8,0-34.1c0-13.9-5.5-24.3-18.7-29.7c-1-0.4-1.5-0.9-1.6-1.9C320,5.5,329.8,5.5,339.7,5.5z"
	/>
<Path fill="#FEFEFE" d="M5.8,454.9c2,8.7,6,16,13.9,20.9c3.5,2.2,7.4,2.8,11.1,4.2c-7.6,0-15.1-0.1-22.7,0.1c-2,0-2.4-0.4-2.4-2.4
	C5.9,470.1,5.8,462.5,5.8,454.9z"/>
<Path fill="#A4CBFE" d="M330.5,71.3c9.3-4,18.7-7.6,28.7-9.4c1.9-0.1,3.6-1.2,5.6-0.7c2.5,1.6,5.5,1.5,8.3,2.2
	c19.5,5,36.2,14.8,50,29.7c16.6,17.8,25.8,38.8,27.5,62.9c1.5,21.2-3.4,41.2-14.5,59.5c-11.5,19-27.5,32.7-47.9,41.6
	c-7.3,3.2-15.2,4.4-22.6,7.1c-3.7-0.5-7.3-1.1-11-1.6c-3.9-1-7.8-2.1-11.7-3.1c-11.3-5-22.2-10.9-32-18.6c-1.9-2.7-4.7-4.5-7.2-6.5
	c-6.9-8.7-13.7-17.3-18.6-27.4c-17.9-36.6-11.4-82,15.8-112.4C309.5,85,319.7,77.9,330.5,71.3z M365.6,225c1.6,0.3,3.3,0.9,5,1
	c22.2,1.3,41-5.5,55.5-22.9c13.5-16.3,17.9-35,12.8-55.3c-6.6-26.2-24.1-41.8-50.2-47.6c-7.9-1.8-16-1.9-23.9,0.7
	c-15,2.7-27,10.4-36.5,22.2c-16.3,20.2-18,48.6-5.6,70.6C332.2,210.8,347,220.5,365.6,225z"/>
<Path fill="#655E68" d="M231.5,422.1c-17.9-9.7-24.2-28.3-14.6-45.4c18.8-33.1,38-66,57-98.9c1.2,2.1,3.5,3,5.4,4.1
	c5.4,3.2,11,6.2,16.3,9.6c7.7,5,9,12.3,4.3,20.3c-9.7,16.6-19.3,33.2-28.8,49.9c-8.8,15.3-17.7,30.6-26.3,46
	C241.5,413.8,236,417.4,231.5,422.1z"/>
<Path fill="#554F57" d="M231.5,422.1c4.5-4.7,10-8.4,13.3-14.3c8.6-15.5,17.5-30.7,26.3-46c9.6-16.7,19.1-33.3,28.8-49.9
	c4.7-8,3.5-15.3-4.3-20.3c-5.3-3.4-10.9-6.4-16.3-9.6c-1.9-1.2-4.2-2-5.4-4.1c4.1-6.6,9.5-8.1,18.5-5.3
	c10.5,6.5,21.2,12.8,32.1,18.5c8.1,7.7,8.7,12.5,3.1,22.3c-18.1,31.4-36.2,62.9-54.4,94.3C264.2,423.5,247.7,429.1,231.5,422.1z"/>
<Path fill="#FED064" d="M152,177.2c8.7,0,17.5-1,26.2,0.5c10.7,1.8,17,8.6,19.9,18.7c1.7,5.7,3.2,11.5,4.4,17.4
	c2.1,10.1,8.1,16.6,17.8,19.8c-7.6,1.8-15.3,0.3-22.9,0.8c-4.8,0.3-9-1.4-13-3.9c-5.5-3.5-8.5-8.8-10.3-14.8
	c-1.7-5.7-3.2-11.5-4.4-17.4c-1.9-9.3-7-15.9-15.9-19.4C152.9,178.6,152.1,178.3,152,177.2z"/>
<Path fill="#8B818E" d="M310.9,240.8c9.8,7.7,20.6,13.5,32,18.6c-3,5.7-6,11.4-9.7,16.6c-11.3-4.8-22-10.8-31.6-18.5
	C304.1,251.6,307.6,246.3,310.9,240.8z"/>
<Path fill="#A198A3" d="M301.6,257.5c9.6,7.8,20.3,13.7,31.6,18.5c-2.8,5-5.7,10.1-8.5,15.1c-11-5.7-21.6-12-32.1-18.5
	C295.5,267.6,298.6,262.5,301.6,257.5z"/>
<Path fill="#A198A3" d="M193.1,55.5c-33.9,0-67.8,0-101.7,0c-6.1,0-9.2-2.5-9-7.4c0.1-3.8,2.1-6,5.8-6.6c1.5-0.2,3.1-0.1,4.6-0.1
	c66.6,0,133.1,0,199.7,0c7.4,0,10.9,2.3,10.8,7.1c-0.1,4.7-3.6,7-10.9,7C259.4,55.5,226.3,55.5,193.1,55.5z"/>
<Path fill="#A198A3" d="M171.4,98.3c-26,0-52,0-78.1,0c-1.5,0-3.1,0-4.6-0.1c-3.8-0.3-5.9-2.4-6.3-6.1c-0.4-4,1.7-6.4,5.3-7.6
	c1.1-0.4,2.4-0.3,3.7-0.3c53.3,0,106.5,0,159.8,0c1.7,0,3.4-0.1,5,0.7c3.4,1.7,4.6,4.4,4,8c-0.6,3.3-2.7,5.1-6.1,5.3
	c-3.1,0.2-6.2,0.1-9.2,0.1C220.4,98.3,195.9,98.3,171.4,98.3z"/>
<Path fill="#A39BA6" d="M164.2,141.6c-23.9,0-47.7,0-71.6,0c-1.4,0-2.8,0.1-4.1-0.1c-3.7-0.8-6.2-3.2-6.3-7c-0.1-3.8,2.2-6.3,5.9-7.3
	c1.5-0.4,3.1-0.3,4.6-0.3c47.6,0,95.2,0,142.7,0c1.1,0,2.2-0.1,3.2,0c4.7,0.4,7.8,3.5,7.6,7.6c-0.2,4.2-3.2,7-8.1,7
	C213.5,141.7,188.9,141.6,164.2,141.6z"/>
<Path fill="#8BB3E9" d="M365.5,264.1c7.4-2.7,15.3-3.9,22.6-7.1c20.4-8.8,36.4-22.6,47.9-41.6c11.1-18.3,16-38.3,14.5-59.5
	c-1.7-24.1-10.9-45.1-27.5-62.9c-13.8-14.8-30.5-24.6-50-29.7c-2.8-0.7-5.8-0.6-8.3-2.2c52.2-7.3,103,29.4,112.9,82.5
	c10.4,55.9-26.5,110.2-83.5,120.2c-3.6,0.6-7.3,0.9-11,1.3C377.3,264.4,371.3,265.9,365.5,264.1z"/>
<Path fill="#E5EBF7" d="M365.6,225c-18.6-4.6-33.4-14.2-43-31.2c-12.4-22-10.6-50.4,5.6-70.6c9.5-11.8,21.5-19.5,36.5-22.2
	c1.1,1.2,2.7,1.3,4.1,1.8c30.6,10.5,48.1,42,41.8,72.4c-4.3,20.8-16.6,35.9-36,45.1C371.6,221.7,367.6,221.6,365.6,225z"/>
<Path fill="#D6E0F2" d="M365.6,225c2-3.5,6-3.3,9-4.7c19.4-9.2,31.7-24.3,36-45.1c6.3-30.4-11.2-61.9-41.8-72.4
	c-1.4-0.5-3-0.6-4.1-1.8c7.9-2.7,16-2.5,23.9-0.7c26.1,5.8,43.6,21.4,50.2,47.6c5.1,20.3,0.7,39-12.8,55.3
	c-14.5,17.5-33.3,24.2-55.5,22.9C368.9,225.9,367.2,225.4,365.6,225z"/>
</Svg>
                    );
                }