import { useState } from "react";
import { StyleSheet } from "react-native"
import { View } from "react-native-animatable"
import CalendarPicker from "react-native-calendar-picker"

export default ({style, calendarProps, onDateChange})=>{

    const [layout, setLayout] = useState({width: 0, height: 0});

    return (
        <View 
            style={[scopedStyles.datePickerContainer, style]}
            onLayout={(e)=>{setLayout(e.nativeEvent.layout)}}
        >
            <CalendarPicker
                {...calendarProps}
                width={layout.width}
                height={layout.height}
                onDateChange={onDateChange}
            />                    
        </View>
    )
}

const scopedStyles = StyleSheet.create({
    datePickerContainer:{
        width: '100%',
        flex: 1
    }
})