import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >

<Path fill={color} d="M242.6,1.5C139.2,1.5,55.3,85.3,55.3,188.8c0,150,169.3,288.9,169.3,288.9c9.9,8.1,26.1,8.1,36,0
	c0,0,169.3-138.9,169.3-288.9C429.9,85.3,346.1,1.5,242.6,1.5z"/>
</Svg>
                    );
                }