import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M483.4,249.3L403.2,75.7c-9.9-21.4-31.5-35.3-55.1-35.3H137.1c-23.6,0-45.2,13.8-55.1,35.2L1.9,249.3
	c-1.2,2.6-1.9,5.5-1.9,8.5v126.4c0,33.4,27.2,60.7,60.7,60.7h363.9c33.4,0,60.7-27.2,60.7-60.7V257.8
	C485.2,254.8,484.6,251.9,483.4,249.3L483.4,249.3z M118.7,92.6c3.3-7.1,10.5-11.7,18.4-11.7h211.1c7.9,0,15.1,4.6,18.4,11.7
	l69.2,150h-41.6c-15.7,0-29.4,10.1-33.9,25.2l-21.7,72.3c-0.6,2.1-2.6,3.6-4.8,3.6H151.5c-2.2,0-4.2-1.5-4.8-3.6L125,267.8
	c-4.5-15.1-18.1-25.2-33.9-25.2H49.5L118.7,92.6z"/>
</Svg>
                    );
                }