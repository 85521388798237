import { ShowMessageModal } from "components/Modals";
import SText from "components/SText";
import { CustomerViewableObjects, DateTimeFormat, QueryKeys } from "enums";
import { Note, Signal, Task } from "models";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import { useContext } from "react";
import { useQuery } from "react-query";
import { CustomerUtils, QueryUtils } from "utils";
import BaseModule from "./BaseModule";


export default ({ data, disabled, warning = false }) => {

    const { showModal } = useContext(ModalContext);
    const { triggerLoading } = useContext(StoreContext);
    const { refetch: refetchSignals } = useQuery(QueryKeys.SIGNALS, QueryUtils.fetchSignals);
    const { refetch: refetchTasks } = useQuery(QueryKeys.TASKS, QueryUtils.fetchTasks);
    const { refetch: refetchNotes } = useQuery(QueryKeys.NOTES, QueryUtils.fetchNotes);

    const model = data instanceof Signal ? CustomerViewableObjects.SIGNAL :
        data instanceof Task ? CustomerViewableObjects.TASK :
            data instanceof Note ? CustomerViewableObjects.NOTE : '';
    const message = data?.customer_message || data?.description;
    const title = data?.title || data?.description
    const date = data?.date ? moment(data.date).format(DateTimeFormat.display_date) : moment(data.created_at).format(DateTimeFormat.display_date);

    return (
        <BaseModule
            disabled={disabled}
            warning={warning}
            title="#title_message"
            underTitle={date}
            text={<SText noTranslate regular sm black style={{ marginBottom: 8, overflow: "hidden" }}>{message}</SText>}
            buttonValue='#action_view_message'
            buttonAction={async () => {
                showModal(ShowMessageModal, { message, date }, 'ShowMessageModal');
                if (!disabled) {
                    data.seen_by_customer = true;
                    await CustomerUtils.view(model, data.id);
                    switch (model) {
                        case CustomerViewableObjects.SIGNAL:
                            refetchSignals();
                            break;
                        case CustomerViewableObjects.TASK:
                            refetchTasks();
                            break;
                        case CustomerViewableObjects.NOTE:
                            refetchNotes();
                            break;
                    }
                    triggerLoading();
                }
            }}
        />
    )
}
