import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<G id="Layer_1_107_">
		<G>
			<Path fill={color} d="M477.147,83.957c-4.775-5.476-11.65-8.626-18.901-8.626H112.749l-4.875-29.102
				c-2-12.051-12.426-20.901-24.651-20.901H26.969c-13.827,0-25.002,11.176-25.002,25.002c0,13.826,11.175,25.001,25.002,25.001
				h35.077l46.503,279.118c0.2,1.125,0.775,2.05,1.125,3.101c0.4,1.324,0.725,2.575,1.35,3.775c0.8,1.649,1.875,3.05,3,4.476
				c0.775,0.975,1.475,1.95,2.375,2.799c1.451,1.351,3.125,2.301,4.826,3.251c0.95,0.525,1.774,1.226,2.799,1.625
				c2.9,1.175,5.951,1.875,9.176,1.875c0.024,0,275.042,0,275.042,0c13.825,0,25.002-11.176,25.002-25.002
				c0-13.825-11.177-25.001-25.002-25.001H154.401l-4.15-25.001h282.993c12.45,0,23.001-9.151,24.751-21.452l25.002-175.011
				C484.022,96.682,481.872,89.407,477.147,83.957z M429.418,125.334l-7.125,50.003h-89.056v-50.003H429.418z M308.237,125.334
				v50.003h-75.005v-50.003H308.237z M308.237,200.339v50.003h-75.005v-50.003H308.237z M208.23,125.334v50.003h-75.005
				c-1.325,0-2.525,0.375-3.7,0.75l-8.451-50.753H208.23z M133.575,200.339h74.655v50.003h-66.33L133.575,200.339z M333.238,250.342
				v-50.003h85.455l-7.125,50.003H333.238z"/>
			<Circle fill={color} cx="170.727" cy="437.854" r="37.502"/>
			<Circle fill={color} cx="395.741" cy="437.854" r="37.502"/>
		</G>
	</G>
</G>
</Svg>
                    );
                }