import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M142.4,397.6c-13.3-33.4-26.5-66.5-39.8-99.8c-11.2,6.7-22,13.2-33.1,19.9c13.3,33.4,26.5,66.7,39.8,99.9
		c0.2,0,0.5,0,0.7,0c5.1-3.2,10.2-6.4,15.3-9.6C130.9,404.4,136.6,401,142.4,397.6z"/>
	<Path fill={color} d="M408.5,263.5c-19.3-5.7-37.8-4-55.4,6c-11.3,6.4-22.6,13.1-33.8,19.7c-10.3,6-20.6,11.9-30.7,18.3
		c-5.3,3.4-10.7,5.6-17,5.6c-15.6-0.1-31.2,0-46.7,0c-1.1,0-2.2,0-3.5,0c0-5.7,0-11,0-16.8c6.5,0,12.7,0,18.9,0
		c8.1,0,16.3,0.1,24.4,0c7.5-0.1,13.2-4.2,15-10.8c2.7-9.5-4.1-18.8-14.2-19.1c-10.7-0.2-21.3-0.3-32-0.2c-10.9,0.1-21.8,0.3-32-4.3
		c-0.9-0.4-2-0.6-3-0.8c-16.5-3.7-32.1-2.4-45.9,8.5c-11.4,9-18.3,21.2-23.1,34.7c-0.4,1,0,2.5,0.4,3.5
		c8.4,21.3,16.9,42.5,25.3,63.8c0.9,2.2,2,2.8,4.3,2.8c32.5-0.1,65.1,0,97.6-0.1c1.7,0,3.6-0.5,5.1-1.4
		c49.5-31,98.9-62.1,148.3-93.2c3.1-1.9,5.6-4.3,5-8.3C415.1,267.3,412.6,264.7,408.5,263.5z"/>
	<Path fill={color} d="M404.2,133.4c-1.7-9.3-2.3-19.1-10.2-25.8c9.6-13,5.2-26.7-2.6-33.7c-8.9-8-22.3-8.3-31.6-0.6
		c-10.1,8.3-11.4,20.8-3.5,34.1c-5.5,6.8-7,9.6-8,15c-2.6,13.9-5.1,27.7-7.6,41.6c-1.3,7.5,1,10.7,8.6,11.9c0.2,0.8,0.4,1.7,0.5,2.6
		c1.3,9.1,2.6,18.1,3.9,27.2c0.7,5,3.7,7.7,8.7,7.7c8.6,0,17.3,0,25.9,0c4.7,0,7.9-2.8,8.5-7.3c0.6-4,1.1-8,1.7-12
		c0.9-6.1,1.8-12.2,2.7-18.2c7.3-0.9,9.9-4.4,8.7-11.4C408,154.1,406.2,143.7,404.2,133.4z M375.2,83.9c4.4,0,8.1,3.6,8.1,8
		c0.1,4.4-3.6,8.1-7.9,8.2c-4.5,0.1-8.3-3.7-8.2-8.1C367.1,87.6,370.8,83.9,375.2,83.9z M381.7,197.1c-4.3,0-8.5,0-13,0
		c-1-7.1-2-14.1-3-21.3c6.4,0,12.6,0,19,0C383.7,182.9,382.7,190,381.7,197.1z M358,159.3c1.5-8.5,3.1-16.8,4.6-25.2
		c0.5-2.9,1-5.8,1.6-8.7c1.3-5.5,5.7-9.1,11.2-9.1c5.3,0,9.9,3.5,10.9,9c2.2,11.3,4.2,22.6,6.3,34
		C380.9,159.3,369.6,159.3,358,159.3z"/>
	<Path fill={color} d="M249.3,106.7c-0.1,0-0.1-0.2-0.1-0.3c7-12.3,5.7-24.3-3.6-32.5c-8.5-7.6-21.2-8.2-30.4-1.5c-4.7,3.4-7.8,7.9-9.2,13.5
		c-2,7.5-0.3,14.4,4,20.7c-7.2,5.8-12,13-12.5,22c-0.6,9.7-0.3,19.4-0.2,29.1c0,2.8,1.9,4.9,4.5,6.3c0.8,0.5,1.7,1.6,1.8,2.5
		c1.5,12.9,2.9,25.7,4.4,38.6c0.6,5.4,3.6,8.2,9.1,8.2c8.4,0,16.7,0,25.1,0c5.5,0,8.5-2.8,9.1-8.2c1.4-13,2.9-25.9,4.4-38.8
		c0.1-0.8,0.8-1.8,1.5-2.1c3.4-1.8,4.9-4.5,4.9-8.3c0-5.9-0.3-11.9,0-17.8C262.6,125.6,259.2,114.9,249.3,106.7z M229.5,83.9
		c4.4,0,8,3.7,8.1,8c0,4.5-3.8,8.2-8.2,8.2c-4.4-0.1-8-3.8-8-8.2C221.4,87.6,225.1,83.9,229.5,83.9z M235.7,197.1
		c-4.2,0-8.2,0-12.5,0c-1.2-10.6-2.4-21.2-3.6-32.1c6.5,0,12.9,0,19.6,0C238.1,175.8,236.9,186.4,235.7,197.1z M245.7,148.5
		c-10.8,0-21.4,0-32.3,0c0-6.1-0.4-12,0.1-17.9c0.7-8.5,8.2-14.6,16.7-14.3c8.4,0.3,15.3,7.2,15.5,15.7
		C245.8,137.5,245.7,142.9,245.7,148.5z"/>
	<Path fill={color} d="M315.8,117c-9-7.9-22.4-8.2-31.7-0.4c-10.2,8.5-11.3,21.1-2.7,35c-3.8,4.6-6,9.9-6,16c0,12.4,0,24.8,0,37.2
		c0,5.4,3.4,8.7,8.8,8.7c10.3,0,20.5,0,30.8,0c5.7,0,9-3.3,9-9.1c0-10.1-0.1-20.1,0.1-30.2c0.2-8.2-0.2-16.2-6.1-22.6
		C328.6,137.7,323.3,123.6,315.8,117z M299.6,127.1c4.4,0,8.1,3.6,8.1,8c0.1,4.4-3.5,8.1-7.9,8.2c-4.5,0.1-8.3-3.6-8.3-8.1
		C291.6,130.8,295.2,127.1,299.6,127.1z M307.7,197.1c-5.5,0-10.7,0-16.2,0c0-4,0-7.9,0-11.9c0-5.7,0-11.5,0-17.2
		c0-5,3.5-8.6,8.1-8.5c4.5,0,8,3.6,8,8.4C307.8,177.5,307.7,187.2,307.7,197.1z"/>
</G>
</Svg>
                    );
                }