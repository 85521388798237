import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M289.1,146.4C289.1,146.4,289.1,146.4,289.1,146.4c0.2-0.6,0.7-1.4,1.6-2.4c17-20.1,33.7-40.3,50.7-60.4
	c1.9-2.2,1.8-3.7,0.2-5.9c-12.1-18-24.2-36-36.1-54.1c-1.7-2.6-3.6-3.3-6.6-3.3c-37.5,0.1-75.1,0.1-112.6,0c-3,0-4.8,0.9-6.5,3.4
	c-11.9,18-23.8,35.9-35.9,53.7c-1.9,2.7-1.6,4.3,0.4,6.8c16.7,19.7,33.2,39.6,49.7,59.4c1.1,1.3,1.9,2.2,2.2,2.9
	c-67.3,19.9-116.4,82.2-116.4,156c0,89.8,72.8,162.7,162.7,162.7s162.7-72.8,162.7-162.7C405.3,228.6,356.3,166.4,289.1,146.4z
	 M237.4,139.4c-4.6-12.6-9.3-25.2-14.1-38.2c13.2,0,25.6,0,38.6,0c-3.5,9.7-6.9,19.1-10.4,28.4c-0.8,2.1-1.6,4.1-2.4,6.1
	c-0.7,1.8-1.3,3.1-1.9,4c-1.5,0-3.1-0.1-4.6-0.1c-1.7,0-3.3,0-5,0.1c0,0,0,0,0,0C237.5,139.6,237.5,139.5,237.4,139.4z M232.5,140
	c-0.2,0-0.4,0-0.6,0C232.1,140,232.3,140,232.5,140z M227,140.4C227,140.4,227,140.4,227,140.4C227,140.4,227,140.4,227,140.4z
	 M258.2,140.4c-0.2,0-0.4,0-0.7-0.1C257.8,140.4,258,140.4,258.2,140.4z M253.2,140c-0.3,0-0.6,0-0.9-0.1
	C252.5,140,252.9,140,253.2,140z M272.4,134.5c-0.3-0.2-0.6-0.4-1-0.6c2.7-7.3,5.3-14.6,8-21.9c4.1-11.1,4.1-11.1,15.7-11
	c1.4,0,2.8,0,5.3,0C290.6,112.7,281.5,123.6,272.4,134.5z M273.9,142.7c-0.1,0-0.2,0-0.3-0.1C273.7,142.6,273.8,142.7,273.9,142.7z
	 M301.1,53.5c5.6,8.4,10.8,16.2,16,24c1.3,1.9,1.4,3.2-1.4,3.2c-8,0-16,0-24.7,0C294.5,71.2,297.7,62.7,301.1,53.5z M284.3,40.7
	c-3.2,8.7-6.3,17-9.7,26.3c-5.1-9.2-9.8-17.5-14.8-26.3C268.1,40.7,275.8,40.7,284.3,40.7z M268.8,141.8c-0.1,0-0.2,0-0.3,0
	C268.6,141.7,268.7,141.7,268.8,141.8z M242.6,51.5c5.5,9.8,10.7,19.1,16.4,29.1c-11.3,0-21.6,0-32.7,0
	C231.7,70.9,236.9,61.5,242.6,51.5z M204.1,40.5c6.9,0,13.8,0,21.5,0c-5.1,9.1-9.8,17.3-14.9,26.3c-3.1-8.4-6-16.1-8.9-23.7
	C200.9,40.7,202.2,40.5,204.1,40.5z M169.7,80.7c-2.6,0-3-1.1-1.6-3.2c5.2-7.8,10.4-15.6,16.1-24.1c3.5,9.5,6.7,18.2,10,27.3
	C185.7,80.7,177.7,80.7,169.7,80.7z M184.9,101c5.7,0,10.6,0,15.5,0c1.1,0,1.7,0.7,2,1.6c3.6,9.8,7.1,19.7,10.6,29.5
	c0.2,0.5-0.1,1.2-0.2,2.4C203.6,123.4,194.6,112.6,184.9,101z M216.6,141.7c0,0-0.1,0-0.1,0C216.5,141.8,216.5,141.7,216.6,141.7z
	 M211.4,142.6c0,0-0.1,0-0.1,0C211.3,142.7,211.4,142.7,211.4,142.6z M242.6,422.4c-66.2,0-120.1-53.9-120.1-120.1
	c0-66.2,53.9-120.1,120.1-120.1c66.2,0,120.1,53.9,120.1,120.1C362.7,368.5,308.8,422.4,242.6,422.4z"/>
</Svg>
                    );
                }