import { createDrawerNavigator } from '@react-navigation/drawer';
import { DrawerActions } from '@react-navigation/routers';
import { useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import Avatar from 'screens/avatar';
import {
    Appointments,
    Contact,
    Documents,
    Inbox,
    Income,
    Landing,
    Living,
    Mortgages,
    Obligations,
    Policies,
    Possessions
} from 'screens/home';
import Damages from 'screens/home/Damages';
import Tasks from 'screens/home/Tasks';
import InventarisatiePortaal from 'screens/inventarisatie';
import { CustomDrawer, CustomHeader } from 'screens/navigation';



export const Drawer = createDrawerNavigator();



export default ({ navigation }: { navigation?: any }) => {

    useEffect(() => {
        if (navigation) {
            //automatically close drawer when switching bottom tabs on mobile
            const listener = navigation.addListener('focus', () => {
                navigation.dispatch(DrawerActions.closeDrawer());
            });
            return listener;

        }
    }, [navigation]);
    const windowSize = useWindowDimensions();
    const largeScreen = windowSize.width >= 768;

    return (
        <Drawer.Navigator
            defaultStatus={'closed'}
            drawerContent={
                (props) => <CustomDrawer {...props} />
            }
            screenOptions={{
                header: (props) => <CustomHeader {...props} />,
                headerShown: true,
                headerStyle: { height: 80 },
                drawerType: 'front',
                drawerPosition: 'right',
                drawerStyle: largeScreen ? {width: 420 } : { width: '100%' },
                swipeEnabled: false,
                overlayColor: 'rgba(0,0,0,0.2)',          
            }}
            
        >

            <Drawer.Screen name='overzicht'
                component={Landing}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='documenten'
                component={Documents}
                options={{freezeOnBlur: true, lazy: false}}
            />            
            <Drawer.Screen name='inbox'
                component={Inbox}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='tasks'
                component={Tasks}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='damages'
                component={Damages}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='appointments'
                component={Appointments}      
                options={{freezeOnBlur: true, lazy: false}}          
            />
            <Drawer.Screen name='hypotheken'
                component={Mortgages}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='verzekeringen'
                component={Policies}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='wonen'
                component={Living}
                options={{freezeOnBlur: true, lazy: true}}
            />
            <Drawer.Screen name='inkomen'
                component={Income}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='vermogen'
                component={Possessions}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='verplichtingen'
                component={Obligations}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='contact'
                component={Contact}
                options={{freezeOnBlur: true, lazy: false}}
            />
            <Drawer.Screen name='avatar'
                component={Avatar}
                options={{freezeOnBlur: true}}
            />
            <Drawer.Screen name='portaal'
                component={InventarisatiePortaal}
                options={{unmountOnBlur: true, freezeOnBlur: true}}
            />

        </Drawer.Navigator>
    );
}
