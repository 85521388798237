import { default as Add } from './jsx/add.jsx';
import { default as Analytics } from './jsx/analytics.jsx';
import { default as Arrow_curved_left } from './jsx/arrow-curved-left.jsx';
import { default as Arrow_curved_right } from './jsx/arrow-curved-right.jsx';
import { default as Arrow_down } from './jsx/arrow-down.jsx';
import { default as Arrow_left } from './jsx/arrow-left.jsx';
import { default as Arrow_long_down } from './jsx/arrow-long-down.jsx';
import { default as Arrow_long_left } from './jsx/arrow-long-left.jsx';
import { default as Arrow_long_right } from './jsx/arrow-long-right.jsx';
import { default as Arrow_long_up } from './jsx/arrow-long-up.jsx';
import { default as Arrow_right } from './jsx/arrow-right.jsx';
import { default as Arrow_up } from './jsx/arrow-up.jsx';
import { default as Attachment } from './jsx/attachment.jsx';
import { default as Avatar_female } from './jsx/avatar-female.jsx';
import { default as Avatar_male } from './jsx/avatar-male.jsx';
import { default as Aviation } from './jsx/aviation.jsx';
import { default as Bank_account } from './jsx/bank-account.jsx';
import { default as Battery } from './jsx/battery.jsx';
import { default as Bicycle } from './jsx/bicycle.jsx';
import { default as Blocked } from './jsx/blocked.jsx';
import { default as Business } from './jsx/business.jsx';
import { default as Calendar } from './jsx/calendar.jsx';
import { default as Camera } from './jsx/camera.jsx';
import { default as Car } from './jsx/car.jsx';
import { default as Caravan } from './jsx/caravan.jsx';
import { default as Cart } from './jsx/cart.jsx';
import { default as Charge } from './jsx/charge.jsx';
import { default as Chat } from './jsx/chat.jsx';
import { default as Check } from './jsx/check.jsx';
import { default as Checklist } from './jsx/checklist.jsx';
import { default as Child_support } from './jsx/child-support.jsx';
import { default as Credit } from './jsx/credit.jsx';
import { default as Dashboard } from './jsx/dashboard.jsx';
import { default as Data_search } from './jsx/data-search.jsx';
import { default as Delete_trash } from './jsx/delete-trash.jsx';
import { default as Delete } from './jsx/delete.jsx';
import { default as Doc } from './jsx/doc.jsx';
import { default as Dots } from './jsx/dots.jsx';
import { default as Download } from './jsx/download.jsx';
import { default as Edit } from './jsx/edit.jsx';
import { default as Editor_bold } from './jsx/editor-bold.jsx';
import { default as Editor_italic } from './jsx/editor-italic.jsx';
import { default as Editor_strikethrough } from './jsx/editor-strikethrough.jsx';
import { default as Electronics } from './jsx/electronics.jsx';
import { default as Email } from './jsx/email.jsx';
import { default as Emergency } from './jsx/emergency.jsx';
import { default as Employment } from './jsx/employment.jsx';
import { default as Euro } from './jsx/euro.jsx';
import { default as Eye_hidden } from './jsx/eye-hidden.jsx';
import { default as Eye_visible } from './jsx/eye-visible.jsx';
import { default as Facebook } from './jsx/facebook.jsx';
import { default as File_doc } from './jsx/file-doc.jsx';
import { default as File_pdf } from './jsx/file-pdf.jsx';
import { default as File_upload } from './jsx/file-upload.jsx';
import { default as File } from './jsx/file.jsx';
import { default as Filter } from './jsx/filter.jsx';
import { default as Fire } from './jsx/fire.jsx';
import { default as Garage } from './jsx/garage.jsx';
import { default as Glass } from './jsx/glass.jsx';
import { default as Heart_solid } from './jsx/heart-solid.jsx';
import { default as Heart } from './jsx/heart.jsx';
import { default as Home_household_insurence } from './jsx/home-household-insurence.jsx';
import { default as Home_insurence } from './jsx/home-insurence.jsx';
import { default as Home } from './jsx/home.jsx';
import { default as Household } from './jsx/household.jsx';
import { default as Inbox_all } from './jsx/inbox-all.jsx';
import { default as Inbox } from './jsx/inbox.jsx';
import { default as Industry } from './jsx/industry.jsx';
import { default as Info_solid } from './jsx/info-solid.jsx';
import { default as Info } from './jsx/info.jsx';
import { default as Instagram } from './jsx/instagram.jsx';
import { default as Legal_counsel } from './jsx/legal-counsel.jsx';
import { default as Life } from './jsx/life.jsx';
import { default as Linkedin } from './jsx/linkedin.jsx';
import { default as List } from './jsx/list.jsx';
import { default as Loan } from './jsx/loan.jsx';
import { default as Logout } from './jsx/logout.jsx';
import { default as Losses } from './jsx/losses.jsx';
import { default as Map } from './jsx/map.jsx';
import { default as Media } from './jsx/media.jsx';
import { default as Menu } from './jsx/menu.jsx';
import { default as Microgrid } from './jsx/microgrid.jsx';
import { default as Minus } from './jsx/minus.jsx';
import { default as More } from './jsx/more.jsx';
import { default as Motorbike } from './jsx/motorbike.jsx';
import { default as News } from './jsx/news.jsx';
import { default as Note } from './jsx/note.jsx';
import { default as Notes } from './jsx/notes.jsx';
import { default as Notification_solid } from './jsx/notification-solid.jsx';
import { default as Notification } from './jsx/notification.jsx';
import { default as Overview } from './jsx/overview.jsx';
import { default as Pages } from './jsx/pages.jsx';
import { default as Person } from './jsx/person.jsx';
import { default as Phone_cut } from './jsx/phone-cut.jsx';
import { default as Phone } from './jsx/phone.jsx';
import { default as Photo_library } from './jsx/photo-library.jsx';
import { default as Pin_map } from './jsx/pin-map.jsx';
import { default as Play } from './jsx/play.jsx';
import { default as Projects } from './jsx/projects.jsx';
import { default as Read_only } from './jsx/read-only.jsx';
import { default as References } from './jsx/references.jsx';
import { default as Refresh } from './jsx/refresh.jsx';
import { default as Retired } from './jsx/retired.jsx';
import { default as Scooter } from './jsx/scooter.jsx';
import { default as Search } from './jsx/search.jsx';
import { default as Send } from './jsx/send.jsx';
import { default as Settings } from './jsx/settings.jsx';
import { default as Ship } from './jsx/ship.jsx';
import { default as Signing } from './jsx/signing.jsx';
import { default as Stats_solid } from './jsx/stats-solid.jsx';
import { default as Stats } from './jsx/stats.jsx';
import { default as Student_debt } from './jsx/student-debt.jsx';
import { default as Study_deposit } from './jsx/study-deposit.jsx';
import { default as Switch } from './jsx/switch.jsx';
import { default as Team } from './jsx/team.jsx';
import { default as Thumbs_down } from './jsx/thumbs-down.jsx';
import { default as Thumbs_up } from './jsx/thumbs-up.jsx';
import { default as Thumbs } from './jsx/thumbs.jsx';
import { default as Timer } from './jsx/timer.jsx';
import { default as Trailer } from './jsx/trailer.jsx';
import { default as Tram } from './jsx/tram.jsx';
import { default as Travel_abroad } from './jsx/travel-abroad.jsx';
import { default as Twitter } from './jsx/twitter.jsx';
import { default as Upload_cloud } from './jsx/upload-cloud.jsx';
import { default as Upload_cloud2 } from './jsx/upload-cloud2.jsx';
import { default as Upload } from './jsx/upload.jsx';
import { default as User } from './jsx/user.jsx';
import { default as Vacancies } from './jsx/vacancies.jsx';
import { default as Valuables } from './jsx/valuables.jsx';
import { default as Vehicles } from './jsx/vehicles.jsx';
import { default as Vimeo } from './jsx/vimeo.jsx';
import { default as Voorstel } from './jsx/voorstel.jsx';
import { default as Warning } from './jsx/warning.jsx';
import { default as Website } from './jsx/website.jsx';
import { default as Youtube } from './jsx/youtube.jsx';

    export const Icons = {
        Add,
Analytics,
Arrow_curved_left,
Arrow_curved_right,
Arrow_down,
Arrow_left,
Arrow_long_down,
Arrow_long_left,
Arrow_long_right,
Arrow_long_up,
Arrow_right,
Arrow_up,
Attachment,
Avatar_female,
Avatar_male,
Aviation,
Bank_account,
Battery,
Bicycle,
Blocked,
Business,
Calendar,
Camera,
Car,
Caravan,
Cart,
Charge,
Chat,
Check,
Checklist,
Child_support,
Credit,
Dashboard,
Data_search,
Delete_trash,
Delete,
Doc,
Dots,
Download,
Edit,
Editor_bold,
Editor_italic,
Editor_strikethrough,
Electronics,
Email,
Emergency,
Employment,
Euro,
Eye_hidden,
Eye_visible,
Facebook,
File_doc,
File_pdf,
File_upload,
File,
Filter,
Fire,
Garage,
Glass,
Heart_solid,
Heart,
Home_household_insurence,
Home_insurence,
Home,
Household,
Inbox_all,
Inbox,
Industry,
Info_solid,
Info,
Instagram,
Legal_counsel,
Life,
Linkedin,
List,
Loan,
Logout,
Losses,
Map,
Media,
Menu,
Microgrid,
Minus,
More,
Motorbike,
News,
Note,
Notes,
Notification_solid,
Notification,
Overview,
Pages,
Person,
Phone_cut,
Phone,
Photo_library,
Pin_map,
Play,
Projects,
Read_only,
References,
Refresh,
Retired,
Scooter,
Search,
Send,
Settings,
Ship,
Signing,
Stats_solid,
Stats,
Student_debt,
Study_deposit,
Switch,
Team,
Thumbs_down,
Thumbs_up,
Thumbs,
Timer,
Trailer,
Tram,
Travel_abroad,
Twitter,
Upload_cloud,
Upload_cloud2,
Upload,
User,
Vacancies,
Valuables,
Vehicles,
Vimeo,
Voorstel,
Warning,
Website,
Youtube,

    }
    