import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M417.93,305.057c-12.317-18.249-18.563-36.995-18.563-55.714V205.79c0-73.034-44.171-130.241-113.223-147.736v-9.948
		c0-19.836-16.139-35.974-35.975-35.974h-15.125c-19.836,0-35.974,16.138-35.974,35.974v10.002
		c-69.051,18.003-113.223,75.2-113.223,147.682v43.553c0,18.902-6.397,38.138-18.517,55.646c-6.179,8.985-9.445,19.54-9.445,30.529
		v3.666c0,29.946,24.363,54.31,54.309,54.31h260.824c29.945,0,54.309-24.363,54.309-54.31v-3.666
		C427.327,324.528,424.061,313.974,417.93,305.057z M408.557,339.184c0,19.598-15.941,35.54-35.538,35.54H112.195
		c-19.598,0-35.539-15.942-35.539-35.54v-3.666c0-7.174,2.124-14.054,6.125-19.869c14.284-20.64,21.835-43.567,21.835-66.306V205.79
		c0-65.828,41.533-117.285,105.811-131.091c4.323-0.93,7.414-4.753,7.414-9.176V48.106c0-9.487,7.717-17.204,17.204-17.204h15.125
		c9.487,0,17.204,7.717,17.204,17.204v17.417c0,4.452,3.127,8.291,7.485,9.192c65.221,13.477,105.738,63.701,105.738,131.074v43.553
		c0,22.529,7.327,44.807,21.82,66.281c4.017,5.84,6.14,12.72,6.14,19.894V339.184z"/>
	<Path fill={color} d="M242.835,473.081c32.514,0,58.296-26.348,58.859-58.859H183.98C184.54,446.733,210.326,473.081,242.835,473.081z"/>
</G>
</Svg>
                    );
                }