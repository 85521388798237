import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M137.3,77.1L137.3,77.1c16.2,0,32.5,3.8,47,11.1c14.5,7.3,27.3,18,37,31L242,147l20.9-27.8c9.7-12.9,22.5-23.6,37-30.8
	c14.5-7.2,30.6-11,46.8-11c0.4,0,0.8,0,1.2,0l0.1,0l0.1,0c28.5,0.1,55.3,11.2,75.5,31.4c20.1,20.1,31.3,46.9,31.4,75.4
	c0,22-8.6,46.1-25.4,71.6c-18.3,27.7-46.4,57.2-83.6,87.5c-32.7,26.4-67.5,50.5-103.8,71.8c-36.2-21.5-71-45.8-103.6-72.4
	c-37.2-30.5-65.4-60-83.7-87.6c-16.9-25.4-25.4-49.4-25.5-71.2c0.1-28.5,11.3-55.3,31.4-75.4c20.2-20.2,47-31.3,75.5-31.4l0.1,0
	l0.1,0C136.7,77.1,137,77.1,137.3,77.1 M137.3,51.1c-0.4,0-0.7,0-1.1,0C63,51.3,3.7,110.6,3.5,183.8c0,54.3,39.9,114.3,118.7,179
	c36.8,30,75.9,57,117.1,80.6c0.9,0.5,1.9,0.7,2.9,0.7c0,0,0,0,0,0c0,0,0,0,0,0c1,0,2-0.2,2.9-0.7c41.3-23.4,80.5-50.1,117.4-80
	C441.3,299.1,481,238.7,481,184.1c-0.2-73.2-59.5-132.6-132.8-132.8c-0.5,0-1,0-1.5,0c-41.1,0-79.9,19.3-104.6,52.3
	C217.4,70.5,178.6,51.1,137.3,51.1L137.3,51.1z"/>
</Svg>
                    );
                }