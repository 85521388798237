import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M286.106,115.868c-6.59-6.215-14.556-9.302-23.86-9.302c-9.284,0-17.292,3.087-23.951,9.302
		c-6.606,6.192-9.979,13.682-9.979,22.381c0,8.722,3.352,16.188,9.979,22.332c6.659,6.132,14.667,9.202,23.951,9.202
		c9.305,0,17.254-3.07,23.86-9.202c6.607-6.145,9.918-13.598,9.918-22.332C296.024,129.55,292.713,122.061,286.106,115.868z"/>
	<Path fill={color} d="M271.227,353.659c-7.334,0-12.516-1.215-15.476-3.625c-2.993-2.418-4.47-6.958-4.47-13.629
		c0-2.633,0.447-6.562,1.362-11.707c0.939-5.156,1.969-9.758,3.128-13.778l14.164-50.15c1.387-4.625,2.34-9.661,2.846-15.177
		c0.503-5.505,0.765-9.357,0.765-11.548c0-10.552-3.72-19.143-11.115-25.753c-7.426-6.582-17.983-9.913-31.662-9.913
		c-7.574,0-15.627,1.346-24.141,4.08c-8.513,2.698-17.439,5.954-26.759,9.746l-3.765,15.494c2.736-1.046,6.063-2.111,9.904-3.295
		c3.875-1.138,7.653-1.683,11.317-1.683c7.509,0,12.54,1.239,15.218,3.765c2.639,2.529,3.968,7.017,3.968,13.412
		c0,3.594-0.413,7.543-1.273,11.852c-0.86,4.27-1.948,8.854-3.204,13.675l-14.236,50.3c-1.259,5.301-2.175,10.021-2.753,14.209
		c-0.564,4.193-0.84,8.311-0.84,12.33c0,10.349,3.8,18.862,11.436,25.586c7.633,6.713,18.355,10.087,32.127,10.087
		c8.964,0,16.845-1.179,23.617-3.541c6.796-2.357,15.854-5.761,27.233-10.255l3.792-15.475c-1.982,0.92-5.127,1.985-9.47,3.181
		C278.563,353.044,274.692,353.659,271.227,353.659z"/>
	<Path fill={color} d="M242.596,21.555c-122.066,0-221.042,98.974-221.042,221.04c0,122.071,98.976,221.064,221.042,221.064
		c122.069,0,221.062-98.993,221.062-221.064C463.658,120.529,364.665,21.555,242.596,21.555z M242.596,442.217
		c-110.063,0-199.602-89.55-199.602-199.622c0-110.06,89.538-199.598,199.602-199.598c110.07,0,199.62,89.538,199.62,199.598
		C442.216,352.667,352.666,442.217,242.596,442.217z"/>
</G>
</Svg>
                    );
                }