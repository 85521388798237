import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M358,181c-2.3-4-4.8-7.8-7.1-11.8c-0.4,0.1-0.7,0.3-1.1,0.4c1.3,27.8-2.4,54.9-11.1,81.3c-1.7,5.3-4.1,11.4-7,17.6
	c-10.1,22.4-25.2,47.8-32.9,53.2c0,0,0,0,0,0c0,0,0,0,0,0c8.5-18.4,11.9-37.9,13-57.8c0.2-2.8,0.3-5.7,0.3-8.5
	c0.5-21.5-0.1-43.1-0.9-64.6c-0.9-22.7-5.9-44.7-15.1-65.6c-20.3-46.1-53.7-80.1-97.2-104.7c-1-0.6-2.2-0.9-3.5-1.5
	c0.4,9.2,1.1,17.9,1.1,26.6c0,22.6-6.7,43.6-16.8,63.6c-13.1,26-26.9,51.6-39.8,77.7c-12.8,25.9-26.3,51.7-36.7,78.6
	c-14.3,36.8-13.8,74.7,0.3,111.9c12.2,32,32.2,58.5,57.8,81c2.8,2.5,5.8,4.7,9.5,7.7c-0.7-2.6-1-4.2-1.5-5.7
	c-10.7-37.9-15.6-76.1-8.2-115.3c6.8-35.9,23.1-66.6,50.1-91.4c4.4-4,9.4-7.5,15-11.9c-3.7,57.8,12.2,108.8,42.2,156.2
	c19.6-7.5,36-19.6,53.4-31.2c-1.8,34-10.4,65.9-18.2,98.8c1.6-1.3,2.5-1.9,3.4-2.7c34.7-31.1,60.7-68,76.1-112.3
	c8.8-25.6,11.3-51.7,7.5-78.4C386.1,239.7,374.3,209.5,358,181z"/>
</Svg>
                    );
                }