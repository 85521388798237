import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="seacrh">
	<Path fill={color} d="M472.757,414.273L361.598,303.106c18.888-30.066,29.849-65.606,29.849-103.746c0-107.902-87.473-195.363-195.37-195.363
		C88.178,3.999,0.714,91.459,0.714,199.362c0,107.907,87.46,195.358,195.362,195.358c41.506,0,79.949-12.977,111.583-35.025
		l109.836,109.844c7.633,7.624,17.638,11.421,27.63,11.421c10.006,0,19.998-3.797,27.643-11.421
		C488.021,454.269,488.021,429.538,472.757,414.273z M196.076,331.425c-72.93,0-132.056-59.122-132.056-132.055
		c0-72.933,59.126-132.06,132.056-132.06c72.933,0,132.055,59.126,132.055,132.06C328.131,272.304,269.009,331.425,196.076,331.425z
		"/>
</G>
</Svg>
                    );
                }