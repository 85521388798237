import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<G>
		<Path fill={color} d="M392.6,202l-40.2-40.2c-5.6-5.6-14.5-5.6-20.1,0l-48,48V14.2c0-7.9-6.4-14.2-14.2-14.2h-56.9C205.4,0,199,6.4,199,14.2
			v195.6l-48-48c-5.6-5.6-14.5-5.6-20.1,0L90.7,202c-5.6,5.6-5.6,14.5,0,20.1L231.6,363c5.6,5.6,14.5,5.6,20.1,0l140.9-140.9
			C398.2,216.5,398.2,207.5,392.6,202z"/>
	</G>
</G>
<G>
	<G>
		<Path fill={color} d="M471,314.6h-56.9c-7.9,0-14.2,6.4-14.2,14.2v71.1H85.3v-71.1c0-7.9-6.4-14.2-14.2-14.2H14.2C6.4,314.6,0,321,0,328.8
			v113.7c0,23.5,19.1,42.6,42.6,42.6h399.9c23.5,0,42.6-19.1,42.6-42.6V328.8C485.2,321,478.9,314.6,471,314.6z"/>
	</G>
</G>
</Svg>
                    );
                }