import { Icons } from "assets/icons";
import { QueryKeys } from "enums";
import { useNewMessageCount } from "hooks";
import { Image, Platform, Pressable, View } from "react-native";
import { useQuery } from "react-query";
import { colors } from "styles";
import { QueryUtils } from "utils";
import Badge from "./Badge";
import SText from "./SText";

export default ({ type, style, onPress }) => {

    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const newMessages = useNewMessageCount();
    const badgeValue = newMessages?.totalNew;
    const useAvatar = customer.avatar && !customer.avatar.includes('iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAAAsTAAALEwEAmpwYAAA4JmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYm');

    return (
        <Pressable onPress={onPress} style={[
            { flexDirection: 'row', alignItems: 'center' },
            style
        ]}>
            <View>
                <SText semibold primary sm style={[Platform.OS == 'web' && { userSelect: 'none' }]}>#hello{customer?.first_name}</SText>
                <SText regular black xs right style={[
                    { marginTop: -4, color: colors.headerNavText },
                    Platform.OS == 'web' && { userSelect: 'none' }
                ]}>
                    #title_userdetails
                </SText>
            </View>

            <View style={{ height: 15, width: 15, marginHorizontal: 8 }}>
                {type == 'open' ?
                    <Icons.Arrow_down size={15} color={colors.primary} />
                    :
                    <Icons.Arrow_up size={15} color={colors.primary} />
                }
            </View>

            <View>
                {useAvatar ? (
                    <Image
                        source={{ uri: `data:image/png;base64,${customer?.avatar}` }}
                        width={50}
                        height={50}
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 25
                        }}
                    />
                ) : (
                    <Icons.Avatar_male size={50} color={''} />
                )}
                {badgeValue > 0 && <Badge sm value={badgeValue} style={{ position: "absolute", right: -10, top: -4, backgroundColor: 'red' }} />}
            </View>



        </Pressable>
    )
}