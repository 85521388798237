import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M406.1,284.3c-9.4-0.4-16.2,1.7-20.5,10.2c-1.6,3.2-4.4,3.8-7.8,3.8c-59.7-0.1-119.3-0.1-179-0.1c-60,0-119.9,0-179.9,0
	c-6.4,0-6.8,0.6-4.5,6.3c13.4,33.1,26.8,66.1,40.1,99.2c1.4,3.6,3.3,4.9,7.2,4.9c110.3-0.1,220.7-0.1,331,0c3.4,0,5.4-1,7.2-4
	c23.4-38,47-76,70.4-114c0.9-1.4,1.1-3.2,1.6-4.8c-1.6-0.4-3.3-1.2-4.9-1.2C446.7,284.5,426.4,285,406.1,284.3z M321.7,344.8
	c-5.1,0.2-9.6-4.1-9.6-9.2c0-4.9,4.2-9.1,9.1-9.1c4.9-0.1,9.2,4,9.3,8.9C330.7,340.2,326.6,344.6,321.7,344.8z M366.5,344.8
	c-5,0.1-9.1-3.9-9.2-8.9c-0.2-5.2,4-9.4,9.2-9.4c5,0,9.1,4.1,9.1,9.1C375.5,340.5,371.5,344.7,366.5,344.8z"/>
<Path fill={color} d="M46.1,282.8c34.7,0,69.3,0,104,0c6.9,0,9.2-2.3,9.2-9.1c0-14.8,0-29.6,0-44.4c0-7.1-2.2-9.3-9.4-9.3c-17.3,0-34.7,0-52,0
	c-17.3,0-34.7,0-52,0c-6.4,0-8.6,2.2-8.7,8.6c0,15.1,0,30.2,0,45.3C37.2,280.6,39.4,282.8,46.1,282.8z"/>
<Path fill={color} d="M76.7,201.3c0,3.8,1.6,5.4,5.4,5.3c10.6-0.1,21.2-0.1,31.8,0c4.3,0.1,5.9-1.8,5.8-5.9c-0.1-6.7,0-13.4,0-20.2
	c0-3.4,0-6.9,0-10.3c0-3.4,0-6.9,0-10.3c0.1-3.9-1.4-5.8-5.5-5.8c-10.7,0.1-21.5,0.1-32.2,0c-3.8,0-5.3,1.7-5.3,5.4
	C76.8,173.5,76.8,187.4,76.7,201.3z"/>
<Path fill={color} d="M203.2,224.6c2.9-0.1,5.8-0.1,8.7-0.1c2.9,0,5.8,0,8.7,0c3,0,6,0,9,0c3,0,6,0,9,0.1c3.1,0.1,4.8-1,4.8-4.3
	c0-12.1,0-24.2,0-36.3c0-2.9-1.2-4.3-4.3-4.3c-12.1,0.1-24.2,0.1-36.3,0c-3.1,0-4.3,1.3-4.3,4.3c0,12.1,0,24.2,0,36.3
	C198.4,223.6,200,224.7,203.2,224.6z"/>
<Path fill={color} d="M261.4,201.8c0,6,0.1,11.9,0,17.9c-0.1,3.4,1.1,4.9,4.6,4.8c11.9-0.1,23.9-0.1,35.8,0c3.3,0,4.6-1.4,4.6-4.6
	c-0.1-11.9-0.1-23.9,0-35.8c0-3.1-1.2-4.6-4.5-4.6c-12.1,0.1-24.2,0.1-36.2,0c-3.1,0-4.3,1.3-4.2,4.3
	C261.5,189.9,261.4,195.9,261.4,201.8z"/>
<Path fill={color} d="M266.4,283.5c11.7-0.1,23.3-0.1,35,0c3.7,0,5-1.5,5-5.1c-0.1-11.7-0.1-23.3,0-35c0-3.8-1.6-5-5.1-4.9
	c-5.8,0.1-11.7,0-17.5,0c-6,0-12,0.1-17.9,0c-3.2-0.1-4.5,1.3-4.5,4.6c0.1,11.8,0.1,23.6,0,35.4
	C261.3,282.3,262.8,283.6,266.4,283.5z"/>
<Path fill={color} d="M324.3,278.6c0,3.4,1.3,4.9,4.7,4.9c11.8-0.1,23.6-0.1,35.4,0c3.7,0,5-1.6,4.9-5.2c-0.1-2.9-0.1-5.8-0.1-8.7
	c0-2.9,0-5.8,0-8.7c0-2.9,0-5.8,0-8.7c0-2.9,0-5.8,0.1-8.7c0.1-3.5-1.3-4.9-4.7-4.8c-11.8,0.1-23.6,0.1-35.4,0
	c-3.3,0-4.9,1.1-4.8,4.7C324.4,255,324.4,266.8,324.3,278.6z"/>
<Path fill={color} d="M198.4,260.9c0,5.8,0.1,11.7,0,17.5c-0.1,3.6,1.2,5.2,4.9,5.2c11.8-0.1,23.6-0.1,35.4,0c3.1,0,4.7-1.3,4.7-4.5
	c0-12,0-23.9,0-35.9c0-3.1-1.2-4.7-4.5-4.6c-12,0.1-23.9,0.1-35.9,0c-3.6,0-4.7,1.6-4.7,4.9C198.5,249.2,198.4,255.1,198.4,260.9z"
	/>
<Path fill={color} d="M83,140.9c8.4,0,16.7,0,25.1,0c12,0,12.2-0.2,11.7-12.3c-0.2-3.7-1.8-5.5-5.5-5.4c-3.4,0.1-6.8,0-10.4,0
	c0-6.1,0-11.6,0-17.1c0-7.9,0-15.8-0.1-23.7c0-3.5-2-5.7-5.6-5.7c-3.3,0-5.2,2-5.5,5.3c-0.2,1.8-0.2,3.6-0.2,5.4c0,11.8,0,23.6,0,36
	c-2,0-3.4,0-4.7,0c-11.2,0.1-11.2,0.1-11.1,11.4C76.8,139.8,77.7,140.9,83,140.9z"/>
</Svg>
                    );
                }