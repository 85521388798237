import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M155.3,254.2l0.2,0l0.2,0c0.9,0,1.8-0.1,2.6-0.1c2.1-0.1,4.2-0.2,6.4-0.4c1.8-0.2,3.7-0.5,5.6-0.9
		c36-8.8,59.5-32.9,70.1-71.6c8.2-30.1,0.5-61.6-20.6-84.1c-20.9-22.3-51.4-31.9-81.5-25.8c-27.1,5.5-49.5,22.9-61.3,47.5
		c-12.5,26-11.7,56.3,2.1,83.2C96,234.9,121.7,252.5,155.3,254.2z M203.7,169.6c-3.8,16.4-12.3,29.3-25,38.3
		c-12.8,9-26.1,10.2-39.7,3.6c-22.5-11-36.8-41-31.2-65.5c5-22,25.2-38,48.2-38c0.1,0,0.2,0,0.2,0c15.1,0.1,29,6.8,38.3,18.6
		C203.9,138.6,207.2,154.3,203.7,169.6z"/>
	<Path fill={color} d="M348.9,278l0.2,0l2.4-0.1c1.8-0.1,3.6-0.2,5.4-0.3c1.6-0.1,3.2-0.4,4.8-0.8c30.6-7.5,50.6-28,59.6-60.9
		c7-25.6,0.4-52.4-17.5-71.5c-17.8-19-43.7-27.2-69.4-21.9c-23.1,4.7-42.1,19.5-52.1,40.4c-10.6,22.1-10,47.9,1.8,70.7
		C298.4,261.6,320.3,276.6,348.9,278z M389.3,205.9c-3.2,13.7-10.3,24.5-21,32.1c-10.6,7.5-21.8,8.5-33.1,3
		c-18.8-9.2-30.8-34.4-26.1-54.9c4.2-18.4,21.1-31.8,40.3-31.8c0.1,0,0.1,0,0.2,0c12.6,0.1,24.2,5.7,32,15.5
		C389.5,180,392.3,193.1,389.3,205.9z"/>
	<Path fill={color} d="M485.1,365.6c-0.8-42.6-29.7-71.7-71.7-72.3c-28.4-0.4-58-0.6-87.9-0.4l-5.3,0l2.8,4.5c5.1,8.3,9.1,17.4,11.9,27l0.6,2.2
		l32.8,0c13.7,0,27.8,0,41.7,0c26.9,0.1,41.3,14.4,41.7,41.4c0.1,4.4,0.1,9,0,13.4c0,5.5,0,11.2,0.1,16.8
		c0.3,10.3,7.1,17.5,16.6,17.5c0.1,0,0.2,0,0.3,0c9.6-0.2,16.2-7.4,16.3-18C485.2,387.5,485.3,376.5,485.1,365.6z"/>
	<Path fill={color} d="M309.1,322.5c-4.4-11-10.9-20.6-19.2-28.4c-15-14.1-35.2-21.8-58.3-22.1c-24-0.4-48.4-0.6-72.8-0.6
		c-24.5,0-49.5,0.2-74.3,0.6c-44.9,0.7-77.5,29.1-83.1,72.3c-1.5,12-1.4,24.4-1.2,36.3c0.1,4.5,0.1,9.2,0.1,13.7
		c0,6.1,1.7,11.4,5.1,15.3c3.4,3.9,8.3,6,13.7,6l0.2,0c11.3,0,19-8.5,19.2-21.1c0.1-7.5,0.1-15,0.1-22.4c0-3.7,0-7.4,0-11.1
		c0.1-33.2,17.6-50.8,50.7-50.8c0,0,0,0,0,0h0.1c8.5,0,16.9,0,25.4,0l42.9,0c6.9,0,13.7,0,20.6,0c16.4,0,32.8,0,49.2,0
		c6.7,0,12.8,0.8,18.3,2.4c10.1,2.9,18,8.4,23.3,16.3c5.3,8,8.1,18.3,8.3,30.8c0.1,5.3,0.1,10.6,0.1,15.8c0,6.5,0,13.2,0.1,19.9
		c0.3,11.9,8.2,20.2,19.1,20.2l0.3,0c11.1-0.2,18.6-8.5,18.8-20.7c0.1-12.1,0.2-25.1,0-38C315.5,344.7,313.3,333.1,309.1,322.5z"/>
</G>
</Svg>
                    );
                }