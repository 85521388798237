import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 417 417"  >
<G>
	<Path fill={color} d="M49.7,310.3c91.8,0.1,183.6,0.1,275.5,0c16.8,0,29.8-13.1,30-29.9c0.1-8.4,0-16.7,0-25.1c0-1.6,0-3.1,0-4.8
		c9.9,0,19.3,0,28.6,0c11.9,0,19.3-7.5,19.3-19.5c0-13.5-0.6-27,0.2-40.4c0.9-16.7-7.9-25.3-24-24.1c-7.9,0.6-15.9,0.1-24.1,0.1
		c0-9.4,0-18.3,0-27.3c0-20.2-12.4-32.6-32.6-32.6c-72.4,0-144.7,0-217.1,0c-18.6,0-37.2-0.2-55.8,0.1c-12.6,0.2-21.8,6.3-27.2,17.7
		c-1.2,2.4-1.8,5.1-2.7,7.7c0,50.9,0,101.8,0,152.8c0.2,0.7,0.6,1.3,0.7,2C23.8,300.8,35.4,310.3,49.7,310.3z M43.7,272.5
		c0-42.7,0-85.4,0-128c0-9.2,4.6-13.8,13.8-13.8c43.3,0,86.6,0,129.9,0c43.2,0,86.3,0,129.5,0c9.7,0,14.2,4.5,14.2,14.1
		c0,42.6,0,85.1,0,127.7c0,9.4-4.6,14-14,14c-86.5,0-172.9,0-259.4,0C48.3,286.4,43.7,281.8,43.7,272.5z"/>
	<Path fill={color} d="M289.2,259.2c0-40.4-40-60.9-40-101.3c0-5.2-0.1-5.2-5.2-5.2c-42.2,0-14.3,0-56.5,0s-74.3,0-116.5,0
		c-5.2,0-5.2,0.1-5.2,5.2c0,40.4,0,60.9,0,101.3c0,5.2,0.1,5.2,5.2,5.2c84.3,0,128.7,0,213,0C289.1,264.4,289.2,264.4,289.2,259.2z"
		/>
</G>
</Svg>
                    );
                }