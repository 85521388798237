import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M124.327,209.463h-18.943v65.284h18.943c20.666,0,32.72-14.873,32.72-32.563
		C157.047,223.709,145.775,209.463,124.327,209.463z"/>
	<Path fill={color} d="M245.03,207.898c-19.257,0-31.625,14.716-31.625,34.286c0,19.413,12.368,34.286,31.625,34.286s31.781-14.873,31.781-34.286
		C276.811,222.614,264.286,207.898,245.03,207.898z"/>
	<Path fill={color} d="M399.019,1.136H86.194c-28.045,0-50.796,22.743-50.796,50.794v380.027c0,28.053,22.751,50.795,50.796,50.795h312.825
		c28.059,0,50.796-22.742,50.796-50.795V51.931C449.815,23.879,427.076,1.136,399.019,1.136z M124.327,294.317H83.153V189.894
		h41.174c32.72,0,55.421,20.822,55.421,52.29S157.047,294.317,124.327,294.317z M245.03,296.195
		c-31.468,0-54.481-22.544-54.481-54.012s23.014-54.012,54.481-54.012c31.625,0,54.639,22.544,54.639,54.012
		S276.655,296.195,245.03,296.195z M365.891,276.47c10.803,0,20.196-6.576,24.579-15.187l19.101,9.237
		c-7.515,12.994-20.666,25.675-43.68,25.675c-31.311,0-55.578-21.917-55.578-54.012c0-32.094,24.268-54.012,55.578-54.012
		c23.014,0,36.321,12.524,43.68,25.518l-19.101,9.394c-4.383-8.455-13.776-15.186-24.579-15.186c-18.943,0-32.72,14.56-32.72,34.286
		C333.171,261.909,346.948,276.47,365.891,276.47z"/>
</G>
</Svg>
                    );
                }