import { useNavigation } from '@react-navigation/native';
import { ProgressBar, SText } from 'components';
import { QueryKeys } from 'enums';
import { useStyle } from 'hooks';
import { ActivityIndicator, Dimensions, Pressable, View } from 'react-native';
import { PieChart } from 'react-native-svg-charts';
import { useQuery } from 'react-query';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import { CalculationUtils, QueryUtils, ValutaUtils } from 'utils';


//This screen show an overview of the customers mortgage values

export default ({ style }) => {

    const styles = useStyle();
    const navigation = useNavigation();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const { data: mortgages, isLoading } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const { total, restant, payed } = CalculationUtils.totalMortgage(mortgages);
    const { monthly, aflossing, rente } = CalculationUtils.monthlyMortgage(mortgages);

    const renderTotal = () => {
        return (
            <View style={{ flex: 0.9, justifyContent: 'space-between' }}>
                <SText medium white sm>#total_mortgage</SText>
                <View style={{
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    flex: 0.9,
                    minHeight: 90,
                    justifyContent: 'space-evenly',
                    borderRadius: 8,
                    padding: 10
                }}>
                    <View style={scopedStyles('mortgageValueContainer')}>
                        <ProgressBar
                            percentage={restant / total}
                            style={scopedStyles('progressBar')}
                            barStyle={{ backgroundColor: colors.action, borderRadius: 4 }}
                        />
                    </View>
                    <View style={scopedStyles('mortgageValueContainer')}>
                        <SText medium greyTransparent sm>#original_value</SText>
                        <SText medium white sm >{ValutaUtils.valutaString(total, true)}</SText>
                    </View>
                    <View style={scopedStyles('mortgageValueContainer')}>
                        <SText medium greyTransparent sm>#rest_value</SText>
                        <SText medium white sm >{ValutaUtils.valutaString(restant, true)}</SText>
                    </View>
                    <View style={scopedStyles('mortgageValueContainer')}>
                        <SText medium greyTransparent sm>#paid_value</SText>
                        <SText medium white sm >{ValutaUtils.valutaString(payed, true)}</SText>
                    </View>
                </View>
            </View>
        )
    }

    const renderMonthly = () => {
        return (!rente && !aflossing) ? (
            <View style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <SText greyTransparent sm regular style={{ paddingHorizontal: 10, marginTop: -20 }}>#empty_mortgageOverview</SText>
            </View>
        ) : (
            <View style={{ flex: 0.9, justifyContent: 'space-between', marginTop: 30 }}>
                <SText medium white sm style={{ flex: 0.1, minHeight: 12 }}>#monthly_value</SText>

                <View style={{ flexDirection: 'row', flex: 0.85 }}>
                    <View style={{ flex: 0.25, justifyContent: 'center' }}>
                        <View style={[{ height: '100%' }, scopedStyles('totalPieSize')]}>
                            {renderPie(
                                [
                                    { value: aflossing, color: colors.action },
                                    { value: rente, color: colors.primary },
                                ],
                                monthly
                            )}
                        </View>
                    </View>
                    <View style={{ flex: 0.7, marginLeft: 40, justifyContent: 'space-evenly' }}>

                        <View style={{ height: '50%', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={scopedStyles('partPieSize')}>
                                {renderPie(
                                    [
                                        { value: aflossing, color: colors.action },
                                        { value: rente, color: colors.darkgrey },
                                    ]
                                )}
                            </View>
                            <SText medium greyTransparent sm style={{ flex: 0.9, marginLeft: 4, alignSelf: 'center' }}>#redeemed</SText>
                            <SText medium white sm style={{ alignSelf: 'center', marginRight: -12 }} >{ValutaUtils.valutaString(aflossing, true)}</SText>
                        </View>

                        <View style={{ height: '50%', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={scopedStyles('partPieSize')}>
                                {renderPie(
                                    [
                                        { value: aflossing, color: colors.darkgrey },
                                        { value: rente, color: colors.primary },
                                    ]
                                )}
                            </View>
                            <SText medium greyTransparent sm style={{ flex: 0.9, marginLeft: 4, alignSelf: 'center' }}>#interest</SText>
                            <SText medium white sm style={{ alignSelf: 'center', marginRight: -12 }} >{ValutaUtils.valutaString(rente, true)}</SText>
                        </View>
                    </View>
                </View>
            </View>
        )
    }


    const renderPie = (data, label = 0) => {

        const pieData = data.map((val, index) => ({
            value: val.value,
            svg: {
                fill: val.color,
            },
            key: `pie-${index}`,
        }))

        return (
            <View style={styles('fullscreen')}>
                <PieChart style={{ width: '100%', height: '100%' }} data={pieData} innerRadius={'85%'} padAngle={0} />
                <View style={{ position: 'absolute', height: '100%', width: '100%', justifyContent: 'center' }}>
                    {label !== 0 &&
                        <SText white sm medium center
                            style={{ fontSize: Dimensions.get('window').height * 0.016 }}
                        >
                            {ValutaUtils.valutaString(label, true)}
                        </SText>}
                </View>
            </View>
        )

    }

    const noData = () => !mortgages || mortgages?.length == 0;


    return (
        <Pressable
            style={style}
            disabled={tenant?.show_mortgages === false}
            onPress={() => navigation.navigate('hypotheken')}
        >

            <View style={{ height: 45 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <SText md semibold white>#title_mortgage</SText>
                        {(mortgages && mortgages.length > 0) &&
                            <SText sm semibold greyTransparent style={{ marginLeft: 8 }}>{mortgages.length}</SText>
                        }
                    </View>
                    <SText greyTransparent sm medium>#more</SText>
                </View>
            </View>

            {(noData() || tenant?.show_mortgages === false) ? (
                <View style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    {isLoading ? (
                        <>
                            <ActivityIndicator color={colors.greyTransparent} />
                            <SText greyTransparent sm regular center style={{ marginTop: 12 }}>#loading_mortgage_data</SText>
                        </>
                    ) : (
                        <SText greyTransparent sm regular center style={{ marginTop: -20 }}>#empty_mortgageOverview</SText>
                    )}
                </View>
            ) : (
                <View style={scopedStyles('itemContainer')}>
                    {!isLoading && renderTotal()}
                    {!isLoading && renderMonthly()}
                </View>
            )}

        </Pressable>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        badge: {
            marginLeft: 8,
            height: 28,
            width: 34,
            borderRadius: 28,
            backgroundColor: colors.whiteTransparent
        },
        itemContainer: {
            flex: 1,
            justifyContent: 'space-between'
        },
        item: {
            width: '50%'
        },
        progressBar: {
            width: '100%',
            height: 10,
            borderRadius: 4,
            backgroundColor: colors.whiteTransparent,
            overflow: 'hidden'
        },
        mortgageValueContainer: {
            height: '25%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        totalPieSize: {
            flex: 0.9
        },
        partPieSize: {
            width: '10%'
        }
    },
    {
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {

        },
        [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
            totalPieSize: {
                flex: 1,
                transform: 'scale(1.4) translateX(10px)'
            },
            partPieSize: {
                width: '16%'
            }
        }
    }
)
