import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M259.9,72c-33.5-3.3-65.4,2.6-95.5,17.9C93.6,125.6,55.7,207.6,76.2,284.3c17.3,64.8,59.4,106.3,124,124.4
	c22.4,6.3,45.4,6.6,68.5,3.4c8.4-1.2,13.9-7.5,12.8-15.4c-1.1-7.6-8-12.4-16.3-11.2c-3.1,0.4-6.1,0.7-9.3,1.1c0-17.2,0-34.1,0-51.1
	c2.9,0,5.5,0,8.2,0c7.6-0.1,13.4-5.6,13.6-12.9c0.2-7.6-5.2-13.4-12.9-13.9c-1.9-0.1-3.8-0.2-5.7-0.1c-2.5,0.2-3.6-0.7-3.4-3.4
	c0.2-4.6,0.1-9.2,0.1-13.7c0-11.6,0-23.2,0-35.3c17.5,0,34.6,0,51.9,0c-0.1,1.3-0.2,2.4-0.3,3.5c-0.4,5.6-0.9,11.1-1.1,16.7
	c-0.2,7.1,4.9,13,11.7,13.6c7.4,0.7,13.5-3.9,14.7-11.2c0.3-1.9,0.5-3.8,0.6-5.7c0.5-5.6,0.9-11.3,1.3-16.9c17.6,0,34.9,0,52.3,0
	c-0.1,1.5-0.2,2.7-0.3,3.9c-0.4,7.6,4.7,13.8,11.9,14.4c7.6,0.7,13.7-4.3,14.6-12.1c2.5-20.8,1.2-41.3-3.9-61.5
	C391.7,130.6,332,79,259.9,72z M228.7,101.9c0,15.7,0,31.2,0,47.5c-11.4-0.8-22.7-1.6-34.4-2.3C200,130,215.3,107.4,228.7,101.9z
	 M181.3,111.6c-5,10.3-9.9,20.4-15.1,31c-7-1.6-14.5-3.4-22.6-5.3C155,126.4,167.4,118.1,181.3,111.6z M104.1,200.5
	c4-14,10.4-27,18.7-39c0.6-0.9,2.1-1.9,2.9-1.7c10.9,2.9,21.7,6,32,8.9c-2.8,19.9-5.6,40-8.4,60.3c-16.3,0-33.4,0-51.6,0
	C99.8,219.2,101.4,209.7,104.1,200.5z M122.7,323.8c-13.6-20.2-21.7-42.4-24.2-66.7c0-0.2,0.1-0.4,0.2-0.9c17,0,34,0,50.6,0
	c2.7,20,5.5,39.8,8.2,60c-3.3,0.8-7.3,1.6-11.2,2.7c-6.7,1.8-13.3,3.8-19.9,5.7C125,325.1,123.9,325.5,122.7,323.8z M142.8,347.3
	c7.8-1.8,14.8-3.5,21.7-5c0.8-0.2,2.2,0.5,2.5,1.2c4.7,9.9,9.3,20,13.9,29.9C167.2,367.1,154.7,358.4,142.8,347.3z M228.7,383.7
	c-18-10.9-27-27.8-35.1-46.2c11.9-0.8,23.3-1.6,35.1-2.4C228.7,351.4,228.7,367.2,228.7,383.7z M226.3,308.6
	c-13.6,0.8-27.2,1.7-41.3,2.7c-4.6-18-7.1-36.3-7.9-55.2c17.3,0,34.3,0,51.6,0c0.1,1.1,0.2,2.1,0.2,3.1c0,15.3,0,30.6,0,45.9
	C229,307,229.1,308.5,226.3,308.6z M228.9,227.5c0,0.4-0.2,0.8-0.3,1.5c-17.1,0-34.2,0-51.5,0c0.8-18.9,3.3-37.4,8.1-56.1
	c6.1,0.6,12,1.2,17.8,1.7c7.7,0.6,15.4,1,23.1,1.3c1.9,0.1,2.8,0.5,2.8,2.7C228.9,194.9,229,211.2,228.9,227.5z M256,102.2
	c11.8,3.4,28.5,27,34.4,44.8c-11.6,0.8-22.8,1.6-34.4,2.4C256,133.4,256,117.8,256,102.2z M256,229c0-17.5,0-35,0-52.6
	c14.4-1.1,28.7-2.2,43.4-3.3c4.8,18.2,7.3,36.8,8.2,55.9C290.4,229,273.3,229,256,229z M318.6,142.7c-5.1-10.6-10.2-20.8-15.3-31.4
	c14.1,6.3,26.5,14.8,38.5,25.9C333.6,139.1,326,140.9,318.6,142.7z M335.5,229c-2.8-20.2-5.6-40.2-8.4-60.5
	c10.7-2.9,22.3-6,33.9-9.2c15.2,19.9,25.1,50.2,25.7,69.7C369.5,229,352.3,229,335.5,229z"/>
<Path fill={color} d="M400.5,299c-4.3,0.3-7.9,2.2-11,5.2c-3.8,3.7-7.6,7.6-11.6,11.2c-1.3,1.2-3.4,2.1-5.2,2.1c-21.4,0.1-42.7,0.1-64.1,0.1
	c-5.4,0-9.1,3.1-9.8,7.8c-0.7,4.8,2,8.6,7.2,10.1c4.8,1.4,9.7,2.7,14.5,4.1c8.6,2.5,17.1,4.9,26.8,7.7c-8.8,8.5-16.9,16.5-25.1,24.4
	c-0.7,0.6-2,0.8-3.1,0.8c-4.7,0.1-9.4,0.1-14.1,0c-2.8,0-5.1,0.7-6.3,3.5c-1.1,2.7,0.1,4.8,2,6.6c9.7,9.7,19.5,19.4,29.2,29.1
	c1.8,1.8,3.9,3,6.6,1.8c2.6-1.1,3.5-3.2,3.5-5.9c-0.1-4.8-0.1-9.6,0-14.4c0-1,0.2-2.4,0.9-3c8-7.9,16.1-15.8,24.5-24
	c0.4,1.2,0.5,1.7,0.7,2.2c3.7,12.9,7.3,25.8,11.1,38.6c1.5,5,5.5,7.7,10,7c4.6-0.8,7-4,7-9.4c0-21.5,0-42.9-0.1-64.4
	c0-2.2,0.6-3.7,2.2-5.2c4-3.7,8.2-7.4,11.6-11.6c2.3-2.8,4.4-6.3,5.2-9.8C415.5,305.1,409.1,298.3,400.5,299z"/>
</Svg>
                    );
                }