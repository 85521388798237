
export class MortgagePart {
    id: string;
    afsluitprovisie: string;
    agency_code: string;
    amount_repaid: string;
    bedenktijd: number;
    bedenktijdStartDatum: string;
    begindatum: string;
    begindatumRentevast: string;
    company_rate_id: number;
    consumptive_amount: number;
    consumptive_percentage: number;
    coverage_percentage: number
    current_value: number;
    einddatum: string;
    einddatumRentevast: string;
    highest_deposit_any_year: number;
    hoofdsom: number;
    hypotheekType: number;
    hypotheeknummer: number;
    interest_agreement_type: number;
    interest_cooling_off_period_in_months: number;
    interest_cooling_off_period_type: number;
    interest_deduction_end_date: string;
    interest_type: string;
    interest_type_description: string;
    leningdeelnummer: string;
    lowest_deposit_any_year: number;
    monthly_amount: number;
    monthly_deposit: number;
    monthly_interest_amount: number;
    monthly_premium: number;
    monthly_repayment_amount: number;
    mortgage_guarantee: string;
    mortgage_guarantee_description: string;
    mortgage_id: number;
    national_mortgage_guarantee: number;
    payment_term: number;
    penalty_free_repayment_percentage: string;
    rate_based_on: number;
    rate_class_description: string;
    remaining_deb_last_year: string;
    remaining_debt: string;
    remaining_debt_date: string;
    remaining_debt_last_year_date: string;
    remaining_debt_old_age_pension: string;
    remaining_fixed_interest_period_comparison_rate: string;
    remaining_months_duration: number;
    remaining_months_fixed_interest_duration: number;
    rente: number;
    renteType: number;
    rentevasteperiode: string;
    repayment_type: number;
    repayment_type_description: string;
    tax_box_type: number;
    total_months_duration: number;
    transfer_fine_amount: string;
    transfer_fine_amount_date: string;
    type: number;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): MortgagePart {
        this.id = json.id ? json.id : "";
        this.afsluitprovisie = json.afsluitprovisie ? json.afsluitprovisie : 0;
        this.agency_code = json.agency_code ? json.agency_code : 0;
        this.amount_repaid = json.amount_repaid ? json.amount_repaid : 0;
        this.bedenktijd = json.bedenktijd ? json.bedenktijd : 0;
        this.bedenktijdStartDatum = json.bedenktijdStartDatum ? json.bedenktijdStartDatum : 0;
        this.begindatum = json.begindatum ? json.begindatum : 0;
        this.begindatumRentevast = json.begindatumRentevast ? json.begindatumRentevast : 0;
        this.company_rate_id = json.company_rate_id ? json.company_rate_id : 0;
        this.consumptive_amount = json.consumptive_amount ? json.consumptive_amount : 0;
        this.consumptive_percentage = json.consumptive_percentage ? json.consumptive_percentage : 0;
        this.coverage_percentage = json.coverage_percentage ? json.coverage_percentage : 0;
        this.current_value = json.current_value ? json.current_value : 0;
        this.einddatum = json.einddatum ? json.einddatum : 0;
        this.einddatumRentevast = json.einddatumRentevast ? json.einddatumRentevast : 0;
        this.highest_deposit_any_year = json.highest_deposit_any_year ? json.highest_deposit_any_year : 0;
        this.hoofdsom = json.hoofdsom ? json.hoofdsom : 0;
        this.hypotheekType = json.hypotheekType ? json.hypotheekType : 0;
        this.hypotheeknummer = json.hypotheeknummer ? json.hypotheeknummer : 0;
        this.interest_agreement_type = json.interest_agreement_type ? json.interest_agreement_type : 0;
        this.interest_cooling_off_period_in_months = json.interest_cooling_off_period_in_months ? json.interest_cooling_off_period_in_months : 0;
        this.interest_cooling_off_period_type = json.interest_cooling_off_period_type ? json.interest_cooling_off_period_type : 0;
        this.interest_deduction_end_date = json.interest_deduction_end_date ? json.interest_deduction_end_date : 0;
        this.interest_type = json.interest_type ? json.interest_type : 0;
        this.interest_type_description = json.interest_type_description ? json.interest_type_description : 0;
        this.leningdeelnummer = json.leningdeelnummer ? json.leningdeelnummer : 0;
        this.lowest_deposit_any_year = json.lowest_deposit_any_year ? json.lowest_deposit_any_year : 0;
        this.monthly_amount = json.monthly_amount ? json.monthly_amount : 0;
        this.monthly_deposit = json.monthly_deposit ? json.monthly_deposit : 0;
        this.monthly_interest_amount = json.monthly_interest_amount ? json.monthly_interest_amount : 0;
        this.monthly_premium = json.monthly_premium ? json.monthly_premium : 0;
        this.monthly_repayment_amount = json.monthly_repayment_amount ? json.monthly_repayment_amount : 0;
        this.mortgage_guarantee = json.mortgage_guarantee ? json.mortgage_guarantee : 0;
        this.mortgage_guarantee_description = json.mortgage_guarantee_description ? json.mortgage_guarantee_description : 0;
        this.mortgage_id = json.mortgage_id ? json.mortgage_id : 0;
        this.national_mortgage_guarantee = json.national_mortgage_guarantee ? json.national_mortgage_guarantee : 0;
        this.payment_term = json.payment_term ? json.payment_term : 0;
        this.penalty_free_repayment_percentage = json.penalty_free_repayment_percentage ? json.penalty_free_repayment_percentage : 0;
        this.rate_based_on = json.rate_based_on ? json.rate_based_on : 0;
        this.rate_class_description = json.rate_class_description ? json.rate_class_description : 0;
        this.remaining_deb_last_year = json.remaining_deb_last_year ? json.remaining_deb_last_year : 0;
        this.remaining_debt = json.remaining_debt ? json.remaining_debt : 0;
        this.remaining_debt_date = json.remaining_debt_date ? json.remaining_debt_date : 0;
        this.remaining_debt_last_year_date = json.remaining_debt_last_year_date ? json.remaining_debt_last_year_date : 0;
        this.remaining_fixed_interest_period_comparison_rate = json.remaining_fixed_interest_period_comparison_rate ? json.remaining_fixed_interest_period_comparison_rate : 0;
        this.remaining_months_duration = json.remaining_months_duration ? json.remaining_months_duration : 0;
        this.remaining_months_fixed_interest_duration = json.remaining_months_fixed_interest_duration ? json.remaining_months_fixed_interest_duration : 0;
        this.rente = json.rente ? json.rente : 0;
        this.renteType = json.renteType ? json.renteType : 0;
        this.rentevasteperiode = json.rentevasteperiode ? json.rentevasteperiode : 0;
        this.repayment_type = json.repayment_type ? json.repayment_type : 0;
        this.repayment_type_description = json.repayment_type_description ? json.repayment_type_description : 0;
        this.tax_box_type = json.tax_box_type ? json.tax_box_type : 0;
        this.total_months_duration = json.total_months_duration ? json.total_months_duration : 0;
        this.transfer_fine_amount = json.transfer_fine_amount ? json.transfer_fine_amount : 0;
        this.transfer_fine_amount_date = json.transfer_fine_amount_date ? json.transfer_fine_amount_date : 0;
        this.type = json.type ? json.type : 0;
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
