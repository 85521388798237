import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 512 512"  >
<G>
	<G>
		<Path fill={color} d="M405.333,170.667v-21.333C405.333,67.008,338.347,0,256,0S106.667,67.008,106.667,149.333v21.333
			c-35.285,0-64,28.715-64,64V448c0,35.285,28.715,64,64,64h298.667c35.285,0,64-28.715,64-64V234.667
			C469.333,199.381,440.619,170.667,405.333,170.667z M149.333,149.333c0-58.816,47.851-106.667,106.667-106.667
			s106.667,47.851,106.667,106.667v21.333H149.333V149.333z M426.667,448c0,11.776-9.579,21.333-21.333,21.333H106.667
			c-11.755,0-21.333-9.557-21.333-21.333V234.667c0-11.776,9.579-21.333,21.333-21.333h298.667c11.755,0,21.333,9.557,21.333,21.333
			V448z"/>
	</G>
</G>
<G>
	<G>
		<Path fill={color} d="M256,245.333c-35.285,0-64,28.715-64,64c0,27.776,17.899,51.243,42.667,60.075v35.925
			c0,11.797,9.557,21.333,21.333,21.333c11.776,0,21.333-9.536,21.333-21.333v-35.925C302.101,360.576,320,337.109,320,309.333
			C320,274.048,291.285,245.333,256,245.333z M256,330.667c-11.755,0-21.333-9.557-21.333-21.333S244.245,288,256,288
			c11.755,0,21.333,9.557,21.333,21.333S267.755,330.667,256,330.667z"/>
	</G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
<G>
</G>
</Svg>
                    );
                }