import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<G>
		<Path fill={color} d="M4.689,438.266c-5.01,11.945-2.298,25.735,6.862,34.896c9.163,9.161,22.951,11.873,34.898,6.862l116.767-48.962
			L53.648,321.499L4.689,438.266z"/>
		<Path fill={color} d="M475.867,87.804l-78.961-78.96c-12.459-12.458-32.659-12.458-45.12,0L82.903,277.736
			c-0.068,0.066-0.125,0.141-0.193,0.209l124.06,124.06c0.068-0.066,0.143-0.124,0.209-0.19l268.888-268.887
			C488.327,120.466,488.327,100.265,475.867,87.804z"/>
	</G>
</G>
</Svg>
                    );
                }