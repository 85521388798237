import { Icons } from 'assets/icons';
import { Platform } from 'expo-modules-core';
import { useStyle } from 'hooks';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Pressable, StyleProp, StyleSheet, TextInput, View } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import SText from './SText';


interface Props {
    label?: string
    placeholder?: string,
    onChangeText?: (e: string | ChangeEvent<any>) => void,
    onBlur?: (e: string | ChangeEvent<any>) => void,
    onKeyPress?: (e: any) => void,
    style?: StyleSheet.NamedStyles<any> | StyleProp<any>,
    value?: any,
    autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined,
    autoCompleteType?: "cc-csc" | "cc-exp" | "cc-exp-month" | "cc-exp-year" | "cc-number" | "email" | "name" | "password" | "postal-code" | "street-address" | "tel" | "username" | "off" | undefined,    
}

const SecureTextInput: React.FC<Props> = ({ label, value, placeholder, onChangeText, onBlur, onKeyPress, style, autoCapitalize, autoCompleteType }) => {

    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const [passVisible, setPassVisible] = useState(false);

    const inputRef = useRef(null);
    useEffect(() => {
        if ( Platform.OS === 'web' && inputRef.current) inputRef.current.setAttribute('tabindex', -1);
    }, [inputRef])

    const textStyles = [
        styles('text-light'),
        styles('text-sm'),
        styles('text-black'),
        scopedStyles('input'),
        scopedStyles('input-border')
    ];

    return (
        <View style={style}>
            <SText medium md black style={scopedStyles('label')}>
                {label}
            </SText>
            <View style={{ justifyContent: 'center' }}>
                <TextInput                    
                    secureTextEntry={!passVisible}
                    autoCompleteType={autoCompleteType}
                    placeholder={placeholder}
                    value={value}
                    onChangeText={onChangeText}
                    onBlur={onBlur}
                    onKeyPress={onKeyPress}
                    autoCapitalize={autoCapitalize}
                    spellCheck={false}
                    style={[...textStyles, { marginRight: 36 }]}
                />

                <Pressable
                    ref={inputRef}
                    focusable={false}
                    accessible={false}
                    onPress={() => { setPassVisible(c => !c) }}
                    style={scopedStyles('icon')}
                >
                    {passVisible ? <Icons.Eye_visible size={18} color={colors.grey} /> : <Icons.Eye_hidden size={18} color={colors.grey} />}
                </Pressable>

            </View>
        </View>
    );
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        label: {
            marginBottom: 4
        },
        input: {
            paddingLeft: 12,
            paddingVertical: 8,
        },
        'input-border': {
            borderWidth: 1,
            borderColor: colors.border,
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
        },
        icon: {
            position: 'absolute',
            width: 36,
            height: '100%',
            right: 0,
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: colors.border,
            borderTopWidth: 1,
            borderRightWidth: 1,
            borderBottomWidth: 1,
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
        }
    },
    {
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            input: {
                fontSize: 14,
                paddingVertical: 6
            }
        }
    }
)

export default SecureTextInput;