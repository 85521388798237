import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M225.6,313.4c0.9-0.3,2-2.1,2-3.2c0.1-22.4,0.2-44.8,0-67.1c-0.1-10.5-7.5-17.7-18.1-17.8c-8.3-0.1-16.7-0.1-25,0
	c-8.3,0.1-14.9,4.9-17.1,12.5c-0.7,2.6-0.9,5.4-0.9,8.1c-0.1,35.4,0,70.9,0,106.3c0,1.5,0,2.9,0,4.5c-10,0-19.7,0-29.6,0
	c0-2,0-3.7,0-5.4c0-78.7,0-157.4,0-236.1c0-1.7,0-3.5-0.2-5.2c-1.1-9.7-8.3-16.3-18.1-16.4c-8.3-0.1-16.7,0-25,0
	c-5.1,0-9.5,1.9-13,5.5c-4,4.1-5,9.2-5,14.7c0.1,79.3,0,158.7,0,238c0,1.6,0,3.2,0,5.1c-3.1,0-5.8,0-8.6,0c-2.7,0-5.5-0.1-8.2,0.1
	c-3.3,0.2-5.7,2.6-5.8,5.7c-0.1,3.2,2.3,5.8,5.7,6.2c1,0.1,2,0,3,0c41.3,0,82.5,0,123.8,0c1.3,0,2.7,0,4,0c0.1-0.7,0.3-1.1,0.3-1.4
	C187.7,340.8,205.4,319.5,225.6,313.4z"/>
<Path fill={color} d="M426.2,356.8c-5.4-0.2-10.8,0-16.5,0c0-2,0-3.5,0-5c0-21.6,0-43.3,0-64.9c0-11.9-7.2-19-19-19.2c-8.4-0.1-16.9-0.1-25.3,0.1
	c-7.5,0.2-13.9,5.3-15.9,12.5c-0.7,2.5-0.9,5.1-0.9,7.7c-0.1,21.4,0,42.8,0,64.1c0,1.4,0,2.9,0,4.4c-10.1,0-19.7,0-29.8,0
	c0-1.9,0-3.5,0-5.1c0-51.1,0-102.2,0-153.3c0-12.4-7.3-19.6-19.7-19.7c-7,0-13.9,0-20.9,0c-13.4,0-20.5,7.1-20.6,20.4
	c0,36.8,0,73.6-0.1,110.4c0,2.9,0.8,4.2,3.6,5.4c17,7.2,27.6,19.9,31.3,38c1,5.1,1,10.5,1.4,15.9c1.3,0,2.7,0,4.1,0
	c23.5,0,47,0,70.5,0c18.4,0,36.8,0,55.2,0c1,0,2,0.1,3,0c3.3-0.3,5.6-2.7,5.6-5.8C432.1,359.5,429.8,356.9,426.2,356.8z"/>
<Path fill={color} d="M242.1,323.6c-21.5-0.4-39.2,16.9-39.6,38.6c-0.4,21.4,16.9,39.2,38.6,39.6c21.5,0.4,39.2-16.9,39.6-38.6
	C281.1,341.7,263.8,324,242.1,323.6z M263.7,378.5c-0.1,3.4-2.6,5.3-6,5.3c-6.7,0.1-13.4,0.1-20.1,0c-3.7-0.1-6-2.5-6-5.8
	c0-3.3,2.2-5.6,5.8-6c1.6-0.1,3.2,0,5.6,0c-1-1.3-1.5-2-2.1-2.6c-5.6-5.6-11.2-11.2-16.8-16.9c-3.2-3.2-3.5-6.5-1-9.1
	c2.6-2.7,6.2-2.4,9.4,0.8c6.2,6.2,12.4,12.4,19.5,19.5c0-2.9-0.1-4.6,0-6.3c0.2-3.3,2.5-5.7,5.6-5.8c3-0.1,6,2.1,6.1,5.3
	C264,364.1,264,371.3,263.7,378.5z"/>
</Svg>
                    );
                }