import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M442.5,228.9c-6.9-27.4-19.9-53.5-38.8-77.7c0.9-0.9,1.8-1.8,2.8-2.7c4.2-4.1,8.6-8.4,12.8-12.7c8.8-9.2,9.5-22.1,1.6-31.5
		c-4.6-5.4-10.8-8.4-17.6-8.4c-6.1,0-12,2.5-16.7,7c-4.4,4.2-8.6,8.6-12.8,12.9c-0.9,0.9-1.8,1.8-2.7,2.8
		c-15.5-12.2-32.1-22.1-49.5-29.3c-17.3-7.2-36-12-55.4-14.3V46.4c0.2,0,0.4,0,0.6,0c1.7,0,3.4,0,5.1,0c2.1,0,4.1,0,6.1,0
		c12.6-0.1,22.1-7.7,24.1-19.4c1.9-11.3-4-21.1-15.6-25.8c-0.2-0.1-0.3-0.3-0.5-0.4L284.8,0h-1.5h-80.9H201l-1.2,0.8
		c-1,0.7-2.2,1.4-3.4,2.1c-3,1.8-6.1,3.7-8.6,6.6c-5.7,6.7-6.6,15.3-2.4,23.8c4.1,8.2,11.1,12.7,20.3,13c2.1,0.1,4.3,0.1,6.9,0.1
		c1.8,0,3.6,0,5.5,0c0.3,0,0.7,0,1,0v28.3c-1.5,0.3-3,0.5-4.5,0.7c-6,1-12.3,2-18.4,3.6c-41,10.8-74.8,29.3-100.5,55.1
		C69.6,160.3,51.6,194.2,42.2,235C32,279,36.3,323.6,54.5,363.9c18.1,39.9,48.7,72.9,88.4,95.5c25.2,14.3,52.9,22.7,82.5,25
		c0.4,0,0.9,0.2,1.5,0.4c0.4,0.1,0.9,0.2,1.3,0.3l0.5,0.1h0.5h26h0.4l0.3-0.1c3.2-0.5,6.4-0.9,9.5-1.4c6.9-0.9,14-1.9,20.9-3.4
		c19.8-4.1,38.9-11.3,56.8-21.4c17.5-9.8,33.5-22.2,47.5-36.7c14-14.5,25.8-30.9,35-48.6c9.4-18.2,15.9-37.4,19.2-57.2
		C450.3,285.9,449.4,256.5,442.5,228.9z M402.2,279.8c-0.3,87.7-71.8,159-159.4,159h-0.1c-21.5,0-42.3-4.2-62-12.6
		c-19-8-36-19.6-50.7-34.2c-14.6-14.7-26.1-31.7-34.2-50.7c-8.3-19.6-12.5-40.5-12.5-62c0-21.4,4.2-42.2,12.6-61.9
		c8-19,19.6-36,34.2-50.6c14.6-14.7,31.7-26.2,50.6-34.2c19.6-8.3,40.4-12.5,61.7-12.5l0.5,0c21.5,0.1,42.3,4.3,62,12.7
		c19,8.1,36,19.7,50.7,34.4c14.6,14.7,26.1,31.8,34.1,50.7C398.1,237.5,402.2,258.3,402.2,279.8z"/>
	<Path fill={color} d="M324.7,185.2c-3.2-1.3-6.3-2-9.4-2h0c-6,0-11.6,2.5-16.5,7.4c-29.2,29-51.3,51.2-71.9,72c-9.5,9.6-9.8,23.9-0.6,33.2
		c4.4,4.5,10.2,6.9,16.4,6.9c6.2,0,12.2-2.5,16.9-7.2c22-21.8,47.5-47.2,72.2-72.4c2.8-2.8,4.4-6.3,5.9-9.7c0.6-1.4,1.2-2.7,1.9-3.9
		l2.2-4.1l-3-1.6C337.8,195.6,332.4,188.4,324.7,185.2z"/>
</G>
</Svg>
                    );
                }