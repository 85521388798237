import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M200.1,93.5c-14.9,2.9-25.2,14.9-25.6,28.6c-0.4,13.8-0.4,27.7-0.2,41.5c0.3,20.6,8.4,37.7,23.9,51.4
		c26.9,23.8,70.3,21.3,94.5-5.3c12.5-13.8,18.4-30,18.4-48.6c0-22.4,0-44.9,0-67.3c0-10.8-2.9-14.9-12.8-18.6c-1.7,0-3.5,0-5.2,0
		c-0.7,0.2-1.4,0.5-2.2,0.7C260.6,81.7,230.4,87.6,200.1,93.5z"/>
	<Path fill={color} d="M355.1,275.5c-0.9-13.5-12.8-26.1-26.3-27c-10.1-0.7-20.2-0.5-30.4-0.6c-6.7,0-11.1,3.6-12.6,10.1
		c-2.9,12.2-5.7,24.4-8.6,36.5c-2.6,11-5.1,22-8,33c-1.3,5.1-4.3,9.3-9.2,12.8c1.6-9.6,1.1-18.3-1.2-26.9
		c-3.4-12.6-6.6-25.3-9.8-37.9c-0.4-1.6-0.2-3.7,0.4-5.3c2-4.9,4.4-9.7,6.5-14.6c1.8-4.1-0.5-7.6-4.9-7.7c-5.7-0.1-11.3-0.1-17,0
		c-4.2,0.1-6.3,3.6-4.8,7.6c0.4,1,0.8,2.1,1.4,3c6.2,9.4,6.3,19,2.7,29.6c-3.8,11.2-6.2,22.9-8.4,34.5c-1,5.5-0.2,11.4-0.2,17.4
		c-4.2-3.1-7.1-7.1-8.5-12c-1.9-6.5-3.4-13.1-4.9-19.6c-4-16.8-7.9-33.7-11.9-50.5c-1.4-6-5.6-9.8-11.4-9.9
		c-9.2-0.2-18.3-0.1-27.5,0c-2.5,0-5,0.4-7.4,1.1c-14.8,4.2-23.3,15.5-23.3,31.5c-0.1,38.7,0,77.4,0,116.1c0,9,4.5,13.4,13.5,13.4
		c66.3,0,132.5,0,198.8,0c1.4,0,2.9,0,4.2-0.3c6.1-1.4,9.2-5.9,9.2-13.6c0-36.8,0-73.7,0-110.5C355.5,282.3,355.3,278.9,355.1,275.5
		z"/>
</G>
</Svg>
                    );
                }