import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M47.5,14.3l414.3,204.8c19.3,9.9,19.3,38,0,47.9L47.5,471.3c-19.3,9.9-41.8-7.5-37.1-29.1L45.6,274l259.3-26.8
	c7.5-0.5,7.5-8.5,0-9.4L44.7,211.1L9.4,43C5.7,21.4,26.8,4,47.5,14.3z"/>
</Svg>
                    );
                }