import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 417 417"  >
<Path fill={color} d="M171.8,400.1c-8.2-4.9-9.4-7.9-7-17.6c10.7-42.5,21.4-85,32.1-127.5c0.1-0.4,0.1-0.7,0.2-1.7c-1.5,0-2.9,0-4.3,0
	c-28.1,0-56.1,0-84.2,0c-6.3,0-10.7-3.1-12.1-8.5c-1-3.8,0.3-7,2.3-10.2c20-31.7,40-63.5,60-95.2c24.3-38.6,48.6-77.2,72.9-115.8
	c3.4-5.4,7.8-7.6,12.7-6.6c6.5,1.4,10.2,7.5,8.5,14.3c-4.9,19.7-9.9,39.4-14.8,59.1c-6,23.7-11.9,47.3-17.9,71
	c-0.1,0.6-0.2,1.2-0.4,2.1c1.5,0,2.9,0,4.2,0c27.8,0,55.6,0,83.4,0c5,0,9.2,1.2,11.8,5.7c2.7,4.7,1.5,9-1.2,13.3
	c-44.2,70.1-88.3,140.3-132.6,210.3c-1.8,2.9-5.1,4.9-7.6,7.3C175.8,400.1,173.8,400.1,171.8,400.1z"/>
</Svg>
                    );
                }