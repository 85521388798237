import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M393.2,186.3l39-21.9l0,0l36.8-20.6c11.8-6.6,16-21.5,9.4-33.4c-6.6-11.8-21.5-16-33.4-9.4L393.5,130
		C351,98.5,301.2,80.8,242.7,79.1c-13.9-0.3-27.6,1.2-41.1,3.7C110,100.1,46.8,154.8,2.4,234.2c-3.7,6.7-2.7,12,0.7,18.1
		c14,25,30.2,48.2,49.6,68.7L17,341c-11.8,6.6-16,21.5-9.4,33.4c4.5,8,12.8,12.5,21.4,12.5c4.1,0,8.2-1,12-3.1L393.2,186.3
		L393.2,186.3z M193.3,241.9c0.3-26.8,22.7-48.8,49.6-48.6c9.1,0.1,17.6,2.7,24.9,7.1l-74.5,41.8C193.3,242.1,193.3,242,193.3,241.9
		z M308.2,177.8c-16.5-16.6-39.1-27.3-63.6-27.7c-52.5-0.8-94.5,40.9-94.5,93.7c0,7.3,0.9,14.3,2.6,21.1L91.9,299
		c-15.2-14.4-28.2-30.8-39.7-48.4c-3.6-5.6-4.1-9.9-0.3-15.7c38.7-58.3,90.1-98,160.4-110c2.1-0.4,4.3-0.8,6.4-0.9
		c7.9-0.5,15.9-1,25.1-1.5c40.5,1.1,76.2,11.9,107.6,31L308.2,177.8z"/>
	<Path fill={color} d="M482.7,234.3c-6.1-10.6-12.2-21.2-19.1-31.3l-37.9,21.3c16.5,20.9,15,16.5-5.6,43.9c-36,47.9-82.7,80.6-142.6,90.9
		c-30.7,5.3-59.6,4-86.7-3.2l-50.1,28.1c43.1,19.8,89.7,26.9,140.1,18.5c93.2-15.5,157.3-71.4,202.2-152.1
		C486.1,244.8,485.9,239.9,482.7,234.3z"/>
	<Path fill={color} d="M327,279.6L230,334c3.8,0.5,7.7,0.8,11.6,0.8C280.2,335.1,312.6,312.8,327,279.6z"/>
</G>
</Svg>
                    );
                }