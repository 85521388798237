import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M314.3,204.5c-0.1-24.4-20.6-45-45-45.2c-17.8-0.1-35.6-0.1-53.4,0c-24.4,0.2-44.9,20.8-45,45.2c-0.1,20.1-0.2,40.1,0,60.2
		c0.2,14.9,7.3,25.6,21,31.6c2.4,1.1,3,2.3,2.9,4.7c-0.1,14.1-0.1,28.2-0.1,42.2c0,6.7,0,13.5,0,20.2c0,6.9,0,13.7,0,20.6
		c0,15.2,11.2,26.5,26.3,26.5c13.9,0,27.9,0,41.8,0c16.7,0,27.5-10.9,27.5-27.7c0-27.3,0-54.6,0-81.8c0-2.1,0.1-3.5,2.6-4.5
		c14-6,21.2-16.9,21.3-32.1C314.5,244.4,314.4,224.4,314.3,204.5z"/>
	<Path fill={color} d="M242.5,148.7c20.4,0.1,37-16.6,36.9-37.1c-0.1-20.6-16.6-36.9-37.2-36.8c-20.3,0.1-36.7,16.7-36.6,37
		C205.8,132.1,222.2,148.6,242.5,148.7z"/>
</G>
</Svg>
                    );
                }