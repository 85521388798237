import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G id="calendar">
	<Path fill={color} d="M151.518,227.633v30.304c0,8.375-6.776,15.151-15.151,15.151h-30.304c-8.376,0-15.151-6.776-15.151-15.151v-30.304
		c0-8.375,6.775-15.15,15.151-15.15h30.304C144.741,212.483,151.518,219.258,151.518,227.633z M136.366,333.697h-30.304
		c-8.376,0-15.151,6.774-15.151,15.149v30.306c0,8.375,6.775,15.151,15.151,15.151h30.304c8.375,0,15.151-6.776,15.151-15.151
		v-30.306C151.518,340.471,144.741,333.697,136.366,333.697z M257.579,212.483h-30.304c-8.375,0-15.15,6.775-15.15,15.15v30.304
		c0,8.375,6.775,15.151,15.15,15.151h30.304c8.375,0,15.15-6.776,15.15-15.151v-30.304
		C272.731,219.258,265.954,212.483,257.579,212.483z M257.579,333.697h-30.304c-8.375,0-15.15,6.774-15.15,15.149v30.306
		c0,8.375,6.775,15.151,15.15,15.151h30.304c8.375,0,15.15-6.776,15.15-15.151v-30.306
		C272.731,340.471,265.954,333.697,257.579,333.697z M378.793,212.483H348.49c-8.375,0-15.15,6.775-15.15,15.15v30.304
		c0,8.375,6.775,15.151,15.15,15.151h30.305c8.375,0,15.151-6.776,15.151-15.151v-30.304
		C393.946,219.258,387.168,212.483,378.793,212.483z M378.793,333.697H348.49c-8.375,0-15.15,6.774-15.15,15.149v30.306
		c0,8.375,6.775,15.151,15.15,15.151h30.305c8.375,0,15.151-6.776,15.151-15.151v-30.306
		C393.946,340.471,387.168,333.697,378.793,333.697z M106.063,91.269h30.304c8.375,0,15.151-6.777,15.151-15.152V15.509
		c0-8.375-6.776-15.151-15.151-15.151h-30.304c-8.376,0-15.151,6.776-15.151,15.151v60.607
		C90.911,84.491,97.687,91.269,106.063,91.269z M484.856,121.572v303.034c0,33.472-27.137,60.607-60.607,60.607H60.607
		C27.136,485.213,0,458.077,0,424.606V121.572c0-33.47,27.136-60.607,60.606-60.607h15.152v15.152
		c0,16.69,13.597,30.304,30.304,30.304h30.304c16.705,0,30.304-13.612,30.304-30.304V60.964h151.517v15.152
		c0,16.69,13.612,30.304,30.304,30.304h30.304c16.689,0,30.304-13.612,30.304-30.304V60.964h15.15
		C457.719,60.964,484.856,88.102,484.856,121.572z M454.552,182.178c0-16.689-13.583-30.303-30.305-30.303H60.607
		c-16.706,0-30.303,13.613-30.303,30.303v242.428c0,16.722,13.597,30.304,30.303,30.304h363.641
		c16.722,0,30.305-13.582,30.305-30.304V182.178z M348.49,91.269h30.305c8.375,0,15.151-6.777,15.151-15.152V15.509
		c0-8.375-6.777-15.151-15.152-15.151H348.49c-8.375,0-15.15,6.776-15.15,15.151v60.607
		C333.338,84.491,340.115,91.269,348.49,91.269z"/>
</G>
</Svg>
                    );
                }