import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<G id="thumb-up">
		<Path fill={color} d="M10.1,436h84.6V182.3H10.1V436z M475.1,203.5c0-23.3-19-42.3-42.3-42.3H299.7L320.8,64c0-2.1,0-4.2,0-6.3
			c0-8.5-4.2-16.9-8.5-23.3L289,13.3L149.6,152.7c-8.5,6.3-12.7,16.9-12.7,29.6v211.4c0,23.3,19,42.3,42.3,42.3h190.2
			c16.9,0,31.7-10.6,38-25.4l63.4-150.1c2.1-4.2,4.2-10.6,4.2-14.8L475.1,203.5L475.1,203.5C475.1,205.6,475.1,203.5,475.1,203.5z"
			/>
	</G>
</G>
</Svg>
                    );
                }