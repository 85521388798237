import { Footer } from "components";
import { QueryKeys, TenantShowCategoryKeys } from "enums";
import { LinearGradient } from "expo-linear-gradient";
import { useLayoutMargin, useStyle } from "hooks";
import React, { useState } from "react";
import { Platform, ScrollView, View } from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { LivingOverview, MortgageOverview, PolicyOverview } from 'screens/overview';
import { colors } from "styles";
import { QueryUtils } from "utils";
import SignalItems from "./SignalItems";




export default () => {

    const styles = useStyle();
    const { styles: scopedStyles } = scopedResponsiveStyles();
    const margin = useLayoutMargin();

    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const [infoViewLayout, setInfoViewLayout] = useState({ width: '100%', height: '100%' });
    const handleInfoviewLayout = (e) => {
        setInfoViewLayout({
            width: (e.nativeEvent.layout.width - (4 * margin)) / 3,
            height: '100%',
        })
    }

    const infoStyles = [
        scopedStyles('infoView'),
        styles('bigMarginRight'),
        infoViewLayout
    ];

    const scrollWidth = (Math.max(400, Number(infoViewLayout.width)) * 3) + (margin * 4);

    const showPolicies = tenant[TenantShowCategoryKeys.Polissen];
    const showMortgages = tenant[TenantShowCategoryKeys.Hypotheken];
    const showMaps = !showPolicies && !showMortgages;

    return (
        <>
            <ScrollView style={{ flex: 1 }} contentContainerStyle={scopedStyles('container')}>

                <LinearGradient
                    colors={[colors.gradientFrom, colors.gradientTo]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                    style={[{ height: 10 }, scopedStyles('gradientContainer')]}
                    onLayout={handleInfoviewLayout}
                >
                    <ScrollView
                        horizontal
                        showsVerticalScrollIndicator={false}
                        nestedScrollEnabled
                        style={{ width: '100%', height: '100%', paddingBottom: 30 }}
                        contentContainerStyle={[
                            {
                                flexDirection: 'row',
                                width: Platform.OS != 'web' ? (scrollWidth || '120%') : 'auto',
                            },
                            styles('bigPaddingLeft'),
                            styles('bigPaddingVertical')
                        ]}
                    >
                        <LivingOverview style={infoStyles} showMaps={showMaps}/>
                        { showMortgages && <MortgageOverview style={infoStyles} /> }
                        { showPolicies && <PolicyOverview style={infoStyles} /> }
                    </ScrollView>
                </LinearGradient>

                <View style={[scopedStyles('signalItemView'), styles('bigPaddingHorizontal'), styles('bigPaddingBottom')]}>
                    <SignalItems
                        itemStyle={[
                            scopedStyles('signalItem'),
                            (Platform.OS == 'web') && { transition: 'transform 0.2s ease-in-out' }
                        ]}
                        hoveredItemStyle={scopedStyles('signalItemHovered')}
                    />
                </View>

            </ScrollView>
            <Footer style={{ backgroundColor: colors.background }} textStyle={{ color: colors.black }} />
        </>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        container: {
            width: '100%',
            height: 0,
            minHeight: '100%',
            backgroundColor: colors.background
        },
        gradientContainer: {
            position: 'relative',
            flexGrow: 0.7,
            flexShrink: 0.7,
            flexBasis: 0,
            width: '100%',
            minHeight: 450,
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            overflow: "hidden"
        },
        infoView: {
            height: '100%',
            minWidth: 400,
            paddingHorizontal: 30,
            paddingVertical: 20,
            borderRadius: 8,
            backgroundColor: colors.blackTransparent
        },
        signalItemView: {
            flex: 0.32,
            minHeight: 315,
            width: '100%',
            flexDirection: 'row',
            alignItems: "flex-start"
        },
        signalItem: {
            marginTop: -30, //this is a fixed overlap            
            width: '25%',
            height: '100%',
            flex: 1
        },
        signalItemHovered: {
            transform: 'scale(1.015)',
        }
    },
    {
        [DEVICE_SIZES.LARGE_DEVICE]: {
            infoView: {
                minWidth: 400,
            },
            gradientContainer: {
                minHeight: 415,
                flex: 0.7,
            },
            signalItemView: {
                flex: 0.4
            }
        },
        [DEVICE_SIZES.MEDIUM_DEVICE]: {
            infoView: {
                minWidth: 400,
            }
        },
        [DEVICE_SIZES.SMALL_DEVICE]: {
            infoView: {
                minWidth: 235
            }
        }
    }
)