import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M455.9,216.8c-26.9-4.9-53.8-9.9-80.8-14.6c-6.6-1.1-11.9-3.7-16.9-8c-22-18.6-44.4-36.8-66.5-55.4
		c-9.5-8-20.2-11.7-32.6-11.7c-35.1,0.2-70.2,0.3-105.3,0c-16.1-0.2-28.7,5.6-38.9,18c-14.4,17.4-29.3,34.4-43.9,51.7
		c-2.2,2.6-4.3,3.7-7.7,3.6c-9.1-0.3-18.3-0.1-27.4-0.1c-20.5,0.1-35.5,15-35.6,35.5c-0.1,13.5-0.1,26.9,0,40.4
		c0.1,27.6,23.2,47.1,50.7,42.9c-6.3-40.7,24.2-70.6,56.7-72.6c16.9-1,32.1,3.9,44.7,15.2c17.2,15.4,23.3,34.9,20.2,57.5
		c52.1,0,103.8,0,155.5,0c-6.3-39.1,22.3-70.6,57.3-72.8c34.4-2.2,71,27.7,64.4,73c1.4,0,2.9,0.1,4.2,0
		c17.3-1.9,30.6-16.3,30.9-33.7c0.2-11.4,0.1-22.8,0-34.1C484.8,233.7,473.4,220,455.9,216.8z M169.4,189c-0.1,6.7-5,11.2-11.9,11.3
		c-8.2,0.1-16.3,0-24.5,0c-7.8,0-15.7,0.3-23.5-0.1c-2.5-0.1-6.1-1.4-7.1-3.3c-0.9-1.8,0.2-5.6,1.8-7.5c10.3-12.5,20.9-24.7,31.3-37
		c2.9-3.4,6.4-5.1,10.9-5c3.8,0.1,7.7-0.1,11.5,0.1c6.5,0.3,11.2,4.8,11.4,11.3C169.6,168.9,169.6,178.9,169.4,189z M227.7,242.6
		c-2.7,0.2-5.4,0-8.1,0c0,0,0,0,0,0c-2.9,0-5.8,0.2-8.6,0c-6.1-0.5-10.7-5.6-10.6-11.5c0-6,4.6-11,10.8-11.4
		c5.6-0.3,11.2-0.3,16.8,0c6.2,0.3,10.8,5.3,10.9,11.3C238.8,237.1,234.1,242.2,227.7,242.6z M323.3,197c-1.4,3.6-4.5,3.5-7.7,3.5
		c-17.8,0-35.6,0-53.4,0c0-0.1,0-0.1,0-0.2c-17.5,0-34.9,0-52.4,0c-8.9,0-13.3-4.4-13.4-13.2c0-8.8,0-17.6,0-26.5
		c0-8.6,4.5-13.2,13-13.2c17.5-0.1,34.9-0.2,52.4,0.1c3.3,0.1,7.1,1.3,9.6,3.3c16.3,12.5,32.3,25.3,48.4,38
		C322.6,191,324.7,193.3,323.3,197z"/>
	<Path fill={color} d="M112.1,258.1c-27.4-0.2-50.1,22.2-50.3,49.5c-0.2,27.6,21.9,50.2,49.2,50.5c27.8,0.3,50.6-22,50.8-49.5
		C161.9,280.8,139.8,258.3,112.1,258.1z M111.9,331.2c-12.8,0-23.2-10.4-23.2-23.2c0.1-12.6,10.4-22.9,22.9-22.9
		c12.8-0.1,23.2,10.4,23.2,23.2C134.8,320.7,124.4,331.2,111.9,331.2z"/>
	<Path fill={color} d="M388.8,258.1c-27.4-0.1-50,22.4-50,49.7c-0.1,27.8,22.2,50.2,50,50.3c27.4,0.1,50-22.4,50.1-49.7
		C438.8,280.6,416.5,258.2,388.8,258.1z M389.1,331.2c-12.8,0.1-23.4-10.2-23.4-23c0-12.6,10.2-23,22.7-23.2
		c12.8-0.2,23.3,10.2,23.4,23C412,320.5,401.6,331.1,389.1,331.2z"/>
</G>
</Svg>
                    );
                }