import { Icons } from "assets/icons";
import { AuthUser } from "models/UserProfile";
import { useEffect, useRef, useState } from "react";
import { Image, Platform, Pressable, View } from "react-native";
import { colors } from "styles";
import { AuthUtils } from "utils";
import SText from "./SText";

export default ({ handleAccountChange, handleAccountDelete }: { handleAccountChange: (id: string) => void, handleAccountDelete: () => void }) => {

    const [users, setUsers] = useState<[string, AuthUser][]>([]);
    const currentUserId: any = useRef(null);

    useEffect(() => {
        const getUsers = async () => {
            currentUserId.current = await AuthUtils.getCurrentUserId();
            const users = await AuthUtils.getUsers();
            setUsers(Object.entries(users));
        }
        getUsers();
    }, []);

    const removeAccount = async (id: string) => {
        const users = await AuthUtils.getUsers();
        if (users[id]) {
            delete users[id];
            setUsers(Object.entries(users));
            await AuthUtils.storeUsers(users);
            handleAccountDelete && handleAccountDelete();
        }
    }

    const renderAvatar = (avatar) => {
        return avatar ? (
            <Image
                source={{ uri: `data:image/png;base64,${avatar}` }}
                width={50}
                height={50}
                style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    alignSelf: 'center'
                }}
            />
        ) : (
            <View style={{
                width: 50,
                height: 50,
                alignSelf: 'center'
            }}>
                <Icons.Avatar_male size={50} color={''} />
            </View>
        );
    }

    return (
        <View>
            {users.map(([id, user]: [string, AuthUser]) => (
                <Pressable
                    disabled={id === currentUserId.current}
                    key={id}
                    style={[
                        {
                            flexDirection: "row",
                            backgroundColor: id === currentUserId.current ? colors.blackTransparent : colors.primarySuperTransparent,
                            borderRadius: 12,
                            padding: 12,
                            marginBottom: 30
                        },
                        Platform.OS === 'web' && id !== currentUserId.current && {
                            cursor: 'pointer'
                        }
                    ]}
                    onPress={() => handleAccountChange(id)}
                >
                    <View style={{ flex: 0.25 }}>
                        {renderAvatar(user.avatar)}
                    </View>
                    <View style={{ flex: 0.75, flexDirection: 'row', alignItems: "center" }}>
                        <SText semibold md
                            style={{
                                flex: 1,
                                color: id === currentUserId.current ? colors.white : colors.primary,
                            }}
                        >
                            {user.name}
                        </SText>
                        <Pressable
                            disabled={id === currentUserId.current}
                            onPress={(e) => {
                                removeAccount(id);
                                e.stopPropagation();
                            }}
                            style={{
                                width: 28,
                                height: 28,
                                borderRadius: 999,
                                backgroundColor: id === currentUserId.current ? colors.blackTransparent : colors.primaryLessTransparent,
                                justifyContent: "center",
                                alignItems: 'center'
                            }}
                        >
                            <Icons.Delete_trash color={colors.white} size={14} />
                        </Pressable>
                    </View>
                </Pressable>
            ))}
        </View>
    )
}