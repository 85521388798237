import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M430,162.7l-12.9-2.4L398.8,56.2l0,0C393.5,27,368,6,339.1,6c-3.4,0-7.1,0.3-10.8,1.1l0,0L57.2,54.9l0,0
		c-14.7,2.4-27.3,10.3-36.3,20.8S7,99.8,7,114.3c0,3.7,0.3,7.1,1.1,10.8l0,0l45,254.5c0.3,1.1,0.5,1.8,1.1,2.6
		c4.7,23.9,23.7,43.6,49.2,48.1l271.1,47.9c3.7,0.5,7.1,1.1,10.5,1.1c28.9,0,54.7-20.8,59.7-50.2l34.4-195.9
		c0.5-3.7,1.1-7.1,1.1-10.5C480.3,193.4,459.5,167.9,430,162.7z M23,122.4L23,122.4c-0.5-2.6-0.8-5.5-0.8-8.2
		c0-10.8,3.9-21,10.5-28.9s16-13.7,27.1-15.5l0,0L330.9,22l0,0c2.6-0.5,5.5-0.8,8.2-0.8c21.6,0,40.8,15.8,44.7,37.6l0,0l17.4,98.6
		l-242.4-42.6c-3.7-0.5-7.1-1.1-10.5-1.1c-28.9,0-54.4,20.8-59.7,50.2L59.6,328.3L23,122.4z M464.2,230.2L432.7,409l-15.5-22.1
		c-8.4-12.1-22.1-18.7-36-18.7c-8.7,0-17.6,2.6-25.2,7.9c-5,3.4-10.8,5.3-16.3,5.3c-8.9,0-17.9-4.2-23.4-12.1l-78.4-112
		c-8.4-12.1-22.1-18.7-36-18.7c-8.7,0-17.6,2.6-25.2,7.9l-99.4,69.7l26.3-149.3c3.9-22.1,23.1-37.6,44.7-37.6c2.6,0,5.3,0.3,7.9,0.8
		L408,174.2c0.8,0.3,1.8,0.5,2.6,0.5l16.6,2.9c22.1,3.9,37.6,23.1,37.6,44.7C465,225,464.7,227.6,464.2,230.2z"/>
	<Path fill={color} d="M363.5,218.1c-2.4-0.5-4.7-0.5-7.1-0.5c-19.5,0-36.8,13.9-40.5,33.9c-0.5,2.4-0.5,4.7-0.5,7.1c0,19.5,13.9,36.8,33.9,40.5
		c2.4,0.5,4.7,0.5,7.1,0.5c19.5,0,36.8-13.9,40.5-33.9c0.5-2.4,0.5-4.7,0.5-7.1C397.4,238.9,383.2,221.6,363.5,218.1z"/>
</G>
</Svg>
                    );
                }