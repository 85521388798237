import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<G>
	<Path fill={color} d="M417.93,305.53c-12.317-18.244-18.563-36.989-18.563-55.711v-43.552c0-73.038-44.171-130.242-113.223-147.734v-9.95
		c0-19.838-16.139-35.974-35.975-35.974h-15.125c-19.836,0-35.974,16.136-35.974,35.974v10.002
		c-69.051,18.006-113.223,75.2-113.223,147.682v43.552c0,18.905-6.397,38.136-18.517,55.644c-6.179,8.988-9.445,19.546-9.445,30.533
		v3.665c0,29.945,24.363,54.31,54.309,54.31h260.824c29.945,0,54.309-24.364,54.309-54.31v-3.665
		C427.327,325.009,424.061,314.451,417.93,305.53z"/>
	<Path fill={color} d="M242.835,473.559c32.514,0,58.296-26.348,58.859-58.86H183.98C184.54,447.211,210.326,473.559,242.835,473.559z"/>
</G>
</Svg>
                    );
                }