import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<G>
	<Path fill={color} d="M461.3,207.3c0-29.7,0.1-60.4-0.1-90.6c0-7-0.6-12.7-1.8-17.8c-0.2-0.9-0.4-1.7-0.7-2.5l0-0.1l0,0l0-0.1
		c-0.1-0.3-0.2-0.6-0.2-0.8c-4.1-15-12.6-27.8-24.6-37.2c-0.5-0.4-1-0.8-1.6-1.2l-0.1-0.1l-0.2-0.1c-0.1-0.1-0.2-0.2-0.4-0.3
		c-4.9-3.6-10.4-6.5-16.1-8.7c-4-1.6-8.2-2.8-12.5-3.7c-1.2-0.3-2.5-0.5-3.8-0.8c-0.6-0.1-1.2-0.3-1.8-0.4l-0.5-0.1h-0.5h-37.1
		c0-6.1,0-12.1,0-20.9c0-11.2-7.3-20.1-17.6-21.7c-1.2-0.2-2.4-0.3-3.6-0.3c-11.9,0-21,9.3-21.1,21.6c-0.1,8.9-0.1,15.1-0.1,21.3
		h-53.1c0-6.1,0-12.1,0-20.9c0-11.2-7.3-20.1-17.6-21.7C245,0.1,243.7,0,242.6,0c-11.9,0-21,9.3-21.1,21.6
		c-0.1,8.9-0.1,15.1-0.1,21.3c-4.1,0-8.1,0-12.2,0c-14,0-27.6,0-40.9,0c0-6.1,0-12.1,0-20.9c0-11.2-7.3-20.1-17.6-21.7
		C149.4,0.1,148.2,0,147,0c-11.9,0-21,9.3-21.1,21.6c-0.1,8.9-0.1,15.1-0.1,21.3c-9.7,0-19.2,0-28.7,0c-5.4,0-10.5,0.5-15.1,1.4
		c-34.6,7-57.9,35.8-58,71.7c-0.1,67.9-0.2,136.4-0.1,203.5l0,1.1c0,29.7-0.1,60.4,0.1,90.6c0,7,0.6,12.7,1.8,17.8
		c0.2,0.9,0.4,1.7,0.6,2.5l0,0.1l0,0l0,0.1c0.1,0.3,0.2,0.6,0.2,0.8c4.1,15,12.6,27.8,24.6,37.2c0.5,0.4,1,0.8,1.6,1.2L53,471
		l0.2,0.1c0.1,0.1,0.2,0.2,0.4,0.3c4.9,3.6,10.4,6.5,16.1,8.7c4,1.6,8.2,2.8,12.5,3.7c1.2,0.3,2.5,0.5,3.8,0.8
		c0.6,0.1,1.2,0.3,1.8,0.4l0.5,0.1h0.5h67.8h1.3l0,0c11.4,0,27.1,0,45.1,0c63.9,0,149,0,185-0.1c5.4,0,10.5-0.5,15.1-1.4
		c34.6-7,57.9-35.8,58-71.7c0.1-68,0.2-136.5,0.1-203.5L461.3,207.3z M418.8,330c0,26.5,0,53.1,0,79.6c0,1.7,0,3.1-0.1,4.4
		c-1.2,17.2-13.5,28.7-30.4,28.8c-29.7,0-74.7,0.1-120.5,0.1c-73.1,0-135.1-0.1-169.9-0.2c-5.6-0.2-10.8-1.3-14.7-3.2
		c-11.3-5.4-16.8-15.1-16.8-29.7c0.1-70.6,0.1-142.4,0.1-211.8c0-26.5,0-53.1,0-79.6c0-1.7,0-3.1,0.1-4.4C67.7,96.9,80,85.3,97,85.3
		c8.5,0,18.2,0,28.9,0c0,5.7,0,11.4,0,19.7c0,11.2,7.3,20.1,17.6,21.7c1.2,0.2,2.4,0.3,3.6,0.3c11.9,0,21-9.3,21.1-21.6
		c0.1-8.3,0.1-14.2,0.1-20.1c15.8,0,32.4,0,49.1,0h0.1c1.3,0,2.6,0,3.9,0c0,5.7,0,11.5,0,19.7c0,11.2,7.3,20.1,17.6,21.7
		c1.2,0.2,2.4,0.3,3.6,0.3c11.9,0,21-9.3,21.1-21.6c0.1-8.3,0.1-14.2,0.1-20.1c19,0,36.8,0,53.1,0c0,5.7,0,11.4,0,19.6
		c0,11.2,7.3,20.1,17.6,21.7c1.2,0.2,2.4,0.3,3.6,0.3c11.9,0,21-9.3,21.1-21.6c0.1-8.3,0.1-14.2,0.1-20c10.6,0,20.1,0,28.1,0.1
		c5.6,0.2,10.8,1.3,14.7,3.2c11.3,5.4,16.8,15.1,16.8,29.7C418.8,188.8,418.8,260.6,418.8,330z M30.6,430.4L30.6,430.4L30.6,430.4
		L30.6,430.4z"/>
	<Path fill={color} d="M338.2,191.9c-23.9,0-52.7,0-90.7,0c-21.1,0-42.4,0-62.5,0l-38.8,0c-0.9,0-1.8,0-2.6,0.1c-9.8,1.1-17.4,8.6-18.6,18.2
		c-0.8,6.4,0.9,12.3,4.9,16.8c4.1,4.7,10.2,7.2,17.1,7.2c10.9,0,21,0,32.6,0c6.3,0,12.5,0,19,0c7.2,0,14.7,0,23.2,0h0.5l8.3,0
		l15.3,0l14,0h0.1c3,0,8.1,0,14.3,0c9.7,0,22.2,0,33.7,0c15.7,0,25.5,0,30.1-0.1c11.9-0.1,21-8.6,21.6-20.1c0.3-6-1.7-11.5-5.7-15.7
		C350,194.2,344.4,191.9,338.2,191.9z"/>
	<Path fill={color} d="M230,278.3c-6.4,0-11.8-0.1-17.5-0.1c-3.3,0-6.5,0-10,0c-4.1,0-8.6,0-14.1,0h-0.3c-5.5,0-9.9,0-14,0c-3.5,0-6.7,0-10,0
		c-5.7,0-11.1,0-17.5,0c-11.2,0-20.1,7.3-21.7,17.6c-1,6.5,0.7,12.6,4.7,17.3c4,4.7,9.9,7.4,16.6,7.4c6.6,0,12.2,0.1,18.1,0.1
		c3.4,0,6.6,0,10.2,0c4.2,0,8.7,0,14.4,0h0.1c5.4,0,9.7,0,13.6,0c3.3,0,6.4,0,9.5,0c5.7,0,11.1,0,17.5,0c11.2,0,20.1-7.3,21.7-17.6
		c1-6.5-0.6-12.6-4.6-17.3C242.6,281,236.7,278.4,230,278.3z"/>
</G>
</Svg>
                    );
                }