import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	   viewBox="0 0 485.213 485.213" enableBackground="new 0 0 485.213 485.213"
	 >
<Path fill={color} d="M404.398,110.064l-93.183-93.184c-8.971-9.01-21.17-14.061-33.885-14.024H114.74c-26.481,0-47.95,21.469-47.95,47.95v383.6
	c0,26.482,21.469,47.95,47.95,47.95h255.733c26.482,0,47.949-21.468,47.949-47.95V143.949
	C418.458,131.235,413.408,119.037,404.398,110.064z M309.857,274.693c-6.238,6.243-16.357,6.251-22.604,0.012
	c-0.003-0.002-0.008-0.007-0.013-0.012l-28.65-28.65v73.283c0,8.826-7.157,15.983-15.983,15.983
	c-8.828,0-15.983-7.157-15.983-15.983v-73.283l-28.65,28.65c-6.246,6.243-16.371,6.243-22.616,0c-6.246-6.244-6.246-16.373,0-22.617
	l55.942-55.941c0.691-0.68,1.436-1.293,2.237-1.839l0.717-0.44c0.592-0.361,1.206-0.68,1.841-0.959l0.759-0.32
	c0.866-0.343,1.761-0.61,2.678-0.798l0,0c0.886-0.156,1.78-0.234,2.677-0.239h0.762c0.691-0.046,1.386-0.046,2.075,0h0.8
	c0.759,0.171,1.508,0.398,2.237,0.679h0.479l0,0c0.849,0.356,1.663,0.786,2.438,1.279l0.679,0.481c0.59,0.427,1.151,0.896,1.679,1.4
	l0.399,0.319l55.941,55.942c6.409,6.068,6.684,16.186,0.614,22.597C310.161,274.389,310.012,274.543,309.857,274.693z
	 M306.54,130.723c-8.826,0-15.983-7.155-15.983-15.983V41.456l89.267,89.267H306.54z"/>
</Svg>
                    );
                }