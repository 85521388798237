import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M110,429.2c8,6.3,16.7,11.9,25.7,16.5c10.1,5.1,22.2-1.5,25.1-12.5c2.3-8.8-1-16.4-9.9-21.8c-19.4-11.8-36.4-26.3-50.2-44.3
	C71.4,329.2,58.5,286.2,63,238.6c4.4-46.7,24.7-85.8,59.6-117.1c32.3-29,70.4-44.3,113.7-45.9c36.6-1.3,70.6,7.8,101.7,27.4
	c35.6,22.5,60.6,53.7,74.9,93.2c9.6,26.7,11.9,54.2,8.9,82.3c-3.1,28.4-11.4,54.8-30.2,76.9c-16.9,19.9-38.1,32.2-64.8,32.9
	c-29.2,0.8-53.8-17.4-61.1-44.9c0.9-0.3,1.8-0.7,2.7-1c39-10.7,63.6-42.6,63.7-82.9c0-16.6,0-33.3,0-49.9c0-7-1.1-8.1-8.1-8.1
	c-7.2,0-14.4,0-21.9,0c0-2.1,0-3.6,0-5.2c0-14.9,0.1-29.9,0-44.8c-0.1-10.4-5.7-18-14.5-20c-12.5-2.9-23.3,6-23.4,19.5
	c-0.2,15.2,0,30.4,0,45.6c0,1.5,0,3,0,4.6c-14.6,0-28.6,0-42.9,0c0-1.8,0-3.3,0-4.8c0-14.5,0-29,0-43.5c0-2.4-0.1-4.9-0.6-7.2
	c-2-9.6-11.4-16-21-14.5c-10.1,1.6-16.3,9.2-16.4,20.6c-0.1,14.9,0,29.9,0,44.8c0,1.5,0,3,0,4.9c-8.3,0-16.1,0-23.9,0
	c-4.3,0-6.1,1.7-6.1,5.9c0,19.5-0.6,39,0.3,58.4c1.7,38.6,29.8,70.4,67.8,77.5c4.3,0.8,6,2.5,7,6.7c5.6,22.1,16.6,41.1,34.9,55.1
	c31,23.7,65.4,26.8,101.3,14.2c35.4-12.4,60.1-37.5,76.6-70.7c17.9-36.2,22.6-74.8,18.8-114.5c-4.5-46.9-22.4-88.6-54.3-123.4
	c-52.3-57.1-117.6-80.6-194.3-70.8c-43.3,5.5-81.3,23.8-113.7,53c-37.6,33.8-61.2,75.6-69.8,125.6c-1.4,7.9-2.2,16-3.3,24
	c0,8.8,0,17.6,0,26.4c0.8,6.4,1.5,12.9,2.5,19.3C35.9,346.2,63.8,393.2,110,429.2z"/>
</Svg>
                    );
                }