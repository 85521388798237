import { Svg, Path, Circle, Rect, Line, G } from 'react-native-svg';

export default ({color, size}) => {
                    return (
                        <Svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 485.2 485.2"  >
<Path fill={color} d="M342.2,102c-2.6,0.2-5.7,2.2-7.5,4.3c-8.4,10.3-19.2,15.9-32.3,16c-26.4,0.4-52.9,0.2-79.3,0c-4.1,0-8.2-1.1-12.1-2.3
	c-8.4-2.6-15.1-7.8-20.4-14.8c-3-4-7.2-4.7-10.9-2.1c-3.6,2.5-4.2,6.8-1.6,11c0.8,1.3,1.9,2.6,2.9,3.8c12.3,13.9,28,19.9,46.2,20
	c7.9,0,15.8,0.1,23.6-0.1c2.9,0,4.4,0.7,4.3,4c-0.1,15.2-0.1,30.4,0,45.7c0,2.6-0.8,3.6-3.5,4.1c-12.9,2.4-21,12.3-21.1,25.4
	c-0.1,14,0,28,0,42.1c0,0,0,0-0.1,0c0,2.9,0,5.9,0,8.8c0,11.6-0.1,23.2,0,34.8c0,6.5,2.6,8.9,9.1,9c1.1,0,2.3,0.3,3.2-0.1
	c2.3-1.2,5.1-2.4,6.6-4.4c1.8-2.5,0.7-5.5-1-8.2c-1.3-2-2.4-4.5-2.4-6.8c-0.2-17.5-0.1-35-0.1-52.5c0-6.8,0-13.6,0-20.4
	c0-9,3.5-12.5,12.6-12.7c3.1-0.1,6.1-0.1,9.2,0c8.9,0.1,12.6,3.9,12.6,12.8c0,24.2,0.1,48.3-0.1,72.5c0,2.4-1.2,5.1-2.5,7.1
	c-2.9,4.2-2.1,9.5,2.1,11.8c7.7,4.2,15.7-0.3,15.8-8.9c0-28.3,0.1-56.6-0.1-84.9c-0.1-13.1-8.3-23-21.2-25.4
	c-2.7-0.5-3.5-1.6-3.5-4.2c0.1-15.2,0.1-30.4,0-45.7c0-2.9,1-4,3.9-4c8,0.1,16,0.1,24,0c8.3,0,16.5-1.2,24.3-4.4
	c10.3-4.3,19.2-10.5,25.2-20.1C351.4,108.3,348,101.5,342.2,102z"/>
<Path fill={color} d="M270.8,304.8c0-22.9,0-45.9,0-68.8c0-1.3,0.1-2.7-0.1-4c-0.4-3.9-3.4-6.7-7.1-6.9c-4-0.3-7.3,2.2-8.1,6.2
	c-0.3,1.5-0.2,3.2-0.2,4.8c0,45.9,0,91.8,0,137.7c0,1.2-0.1,2.4,0.2,3.6c0.8,3.9,4.2,6.6,8,6.4c3.9-0.2,6.9-3.2,7.3-7.2
	c0.1-1.6,0.1-3.2,0.1-4.8C270.8,349.3,270.8,327,270.8,304.8z"/>
<Path fill={color} d="M114.4,260c-8.7,0-17.4,0-26.1,0c-8.8,0-17.6,0-26.5,0c-6.5,0-9.5,2.9-9.6,9.5c-0.1,7.9-0.1,15.7,0,23.6
	c0.1,6.5,3.1,9.5,9.6,9.5c17.5,0.1,35,0.1,52.6,0c6.6,0,9.5-3,9.5-9.6c0-7.9,0.1-15.7,0-23.6C123.9,262.8,121.1,260,114.4,260z"/>
<Path fill={color} d="M439.9,143.8c-0.8,12.7,9,25.1,23.7,25.6c0.4,0,0.9,0,1.3,0v-48.8C451.8,120.3,440.8,130.2,439.9,143.8z"/>
<Path fill={color} d="M463.6,169.4c-14.7-0.5-24.5-12.9-23.7-25.6c0.9-13.6,11.9-23.5,25-23.1v-19.1c-17.5-0.2-34.7,10-41.4,28.3
	c-0.7,1.8-1.4,2.3-3.3,2.3c-14.3-0.1-28.6-0.1-42.8,0c-7.9,0-13.5,5.1-13.5,12.2c0,7.2,5.5,12.3,13.4,12.3c9.2,0,18.4,0,27.6,0
	c6.1,0,12.2,0,18.4,0c3.2,10.4,9.5,18.4,18.9,24.5c-1.8,0.7-3.1,1.1-4.3,1.6c-29.3,10.1-51.7,38.9-52.8,69.8
	c-0.8,23.3-0.4,46.7-0.2,70.1c0.1,7.7,3.9,13.5,10.7,17.2c8.2,4.4,17.1,1.7,25.9,2.6c0-1.7,0-2.8,0-4c0.1-11.5-0.4-23.1,0.4-34.6
	c1.4-22.4,20.7-39.8,43.2-39.8v-94.5C464.5,169.4,464,169.4,463.6,169.4z"/>
<Path fill={color} d="M459.6,282.7c-9.6,1.7-18.5,10.4-19.2,20.1c-0.7,9.5-0.1,19.1-0.1,28.6c-0.1,0-0.3,0-0.4,0c0,8.2-0.1,16.4,0,24.5
	c0.2,14.6,11.1,25,25.1,24.8v-98.3C463.1,282.3,461.4,282.4,459.6,282.7z"/>
<Path fill={color} d="M114.4,260c-8.7,0-17.4,0-26.1,0c-8.8,0-17.6,0-26.5,0c-6.5,0-9.5,2.9-9.6,9.5c-0.1,7.9-0.1,15.7,0,23.6
	c0.1,6.5,3.1,9.5,9.6,9.5c17.5,0.1,35,0.1,52.6,0c6.6,0,9.5-3,9.5-9.6c0-7.9,0.1-15.7,0-23.6C123.9,262.8,121.1,260,114.4,260z"/>
<Path fill={color} d="M170,176.3c-2.6-7.5-10.5-10-20-7.5c-5.7,1.5-11.3,3.1-17.1,4.7c-0.7-1.5-1.2-2.7-1.7-3.9c-5.7-14-11.4-28-17.2-41.9
	c-6.6-15.8-21.9-26-39-26c-18.2,0-36.5,0-54.7,0v15.9c15.4,0,30.8,0,46.2,0.1c10.7,0,18.6,5.4,22.7,15.2
	c9.9,23.4,19.4,46.9,28.9,70.4c3.8,9.4-0.5,15.3-10.9,15.3c-29,0-58,0-87.1,0v121.9c25.5,0,51,0,76.5,0c0,7.7-0.1,15,0,22.4
	c0.2,12.5,8.3,20.6,20.8,20.7c7.3,0.1,14.5,0,21.8,0c14.8,0,22.7-7.9,22.7-22.6c0-29.7,0.1-59.3,0-89c-0.1-14.6-2.5-28.9-7-42.8
	c-1.5-4.8-3.3-9.4-5.1-14.4c1.6-0.2,2.7-0.3,3.9-0.4c6.9-0.7,12.4-4,15.3-10.2C173.4,195.2,173.2,185.5,170,176.3z M124,293.1
	c0,6.5-2.9,9.5-9.5,9.6c-17.5,0.1-35,0.1-52.6,0c-6.5,0-9.5-3-9.6-9.5c-0.1-7.9-0.1-15.7,0-23.6c0.1-6.6,3.1-9.5,9.6-9.5
	c8.8,0,17.6,0,26.5,0c8.7,0,17.4,0,26.1,0c6.7,0,9.5,2.9,9.5,9.5C124,277.3,124,285.2,124,293.1z"/>
</Svg>
                    );
                }