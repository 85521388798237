import { Icons } from "assets/icons";
import { Pressable } from "react-native"
import { colors } from "styles";


export default ({ onPress, style }: { onPress: (e: any) => void, style?: any }) => {
    return (
        <Pressable
            style={[{
                position: 'absolute',
                width: 40,
                height: 40,
                top: -12,
                right: -12,
                zIndex: 99999999,
                borderRadius: 8,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.action
            }, style]}
            onPress={onPress}
        >


            <Icons.Delete size={15} color={colors.white} />

        </Pressable>
    )
}